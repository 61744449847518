/**
 * @generated SignedSource<<c3018a7ab04574b173737bbad21c9ff5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BillingCodeFormFragment$data = {
  readonly billingCodes: ReadonlyArray<{
    readonly code: number;
    readonly description: Record<string, string>;
    readonly id: string;
    readonly microDescription: Record<string, string> | null | undefined;
  }>;
  readonly " $fragmentType": "BillingCodeFormFragment";
};
export type BillingCodeFormFragment$key = {
  readonly " $data"?: BillingCodeFormFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeFormFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillingCodeFormFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "billingCodeCategory": {
              "eq": "accessories"
            }
          }
        }
      ],
      "concreteType": "BillingCode",
      "kind": "LinkedField",
      "name": "billingCodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "microDescription",
          "storageKey": null
        }
      ],
      "storageKey": "billingCodes(where:{\"billingCodeCategory\":{\"eq\":\"accessories\"}})"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "dd0c7291f28f4dcc0a08820eee41b91d";

export default node;
