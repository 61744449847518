import graphql from 'babel-plugin-relay/macro';
import { DetailsLayout, SidebarContentProps } from '../../layout/Layouts';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import { Suspense, useCallback } from 'react';
import { ElementNotFoundErrorBoundary } from '../../layout/ElementNotFoundErrorBoundary';
import { DataID, useFragment, useLazyLoadQuery } from 'react-relay';
import { FormSectionSkeleton } from '../../layout/components/loaders/FormSectionSkeleton';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { ElementNotFoundError } from '../../common/exceptions/ElementNotFoundError';
import { RequireAdmin, UnauthorizedFallback } from '../../auth/Authorization';
import { ConfigurationPageQuery$data } from '../__generated__/ConfigurationPageQuery.graphql';
import { NavigationMenu } from '../../layout/SidebarDrawer';
import { ErrorBanner } from '../../common/components/ErrorBanner';
import { FormSectionContent, FormSectionHeader, FormSectionHeader_Subtitle } from '../../layout/FormLayout';
import { FormProvider } from '../../common/utils/forms';
import {
  rateStrategyFormContext,
  useFieldRateStrategyCategories,
  useRateStrategyFieldEnglishLabel,
  useRateStrategyFieldFrenchLabel,
} from './RateStrategyFields';
import { RateStrategyDetailsPageFragment$key } from './__generated__/RateStrategyDetailsPageFragment.graphql';
import { RateStrategyDetailsPageQuery } from './__generated__/RateStrategyDetailsPageQuery.graphql';
import { RateStrategySaveButton } from './RateStrategySaveButton';
import { RateStrategyDeleteButton } from './RateStrategyDeleteButton';

const flagName = 'app_navigation_configuration';

export function RateStrategyDetails_Page() {
  const { t } = useAmbientTranslation();
  const $data = useOutletContext<ConfigurationPageQuery$data>();

  return (
    <ElementNotFoundErrorBoundary heading={t('rateStrategies.edit')} flagName={flagName} sidebar$key={$data} detailsLayout$key={$data}>
      <Suspense fallback={<RateStrategyDetailsSkeleton $data={$data} />}>
        <RateStrategyDetails />
      </Suspense>
    </ElementNotFoundErrorBoundary>
  );
}

function RateStrategyDetailsSkeleton({ $data }: { $data: ConfigurationPageQuery$data }) {
  const { t } = useAmbientTranslation();
  const sidebar = useCallback((props: SidebarContentProps) => <NavigationMenu {...props} $key={$data} />, [$data]);

  return (
    <DetailsLayout heading={t('addenda.edit')} flagName={flagName} sidebarProvider={sidebar} $key={$data}>
      <FormSectionSkeleton />
    </DetailsLayout>
  );
}

function RateStrategyDetails() {
  const params = useParams<{ id: string }>();
  const id = params.id ?? 'new';

  const $data = useLazyLoadQuery<RateStrategyDetailsPageQuery>(
    graphql`
      query RateStrategyDetailsPageQuery($id: ID!, $isNew: Boolean!) {
        node(id: $id) @skip(if: $isNew) {
          ... on RateStrategyLookup {
            ...RateStrategyDetailsPageFragment
          }
        }
      }
    `,
    { id, isNew: id === 'new' },
    { fetchPolicy: 'store-and-network' },
  );

  if (id !== 'new' && $data.node == null) {
    throw new ElementNotFoundError('RateStrategyLookup', id, '/configuration/rate-strategies');
  }

  return (
    <FormProvider key={id} context={rateStrategyFormContext}>
      <RateStrategyForm $key={$data.node} />;
    </FormProvider>
  );
}

function RateStrategyForm({ $key }: { $key: RateStrategyDetailsPageFragment$key | null | undefined }) {
  const { t } = useAmbientTranslation();
  const params = useParams<{ id: string }>();
  const $data = useOutletContext<ConfigurationPageQuery$data>();
  const navigate = useNavigate();

  const rateStrategy$data = useFragment(
    graphql`
      fragment RateStrategyDetailsPageFragment on RateStrategyLookup {
        id
        ...RateStrategyFieldsEnglishLabelFragment
        ...RateStrategyFieldsFrenchLabelFragment
        ...RateStrategyFields_useFieldRateStrategyCategoriesFragment
      }
    `,
    $key,
  );

  const heading = params.id === 'new' ? t('rateStrategies.new') : t('rateStrategies.edit');

  const sidebar = useCallback((props: SidebarContentProps) => <NavigationMenu {...props} $key={$data} />, [$data]);
  const { renderRateStrategyFrenchLabel } = useRateStrategyFieldFrenchLabel(rateStrategy$data);
  const { renderRateStrategyEnglishLabel } = useRateStrategyFieldEnglishLabel(rateStrategy$data);
  const { renderRateStrategyCategories } = useFieldRateStrategyCategories(rateStrategy$data);
  return (
    <RequireAdmin
      $key={$data}
      fallback={
        <DetailsLayout heading={heading} flagName={flagName} sidebarProvider={sidebar} $key={$data}>
          <UnauthorizedFallback onButtonClick={() => navigate('/')} />
        </DetailsLayout>
      }>
      <DetailsLayout
        heading={heading}
        flagName={flagName}
        sidebarProvider={sidebar}
        $key={$data}
        actions={<RateStrategyCommands id={rateStrategy$data?.id ?? null} />}>
        <ErrorBanner />

        <FormSectionHeader label={<FormSectionHeader_Subtitle label={t('rateStrategies.label')} />} />
        <FormSectionContent>
          {renderRateStrategyEnglishLabel()}
          {renderRateStrategyFrenchLabel()}
        </FormSectionContent>

        <FormSectionHeader label={<FormSectionHeader_Subtitle label={t('rateStrategies.categories')} />} />
        <FormSectionContent>{renderRateStrategyCategories()}</FormSectionContent>
      </DetailsLayout>
    </RequireAdmin>
  );
}

function RateStrategyCommands({ id }: { id: DataID | null }) {
  return (
    <>
      {id && <RateStrategyDeleteButton id={id} />}
      <RateStrategySaveButton id={id} />
    </>
  );
}
