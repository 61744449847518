/**
 * @generated SignedSource<<d630a07bef7d7fb609463fc1a26bf855>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaleClientFields_OrderNumberFragment$data = {
  readonly orderNumber: string | null | undefined;
  readonly " $fragmentType": "SaleClientFields_OrderNumberFragment";
};
export type SaleClientFields_OrderNumberFragment$key = {
  readonly " $data"?: SaleClientFields_OrderNumberFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleClientFields_OrderNumberFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleClientFields_OrderNumberFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "orderNumber",
      "storageKey": null
    }
  ],
  "type": "ISaleClient",
  "abstractKey": "__isISaleClient"
};

(node as any).hash = "ebf30872821861cac2d7e3825adf2b8a";

export default node;
