/**
 * @generated SignedSource<<f619d2fee3a27b0ff44eaef391da1bab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CraneCapacityAutocompleteQuery$variables = {
  configurationKindCode?: number | null | undefined;
  equipmentKindCode?: number | null | undefined;
  searchTerm?: string | null | undefined;
  vehicleIds?: ReadonlyArray<string> | null | undefined;
};
export type CraneCapacityAutocompleteQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CraneCapacityAutocompleteFragment">;
};
export type CraneCapacityAutocompleteQuery = {
  response: CraneCapacityAutocompleteQuery$data;
  variables: CraneCapacityAutocompleteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "configurationKindCode"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "equipmentKindCode"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchTerm"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "vehicleIds"
},
v4 = {
  "kind": "Variable",
  "name": "configurationKindCode",
  "variableName": "configurationKindCode"
},
v5 = {
  "kind": "Variable",
  "name": "equipmentKindCode",
  "variableName": "equipmentKindCode"
},
v6 = {
  "kind": "Variable",
  "name": "searchTerm",
  "variableName": "searchTerm"
},
v7 = {
  "kind": "Variable",
  "name": "vehicleIds",
  "variableName": "vehicleIds"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "capacity",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CraneCapacityAutocompleteQuery",
    "selections": [
      {
        "args": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "CraneCapacityAutocompleteFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CraneCapacityAutocompleteQuery",
    "selections": [
      {
        "alias": "searchResults",
        "args": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "kind": "Literal",
            "name": "skip",
            "value": 0
          },
          {
            "kind": "Literal",
            "name": "take",
            "value": 25
          },
          (v7/*: any*/)
        ],
        "concreteType": "SearchCraneCapacitiesCollectionSegment",
        "kind": "LinkedField",
        "name": "searchCraneCapacities",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FullTextSearchResultOfCraneCapacity",
            "kind": "LinkedField",
            "name": "items",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CraneCapacity",
                "kind": "LinkedField",
                "name": "result",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "name": "label",
                    "args": null,
                    "fragment": {
                      "kind": "InlineFragment",
                      "selections": [
                        (v8/*: any*/)
                      ],
                      "type": "CraneCapacity",
                      "abstractKey": null
                    },
                    "kind": "RelayResolver",
                    "storageKey": null,
                    "isOutputType": true
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionSegmentInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9642f4adc817bc4bd22dd445cd41a804",
    "id": null,
    "metadata": {},
    "name": "CraneCapacityAutocompleteQuery",
    "operationKind": "query",
    "text": "query CraneCapacityAutocompleteQuery(\n  $searchTerm: String\n  $vehicleIds: [String!]\n  $equipmentKindCode: Int\n  $configurationKindCode: Int\n) {\n  ...CraneCapacityAutocompleteFragment_33KzE8\n}\n\nfragment CraneCapacityAutocompleteFragment_33KzE8 on Query {\n  searchResults: searchCraneCapacities(searchTerm: $searchTerm, vehicleIds: $vehicleIds, equipmentKindCode: $equipmentKindCode, configurationKindCode: $configurationKindCode, skip: 0, take: 25) {\n    items {\n      result {\n        capacity\n        ...CraneCapacityLabelResolver\n      }\n    }\n    pageInfo {\n      hasNextPage\n    }\n  }\n}\n\nfragment CraneCapacityLabelResolver on CraneCapacity {\n  capacity\n}\n"
  }
};
})();

(node as any).hash = "d4d61f0fa32689187d662b31dd590ce1";

export default node;
