import {
  createFieldKey,
  createFormContext,
  SetValueFn,
  useField,
  useFieldErrors,
  useFieldMapper,
  useFieldValidation,
} from '../../common/utils/forms';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { TextField, TextFieldProps } from '@mui/material';
import { useCallback } from 'react';
import { RateStrategyFieldsEnglishLabelFragment$key } from './__generated__/RateStrategyFieldsEnglishLabelFragment.graphql';
import { RateStrategyFieldsFrenchLabelFragment$key } from './__generated__/RateStrategyFieldsFrenchLabelFragment.graphql';
import { SelectPicker } from '../../common/components/SelectPicker';
import {
  castRateStrategyCategory,
  isRateStrategyCategory,
  rateStrategyCategories,
  RateStrategyCategory,
} from '../../__enums__/RateStrategyCategory';
import { RateStrategyFields_useFieldRateStrategyCategoriesFragment$key } from './__generated__/RateStrategyFields_useFieldRateStrategyCategoriesFragment.graphql';

export interface RateStrategyFieldsMappings {}

export const rateStrategyFormContext = createFormContext<RateStrategyFieldsMappings>();

const fieldRateStrategyFrenchLabelKey = createFieldKey<string>();
export function useRateStrategyFieldFrenchLabel($key: RateStrategyFieldsFrenchLabelFragment$key | null | undefined) {
  const { t } = useAmbientTranslation();
  const $data = useFragment(
    graphql`
      fragment RateStrategyFieldsFrenchLabelFragment on RateStrategyLookup {
        description
      }
    `,
    $key,
  );

  const [frenchLabel, setFrenchLabel] = useField(rateStrategyFormContext, fieldRateStrategyFrenchLabelKey, $data?.description['fr'] ?? '');
  const useValidation = useFieldValidation(rateStrategyFormContext, fieldRateStrategyFrenchLabelKey);
  useValidation((v) => v.trim().length > 0, [], 'save:required');

  const handleChange = useCallback<NonNullable<TextFieldProps['onChange']>>(
    (e) => {
      setFrenchLabel(e.target.value);
    },
    [setFrenchLabel],
  );
  const errors = useFieldErrors(rateStrategyFormContext, fieldRateStrategyFrenchLabelKey);

  const useMapper = useFieldMapper(rateStrategyFormContext, fieldRateStrategyFrenchLabelKey);
  useMapper(() => ({ labelFr: frenchLabel.trim() }), [frenchLabel], 'save');

  const renderRateStrategyFrenchLabel = useCallback(
    () => (
      <TextField
        label={t('rateStrategies.labelFr')}
        required={true}
        value={frenchLabel}
        onChange={handleChange}
        error={!!errors['required']}
      />
    ),
    [t, frenchLabel, handleChange, errors],
  );

  return { frenchLabel, setFrenchLabel, renderRateStrategyFrenchLabel };
}

const fieldRateStrategyEnglishLabelKey = createFieldKey<string>();
export function useRateStrategyFieldEnglishLabel($key: RateStrategyFieldsEnglishLabelFragment$key | null | undefined) {
  const { t } = useAmbientTranslation();
  const $data = useFragment(
    graphql`
      fragment RateStrategyFieldsEnglishLabelFragment on RateStrategyLookup {
        description
      }
    `,
    $key,
  );

  const [englishLabel, setEnglishLabel] = useField(
    rateStrategyFormContext,
    fieldRateStrategyEnglishLabelKey,
    $data?.description['en'] ?? '',
  );
  const useValidation = useFieldValidation(rateStrategyFormContext, fieldRateStrategyEnglishLabelKey);
  useValidation((v) => v.trim().length > 0, [], 'save:required');
  const useMapper = useFieldMapper(rateStrategyFormContext, fieldRateStrategyEnglishLabelKey);
  useMapper(() => ({ labelEn: englishLabel.trim() }), [englishLabel], 'save');
  const handleChange = useCallback<NonNullable<TextFieldProps['onChange']>>(
    (e) => {
      setEnglishLabel(e.target.value);
    },
    [setEnglishLabel],
  );
  const errors = useFieldErrors(rateStrategyFormContext, fieldRateStrategyEnglishLabelKey);

  const renderRateStrategyEnglishLabel = useCallback(
    () => (
      <TextField
        label={t('rateStrategies.labelEn')}
        required={true}
        value={englishLabel}
        onChange={handleChange}
        error={!!errors['required']}
      />
    ),
    [t, englishLabel, handleChange, errors],
  );

  return { englishLabel: englishLabel, setEnglishLabel: setEnglishLabel, renderRateStrategyEnglishLabel };
}

type RateStrategyCategories = ReadonlyArray<RateStrategyCategory>;
const fieldRateStrategyCategoriesKey = createFieldKey<RateStrategyCategories>();
export function useFieldRateStrategyCategories($key: RateStrategyFields_useFieldRateStrategyCategoriesFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment RateStrategyFields_useFieldRateStrategyCategoriesFragment on RateStrategyLookup {
        categories
      }
    `,
    $key,
  );

  const [strategyCategoryKinds, setStrategyCategoryKinds] = useField(
    rateStrategyFormContext,
    fieldRateStrategyCategoriesKey,
    () => $data?.categories.filter((k) => isRateStrategyCategory(k)).map((k) => castRateStrategyCategory(k)) ?? rateStrategyCategories,
  );

  const useMapper = useFieldMapper(rateStrategyFormContext, fieldRateStrategyCategoriesKey);
  useMapper((val) => ({ categories: val }), [], 'save');

  const renderRateStrategyCategories = useCallback(
    () => <RateStrategyCategoriesInput value={strategyCategoryKinds} setValueFn={setStrategyCategoryKinds} />,
    [setStrategyCategoryKinds, strategyCategoryKinds],
  );

  return { strategyCategoryKinds, setStrategyCategoryKinds, renderRateStrategyCategories };
}

function RateStrategyCategoriesInput({
  value,
  setValueFn,
}: {
  value: RateStrategyCategories;
  setValueFn: SetValueFn<RateStrategyCategories>;
}) {
  const { t } = useAmbientTranslation();

  return (
    <SelectPicker
      multiple
      value={value}
      onChange={(_, values) => {
        setValueFn(values);
      }}
      options={rateStrategyCategories}
      getOptionKey={(o) => o}
      getOptionLabel={(option) => t(`rateStrategies.rateStrategyCategory.${option}`)}
      textFieldProps={(params) => ({
        ...params,
        label: t('rateStrategies.transportCategoryKinds'),
      })}
    />
  );
}
