/**
 * @generated SignedSource<<1c02e9905794cf69b36e658fb3a4846a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddendumTemplateFieldsQuestionKindsFragment$data = {
  readonly conditions: {
    readonly questionKinds: ReadonlyArray<string>;
  };
  readonly " $fragmentType": "AddendumTemplateFieldsQuestionKindsFragment";
};
export type AddendumTemplateFieldsQuestionKindsFragment$key = {
  readonly " $data"?: AddendumTemplateFieldsQuestionKindsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddendumTemplateFieldsQuestionKindsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddendumTemplateFieldsQuestionKindsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AddendumTemplateConditionsInternal",
      "kind": "LinkedField",
      "name": "conditions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "questionKinds",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AddendumTemplate",
  "abstractKey": null
};

(node as any).hash = "ee540378fec22e41dee7a0bfea723d09";

export default node;
