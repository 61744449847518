/**
 * @generated SignedSource<<add62f1f11bcfd1709320b82eb373ffe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RateStrategyDetailsPageQuery$variables = {
  id: string;
  isNew: boolean;
};
export type RateStrategyDetailsPageQuery$data = {
  readonly node?: {
    readonly " $fragmentSpreads": FragmentRefs<"RateStrategyDetailsPageFragment">;
  } | null | undefined;
};
export type RateStrategyDetailsPageQuery = {
  response: RateStrategyDetailsPageQuery$data;
  variables: RateStrategyDetailsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isNew"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RateStrategyDetailsPageQuery",
    "selections": [
      {
        "condition": "isNew",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "RateStrategyDetailsPageFragment"
                  }
                ],
                "type": "RateStrategyLookup",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RateStrategyDetailsPageQuery",
    "selections": [
      {
        "condition": "isNew",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "categories",
                    "storageKey": null
                  }
                ],
                "type": "RateStrategyLookup",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "45d9f2b936d2f616a4b40314b039bce0",
    "id": null,
    "metadata": {},
    "name": "RateStrategyDetailsPageQuery",
    "operationKind": "query",
    "text": "query RateStrategyDetailsPageQuery(\n  $id: ID!\n  $isNew: Boolean!\n) {\n  node(id: $id) @skip(if: $isNew) {\n    __typename\n    ... on RateStrategyLookup {\n      ...RateStrategyDetailsPageFragment\n    }\n    id\n  }\n}\n\nfragment RateStrategyDetailsPageFragment on RateStrategyLookup {\n  id\n  ...RateStrategyFieldsEnglishLabelFragment\n  ...RateStrategyFieldsFrenchLabelFragment\n  ...RateStrategyFields_useFieldRateStrategyCategoriesFragment\n}\n\nfragment RateStrategyFieldsEnglishLabelFragment on RateStrategyLookup {\n  description\n}\n\nfragment RateStrategyFieldsFrenchLabelFragment on RateStrategyLookup {\n  description\n}\n\nfragment RateStrategyFields_useFieldRateStrategyCategoriesFragment on RateStrategyLookup {\n  categories\n}\n"
  }
};
})();

(node as any).hash = "cb0a754dde7f8a077d2042e34206e9d3";

export default node;
