import {
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  FabProps,
  IconButton,
  List,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  SpeedDial,
  SpeedDialAction,
  SpeedDialActionProps,
  SpeedDialIcon,
  SpeedDialProps,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataAttributes } from '../types/dataAttributes';

export interface AutoSpeedDialProps<T> {
  mainOption?: T;
  options?: readonly T[];
  keySelector: (option: T) => string;
  iconSelector: (option: T) => JSX.Element | undefined;
  labelSelector: (option: T) => string | JSX.Element;
  onOptionClick?: (selectedOption: T) => void;
  componentProps?: {
    root?: Partial<Omit<SpeedDialProps, 'ref'>>;
    fab?: Partial<Omit<FabProps, 'onClick'>>;
    speedDialAction?: Partial<Omit<SpeedDialActionProps, 'onClick'>>;
    speedDialActionOption?: (options: T) => Partial<Omit<SpeedDialActionProps, 'onClick'>>;
    dialog?: { title?: string };
    listItemButtonOption?: (option: T) => Partial<Omit<ListItemButtonProps, 'onClick' | 'divider'> & DataAttributes>;
  };
}

/**
 * Generic speed dial component adapted From Mui. Displays options in a Dialog if options exceed 6
 * @param options Options of the speed dial
 * @param mainOption Main option
 * @param keySelector Selects key of option for translation
 * @param iconSelector Selects icon of option
 * @param labelSelector Selects label of option
 * @param handleOptionClick Click event of option
 * @param componentProps Customizable root, fab, speedDialAction, dialog props
 * @constructor
 */
export function AutoSpeedDial<T>({
  mainOption,
  options = [],
  keySelector,
  iconSelector,
  labelSelector,
  onOptionClick: handleOptionClick,
  componentProps,
}: AutoSpeedDialProps<T>) {
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  const handleOpenDialog = useCallback(() => setOpen(true), []);
  const handleCloseDialog = useCallback(() => setOpen(false), []);

  if (options.length === 0) {
    return (
      <Fab
        onClick={() => mainOption !== undefined && handleOptionClick?.(mainOption)}
        color='primary'
        data-testid='autoSpeedDialFab'
        {...componentProps?.fab}>
        <AddIcon />
      </Fab>
    );
  }

  // According to mui Speed Dial docs (https://mui.com/material-ui/react-speed-dial/): "If more than six actions are needed, something other than a FAB should be used to present them."
  // In this case, main option + 5 options. Above that, display in a dialog
  if (options.length > 5) {
    return (
      <>
        <Fab onClick={handleOpenDialog} color='primary' data-testid='autoSpeedDialFab' {...componentProps?.fab}>
          <AddIcon />
        </Fab>
        <Dialog open={open} onClose={handleCloseDialog}>
          <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <IconButton color='inherit' data-testid='closeButton' onClick={handleCloseDialog} sx={{ ml: '-1rem' }}>
              <CloseIcon />
            </IconButton>
            {componentProps?.dialog?.title ?? t('selectOption')}
          </DialogTitle>
          <DialogContent dividers sx={{ p: 0 }}>
            <List disablePadding>
              {mainOption !== undefined &&
                [mainOption, ...options].map((option) => (
                  <ListItemButton
                    key={keySelector(option)}
                    onClick={() => handleOptionClick?.(option)}
                    divider
                    {...componentProps?.listItemButtonOption?.(option)}>
                    <ListItemIcon>{iconSelector(option)}</ListItemIcon>
                    <ListItemText>{labelSelector(option)}</ListItemText>
                  </ListItemButton>
                ))}
            </List>
          </DialogContent>
        </Dialog>
      </>
    );
  }

  return (
    <SpeedDial icon={<SpeedDialIcon />} {...{ ariaLabel: '', ...componentProps?.root }}>
      {mainOption !== undefined &&
        [mainOption, ...options].map((option) => (
          <SpeedDialAction
            key={keySelector(option)}
            icon={iconSelector(option)}
            tooltipTitle={labelSelector(option)}
            tooltipOpen
            onClick={() => handleOptionClick?.(option)}
            {...componentProps?.speedDialAction}
            {...componentProps?.speedDialActionOption?.(option)}
          />
        ))}
    </SpeedDial>
  );
}
