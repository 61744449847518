/**
 * @generated SignedSource<<7a1906323010cf2a59efcc1651a1064a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ErrorBannerInvalidTransitionErrorFragment$data = {
  readonly __typename: "InvalidJobStageBaseTransitionError";
  readonly typeFrom: string;
  readonly typeTo: string;
  readonly " $fragmentType": "ErrorBannerInvalidTransitionErrorFragment";
};
export type ErrorBannerInvalidTransitionErrorFragment$key = {
  readonly " $data"?: ErrorBannerInvalidTransitionErrorFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ErrorBannerInvalidTransitionErrorFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ErrorBannerInvalidTransitionErrorFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "typeFrom",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "typeTo",
      "storageKey": null
    }
  ],
  "type": "InvalidJobStageBaseTransitionError",
  "abstractKey": null
};

(node as any).hash = "72cddec795ad33a2c1bf9ce091bbf4d0";

export default node;
