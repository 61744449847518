/**
 * @generated SignedSource<<3bcce6e55e5be578f42e22d595249654>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RequirementLabelResolver$data = {
  readonly code: number;
  readonly name: string;
  readonly " $fragmentType": "RequirementLabelResolver";
};
export type RequirementLabelResolver$key = {
  readonly " $data"?: RequirementLabelResolver$data;
  readonly " $fragmentSpreads": FragmentRefs<"RequirementLabelResolver">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RequirementLabelResolver",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Requirement",
  "abstractKey": null
};

(node as any).hash = "cbc4265de1de5d15365e37e56bd5da46";

export default node;
