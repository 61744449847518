/**
 * @generated SignedSource<<38ae113b10a8f7d58b95652f0cb91bf7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BillingCodeRuleFields_SaleStagesFragment$data = {
  readonly conditions: {
    readonly jobConcreteTypes: ReadonlyArray<string>;
  };
  readonly " $fragmentType": "BillingCodeRuleFields_SaleStagesFragment";
};
export type BillingCodeRuleFields_SaleStagesFragment$key = {
  readonly " $data"?: BillingCodeRuleFields_SaleStagesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeRuleFields_SaleStagesFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillingCodeRuleFields_SaleStagesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ConditionsInternal",
      "kind": "LinkedField",
      "name": "conditions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jobConcreteTypes",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BillingCodeRule",
  "abstractKey": null
};

(node as any).hash = "48b3a84f22389a3fd2f192f2a091a274";

export default node;
