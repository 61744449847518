/**
 * @generated SignedSource<<61e8a46c8c56c1a6405393328ccd7a0b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccessoryRuleFields_AutomaticGridFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryRuleFields_BillingCodeCollectionFragment">;
  readonly " $fragmentType": "AccessoryRuleFields_AutomaticGridFragment";
};
export type AccessoryRuleFields_AutomaticGridFragment$key = {
  readonly " $data"?: AccessoryRuleFields_AutomaticGridFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryRuleFields_AutomaticGridFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccessoryRuleFields_AutomaticGridFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccessoryRuleFields_BillingCodeCollectionFragment"
    }
  ],
  "type": "AccessoryRule",
  "abstractKey": null
};

(node as any).hash = "b402a6c7cbf35e0c6f693131b9b8210f";

export default node;
