/**
 * @generated SignedSource<<c754c6b86f93ed3f2a930aea99c65bb8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CostLinesFields_useQuantityRateFromSalesRate_DefaultQuantityFragment$data = {
  readonly defaultQuantity: number | null | undefined;
  readonly isFixedQuantity: boolean;
  readonly " $fragmentType": "CostLinesFields_useQuantityRateFromSalesRate_DefaultQuantityFragment";
};
export type CostLinesFields_useQuantityRateFromSalesRate_DefaultQuantityFragment$key = {
  readonly " $data"?: CostLinesFields_useQuantityRateFromSalesRate_DefaultQuantityFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CostLinesFields_useQuantityRateFromSalesRate_DefaultQuantityFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CostLinesFields_useQuantityRateFromSalesRate_DefaultQuantityFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultQuantity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFixedQuantity",
      "storageKey": null
    }
  ],
  "type": "BillingCode",
  "abstractKey": null
};

(node as any).hash = "f885feea8238a3523502ba4888f62c75";

export default node;
