/**
 * @generated SignedSource<<d2bb25248d63af56ceb4b35cb6de2c8c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ListPageRootErrorBoundaryFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LayoutsListLayoutFragment" | "SidebarDrawerFragment">;
  readonly " $fragmentType": "ListPageRootErrorBoundaryFragment";
};
export type ListPageRootErrorBoundaryFragment$key = {
  readonly " $data"?: ListPageRootErrorBoundaryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ListPageRootErrorBoundaryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ListPageRootErrorBoundaryFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SidebarDrawerFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LayoutsListLayoutFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "d71be21958197d9a46c0f70773ea9584";

export default node;
