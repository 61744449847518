import { useNavigate } from 'react-router';
import { namedPageTitle, usePageTitle } from '../common/hooks/usePageTitle';
import graphql from 'babel-plugin-relay/macro';
import { useNavigatorOnline } from '../common/hooks/useNavigatorOnline';
import { DataID, useFragment, useLazyLoadQuery } from 'react-relay';
import { WorkPlanningList, WorkPlanningListProps } from './WorkPlanningList';
import { Skeleton, Stack, Theme, useMediaQuery, useTheme } from '@mui/material';
import { Suspense, useCallback } from 'react';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { EmptyLayout, ListLayout, ListLayoutActions, SidebarContentProps } from '../layout/Layouts';
import { Add } from '@mui/icons-material';
import { RequireRead, RequireWrite, UnauthorizedFallback } from '../auth/Authorization';
import {
  WorkPlanningListPageContentQuery,
  WorkPlanningListPageContentQuery$variables,
} from './__generated__/WorkPlanningListPageContentQuery.graphql';
import { NavigationMenu } from '../layout/SidebarDrawer';
import { WorkPlanningListPageRootQuery } from './__generated__/WorkPlanningListPageRootQuery.graphql';
import { EmployeeNotFoundError, EmployeeNotFoundErrorBoundary } from '../auth/EmployeeNotFoundErrorBoundary';
import { DateTime } from 'luxon';
import { WorkPlanningListPageContentDefaultFiltersFragment$key } from './__generated__/WorkPlanningListPageContentDefaultFiltersFragment.graphql';
import { ListPageErrorBoundary } from '../layout/ListPageErrorBoundary';
import { ListPageRootErrorBoundary } from '../layout/ListPageRootErrorBoundary';
import { convertToTsQuery } from '../common/utils/stringUtils';
import { useJobFilters } from '../jobs/JobFilters';
import { createSharedStateKey } from '../common/utils/sharedState';
import { WorkPlanningFilters } from './WorkPlanningFilters';
import { useConstantValue } from '../common/hooks/useConstantValue';

const flagName = 'app_navigation_work_plannings';

export function WorkPlanningListPage() {
  const { t } = useAmbientTranslation();

  return (
    <ListPageErrorBoundary heading={t('pages.list.title')} flagName={flagName}>
      <EmployeeNotFoundErrorBoundary>
        <Suspense fallback={<WorkPlanningListPageSkeleton />}>
          <WorkPlanningListPageRoot />
        </Suspense>
      </EmployeeNotFoundErrorBoundary>
    </ListPageErrorBoundary>
  );
}

function WorkPlanningListPageSkeleton() {
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return <EmptyLayout>{compact ? <ListSkeleton /> : <GridSkeleton />}</EmptyLayout>;
}

function WorkPlanningListPageRoot() {
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const $data = useLazyLoadQuery<WorkPlanningListPageRootQuery>(
    graphql`
      query WorkPlanningListPageRootQuery {
        ...AuthorizationReadFragment
        ...AuthorizationWriteFragment
        ...SidebarDrawerFragment
        ...LayoutsListLayoutFragment
        ...ListPageRootErrorBoundaryFragment
        me {
          ...WorkPlanningListPageContentDefaultFiltersFragment
        }
      }
    `,
    {},
  );

  const { t } = useAmbientTranslation();
  usePageTitle(namedPageTitle('sidebar.workPlannings'));

  const theme = useTheme();
  const navigate = useNavigate();

  const handleItemClick = useCallback((id: DataID) => navigate(`/work-plannings/${id}`), [navigate]);
  const sidebar = useCallback((props: SidebarContentProps) => <NavigationMenu {...props} $key={$data} />, [$data]);
  const heading = t('pages.list.title');

  if (!$data.me) throw new EmployeeNotFoundError();

  return (
    <ListPageRootErrorBoundary $key={$data} heading={heading} flagName={flagName}>
      <RequireRead
        $key={$data}
        fallback={
          <ListLayout heading={heading} flagName={flagName} sidebarProvider={sidebar} $key={$data}>
            <UnauthorizedFallback />
          </ListLayout>
        }>
        <ListLayout
          heading={heading}
          flagName={flagName}
          sidebarProvider={sidebar}
          $key={$data}
          actions={
            <RequireWrite $key={$data}>
              <ListLayoutActions
                mainAction={{
                  label: t('button.create', { ns: 'common' }),
                  icon: <Add />,
                  onClick: () => navigate('/work-plannings/new'),
                }}
                componentProps={{
                  autoSplitButton: {
                    root: { 'aria-label': t('createWorkPlanningAction.ariaLabels.root') },
                    buttonMain: { 'aria-label': t('createWorkPlanningAction.ariaLabels.main') },
                  },
                  autoSpeedDial: {
                    fab: {
                      'aria-label': 'createWorkPlanningAction.ariaLabels.main',
                    },
                  },
                }}
              />
            </RequireWrite>
          }>
          <Suspense fallback={compact ? <ListSkeleton /> : <GridSkeleton />}>
            <WorkPlanningListPageContent
              defaultFilters$key={$data.me}
              compact={compact}
              onItemClick={handleItemClick}
              paginationSx={{ [theme.breakpoints.down('sm')]: { mb: '6rem' } }}
            />
          </Suspense>
        </ListLayout>
      </RequireRead>
    </ListPageRootErrorBoundary>
  );
}

const workPlanningFiltersSharedStateKey = createSharedStateKey<WorkPlanningFilters | null>(() => null);

function WorkPlanningListPageContent({
  defaultFilters$key,
  ...listProps
}: {
  defaultFilters$key: WorkPlanningListPageContentDefaultFiltersFragment$key;
} & Omit<WorkPlanningListProps, '$key' | 'filters$key' | 'filters' | 'onFiltersChange'>) {
  const online = useNavigatorOnline();

  const $data = useFragment(
    graphql`
      fragment WorkPlanningListPageContentDefaultFiltersFragment on Employee {
        representativeId
        roles
        ...JobFilters_useJobFiltersFragment
      }
    `,
    defaultFilters$key,
  );

  const salesDefaultFilters = useConstantValue(() => {
    const today = DateTime.now().startOf('day');
    const isSalesRepresentative = $data.roles.includes('salesRepresentative');
    return WorkPlanningFilters.EMPTY.with({
      arrivalDate: { start: today, end: today.plus({ day: 90 }) },
      statuses: ['inWriting', 'worksiteSelected'],
      representatives: isSalesRepresentative && $data.representativeId ? [{ id: $data.representativeId }] : [],
    });
  });

  const [filters, handleFiltersChange] = useJobFilters<WorkPlanningFilters>(
    $data,
    workPlanningFiltersSharedStateKey,
    WorkPlanningFilters.EMPTY,
    salesDefaultFilters,
    (sp) => WorkPlanningFilters.fromSearchParams(sp),
  );

  const queryFilters = useConstantValue<WorkPlanningListPageContentQuery$variables>(() => ({
    first: 25,
    where: filters.toJobRevisionFilter(),
    searchTerm: convertToTsQuery(filters.get('searchTerm'), '|'),
    representativeIds: filters.get('representatives').map(({ id }) => id),
  }));

  const workPlannings = useLazyLoadQuery<WorkPlanningListPageContentQuery>(
    graphql`
      query WorkPlanningListPageContentQuery(
        $first: Int
        $where: WorkPlanningJobRevisionFilterType
        $representativeIds: [ID!]!
        $searchTerm: String
      ) {
        ...WorkPlanningListFragment @arguments(searchTerm: $searchTerm, representativeIds: $representativeIds, first: $first, where: $where)
        ...WorkPlanningListFiltersFragment @arguments(representativeIds: $representativeIds)
      }
    `,
    queryFilters,
    { fetchPolicy: online ? 'store-and-network' : 'store-only' },
  );

  return (
    <WorkPlanningList
      {...listProps}
      $key={workPlannings}
      filters$key={workPlannings}
      filters={filters}
      onFiltersChange={handleFiltersChange}
    />
  );
}

function GridSkeleton() {
  return <Skeleton variant='rounded' height='40rem' />;
}

function ListSkeleton() {
  return (
    <Stack gap={0.5}>
      <Skeleton variant='rounded' height='5.3rem' />
      <Skeleton variant='rounded' height='5.3rem' />
      <Skeleton variant='rounded' height='5.3rem' />
      <Skeleton variant='rounded' height='5.3rem' />
      <Skeleton variant='rounded' height='5.3rem' />
      <Skeleton variant='rounded' height='5.3rem' />
      <Skeleton variant='rounded' height='5.3rem' />
      <Skeleton variant='rounded' height='5.3rem' />
      <Skeleton variant='rounded' height='5.3rem' />
      <Skeleton variant='rounded' height='5.3rem' />
    </Stack>
  );
}
