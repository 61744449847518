import { Box, MenuItem, Typography, useTheme } from '@mui/material';
import TimeAgo from 'react-timeago';
import { DataID, useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import CircleIcon from '@mui/icons-material/Circle';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { NotificationRow_PermalinkFragment$key } from './__generated__/NotificationRow_PermalinkFragment.graphql';
import { NotificationRow_BaseFragment$key } from './__generated__/NotificationRow_BaseFragment.graphql';
import { PropsWithChildren, ReactNode, useCallback, useMemo } from 'react';
import { NotificationRowFragment$key } from './__generated__/NotificationRowFragment.graphql';
import { dateTimeFormat, parseDateTime, timeAgoFormatter } from '../common/utils/dateTimeUtils';
import { DateTime } from 'luxon';
import { NotificationRow_ModificationRequestedFragment$key } from './__generated__/NotificationRow_ModificationRequestedFragment.graphql';
import { toCamelCase } from '../common/utils/stringUtils';
import { NotificationRow_QuoteStatusNotificationFragment$key } from './__generated__/NotificationRow_QuoteStatusNotificationFragment.graphql';

function NotificationRow_Base({
  $key,
  onClick,
  heading,
  children,
}: PropsWithChildren<{
  $key: NotificationRow_BaseFragment$key;
  heading: ReactNode;
  onClick: (id: DataID, isRead: boolean) => Promise<void> | void;
}>) {
  const { i18n } = useTranslation('layout');
  const language = i18n.resolvedLanguage || i18n.languages[0];

  const $data = useFragment(
    graphql`
      fragment NotificationRow_BaseFragment on Notification {
        id
        isRead
        createdAt
      }
    `,
    $key,
  );

  const handleClick = async (id: DataID, isRead: boolean) => {
    await onClick(id, isRead);
  };

  const theme = useTheme();

  return (
    <MenuItem
      id={$data.id}
      divider
      onClick={() => handleClick($data.id, $data.isRead)}
      sx={{
        flexDirection: 'column',
        alignItems: 'stretch',
        gap: '0.5rem',
        ...($data.isRead
          ? {}
          : {
              position: 'relative',
              '&::before': {
                content: '" "',
                position: 'absolute',
                left: 0,
                top: 0,
                backgroundColor: theme.palette.info.main,
                width: ' 100%',
                height: '100%',
                opacity: 0.08,
              },
            }),
      }}>
      <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'baseline', [`& > *:nth-child(${$data.isRead ? 1 : 2})`]: { flexGrow: 1 } }}>
        {!$data.isRead && <CircleIcon sx={{ fontSize: '0.75rem' }} color='secondary' />}
        {heading}
        <TimeAgo
          style={{ ...theme.typography.caption, fontWeight: $data.isRead ? 'normal' : 'bold' }}
          date={$data.createdAt}
          formatter={language === 'fr' ? timeAgoFormatter(language) : undefined}
          title={DateTime.fromISO($data.createdAt).toFormat(dateTimeFormat)}
        />
      </Box>
      {children}
    </MenuItem>
  );
}

function NotificationRow_Permalink({
  $key,
  onClick,
  heading,
  children,
}: PropsWithChildren<{
  $key: NotificationRow_PermalinkFragment$key;
  onClick: (id: DataID, isRead: boolean) => Promise<void> | void;
  heading: string;
}>) {
  const $data = useFragment(
    graphql`
      fragment NotificationRow_PermalinkFragment on PermalinkNotificationBase {
        permalink
        isRead
        ...NotificationRow_BaseFragment
      }
    `,
    $key,
  );

  const navigate = useNavigate();

  const handleClick = useCallback(
    async (id: DataID, isRead: boolean) => {
      await onClick(id, isRead);
      const permalinkUrl = new URL($data.permalink);
      if (permalinkUrl.origin === window.location.origin) {
        navigate(permalinkUrl.pathname);
        return;
      }

      window.location.assign($data.permalink);
    },
    [$data.permalink, navigate, onClick],
  );

  return (
    <NotificationRow_Base
      heading={
        <Typography
          variant='subtitle2'
          sx={(theme) => ({
            color: theme.palette.text.primary,
            wordBreak: 'break-word',
            textWrap: 'wrap',
            ...($data.isRead ? {} : { fontWeight: '600' }),
          })}>
          {heading}
        </Typography>
      }
      $key={$data}
      onClick={handleClick}>
      {children}
    </NotificationRow_Base>
  );
}

function NotificationRow_ModificationRequested({
  $key,
  onClick: handleClick,
}: {
  $key: NotificationRow_ModificationRequestedFragment$key;
  onClick: (id: DataID, isRead: boolean) => Promise<void> | void;
}) {
  const { t } = useTranslation('layout');
  const $data = useFragment(
    graphql`
      fragment NotificationRow_ModificationRequestedFragment on ServiceCallModificationRequestedNotification {
        isRead
        friendlyId
        comment
        ...NotificationRow_PermalinkFragment
      }
    `,
    $key,
  );

  return (
    <NotificationRow_Permalink
      heading={t('notification.modificationRequestedNotification', { friendlyId: $data.friendlyId })}
      $key={$data}
      onClick={handleClick}>
      <Typography
        variant='body2'
        sx={(theme) => ({
          color: theme.palette.text.secondary,
          wordBreak: 'break-word',
          textWrap: 'wrap',
          maxWidth: 'var(--notification_menu__width)',
          ...($data.isRead ? {} : { fontWeight: '500' }),
        })}>
        {$data.comment}
      </Typography>
    </NotificationRow_Permalink>
  );
}

function NotificationRow_QuoteStatusNotification({
  $key,
  titlePath,
  onClick: handleClick,
}: {
  $key: NotificationRow_QuoteStatusNotificationFragment$key;
  titlePath: string;
  onClick: (id: DataID, isRead: boolean) => Promise<void> | void;
}) {
  const { t } = useTranslation('layout');
  const $data = useFragment(
    graphql`
      fragment NotificationRow_QuoteStatusNotificationFragment on QuoteStatusChangeNotification {
        isRead
        friendlyId
        clientName
        arrivalDate {
          rawValue
        }
        equipmentKindAbbreviation
        craneCapacity
        ...NotificationRow_PermalinkFragment
      }
    `,
    $key,
  );

  const notificationContent = useMemo(() => {
    const equipmentSection =
      $data.craneCapacity && $data.equipmentKindAbbreviation ? ` - ${$data.craneCapacity}T ${$data.equipmentKindAbbreviation}` : '';
    const date = parseDateTime($data?.arrivalDate?.rawValue)?.toISODate();

    return `${$data.clientName} - ${date}${equipmentSection}`;
  }, [$data?.arrivalDate?.rawValue, $data.clientName, $data.craneCapacity, $data.equipmentKindAbbreviation]);

  return (
    <NotificationRow_Permalink heading={t(titlePath, { friendlyId: $data.friendlyId })} $key={$data} onClick={handleClick}>
      <Typography
        variant='body2'
        sx={(theme) => ({
          color: theme.palette.text.secondary,
          wordBreak: 'break-word',
          textWrap: 'wrap',
          maxWidth: 'var(--notification_menu__width)',
          ...($data.isRead ? {} : { fontWeight: '500' }),
        })}>
        {notificationContent}
      </Typography>
    </NotificationRow_Permalink>
  );
}

export function NotificationRow({
  $key,
  onClick: handleClick,
}: {
  $key: NotificationRowFragment$key;
  onClick: (id: DataID, isRead: boolean) => Promise<void> | void;
}) {
  const $data = useFragment(
    graphql`
      fragment NotificationRowFragment on Notification {
        __typename
        ...NotificationRow_ModificationRequestedFragment
        ...NotificationRow_QuoteStatusNotificationFragment
      }
    `,
    $key,
  );

  switch ($data.__typename) {
    case 'ServiceCallModificationRequestedNotification':
      return <NotificationRow_ModificationRequested $key={$data} onClick={handleClick} />;
    case 'QuoteApprovalRequestedNotification':
    case 'QuoteChangesRequestedNotification':
    case 'QuoteApprovedNotification':
      return (
        <NotificationRow_QuoteStatusNotification
          titlePath={`notification.${toCamelCase($data.__typename)}`}
          $key={$data}
          onClick={handleClick}
        />
      );
    default:
      return null;
  }
}
