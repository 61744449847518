/**
 * @generated SignedSource<<b71f4ef7033a4468ff11752110b23b44>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BillingCodeRuleDetailsPage_FormFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeRuleDetailsPage_ConditionsFragment" | "BillingCodeRuleDetailsPage_EffectsFragment" | "BillingCodeRuleDetailsPage_InformationFragment">;
  readonly " $fragmentType": "BillingCodeRuleDetailsPage_FormFragment";
};
export type BillingCodeRuleDetailsPage_FormFragment$key = {
  readonly " $data"?: BillingCodeRuleDetailsPage_FormFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeRuleDetailsPage_FormFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillingCodeRuleDetailsPage_FormFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleDetailsPage_InformationFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleDetailsPage_ConditionsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleDetailsPage_EffectsFragment"
    }
  ],
  "type": "BillingCodeRule",
  "abstractKey": null
};

(node as any).hash = "a6f33e755628df8139f6de9e104e9713";

export default node;
