import {
  createFieldKey,
  createFormContext,
  FieldKey,
  SetValueFn,
  useField,
  useFieldErrors,
  useFieldMapper,
  useFieldValidation,
} from '../../common/utils/forms';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { TextField, TextFieldProps } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { AddendumKind, addendumKinds, isAddendumKind } from '../../__enums__/AddendumKind';
import { SelectPicker, SelectPickerProps } from '../../common/components/SelectPicker';
import { AddendumTemplateFieldsKindFragment$key } from './__generated__/AddendumTemplateFieldsKindFragment.graphql';
import { AddendumTemplateFields_TitleFrFragment$key } from './__generated__/AddendumTemplateFields_TitleFrFragment.graphql';
import { AddendumTemplateFields_TitleEnFragment$key } from './__generated__/AddendumTemplateFields_TitleEnFragment.graphql';
import { AddendumTemplateFields_TemplateFrFragment$key } from './__generated__/AddendumTemplateFields_TemplateFrFragment.graphql';
import { AddendumTemplateFields_TemplateEnFragment$key } from './__generated__/AddendumTemplateFields_TemplateEnFragment.graphql';
import { AddendumTemplateFieldsSaleKindsFragment$key } from './__generated__/AddendumTemplateFieldsSaleKindsFragment.graphql';
import { ForwardSaleKindAutocompleteProps, SaleKindAutocomplete } from '../../common/components/SaleKindAutocomplete';
import { ForwardNatureOfWorkAutocompleteProps, NatureOfWorkAutocomplete } from '../../common/components/NatureOfWorkAutocomplete';
import { AddendumTemplateFieldsNatureOfWorkCodesFragment$key } from './__generated__/AddendumTemplateFieldsNatureOfWorkCodesFragment.graphql';
import {
  ForwardNatureOfWorkSubCategoryAutocompleteProps,
  NatureOfWorkSubCategoryAutocomplete,
} from '../../common/components/NatureOfWorkSubCategoryAutocomplete';
import { castNatureOfWorkSubCategoryEnum, natureOfWorkSubCategoryEnums } from '../../__enums__/NatureOfWorkSubCategoryEnum';
import { AddendumTemplateFieldsNatureOfWorkSubcategoryFragment$key } from './__generated__/AddendumTemplateFieldsNatureOfWorkSubcategoryFragment.graphql';
import { castSalesQuestionKind, SalesQuestionKind } from '../../__enums__/SalesQuestionKind';
import { specialProjects } from '../../jobs/fields/QuestionsBaseFields';
import { AddendumTemplateFieldsQuestionKindsFragment$key } from './__generated__/AddendumTemplateFieldsQuestionKindsFragment.graphql';
import { QuestionKindAutocomplete } from '../../common/components/QuestionKindAutocomplete';
import { isQuoteKind } from '../../__enums__/QuoteKind';
import { isServiceCallKind } from '../../__enums__/ServiceCallKind';
import { _throw } from '../../common/utils/_throw';

/** Maximum length of an addendum template's description. */
export const MAXIMUM_TEMPLATE_LENGTH = 3000;

export interface AddendumTemplateFieldsMappings {}

export const addendumTemplateFormContext = createFormContext<AddendumTemplateFieldsMappings>();

const fieldTitleFrKey = createFieldKey<string>();
export function useAddendumTemplateTitleFr($key: AddendumTemplateFields_TitleFrFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AddendumTemplateFields_TitleFrFragment on AddendumTemplate {
        title
      }
    `,
    $key,
  );

  const [title, setTitle] = useField(addendumTemplateFormContext, fieldTitleFrKey, $data?.title.fr ?? '');
  const useValidation = useFieldValidation(addendumTemplateFormContext, fieldTitleFrKey);
  useValidation((v) => v.trim().length > 0, [], 'save:required');

  const useMapper = useFieldMapper(addendumTemplateFormContext, fieldTitleFrKey);
  useMapper((val) => ({ titleFr: val.trim() }), [], 'save');

  const handleChange = useCallback<NonNullable<TextFieldProps['onChange']>>(
    (e) => {
      setTitle(e.target.value);
    },
    [setTitle],
  );

  const renderAddendumTemplateTitleFr = useCallback(
    () => <TitleInput fieldKey={fieldTitleFrKey} onChange={handleChange} value={title} labelKey='addenda.field.titleFr' />,
    [handleChange, title],
  );

  return { titleFr: title, setTitleFr: setTitle, renderAddendumTemplateTitleFr };
}

const fieldTitleEnKey = createFieldKey<string>();
export function useAddendumTemplateTitleEn($key: AddendumTemplateFields_TitleEnFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AddendumTemplateFields_TitleEnFragment on AddendumTemplate {
        title
      }
    `,
    $key,
  );

  const [title, setTitle] = useField(addendumTemplateFormContext, fieldTitleEnKey, $data?.title.en ?? '');
  const useValidation = useFieldValidation(addendumTemplateFormContext, fieldTitleEnKey);
  useValidation((v) => v.trim().length > 0, [], 'save:required');

  const useMapper = useFieldMapper(addendumTemplateFormContext, fieldTitleEnKey);
  useMapper((val) => ({ titleEn: val.trim() }), [], 'save');

  const handleChange = useCallback<NonNullable<TextFieldProps['onChange']>>(
    (e) => {
      setTitle(e.target.value);
    },
    [setTitle],
  );

  const renderAddendumTemplateTitleEn = useCallback(
    () => <TitleInput fieldKey={fieldTitleEnKey} onChange={handleChange} value={title} labelKey='addenda.field.titleEn' />,
    [handleChange, title],
  );

  return { titleEn: title, setTitleEn: setTitle, renderAddendumTemplateTitleEn };
}
function TitleInput({
  fieldKey,
  onChange: handleChange,
  value,
  labelKey,
}: {
  fieldKey: FieldKey<string>;
  onChange: TextFieldProps['onChange'];
  value: string;
  labelKey: string;
}) {
  const { t } = useAmbientTranslation();
  const errors = useFieldErrors(addendumTemplateFormContext, fieldKey);

  return <TextField label={t(labelKey)} required value={value} onChange={handleChange} error={!!errors['required']} />;
}

const fieldTemplateFrKey = createFieldKey<string>();
export function useAddendumTemplateTemplateFr($key: AddendumTemplateFields_TemplateFrFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AddendumTemplateFields_TemplateFrFragment on AddendumTemplate {
        template
      }
    `,
    $key,
  );

  const [template, setTemplate] = useField(addendumTemplateFormContext, fieldTemplateFrKey, $data?.template.fr ?? '');
  const useValidation = useFieldValidation(addendumTemplateFormContext, fieldTemplateFrKey);
  useValidation((v) => v.trim().length > 0, [], 'save:required');

  const useMapper = useFieldMapper(addendumTemplateFormContext, fieldTemplateFrKey);
  useMapper((val) => ({ templateFr: val.trim() }), [], 'save');

  const handleChange = useCallback<NonNullable<TextFieldProps['onChange']>>(
    (e) => {
      setTemplate(e.target.value);
    },
    [setTemplate],
  );

  const renderAddendumTemplateTemplateFr = useCallback(
    () => <TemplateInput fieldKey={fieldTemplateFrKey} value={template} onChange={handleChange} labelKey='addenda.field.templateFr' />,
    [template, handleChange],
  );

  return { templateFr: template, setTemplateFr: setTemplate, renderAddendumTemplateTemplateFr };
}

const fieldTemplateEnKey = createFieldKey<string>();
export function useAddendumTemplateTemplateEn($key: AddendumTemplateFields_TemplateEnFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AddendumTemplateFields_TemplateEnFragment on AddendumTemplate {
        template
      }
    `,
    $key,
  );

  const [template, setTemplate] = useField(addendumTemplateFormContext, fieldTemplateEnKey, $data?.template.en ?? '');
  const useValidation = useFieldValidation(addendumTemplateFormContext, fieldTemplateEnKey);
  useValidation((v) => v.trim().length > 0, [], 'save:required');

  const useMapper = useFieldMapper(addendumTemplateFormContext, fieldTemplateEnKey);
  useMapper((val) => ({ templateEn: val.trim() }), [], 'save');

  const handleChange = useCallback<NonNullable<TextFieldProps['onChange']>>(
    (e) => {
      setTemplate(e.target.value);
    },
    [setTemplate],
  );

  const renderAddendumTemplateTemplateEn = useCallback(
    () => <TemplateInput fieldKey={fieldTemplateEnKey} value={template} onChange={handleChange} labelKey='addenda.field.templateEn' />,
    [template, handleChange],
  );

  return { templateEn: template, setTemplateEn: setTemplate, renderAddendumTemplateTemplateEn };
}

function TemplateInput({
  fieldKey,
  onChange: handleChange,
  value,
  labelKey,
}: {
  fieldKey: FieldKey<string>;
  onChange: TextFieldProps['onChange'];
  value: string;
  labelKey: string;
}) {
  const { t } = useAmbientTranslation();
  const errors = useFieldErrors(addendumTemplateFormContext, fieldKey);

  return (
    <TextField
      onChange={handleChange}
      required
      multiline
      minRows={3}
      maxRows={10}
      value={value}
      inputProps={{ maxLength: MAXIMUM_TEMPLATE_LENGTH }}
      error={!!errors['required']}
      label={t(labelKey)}
    />
  );
}

const fieldKindKey = createFieldKey<AddendumKind | null>();

export function useAddendumTemplateKind($key: AddendumTemplateFieldsKindFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AddendumTemplateFieldsKindFragment on AddendumTemplate {
        kind
      }
    `,
    $key,
  );

  const [kind, setKind] = useField(
    addendumTemplateFormContext,
    fieldKindKey,
    $data?.kind ? (isAddendumKind($data?.kind) ? $data?.kind : null) : null,
  );
  const useValidation = useFieldValidation(addendumTemplateFormContext, fieldKindKey);
  useValidation((v) => !!v, [], 'save:required');

  const useMapper = useFieldMapper(addendumTemplateFormContext, fieldKindKey);
  useMapper((val) => ({ kind: val ?? _throw(new Error('AddendumTemplateKind should not be null')) }), [], 'save');

  const handleChange = useCallback<NonNullable<SelectPickerProps<AddendumKind>['onChange']>>(
    (_, v) => {
      setKind(v);
    },
    [setKind],
  );

  const renderAddendumTemplateKind = useCallback(() => <KindInput value={kind} onChange={handleChange} />, [kind, handleChange]);

  return { kind, setKind, renderAddendumTemplateKind };
}

function KindInput({
  value,
  onChange: handleChange,
}: {
  value: AddendumKind | null;
  onChange: SelectPickerProps<AddendumKind>['onChange'];
}) {
  const { t } = useAmbientTranslation();
  const errors = useFieldErrors(addendumTemplateFormContext, fieldKindKey);

  return (
    <SelectPicker
      value={value}
      onChange={handleChange}
      options={addendumKinds.filter((a) => a !== 'archived')}
      getOptionKey={(o) => o}
      getOptionLabel={(option) => t(`addendumKind.${option}`, { ns: 'jobs' })}
      textFieldProps={(params) => ({
        ...params,
        error: !!errors['required'],
        required: true,
        label: t('addenda.field.kind'),
      })}
    />
  );
}

type SaleKinds = NonNullable<ForwardSaleKindAutocompleteProps<true>['value']>;
const fieldSaleKindsKey = createFieldKey<SaleKinds>();
export function useAddendumTemplateSaleKinds($key: AddendumTemplateFieldsSaleKindsFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AddendumTemplateFieldsSaleKindsFragment on AddendumTemplate {
        conditions {
          saleKinds
        }
      }
    `,
    $key,
  );

  const [saleKinds, setSaleKinds] = useField(
    addendumTemplateFormContext,
    fieldSaleKindsKey,
    $data?.conditions.saleKinds.filter((k) => isQuoteKind(k) || isServiceCallKind(k)) ?? [],
  );

  const useMapper = useFieldMapper(addendumTemplateFormContext, fieldSaleKindsKey);
  useMapper((val) => ({ conditions: { saleKinds: val } }), [], 'save');

  const renderAddendumTemplateSaleKinds = useCallback(
    () => <SaleKindsInput value={saleKinds} setValue={setSaleKinds} />,
    [saleKinds, setSaleKinds],
  );

  return { saleKinds, setSaleKinds, renderAddendumTemplateSaleKinds };
}

function SaleKindsInput({ value, setValue }: { value: SaleKinds; setValue: SetValueFn<SaleKinds> }) {
  const { t } = useAmbientTranslation();

  const handleChange = useCallback<NonNullable<ForwardSaleKindAutocompleteProps<true>['onChange']>>(
    (_, v) => {
      setValue([...v]);
    },
    [setValue],
  );

  return (
    <SaleKindAutocomplete multiple value={value} onChange={handleChange} textFieldProps={() => ({ label: t('addenda.field.kinds') })} />
  );
}

type NatureOfWorks = NonNullable<ForwardNatureOfWorkAutocompleteProps<true>['value']>;
const fieldNatureOfWorkCodesKey = createFieldKey<NatureOfWorks>();
export function useFieldNatureOfWorkCodes($key: AddendumTemplateFieldsNatureOfWorkCodesFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AddendumTemplateFieldsNatureOfWorkCodesFragment on AddendumTemplate {
        conditions {
          natureOfWorks {
            id
            code
            label
          }
        }
      }
    `,
    $key,
  );

  const [natureOfWorkCodes, setNatureOfWorkCodes] = useField(
    addendumTemplateFormContext,
    fieldNatureOfWorkCodesKey,
    () => $data?.conditions?.natureOfWorks.filter((v) => !!v) ?? [],
  );

  const useMapperNatureOfWorks = useFieldMapper(addendumTemplateFormContext, fieldNatureOfWorkCodesKey);
  useMapperNatureOfWorks((val) => ({ conditions: { natureOfWorkCodes: val.map((v) => `${v.code}`) } }), [], 'save');

  const renderNatureOfWorkCodes = useCallback(
    () => <NatureOfWorkAutocompleteInput value={natureOfWorkCodes} setValue={setNatureOfWorkCodes} />,
    [natureOfWorkCodes, setNatureOfWorkCodes],
  );

  return { natureOfWorkCodes, setNatureOfWorkCodes, renderNatureOfWorkCodes };
}

function NatureOfWorkAutocompleteInput({ value, setValue }: { value: NatureOfWorks; setValue: SetValueFn<NatureOfWorks> }) {
  const { t } = useAmbientTranslation();
  const handleChange = useCallback<NonNullable<ForwardNatureOfWorkAutocompleteProps<true>['onChange']>>(
    (v) => {
      setValue(v);
    },
    [setValue],
  );

  return (
    <NatureOfWorkAutocomplete
      multiple
      value={value}
      onChange={handleChange}
      textFieldProps={() => ({ label: t('addenda.field.natureOfWorks') })}
    />
  );
}

type NatureOfWorkSubCategories = NonNullable<ForwardNatureOfWorkSubCategoryAutocompleteProps<true>['value']>;
const fieldNatureOfWorkSubCategoriesKey = createFieldKey<NatureOfWorkSubCategories>();
export function useFieldNatureOfWorkSubCategories($key: AddendumTemplateFieldsNatureOfWorkSubcategoryFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AddendumTemplateFieldsNatureOfWorkSubcategoryFragment on AddendumTemplate {
        conditions {
          natureOfWorkSubCategories
        }
      }
    `,
    $key,
  );

  const [natureOfWorkSubCategories, setNatureOfWorkSubCategories] = useField(
    addendumTemplateFormContext,
    fieldNatureOfWorkSubCategoriesKey,
    $data?.conditions.natureOfWorkSubCategories
      ? $data.conditions.natureOfWorkSubCategories.map((sc) => castNatureOfWorkSubCategoryEnum(sc))
      : [],
  );

  const useMapperNatureOfWorkSubCategories = useFieldMapper(addendumTemplateFormContext, fieldNatureOfWorkSubCategoriesKey);
  useMapperNatureOfWorkSubCategories((val) => ({ conditions: { natureOfWorkSubCategories: val } }), [], 'save');

  const renderNatureOfWorkSubCategories = useCallback(
    () => <NatureOfWorkSubCategoryInput value={natureOfWorkSubCategories} setValue={setNatureOfWorkSubCategories} />,
    [natureOfWorkSubCategories, setNatureOfWorkSubCategories],
  );

  return { natureOfWorkSubCategories, setNatureOfWorkSubCategories, renderNatureOfWorkSubCategories };
}

function NatureOfWorkSubCategoryInput({
  value,
  setValue,
}: {
  value: NatureOfWorkSubCategories;
  setValue: SetValueFn<NatureOfWorkSubCategories>;
}) {
  const { t } = useAmbientTranslation();
  const handleChange = useCallback<NonNullable<ForwardNatureOfWorkSubCategoryAutocompleteProps<true>['onChange']>>(
    (v) => setValue(v),
    [setValue],
  );

  const sortedCategories = useMemo(
    () =>
      natureOfWorkSubCategoryEnums
        .map((c) => ({ value: c, label: t(`natureOfWorkSubCategories.${c}`, { ns: 'common' }) }))
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((item) => item.value),
    [t],
  );

  return (
    <NatureOfWorkSubCategoryAutocomplete
      multiple
      value={value}
      onChange={handleChange}
      options={sortedCategories}
      getOptionKey={(o) => o}
      getOptionLabel={(o) => t(`natureOfWorkSubCategories.${o}`, { ns: 'common' })}
      textFieldProps={() => ({
        label: t('addenda.field.natureOfWorkSubCategory'),
      })}
    />
  );
}

type QuestionKinds = NonNullable<SelectPickerProps<SalesQuestionKind, true>['value']>;
const fieldQuestionKindsKey = createFieldKey<QuestionKinds>();
export function useFieldQuestionKinds($key: AddendumTemplateFieldsQuestionKindsFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AddendumTemplateFieldsQuestionKindsFragment on AddendumTemplate {
        conditions {
          questionKinds
        }
      }
    `,
    $key,
  );

  const [questionKinds, setQuestionKinds] = useField(addendumTemplateFormContext, fieldQuestionKindsKey, () =>
    $data?.conditions.questionKinds ? $data.conditions.questionKinds.map((q) => castSalesQuestionKind(q)) : [],
  );

  const useMapperQuestionKinds = useFieldMapper(addendumTemplateFormContext, fieldQuestionKindsKey);
  useMapperQuestionKinds((val) => ({ conditions: { questionKinds: val } }), [], 'save');

  const renderQuestionKinds = useCallback(
    () => <QuestionKindsInput value={questionKinds} setValue={setQuestionKinds} />,
    [questionKinds, setQuestionKinds],
  );

  return { questionKinds, setQuestionKinds, renderQuestionKinds };
}

function QuestionKindsInput({ value, setValue }: { value: QuestionKinds; setValue: SetValueFn<QuestionKinds> }) {
  const { t } = useAmbientTranslation();
  const handleChange = useCallback<NonNullable<SelectPickerProps<SalesQuestionKind, true>['onChange']>>((_, v) => setValue(v), [setValue]);

  return (
    <QuestionKindAutocomplete
      multiple
      value={value}
      onChange={handleChange}
      groupBy={(o) => (specialProjects.includes(o) ? t('addenda.field.specialProjects') : '')}
      textFieldProps={() => ({
        label: t('addenda.field.specialSituation'),
      })}
    />
  );
}
