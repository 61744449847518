/**
 * @generated SignedSource<<2da6854c4fa35657e4c2820922f2f62b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BillingCodeCategory = "accessories" | "boomAndCounterWeightTransport" | "equipment" | "escort" | "fixedFees" | "flatbedTransport" | "labor" | "movingFees" | "otherFees" | "permit" | "rateKind" | "%future added value";
export type CostLinesFields_Item_RulesQuery$variables = {
  billingCodeId: string;
};
export type CostLinesFields_Item_RulesQuery$data = {
  readonly node: {
    readonly billingCodeCategory?: BillingCodeCategory;
    readonly " $fragmentSpreads": FragmentRefs<"CostLinesFields_useQuantityRateFromSalesRate_DefaultQuantityFragment" | "CostLinesFields_useResetWorkForceTypeFragment" | "CostLinesFields_useSetBillingCodeAdditionalDataFragment">;
  };
};
export type CostLinesFields_Item_RulesQuery = {
  response: CostLinesFields_Item_RulesQuery$data;
  variables: CostLinesFields_Item_RulesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "billingCodeId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "billingCodeId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billingCodeCategory",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CostLinesFields_Item_RulesQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "CostLinesFields_useResetWorkForceTypeFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "CostLinesFields_useSetBillingCodeAdditionalDataFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "CostLinesFields_useQuantityRateFromSalesRate_DefaultQuantityFragment"
                },
                (v2/*: any*/)
              ],
              "type": "BillingCode",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CostLinesFields_Item_RulesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isFractionAllowed",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isFixedQuantity",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "defaultQuantity",
                "storageKey": null
              }
            ],
            "type": "BillingCode",
            "abstractKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1f9ace1ebad962d84716c2dcd0a3833a",
    "id": null,
    "metadata": {},
    "name": "CostLinesFields_Item_RulesQuery",
    "operationKind": "query",
    "text": "query CostLinesFields_Item_RulesQuery(\n  $billingCodeId: ID!\n) {\n  node(id: $billingCodeId) {\n    __typename\n    ... on BillingCode {\n      ...CostLinesFields_useResetWorkForceTypeFragment\n      ...CostLinesFields_useSetBillingCodeAdditionalDataFragment\n      ...CostLinesFields_useQuantityRateFromSalesRate_DefaultQuantityFragment\n      billingCodeCategory\n    }\n    id\n  }\n}\n\nfragment CostLinesFields_useQuantityRateFromSalesRate_DefaultQuantityFragment on BillingCode {\n  defaultQuantity\n  isFixedQuantity\n}\n\nfragment CostLinesFields_useResetWorkForceTypeFragment on BillingCode {\n  billingCodeCategory\n}\n\nfragment CostLinesFields_useSetBillingCodeAdditionalDataFragment on BillingCode {\n  billingCodeCategory\n  isFractionAllowed\n  isFixedQuantity\n  defaultQuantity\n}\n"
  }
};
})();

(node as any).hash = "5621496dc20bf52a74f65c68009f0982";

export default node;
