/**
 * @generated SignedSource<<17615482e0b6ee9d7227c21e4baef535>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccessoryRuleDetailsPage_ConditionsFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryRuleFields_BaseQuestions" | "AccessoryRuleFields_CapacitiesFragment" | "AccessoryRuleFields_ClientsFragment" | "AccessoryRuleFields_EquipmentKindsFragment" | "AccessoryRuleFields_NatureOfWorkFragment" | "AccessoryRuleFields_NatureOfWorkSubCategoriesFragment" | "AccessoryRuleFields_SaleKindsFragment" | "AccessoryRuleFields_SaleStagesFragment">;
  readonly " $fragmentType": "AccessoryRuleDetailsPage_ConditionsFragment";
};
export type AccessoryRuleDetailsPage_ConditionsFragment$key = {
  readonly " $data"?: AccessoryRuleDetailsPage_ConditionsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryRuleDetailsPage_ConditionsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccessoryRuleDetailsPage_ConditionsFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccessoryRuleFields_SaleStagesFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccessoryRuleFields_SaleKindsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccessoryRuleFields_CapacitiesFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccessoryRuleFields_EquipmentKindsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccessoryRuleFields_ClientsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccessoryRuleFields_NatureOfWorkFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccessoryRuleFields_NatureOfWorkSubCategoriesFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccessoryRuleFields_BaseQuestions"
    }
  ],
  "type": "AccessoryRule",
  "abstractKey": null
};

(node as any).hash = "ac14426ea6f524d35c54894a5823eae9";

export default node;
