/**
 * @generated SignedSource<<60ba81a40f79ece54b05a61f109745cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type SalesQuestionKind = "accidentScene" | "bargeWork" | "clamShellBucketOrGrapple" | "conventionalCrawlerCranesWithTravel" | "conventionalCrawlerCranesWithoutTravel" | "craneOrObstacleProximity" | "events" | "excavationProximityLessThan10Feet" | "excavationProximityMoreThan10Feet" | "hazardousTerrain" | "jettyBridgeViaductDamSlabOrParkingDeck" | "luffingJibOrExtensionInstallation" | "movingCraneOrEquipmentWithPulling" | "movingCraneOrEquipmentWithoutPulling" | "peopleLifting" | "powerlineProximity" | "tandemLiftingClientCrane" | "tandemLiftingGuayCrane" | "unloadingOnQuay" | "valueOf1MillionOrMore" | "videoOrPhotoShooting" | "windTurbines" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type QuestionsBaseFields_SelectedQuestionKindsFragment$data = {
  readonly salesQuestions: ReadonlyArray<{
    readonly kind: SalesQuestionKind;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"QuestionsBaseFields_ExcavationLessThan10FeetFragment" | "QuestionsBaseFields_ProximityToPowerlineFragment">;
  readonly " $fragmentType": "QuestionsBaseFields_SelectedQuestionKindsFragment";
};
export type QuestionsBaseFields_SelectedQuestionKindsFragment$key = {
  readonly " $data"?: QuestionsBaseFields_SelectedQuestionKindsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuestionsBaseFields_SelectedQuestionKindsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuestionsBaseFields_SelectedQuestionKindsFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "QuestionsBaseFields_ProximityToPowerlineFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "QuestionsBaseFields_ExcavationLessThan10FeetFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "salesQuestions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "QuestionsInternalBase",
  "abstractKey": null
};

(node as any).hash = "d68d3c8316923f3748ad25a2a0222425";

export default node;
