import { ElementType, forwardRef, ReactElement, Ref, RefAttributes } from 'react';
import { ChipTypeMap } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ForwardAutocompleteProps, SelectPicker, SelectPickerProps } from './SelectPicker';
import { SaleStage, saleStages } from '../../jobs/saleStage';

export type ForwardSaleStageAutocompleteProps<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = Omit<
  SelectPickerProps<SaleStage, Multiple, DisableClearable, false, ChipComponent> &
    ForwardAutocompleteProps<SaleStage, Multiple, DisableClearable, false, ChipComponent>,
  'ref' | 'options' | 'getOptionLabel' | 'getOptionKey'
>;

export const SaleStageAutocomplete = forwardRef<HTMLInputElement, ForwardSaleStageAutocompleteProps<false, false, ElementType>>(
  function SaleKindAutocomplete<
    Multiple extends boolean | undefined = false,
    DisableClearable extends boolean | undefined = false,
    ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
  >(props: ForwardSaleStageAutocompleteProps<Multiple, DisableClearable, ChipComponent>, ref: Ref<HTMLInputElement>) {
    const { t } = useTranslation('common');

    return (
      <SelectPicker
        ref={ref}
        options={saleStages}
        getOptionKey={(o) => o}
        getOptionLabel={(o) => t(`saleStages.${o}`, { ns: 'jobs' })}
        {...props}
      />
    );
  },
) as <
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  props: ForwardSaleStageAutocompleteProps<Multiple, DisableClearable, ChipComponent> & RefAttributes<HTMLInputElement>,
) => ReactElement;
