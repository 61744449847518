/**
 * @generated SignedSource<<921c1ab9322eff6c94c917f82e5592b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CraneSelectorMode = "lifts" | "manual" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SaleCraneSelectorFields_CraneSelectorModeFragment$data = {
  readonly mode: CraneSelectorMode;
  readonly " $fragmentType": "SaleCraneSelectorFields_CraneSelectorModeFragment";
};
export type SaleCraneSelectorFields_CraneSelectorModeFragment$key = {
  readonly " $data"?: SaleCraneSelectorFields_CraneSelectorModeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleCraneSelectorFields_CraneSelectorModeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleCraneSelectorFields_CraneSelectorModeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mode",
      "storageKey": null
    }
  ],
  "type": "CraneSelectorInternal",
  "abstractKey": null
};

(node as any).hash = "645bce95b289c608be896f08b514d4f0";

export default node;
