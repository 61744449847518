/**
 * @generated SignedSource<<9cd7e3d5d10a4b8596c9273318e1231f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientBaseFields_AssignedClientFragment$data = {
  readonly assignedClient: {
    readonly " $fragmentSpreads": FragmentRefs<"SalesDetails_useBillingCodeSearchInput_ClientFragment">;
  } | null | undefined;
  readonly assignedClientId: string | null | undefined;
  readonly " $fragmentType": "ClientBaseFields_AssignedClientFragment";
};
export type ClientBaseFields_AssignedClientFragment$key = {
  readonly " $data"?: ClientBaseFields_AssignedClientFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientBaseFields_AssignedClientFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientBaseFields_AssignedClientFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "assignedClientId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Client",
      "kind": "LinkedField",
      "name": "assignedClient",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SalesDetails_useBillingCodeSearchInput_ClientFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ClientInternalBase",
  "abstractKey": null
};

(node as any).hash = "6a3d28073944d25021feafd4929b6ed8";

export default node;
