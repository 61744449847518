/**
 * @generated SignedSource<<e37afd336a380806ca677e193da2e77f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as billingCodeLabelResolverType } from "../../../__resolvers__/BillingCodeLabelResolver";
// Type assertion validating that `billingCodeLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(billingCodeLabelResolverType satisfies (
  rootKey: BillingCodeLabelResolver$key,
) => Record<string, string> | null | undefined);
import { label as workForceTypeLookupLabelResolverType } from "../../../__resolvers__/WorkForceTypeLabelResolver";
// Type assertion validating that `workForceTypeLookupLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(workForceTypeLookupLabelResolverType satisfies (
  rootKey: WorkForceTypeLabelResolver$key,
) => Record<string, string> | null | undefined);
export type BillingCodeRuleFields_BillingCodeCollectionFragment$data = {
  readonly effects: {
    readonly billingCodes: {
      readonly automatic: ReadonlyArray<{
        readonly billingCode: {
          readonly billingSection: {
            readonly shortDescription: Record<string, string>;
          } | null | undefined;
          readonly code: number;
          readonly id: string;
          readonly label: Record<string, string> | null | undefined;
        } | null | undefined;
        readonly workForceType: {
          readonly code: number;
          readonly id: string;
          readonly label: Record<string, string> | null | undefined;
        } | null | undefined;
      }>;
    };
  };
  readonly " $fragmentType": "BillingCodeRuleFields_BillingCodeCollectionFragment";
};
export type BillingCodeRuleFields_BillingCodeCollectionFragment$key = {
  readonly " $data"?: BillingCodeRuleFields_BillingCodeCollectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeRuleFields_BillingCodeCollectionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillingCodeRuleFields_BillingCodeCollectionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EffectsInternal",
      "kind": "LinkedField",
      "name": "effects",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BillingCodeEffects",
          "kind": "LinkedField",
          "name": "billingCodes",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AutomaticBillingCodeEffectResponse",
              "kind": "LinkedField",
              "name": "automatic",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BillingCode",
                  "kind": "LinkedField",
                  "name": "billingCode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "fragment": {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "BillingCodeLabelResolver"
                      },
                      "kind": "RelayResolver",
                      "name": "label",
                      "resolverModule": require('./../../../__resolvers__/BillingCodeLabelResolver').label,
                      "path": "effects.billingCodes.automatic.billingCode.label"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "BillingSection",
                      "kind": "LinkedField",
                      "name": "billingSection",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "shortDescription",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "WorkForceTypeLookup",
                  "kind": "LinkedField",
                  "name": "workForceType",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "fragment": {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "WorkForceTypeLabelResolver"
                      },
                      "kind": "RelayResolver",
                      "name": "label",
                      "resolverModule": require('./../../../__resolvers__/WorkForceTypeLabelResolver').label,
                      "path": "effects.billingCodes.automatic.workForceType.label"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BillingCodeRule",
  "abstractKey": null
};
})();

(node as any).hash = "97a90e075fa6e80b038414d6627456ef";

export default node;
