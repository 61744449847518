/**
 * @generated SignedSource<<61d127c3a255a6f8546925b7c3b24bcf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddendumKind = "archived" | "clientOrWorksite" | "craneOperator" | "dispatching" | "internalNote" | "invoicing" | "quote" | "%future added value";
export type SaveAddendumTemplateInput = {
  conditions: SaveConditionsInternalInput;
  kind: AddendumKind;
  templateEn: string;
  templateFr: string;
  titleEn: string;
  titleFr: string;
};
export type SaveConditionsInternalInput = {
  natureOfWorkCodes: ReadonlyArray<string>;
  natureOfWorkSubCategories: ReadonlyArray<string>;
  questionKinds: ReadonlyArray<string>;
  saleKinds: ReadonlyArray<string>;
};
export type AddendumTemplateSaveButtonMutation$variables = {
  id?: string | null | undefined;
  saveAddendumTemplate: SaveAddendumTemplateInput;
};
export type AddendumTemplateSaveButtonMutation$data = {
  readonly saveAddendumTemplate: {
    readonly addendumTemplate: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"AddendumTemplateDetailsPageFragment">;
    } | null | undefined;
  };
};
export type AddendumTemplateSaveButtonMutation = {
  response: AddendumTemplateSaveButtonMutation$data;
  variables: AddendumTemplateSaveButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "saveAddendumTemplate"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "saveAddendumTemplate",
    "variableName": "saveAddendumTemplate"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddendumTemplateSaveButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SaveAddendumTemplatePayload",
        "kind": "LinkedField",
        "name": "saveAddendumTemplate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AddendumTemplate",
            "kind": "LinkedField",
            "name": "addendumTemplate",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AddendumTemplateDetailsPageFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddendumTemplateSaveButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SaveAddendumTemplatePayload",
        "kind": "LinkedField",
        "name": "saveAddendumTemplate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AddendumTemplate",
            "kind": "LinkedField",
            "name": "addendumTemplate",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "kind",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "template",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AddendumTemplateConditionsInternal",
                "kind": "LinkedField",
                "name": "conditions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "saleKinds",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NatureOfWorkLookup",
                    "kind": "LinkedField",
                    "name": "natureOfWorks",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "name": "label",
                        "args": null,
                        "fragment": {
                          "kind": "InlineFragment",
                          "selections": [
                            (v3/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "description",
                              "storageKey": null
                            }
                          ],
                          "type": "NatureOfWorkLookup",
                          "abstractKey": null
                        },
                        "kind": "RelayResolver",
                        "storageKey": null,
                        "isOutputType": true
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "natureOfWorkSubCategories",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "questionKinds",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "521c064e216bec369960b93b79083a2f",
    "id": null,
    "metadata": {},
    "name": "AddendumTemplateSaveButtonMutation",
    "operationKind": "mutation",
    "text": "mutation AddendumTemplateSaveButtonMutation(\n  $id: ID\n  $saveAddendumTemplate: SaveAddendumTemplateInput!\n) {\n  saveAddendumTemplate(id: $id, saveAddendumTemplate: $saveAddendumTemplate) {\n    addendumTemplate {\n      id\n      ...AddendumTemplateDetailsPageFragment\n    }\n  }\n}\n\nfragment AddendumTemplateDetailsPageFragment on AddendumTemplate {\n  id\n  ...AddendumTemplateFields_TitleFrFragment\n  ...AddendumTemplateFields_TitleEnFragment\n  ...AddendumTemplateFieldsKindFragment\n  ...AddendumTemplateFields_TemplateFrFragment\n  ...AddendumTemplateFields_TemplateEnFragment\n  ...AddendumTemplateFieldsSaleKindsFragment\n  ...AddendumTemplateFieldsNatureOfWorkCodesFragment\n  ...AddendumTemplateFieldsNatureOfWorkSubcategoryFragment\n  ...AddendumTemplateFieldsQuestionKindsFragment\n}\n\nfragment AddendumTemplateFieldsKindFragment on AddendumTemplate {\n  kind\n}\n\nfragment AddendumTemplateFieldsNatureOfWorkCodesFragment on AddendumTemplate {\n  conditions {\n    natureOfWorks {\n      id\n      code\n      ...NatureOfWorkLabelResolver\n    }\n  }\n}\n\nfragment AddendumTemplateFieldsNatureOfWorkSubcategoryFragment on AddendumTemplate {\n  conditions {\n    natureOfWorkSubCategories\n  }\n}\n\nfragment AddendumTemplateFieldsQuestionKindsFragment on AddendumTemplate {\n  conditions {\n    questionKinds\n  }\n}\n\nfragment AddendumTemplateFieldsSaleKindsFragment on AddendumTemplate {\n  conditions {\n    saleKinds\n  }\n}\n\nfragment AddendumTemplateFields_TemplateEnFragment on AddendumTemplate {\n  template\n}\n\nfragment AddendumTemplateFields_TemplateFrFragment on AddendumTemplate {\n  template\n}\n\nfragment AddendumTemplateFields_TitleEnFragment on AddendumTemplate {\n  title\n}\n\nfragment AddendumTemplateFields_TitleFrFragment on AddendumTemplate {\n  title\n}\n\nfragment NatureOfWorkLabelResolver on NatureOfWorkLookup {\n  code\n  description\n}\n"
  }
};
})();

(node as any).hash = "b9a96975d80b8a85d78f632a2222cc80";

export default node;
