/**
 * @generated SignedSource<<fde77c5353809c1a202fed74a8306bd1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SaleProjectFields_useIncludeWeekendsAndHolidaysQuery$variables = {
  date: string;
};
export type SaleProjectFields_useIncludeWeekendsAndHolidaysQuery$data = {
  readonly dateInfo: {
    readonly isStatutoryHoliday: boolean;
    readonly isWeekend: boolean;
  };
};
export type SaleProjectFields_useIncludeWeekendsAndHolidaysQuery = {
  response: SaleProjectFields_useIncludeWeekendsAndHolidaysQuery$data;
  variables: SaleProjectFields_useIncludeWeekendsAndHolidaysQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "date"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "date",
    "variableName": "date"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isStatutoryHoliday",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isWeekend",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SaleProjectFields_useIncludeWeekendsAndHolidaysQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DateInfo",
        "kind": "LinkedField",
        "name": "dateInfo",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SaleProjectFields_useIncludeWeekendsAndHolidaysQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DateInfo",
        "kind": "LinkedField",
        "name": "dateInfo",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3539bba2f533c6f8212ed4b206145f4e",
    "id": null,
    "metadata": {},
    "name": "SaleProjectFields_useIncludeWeekendsAndHolidaysQuery",
    "operationKind": "query",
    "text": "query SaleProjectFields_useIncludeWeekendsAndHolidaysQuery(\n  $date: Date!\n) {\n  dateInfo(date: $date) {\n    isStatutoryHoliday\n    isWeekend\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f1f3fc553b2cbeb81a0374060a4dfb1c";

export default node;
