/**
 * @generated SignedSource<<bea33774d8cddae4178f8726cdf41e9d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaleCraneSelectorFields_Manual_CounterweightFragment$data = {
  readonly counterweight: string | null | undefined;
  readonly " $fragmentType": "SaleCraneSelectorFields_Manual_CounterweightFragment";
};
export type SaleCraneSelectorFields_Manual_CounterweightFragment$key = {
  readonly " $data"?: SaleCraneSelectorFields_Manual_CounterweightFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleCraneSelectorFields_Manual_CounterweightFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleCraneSelectorFields_Manual_CounterweightFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "counterweight",
      "storageKey": null
    }
  ],
  "type": "ManualConfigurationInfo",
  "abstractKey": null
};

(node as any).hash = "a843311e5f6d9e89eb226b7001810873";

export default node;
