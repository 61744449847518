import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { useCallback } from 'react';
import { useOperations } from '../AppSharedState';
import { useSharedState } from '../common/utils/sharedState';
import { jobSharedStateContext } from './JobSharedState';
import { LoadingButton } from '@mui/lab';
import { APPLY_INSTANT_CALCULATOR_OPERATION_KEY, applyInstantCalculatorTokenKey } from './useInstantCalculator';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { useMissingCostsDependencies } from './useMissingCostsDependencies';
import { useFieldBranchToWorksiteDistanceValue } from './fields/SaleEquipmentFields';
import { ApplyInstantCalculatorButtonFragment$key } from './__generated__/ApplyInstantCalculatorButtonFragment.graphql';
import { Theme, useMediaQuery } from '@mui/material';

export function ApplyInstantCalculatorButton({
  $key,
  disabled,
}: {
  $key: ApplyInstantCalculatorButtonFragment$key | null | undefined;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { hasOperationInFlightForKey, shouldNotifyForKey } = useOperations(APPLY_INSTANT_CALCULATOR_OPERATION_KEY);
  const [, setApplyInstantCalculatorToken] = useSharedState(jobSharedStateContext, applyInstantCalculatorTokenKey);

  const $data = useFragment(
    graphql`
      fragment ApplyInstantCalculatorButtonFragment on ISale {
        equipment {
          ...SaleEquipmentFields_BranchToWorksiteDistanceFragment
        }
        ...useMissingCostsDependenciesFragment
      }
    `,
    $key,
  );

  const { branchToWorksiteDistance } = useFieldBranchToWorksiteDistanceValue($data?.equipment);
  const missingDependencies = useMissingCostsDependencies($data);
  const isValid = !disabled && branchToWorksiteDistance && !missingDependencies;

  const handleInstantCalculator = useCallback(() => {
    if (hasOperationInFlightForKey || !isValid) return;

    // set a value in the shared state that will be read by useInstantCalculator
    // if the reference changed, it will trigger the query
    setApplyInstantCalculatorToken({});
  }, [hasOperationInFlightForKey, isValid, setApplyInstantCalculatorToken]);

  return (
    <LoadingButton
      variant={compact ? 'outlined' : 'text'}
      loading={shouldNotifyForKey}
      color='error'
      onClick={handleInstantCalculator}
      disabled={!isValid}
      data-label-key='instantCalculator.apply'>
      {t('section.calculator.action.apply')}
    </LoadingButton>
  );
}
