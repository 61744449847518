import i18n, { type i18n as I18n, ResourceLanguage } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { auth as authEnTranslation } from './auth/__i18n__/en';
import { auth as authFrTranslation } from './auth/__i18n__/fr';
import { client as clientEnTranslation } from './client/__i18n__/en';
import { client as clientFrTranslation } from './client/__i18n__/fr';
import { common as commonEnTranslation } from './common/__i18n__/en';
import { common as commonFrTranslation } from './common/__i18n__/fr';
import { configuration as configurationFrTranslation } from './configuration/__i18n__/fr';
import { configuration as configurationEnTranslation } from './configuration/__i18n__/en';
import { craneCharts as craneChartsFrTranslation } from './craneCharts/__i18n__/fr';
import { craneCharts as craneChartsEnTranslation } from './craneCharts/__i18n__/en';
import { craneSelector as craneSelectorFrTranslation } from './jobs/craneSelector/__i18n__/fr';
import { craneSelector as craneSelectorEnTranslation } from './jobs/craneSelector/__i18n__/en';
import { layout as layoutEnTranslation } from './layout/__i18n__/en';
import { layout as layoutFrTranslation } from './layout/__i18n__/fr';
import { jobs as jobsEnTranslation } from './jobs/__i18n__/en';
import { jobs as jobsFrTranslation } from './jobs/__i18n__/fr';
import { maintenance as maintenanceFrTranslation } from './maintenance/__i18n__/fr';
import { maintenance as maintenanceEnTranslation } from './maintenance/__i18n__/en';
import { quote as quotesFrTranslation } from './quote/__i18n__/fr';
import { quote as quotesEnTranslation } from './quote/__i18n__/en';
import { serviceCall as serviceCallEnTranslation } from './serviceCall/__i18n__/en';
import { serviceCall as serviceCallFrTranslation } from './serviceCall/__i18n__/fr';
import { workPlanning as workPlanningEnTranslation } from './workPlanning/__i18n__/en';
import { workPlanning as workPlanningFrTranslation } from './workPlanning/__i18n__/fr';
import { worksite as worksiteEnTranslation } from './worksite/__i18n__/en';
import { worksite as worksiteFrTranslation } from './worksite/__i18n__/fr';
import { billingCodeRule as billingCodeRuleEnTranslation } from './billingCodeRules/__i18n__/en';
import { billingCodeRule as billingCodeRuleFrTranslation } from './billingCodeRules/__i18n__/fr';
import { resolvers as resolversEnTranslation } from './__resolvers__/__i18n__/en';
import { resolvers as resolversFrTranslation } from './__resolvers__/__i18n__/fr';

export type SupportedLanguage = 'en' | 'fr';
const fallbackLanguage = 'fr';

/**
 * Eqyivalent to i18n.resolvedLanguage for most scenarios. Returns the fallbackLanguage when undefined.
 * @param instance
 */
export function resolvedLanguage(instance: I18n): SupportedLanguage {
  return (instance.resolvedLanguage as SupportedLanguage | undefined) ?? fallbackLanguage;
}

export const languageToLocale: Readonly<Record<SupportedLanguage, string>> = {
  // keep in sync with the 'locale' translation keys in src/common/__i18n__/*.ts
  en: 'en-CA',
  fr: 'fr-CA',
};

export function initI18n(): void {
  i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      detection: {
        // order and from where user language should be detected
        order: ['localStorage', 'navigator'],
        // keys or params to lookup language from
        lookupLocalStorage: 'i18nextLng',
        // cache user language on
        caches: ['localStorage'],
        excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
      },
      fallbackLng: fallbackLanguage,
      // lng: 'fr', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
      // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
      interpolation: {
        escapeValue: false, // react already safes from xss
        skipOnVariables: false, // https://www.i18next.com/translation-function/nesting
      },
      resources: {
        en: {
          auth: authEnTranslation,
          client: clientEnTranslation,
          common: commonEnTranslation,
          configuration: configurationEnTranslation,
          craneCharts: craneChartsEnTranslation,
          craneSelector: craneSelectorEnTranslation,
          jobs: jobsEnTranslation,
          layout: layoutEnTranslation,
          maintenance: maintenanceEnTranslation,
          quote: quotesEnTranslation,
          serviceCall: serviceCallEnTranslation,
          workPlanning: workPlanningEnTranslation,
          worksite: worksiteEnTranslation,
          billingCodeRule: billingCodeRuleEnTranslation,
          resolvers: resolversEnTranslation,
        },
        fr: {
          auth: authFrTranslation,
          client: clientFrTranslation,
          common: commonFrTranslation,
          configuration: configurationFrTranslation,
          craneCharts: craneChartsFrTranslation,
          craneSelector: craneSelectorFrTranslation,
          jobs: jobsFrTranslation,
          layout: layoutFrTranslation,
          maintenance: maintenanceFrTranslation,
          quote: quotesFrTranslation,
          serviceCall: serviceCallFrTranslation,
          workPlanning: workPlanningFrTranslation,
          worksite: worksiteFrTranslation,
          billingCodeRule: billingCodeRuleFrTranslation,
          resolvers: resolversFrTranslation,
        },
      } satisfies Record<SupportedLanguage, ResourceLanguage>,
    });
}

export default i18n;
