import { Environment, FetchFunction, Network, Observable, RecordSource, RelayFeatureFlags, Store, SubscribeFunction } from 'relay-runtime';
import { Environment as IEnvironment } from 'react-relay';

import { config } from './config';
import { msalInstance } from './msalInstance';
import { apiRequest, loginRequest } from './authConfig';
import { InteractionRequiredAuthError, IPublicClientApplication } from '@azure/msal-browser';
import i18n, { resolvedLanguage } from './i18n';
import { createClient } from 'graphql-ws';
import { Sink } from 'graphql-ws/lib/common';
import { ExecutionResult } from 'graphql/index';
import { PayloadData, PayloadExtensions } from 'relay-runtime/lib/network/RelayNetworkTypes';

const fetchFn: FetchFunction = (request, variables) =>
  Observable.create((sink) => {
    const abortController = new AbortController();

    // HACK: MsalReact is designed around browser events in a way that can break the React render loop
    // if the acquireTokenSilent API is called during the render phase. Delaying all network calls to the
    // next MicroTask ensures we never attempt to call those APIs during the render phase.
    // See https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/5796
    Promise.resolve()
      .then(async () => {
        const accessToken = await getAccessToken(msalInstance);

        const resp = await fetch(`${config.API_URL}/graphql`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Accept-Language': resolvedLanguage(i18n),
            'Content-Type': 'application/json',
            ...(accessToken != null ? { Authorization: `bearer ${accessToken}` } : {}),
            // 'x-request-delay': '00:00:01', //< Use to artificially slow down requests
          },
          body: JSON.stringify({
            query: request.text, // <-- The GraphQL document composed by Relay
            operationName: request.name,
            variables,
          }),
          signal: abortController.signal,
        });

        sink.next(await resp.json());
        sink.complete();
      })
      .catch((err) => {
        if (err instanceof DOMException && err.name === 'AbortError') {
          sink.complete();
        } else {
          sink.error(err);
        }
      });

    return () => {
      abortController.abort();
    };
  });

const wsClient = createClient({
  url: `${config.WS_URL}`,
  retryAttempts: Infinity,
  shouldRetry: () => true,
  keepAlive: 10000,
  connectionParams: async () => {
    const accessToken = await getAccessToken(msalInstance);
    // return an object with `headers`
    return {
      headers: accessToken != null ? { Authorization: `bearer ${accessToken}` } : {},
    };
  },
});

const subscribeFn: SubscribeFunction = (request, variables) => {
  return Observable.create((sink) => {
    let cleanUp = () => {};
    Promise.resolve()
      .then(() => {
        cleanUp = wsClient.subscribe(
          {
            operationName: request.name,
            query: request.text ?? '',
            variables,
          },
          sink as Sink<ExecutionResult<PayloadData, PayloadExtensions>>,
        );
      })
      .catch((err) => sink.error(err));
    return () => cleanUp();
  });
};

function createRelayEnvironment(): IEnvironment {
  RelayFeatureFlags.ENABLE_RELAY_RESOLVERS = true;
  return new Environment({
    network: Network.create(fetchFn, subscribeFn),
    store: new Store(new RecordSource()),
  });
}

export async function getAccessToken(msal: IPublicClientApplication): Promise<string | null> {
  const [account] = msal.getAllAccounts();
  if (!account) {
    // HACK: Msal throws an exception if two authentication interactions are happening at the same time,
    //  and we have no way of knowing if msal is already trying to sign in the user through a redirect at this point,
    //  so we just wait for the redirect to happen and unload our code or trigger our own if it never happens.
    await new Promise<void>((resolve) => {
      setTimeout(() => resolve(), 50);
    });

    await msal.loginRedirect(loginRequest);

    // Stall. We should get redirected anyway so execution should already be stopped.
    return new Promise<null>(() => {});
  }

  const tokenRequest = { ...apiRequest, account };
  try {
    const { accessToken } = (await msal.acquireTokenSilent(tokenRequest)) || {};
    return accessToken;
  } catch (e) {
    if (e instanceof InteractionRequiredAuthError) {
      try {
        await msal.acquireTokenRedirect({ ...tokenRequest, prompt: 'login' });
      } catch {
        await msal.loginRedirect({ ...loginRequest, account: account });
      }
      return null;
    }
  }
  return null;
}

export const RelayEnvironment = createRelayEnvironment();
