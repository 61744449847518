/**
 * @generated SignedSource<<d4833dc44a1255f3db67a8b353b6612e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type TabDataSyncSeedGeneratedMutation$variables = Record<PropertyKey, never>;
export type TabDataSyncSeedGeneratedMutation$data = {
  readonly seedGeneratedData: {
    readonly boolean: boolean | null | undefined;
  };
};
export type TabDataSyncSeedGeneratedMutation = {
  response: TabDataSyncSeedGeneratedMutation$data;
  variables: TabDataSyncSeedGeneratedMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "input",
        "value": {
          "safety": true
        }
      }
    ],
    "concreteType": "SeedGeneratedDataPayload",
    "kind": "LinkedField",
    "name": "seedGeneratedData",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "boolean",
        "storageKey": null
      }
    ],
    "storageKey": "seedGeneratedData(input:{\"safety\":true})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TabDataSyncSeedGeneratedMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TabDataSyncSeedGeneratedMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "c504672d8e827ad0d79b7396e31fde4e",
    "id": null,
    "metadata": {},
    "name": "TabDataSyncSeedGeneratedMutation",
    "operationKind": "mutation",
    "text": "mutation TabDataSyncSeedGeneratedMutation {\n  seedGeneratedData(input: {safety: true}) {\n    boolean\n  }\n}\n"
  }
};
})();

(node as any).hash = "b75e55d057b5f78a8c9677bc8025c846";

export default node;
