import NotificationsIcon from '@mui/icons-material/Notifications';
import { Badge, IconButton, ListItem, Menu, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { DataID, useFragment, usePaginationFragment, useSubscription } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { usePromiseMutation } from '../common/hooks/usePromiseMutation';
import { NotificationMenu_MarkAllNotificationsReadMutation } from './__generated__/NotificationMenu_MarkAllNotificationsReadMutation.graphql';
import { NotificationRow } from './NotificationRow';
import CheckIcon from '@mui/icons-material/Check';
import { NotificationMenu_MarkNotificationAsReadMutation } from './__generated__/NotificationMenu_MarkNotificationAsReadMutation.graphql';
import LoadingButton from '@mui/lab/LoadingButton';
import { NotificationMenuFragment$key } from './__generated__/NotificationMenuFragment.graphql';
import { NotificationMenuAllFragment$data, NotificationMenuAllFragment$key } from './__generated__/NotificationMenuAllFragment.graphql';
import {
  NotificationMenuUnreadFragment$data,
  NotificationMenuUnreadFragment$key,
} from './__generated__/NotificationMenuUnreadFragment.graphql';
import { useTranslation } from 'react-i18next';

export function NotificationButton({
  onClick: handleClick,
  unreadBadgeContent,
  sx,
}: {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  unreadBadgeContent: string | undefined;
  sx?: SxProps<Theme>;
}) {
  return (
    <IconButton sx={sx} onClick={handleClick} aria-label='notification'>
      <Badge badgeContent={unreadBadgeContent} color='secondary' overlap='circular'>
        <NotificationsIcon
          sx={{
            fontSize: '1.75rem',
            color: '#b3b3b3', // set to #b3b3b3 to match what's used in other projects.
          }}
        />
      </Badge>
    </IconButton>
  );
}

export function NotificationMenu({ $key, sx }: { $key: NotificationMenuFragment$key; sx?: SxProps<Theme> }) {
  const $data = useFragment(
    graphql`
      fragment NotificationMenuFragment on Query {
        ...NotificationMenuAllFragment
        ...NotificationMenuUnreadFragment
      }
    `,
    $key,
  );

  const all$key: NotificationMenuAllFragment$key = $data;
  const unread$key: NotificationMenuUnreadFragment$key = $data;
  const notificationListConnection = usePaginationFragment(
    graphql`
      fragment NotificationMenuAllFragment on Query
      @refetchable(queryName: "NotificationMenuAllFragmentQuery_all")
      @argumentDefinitions(cursor: { type: "String" }, count: { type: "Int", defaultValue: 25 }) {
        allNotifications: notifications(after: $cursor, first: $count) @connection(key: "NotificationMenuAllQuery_allNotifications") {
          __id
          edges {
            node {
              id
              ...NotificationRowFragment
            }
          }
        }
      }
    `,
    all$key,
  );

  const unreadNotificationsConnection = usePaginationFragment(
    graphql`
      fragment NotificationMenuUnreadFragment on Query
      @refetchable(queryName: "NotificationMenuUnreadFragmentQuery_unread")
      @argumentDefinitions(cursor: { type: "String" }, count: { type: "Int", defaultValue: 100 }) {
        unreadNotifications: notifications(where: { isRead: { eq: false } }, after: $cursor, first: $count)
          @connection(key: "NotificationMenuUnreadQuery_unreadNotifications") {
          __id
          edges {
            node {
              ...NotificationRowFragment
            }
          }
        }
      }
    `,
    unread$key,
  );

  const handleLoadNext = useCallback(() => {
    notificationListConnection.loadNext(25);
  }, [notificationListConnection]);

  if (!notificationListConnection.data.allNotifications || !unreadNotificationsConnection.data.unreadNotifications) return null;

  return (
    <NotificationMenuContent
      allNotifications$data={notificationListConnection.data.allNotifications}
      unreadNotifications$data={unreadNotificationsConnection.data.unreadNotifications}
      sx={sx}
      unreadNotificationCount={unreadNotificationsConnection.data.unreadNotifications.edges?.length}
      hasNext={notificationListConnection.hasNext}
      isLoadingNext={notificationListConnection.isLoadingNext}
      onLoadNext={handleLoadNext}
    />
  );
}

function NotificationMenuContent({
  allNotifications$data,
  unreadNotifications$data,
  unreadNotificationCount,
  hasNext,
  isLoadingNext,
  onLoadNext: handleLoadNext,
  sx,
}: {
  allNotifications$data: NonNullable<NotificationMenuAllFragment$data['allNotifications']>;
  unreadNotifications$data: NonNullable<NotificationMenuUnreadFragment$data['unreadNotifications']>;
  unreadNotificationCount: number | undefined;
  hasNext: boolean;
  isLoadingNext: boolean;
  onLoadNext: () => void;
  sx?: SxProps<Theme>;
}) {
  const { t } = useTranslation('layout');

  const [commitMarkAllNotificationAsRead] = usePromiseMutation<NotificationMenu_MarkAllNotificationsReadMutation>(graphql`
    mutation NotificationMenu_MarkAllNotificationsReadMutation($deleteFrom: [ID!]!) {
      markAllNotificationsRead {
        notificationEdge {
          node {
            id @deleteEdge(connections: $deleteFrom)
            ...NotificationRowFragment
          }
          cursor
        }
      }
    }
  `);

  const [commitMarkNotificationAsRead] = usePromiseMutation<NotificationMenu_MarkNotificationAsReadMutation>(graphql`
    mutation NotificationMenu_MarkNotificationAsReadMutation($id: ID!, $deleteFrom: [ID!]!) {
      markNotificationRead(input: { id: $id }) {
        notificationEdge {
          node {
            id @deleteEdge(connections: $deleteFrom)
            ...NotificationRowFragment
          }
          cursor
        }
        errors {
          __typename
        }
      }
    }
  `);

  const allNotificationsConnectionId = allNotifications$data.__id;
  const unreadNotificationsConnectionId = unreadNotifications$data.__id;

  useSubscription(
    useMemo(
      () => ({
        subscription: graphql`
          subscription NotificationMenu_NotificationCreatedSubscription($prependIn: [ID!]!) {
            notificationCreated @prependEdge(connections: $prependIn) {
              node {
                ...NotificationRowFragment
              }
              cursor
            }
          }
        `,
        variables: {
          prependIn: [allNotificationsConnectionId, unreadNotificationsConnectionId],
        },
      }),
      [allNotificationsConnectionId, unreadNotificationsConnectionId],
    ),
  );

  useSubscription(
    useMemo(
      () => ({
        subscription: graphql`
          subscription NotificationMenu_NotificationReadSubscription($deleteFrom: [ID!]!) {
            notificationRead {
              node {
                id @deleteEdge(connections: $deleteFrom)
                ...NotificationRowFragment
              }
              cursor
            }
          }
        `,
        variables: {
          deleteFrom: [unreadNotificationsConnectionId],
        },
      }),
      [unreadNotificationsConnectionId],
    ),
  );

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClickMarkAll = async () => {
    await commitMarkAllNotificationAsRead({ variables: { deleteFrom: [unreadNotificationsConnectionId] } });
  };

  const handleClickMarkSingle = useCallback(
    async (id: DataID, isRead: boolean) => {
      if (!isRead) {
        await commitMarkNotificationAsRead({ variables: { id, deleteFrom: [unreadNotificationsConnectionId] } });
      }
    },
    [commitMarkNotificationAsRead, unreadNotificationsConnectionId],
  );

  const notifications =
    allNotifications$data.edges?.map((n) => <NotificationRow key={n.node.id} $key={n.node} onClick={handleClickMarkSingle} />) ?? [];
  const unreadBadgeContent = unreadNotificationCount ? unreadNotificationCount.toString() : undefined;
  return (
    <>
      <NotificationButton sx={sx} onClick={handleMenuClick} unreadBadgeContent={unreadBadgeContent} />
      <Menu
        MenuListProps={{ 'aria-label': 'notificationMenu' }}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleMenuClose}
        sx={{ '--notification_menu__width': '28rem' }}>
        {notifications.length > 0 ? (
          <ListItem sx={{ flexDirection: 'row', justifyContent: 'space-between', gap: '1rem' }} divider>
            <Typography variant='h6' component='h2'>
              {t('notification.title')}
            </Typography>
            <Tooltip title={t('notification.markAllRead')}>
              <IconButton size='medium' onClick={handleClickMarkAll}>
                <CheckIcon />
              </IconButton>
            </Tooltip>
          </ListItem>
        ) : (
          <ListItem>{t('notification.empty')}</ListItem>
        )}
        {notifications}
        {hasNext && (
          <ListItem sx={{ justifyContent: 'center' }}>
            <LoadingButton onClick={handleLoadNext} loading={isLoadingNext}>
              {t('notification.loadNext')}
            </LoadingButton>
          </ListItem>
        )}
      </Menu>
    </>
  );
}
