/**
 * @generated SignedSource<<6921cb17cb5e95d7b6596b1e50f115e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NatureOfWorkSubCategoryEnum = "accessory" | "airports" | "antennas" | "aqueductsSewer" | "blade" | "bridges" | "cages" | "chambers" | "dams" | "demolition" | "distribution" | "docks" | "excavation" | "gearboxes" | "generator" | "gondola" | "hand" | "handling" | "landscaping" | "lifting" | "lines" | "modules" | "newConstruction" | "nonOperated" | "powerPlants" | "pruning" | "pylons" | "refurbishment" | "retentionBasins" | "roadsRails" | "rotor" | "shelters" | "substations" | "towers" | "transformers" | "transport" | "ventilationPlumbingElectrical" | "%future added value";
export type ServiceCallKind = "accessoriesRental" | "bare" | "bareWithOperators" | "laborRental" | "laborRentalOnBare" | "laborRentalOnOperated" | "liftingPlan" | "liftingTest" | "operatedHourly" | "operatedMonthly" | "rollingEquipment" | "storage" | "%future added value";
export type SuggestionPromptInput = {
  accessoryTypes: ReadonlyArray<string>;
  capacity?: number | null | undefined;
  clientId?: string | null | undefined;
  clientRequirements: ReadonlyArray<string>;
  dispatchBranchId?: string | null | undefined;
  equipmentKindCode?: string | null | undefined;
  hoursPerDay?: number | null | undefined;
  inTheCareOf?: string | null | undefined;
  isSecondServing?: boolean | null | undefined;
  lengthInDays?: number | null | undefined;
  natureOfWorkCode?: string | null | undefined;
  natureOfWorkSubCategory?: NatureOfWorkSubCategoryEnum | null | undefined;
  representativeId?: string | null | undefined;
  salesBranchId?: string | null | undefined;
  serviceCallKind?: ServiceCallKind | null | undefined;
  vehicleIds: ReadonlyArray<string>;
  workDescription?: string | null | undefined;
  worksiteId?: string | null | undefined;
  worksiteRequirements: ReadonlyArray<string>;
};
export type NatureOfWorkAutocompleteQuery$variables = {
  searchTerm?: string | null | undefined;
  skipSuggestion: boolean;
  suggestionCount: number;
  suggestionPrompt: SuggestionPromptInput;
  suggestionScoreThreshold: number;
};
export type NatureOfWorkAutocompleteQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"NatureOfWorkAutocompleteListFragment">;
};
export type NatureOfWorkAutocompleteQuery = {
  response: NatureOfWorkAutocompleteQuery$data;
  variables: NatureOfWorkAutocompleteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchTerm"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skipSuggestion"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "suggestionCount"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "suggestionPrompt"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "suggestionScoreThreshold"
},
v5 = {
  "kind": "Variable",
  "name": "searchTerm",
  "variableName": "searchTerm"
},
v6 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 25
  },
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v9 = {
  "name": "label",
  "args": null,
  "fragment": {
    "kind": "InlineFragment",
    "selections": [
      (v8/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      }
    ],
    "type": "NatureOfWorkLookup",
    "abstractKey": null
  },
  "kind": "RelayResolver",
  "storageKey": null,
  "isOutputType": true
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NatureOfWorkAutocompleteQuery",
    "selections": [
      {
        "args": [
          (v5/*: any*/),
          {
            "kind": "Variable",
            "name": "skipSuggestion",
            "variableName": "skipSuggestion"
          },
          {
            "kind": "Variable",
            "name": "suggestionCount",
            "variableName": "suggestionCount"
          },
          {
            "kind": "Variable",
            "name": "suggestionPrompt",
            "variableName": "suggestionPrompt"
          },
          {
            "kind": "Variable",
            "name": "suggestionScoreThreshold",
            "variableName": "suggestionScoreThreshold"
          }
        ],
        "kind": "FragmentSpread",
        "name": "NatureOfWorkAutocompleteListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "NatureOfWorkAutocompleteQuery",
    "selections": [
      {
        "alias": "searchResults",
        "args": (v6/*: any*/),
        "concreteType": "SearchNatureOfWorksConnection",
        "kind": "LinkedField",
        "name": "searchNatureOfWorks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SearchNatureOfWorksEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NatureOfWorkLookup",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "searchResults",
        "args": (v6/*: any*/),
        "filters": [
          "searchTerm"
        ],
        "handle": "connection",
        "key": "natureOfWorkAutocomplete2ListFragment_searchResults",
        "kind": "LinkedHandle",
        "name": "searchNatureOfWorks"
      },
      {
        "condition": "skipSuggestion",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "suggestions",
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "suggestionCount"
              },
              {
                "kind": "Variable",
                "name": "prompt",
                "variableName": "suggestionPrompt"
              },
              {
                "kind": "Variable",
                "name": "scoreThreshold",
                "variableName": "suggestionScoreThreshold"
              }
            ],
            "concreteType": "SuggestionOfNatureOfWorkLookup",
            "kind": "LinkedField",
            "name": "suggestedNatureOfWorks",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "score",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "NatureOfWorkLookup",
                "kind": "LinkedField",
                "name": "value",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "c0f4d91b9a2949947bb02a07f40e46e9",
    "id": null,
    "metadata": {},
    "name": "NatureOfWorkAutocompleteQuery",
    "operationKind": "query",
    "text": "query NatureOfWorkAutocompleteQuery(\n  $searchTerm: String\n  $suggestionPrompt: SuggestionPromptInput!\n  $suggestionCount: Int!\n  $suggestionScoreThreshold: Float!\n  $skipSuggestion: Boolean!\n) {\n  ...NatureOfWorkAutocompleteListFragment_1cLbMR\n}\n\nfragment NatureOfWorkAutocompleteListFragment_1cLbMR on Query {\n  searchResults: searchNatureOfWorks(searchTerm: $searchTerm, first: 25) {\n    edges {\n      node {\n        id\n        code\n        ...NatureOfWorkLabelResolver\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  suggestions: suggestedNatureOfWorks(prompt: $suggestionPrompt, count: $suggestionCount, scoreThreshold: $suggestionScoreThreshold) @skip(if: $skipSuggestion) {\n    score\n    value {\n      id\n      code\n      ...NatureOfWorkLabelResolver\n    }\n  }\n}\n\nfragment NatureOfWorkLabelResolver on NatureOfWorkLookup {\n  code\n  description\n}\n"
  }
};
})();

(node as any).hash = "fcfa31fcd90e78c370adbb4317c28394";

export default node;
