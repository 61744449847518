/**
 * @generated SignedSource<<e094a10f4ce3e46d0f22e2f0fb52aa80>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccessoryLinesFields_AccessoryLineCollectionFragment$data = {
  readonly accessoryLines?: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"AccessoryLinesFields_AccessoryLineItemsFragment">;
  }>;
  readonly " $fragmentType": "AccessoryLinesFields_AccessoryLineCollectionFragment";
};
export type AccessoryLinesFields_AccessoryLineCollectionFragment$key = {
  readonly " $data"?: AccessoryLinesFields_AccessoryLineCollectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryLinesFields_AccessoryLineCollectionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skipAccessories"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccessoryLinesFields_AccessoryLineCollectionFragment",
  "selections": [
    {
      "condition": "skipAccessories",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AccessoryLine",
          "kind": "LinkedField",
          "name": "accessoryLines",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "AccessoryLinesFields_AccessoryLineItemsFragment"
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "CostsInternalBase",
  "abstractKey": null
};

(node as any).hash = "b887ad4d007f676d47fd10b31455324e";

export default node;
