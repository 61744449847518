import { DataID, useFragment, useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {
  arrPatchBy,
  flagRemoved,
  hasChanged,
  isPatchableEditProps,
  Patchable,
  PatchableEditProps,
  toPatchOperation,
  usePatchable,
} from '../../common/utils/patchable';
import {
  costLineSubFormContext,
  FieldCostLineBillable,
  FieldCostLineBillingCode,
  FieldCostLineBillingCodeCategory,
  FieldCostLineCraneIndex,
  FieldCostLineDefaultQuantity,
  FieldCostLineIsFixedQuantity,
  FieldCostLineIsFractionAllowed,
  FieldCostLineKind,
  FieldCostLineQuantity,
  FieldCostLineRate,
  FieldCostLineRequireWorkForceType,
  FieldCostLineSalesRateResult,
  FieldCostLineWorkForceType,
  useFieldCostLineBillable,
  useFieldCostLineBillingCode,
  useFieldCostLineBillingCodeCategory,
  useFieldCostLineCraneIndex,
  useFieldCostLineDefaultQuantity,
  useFieldCostLineFractionAllowed,
  useFieldCostLineId,
  useFieldCostLineIsFixedQuantity,
  useFieldCostLineKind,
  useFieldCostLineQuantity,
  useFieldCostLineRate,
  useFieldCostLineSalesRateResult,
  useFieldCostLineWorkForceType,
} from './CostLineSubFormFields';
import {
  createFieldKey,
  useField,
  useFieldErrorsFirstMessage,
  useFieldHasErrors,
  useFieldIsDirty,
  useFieldMapper,
  useFieldValidation,
  useFormEtag,
  useFormIsDirty,
  useFormMappings,
} from '../../common/utils/forms';
import { jobStageBaseFormContext } from '../JobStageBaseFields';
import { ReactNode, Suspense, useCallback, useEffect, useMemo } from 'react';
import { nanoid } from 'nanoid';
import { undefinedIfEmpty } from '../../common/utils/formUtils';
import { PatchableNewProps, useRenderItemById, useRenderNewItem, useRenderSubFormCollection } from '../../common/utils/patchableForm';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { CostLinesFields_CostLineCollectionFragment$key } from './__generated__/CostLinesFields_CostLineCollectionFragment.graphql';
import { SaveCostLineInput } from '../../quote/__generated__/QuoteSaveButtonMutation.graphql';
import { CostLinesFields_useResetWorkForceTypeFragment$key } from './__generated__/CostLinesFields_useResetWorkForceTypeFragment.graphql';
import { asBillingCodeCategory, BillingCodeCategory, castBillingCodeCategory } from '../../__enums__/BillingCodeCategory';
import { CostLinesFields_CostLineItemsFragment$key } from './__generated__/CostLinesFields_CostLineItemsFragment.graphql';
import { useEffectEvent } from '../../common/utils/effectUtils';
import { useTaskState } from '../../common/utils/useTaskState';
import { CostLinesFields_ItemQueryFragment$key } from './__generated__/CostLinesFields_ItemQueryFragment.graphql';
import { CostLinesFields_ItemFragment$key } from './__generated__/CostLinesFields_ItemFragment.graphql';
import { useFieldArrivalDateValue, useFieldAssignedWorksite } from '../fields/ProjectBaseFields';
import { useFieldAssignedClient } from '../fields/ClientBaseFields';
import { useFieldDispatchBranch, useFieldNatureOfWork } from '../fields/SaleProjectFields';
import { useCraneSelectorFavorite } from '../JobEquipment.CraneSelector.Favorite';
import { _throw } from '../../common/utils/_throw';
import { useMissingCostsDependencies } from '../useMissingCostsDependencies';
import { CostLinesFields_useQuantityRateFromSalesRateFragment$key } from './__generated__/CostLinesFields_useQuantityRateFromSalesRateFragment.graphql';
import { IssueIndicatorSalesQuantity, IssueIndicatorSalesRate } from '../salesRate/IssueIndicator';
import {
  CostLinesFields_Item_SalesRateQuery,
  CostLinesFields_Item_SalesRateQuery$variables,
} from './__generated__/CostLinesFields_Item_SalesRateQuery.graphql';
import { SalesRateResult } from '../../common/types/externalResult';
import { CostLinesFields_Item_RulesQuery } from './__generated__/CostLinesFields_Item_RulesQuery.graphql';
import { AdditionalCrane } from '../AdditionalCranesFields';
import { AutomaticCostLineEquipmentInput } from '../__generated__/useSalesRate_useCostLineSalesRateQuery.graphql';
import { SearchBillingCodeInput } from '../../common/components/CostBillingCodeAutocomplete';
import { CostLinesFields_useSetBillingCodeAdditionalDataFragment$key } from './__generated__/CostLinesFields_useSetBillingCodeAdditionalDataFragment.graphql';
import { CostLinesFields_useQuantityRateFromSalesRate_DefaultQuantityFragment$key } from './__generated__/CostLinesFields_useQuantityRateFromSalesRate_DefaultQuantityFragment.graphql';
import { asCostLineKind } from '../../__enums__/CostLineKind';
import { Price } from '../../common/utils/price';
import { ItemDeleteButton } from '../../common/components/ItemDeleteButton';
import { useSalesSearchParams } from '../useSalesSearchParams';
import { SaleStage } from '../saleStage';

export type CostLine = Patchable<{
  id: DataID;
  kind: FieldCostLineKind;
  salesRateResult: FieldCostLineSalesRateResult;
  isFractionAllowed: FieldCostLineIsFractionAllowed;
  billingCodeCategory: FieldCostLineBillingCodeCategory;
  isFixedQuantity: FieldCostLineIsFixedQuantity;
  defaultQuantity: FieldCostLineDefaultQuantity;

  // Editable fields
  craneIndex: FieldCostLineCraneIndex;
  billingCode: FieldCostLineBillingCode;
  requireWorkForceType: FieldCostLineRequireWorkForceType;
  workForceType: FieldCostLineWorkForceType;
  quantity: FieldCostLineQuantity;
  rate: FieldCostLineRate;
  billable: FieldCostLineBillable;
}>;

export function resetCostLineWorkForceType(
  category: BillingCodeCategory,
  source: { workForceType: FieldCostLineWorkForceType; requireWorkForceType: FieldCostLineRequireWorkForceType },
  setters: {
    setWorkForceType: (value: FieldCostLineWorkForceType) => void;
    setRequireWorkForceType: (value: FieldCostLineRequireWorkForceType) => void;
  },
): void {
  if (source.workForceType != null) {
    setters.setWorkForceType(null);
  }

  const newRequireWorkForceType = category === 'labor';
  if (source.requireWorkForceType !== newRequireWorkForceType) {
    setters.setRequireWorkForceType(newRequireWorkForceType);
  }
}
function keySelector(v: CostLine) {
  return v.id;
}

const fieldCostLineCollectionKey = createFieldKey<CostLine[]>();
export function useFieldCostLinesCollection($key: CostLinesFields_CostLineCollectionFragment$key | null | undefined, disabled: boolean) {
  const $data = useFragment(
    graphql`
      fragment CostLinesFields_CostLineCollectionFragment on CostsInternalBase {
        costLines {
          ...CostLinesFields_CostLineItemsFragment
        }
      }
    `,
    $key,
  );

  const items$key: CostLinesFields_CostLineItemsFragment$key | undefined = $data?.costLines;
  const items$data = useFragment(
    graphql`
      fragment CostLinesFields_CostLineItemsFragment on CostLine @relay(plural: true) {
        id
        kind
        craneIndex
        billingCode @required(action: THROW) {
          id
          code
          label
          billingSection {
            shortDescription
          }
        }
        requireWorkForceType
        workForceType {
          id
          label
          code
        }
        quantity
        rate
        billable
        isFractionAllowed
        billingCodeCategory
        isFixedQuantity
        defaultQuantity
        salesRateResult {
          canEditRate
          value {
            isAnnualContract
            isFlexiblePrice
            price
            minimumQuantity
            createdAt
          }
          error {
            code
            description
          }
        }
      }
    `,
    items$key,
  );

  // FIXME in GG-8492. Temporary fix for mapAll in SaveCopyButton which would not return any item since they were not dirty or new. Once we have created create endpoint in backend, remove this.
  const { isCopying, isCopyInNewProject } = useSalesSearchParams();

  //If you call a setCostLines manually it will not trigger a call to the sales rate api. It only monitors changes in other field in the form OR explicit changes in a specific row.
  const [costLines, setCostLines] = useField(
    jobStageBaseFormContext,
    fieldCostLineCollectionKey,
    () =>
      items$data?.map(({ salesRateResult, quantity, rate, billable, billingCodeCategory, defaultQuantity, kind, ...rest }) => ({
        ...(isCopying ? { $new: true as const } : {}), // FIXME in GG-8492 remove this
        ...rest,
        kind: asCostLineKind(kind) ?? 'manual',
        billingCodeCategory: asBillingCodeCategory(billingCodeCategory),
        quantity: quantity ?? null,
        defaultQuantity: defaultQuantity ?? null,
        rate: isCopyInNewProject && salesRateResult?.value?.price ? Price.fromApi(salesRateResult.value.price) : Price.fromApi(rate),
        billable: billable ?? null,
        salesRateResult: salesRateResult
          ? {
              etag: nanoid(),
              canEditRate: salesRateResult.canEditRate,
              value: salesRateResult.value ?? null,
              error: salesRateResult.error ?? null,
            }
          : null,
      })) ?? [],
  );

  const {
    append,
    prepend,
    remove: removeCostLine,
    replace: replaceCostLine,
    patch: patchCostLine,
  } = usePatchable(setCostLines, keySelector);
  const appendCostLine = useCallback((value: CostLine) => append({ ...value, id: nanoid() }), [append]);
  const prependCostLine = useCallback((value: CostLine) => prepend({ ...value, id: nanoid() }), [prepend]);
  const clearAutomaticCostLines = useCallback(() => {
    setCostLines(arrPatchBy(flagRemoved, (cl) => cl.kind === 'automatic'));
  }, [setCostLines]);
  const costLineById = useCallback((id: DataID) => costLines.find((a) => a.id === id), [costLines]);
  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldCostLineCollectionKey);
  useValidation(
    (vals) => vals.filter((v) => !v.$removed).length > 0 || 'errorMessages.costLineIsRequired',
    [],
    'transferable,submittable:required',
  );
  const costLinesIsDirty = useFieldIsDirty(jobStageBaseFormContext, fieldCostLineCollectionKey);

  const useMapperCostLines = useFieldMapper(jobStageBaseFormContext, fieldCostLineCollectionKey);
  useMapperCostLines(
    (rows) => ({
      costsBase: {
        costLines: undefinedIfEmpty(
          rows
            .filter((v) => hasChanged(v))
            .map((v) =>
              toPatchOperation(
                v,
                keySelector,
                (val) =>
                  ({
                    // TODO: Most of these shouldn't be defined as optional in the API.
                    craneIndex: val.craneIndex,
                    billingCodeId: val.billingCode?.id ?? null,
                    requireWorkForceType: val.requireWorkForceType,
                    workForceTypeCode: val.workForceType?.code ?? null,
                    billable: val.billable,
                    quantity: val.quantity,
                    rate: val.rate?.toJSON() ?? null,
                    salesRateResult: { value: val.salesRateResult?.value ?? null, error: val.salesRateResult?.error ?? null },
                    kind: val.kind,
                    isFractionAllowed: val.isFractionAllowed,
                    billingCodeCategory: val.billingCodeCategory,
                    isFixedQuantity: val.isFixedQuantity,
                    defaultQuantity: val.defaultQuantity,
                  }) satisfies SaveCostLineInput,
              ),
            ),
        ),
      },
    }),
    [],
    'save',
  );

  const renderCostLinesCollection = useRenderSubFormCollection(costLines, setCostLines, keySelector, costLineSubFormContext);

  const renderCostLinesNewItem = useRenderNewItem(costLines, appendCostLine, costLineSubFormContext);
  const renderCostLinesItemById = useRenderItemById(costLines, setCostLines, keySelector, costLineSubFormContext);

  const renderAddButton = useCallback(
    (handleAdd: () => void) => {
      return <CostLinesFields_AddButton append={appendCostLine} onAdd={handleAdd} disabled={disabled} />;
    },
    [appendCostLine, disabled],
  );
  const renderSaveButton = useCallback(
    (handleSave: () => void) => {
      return <CostLinesFields_SaveButton patch={patchCostLine} onSave={handleSave} disabled={disabled} />;
    },
    [disabled, patchCostLine],
  );
  const renderDeleteButton = useCallback(
    (handleDelete: () => void) => {
      return <CostLinesFields_DeleteByItemButton remove={removeCostLine} onDelete={handleDelete} disabled={disabled} />;
    },
    [disabled, removeCostLine],
  );

  return {
    costLines,
    costLinesIsDirty,
    costLineById,
    setCostLines,
    clearAutomaticCostLines,
    appendCostLine,
    prependCostLine,
    removeCostLine,
    replaceCostLine,
    patchCostLine,
    renderCostLinesCollection,
    renderCostLinesNewItem,
    renderCostLinesItemById,
    renderAddButton,
    renderSaveButton,
    renderDeleteButton,
  };
}

export function useFieldCostLinesErrors() {
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldCostLineCollectionKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(jobStageBaseFormContext, fieldCostLineCollectionKey);

  return { costLineErrorMessage: message, costLineErrorParams: errorParams, costLineHasErrors: hasErrors };
}

export type CostLineFields_Item_PresentFn = CostLinesFields_ItemContent_PresentFn;
export function CostLineFields_Item({
  disabled,
  required,
  gridMode,
  presentFn,
  presentFallbackFn,
  $key,
  saleStage,
  ...patchableProps
}: {
  presentFn: CostLineFields_Item_PresentFn;
  presentFallbackFn: CostLineFields_Item_PresentFn;
  gridMode: boolean;
  disabled: boolean;
  required: boolean;
  saleStage: SaleStage;
  $key: CostLinesFields_ItemFragment$key | null | undefined;
} & (PatchableEditProps<CostLine> | PatchableNewProps<CostLine>)): ReactNode {
  const $data = useFragment(
    graphql`
      fragment CostLinesFields_ItemFragment on ISale {
        ...CostLinesFields_ItemQueryFragment
      }
    `,
    $key,
  );

  const { value } = isPatchableEditProps<CostLine>(patchableProps) ? patchableProps : { value: null };
  const { onChange } = patchableProps;

  useFieldCostLineId(value?.id ?? 'new');

  const { billingCode, billingCodeIsDirty } = useFieldCostLineBillingCode(value?.billingCode, disabled);
  const { workForceTypeIsDirty } = useFieldCostLineWorkForceType(value?.workForceType, value?.requireWorkForceType, true);
  const { craneIndexIsDirty } = useFieldCostLineCraneIndex(value?.craneIndex ?? 0, disabled);

  const {
    done: resetWorkForceTypeDone,
    start: startResetWorkForceType,
    complete: handleCompleteResetWorkForceType,
  } = useTaskState(billingCode ? 'completed' : 'initial');

  const {
    done: syncBillableDone,
    start: startSyncBillable,
    complete: handleCompleteSyncBillable,
  } = useTaskState(billingCode ? 'completed' : 'initial');

  const { done: rateApiDone, start: startRateApi, complete: handleCompleteRateApi } = useTaskState(billingCode ? 'completed' : 'initial');

  const {
    done: setBillingCodeAdditionalDataDone,
    start: startSetBillingCodeAdditionalData,
    complete: handleCompleteSetBillingCodeAdditionalData,
  } = useTaskState(billingCode ? 'completed' : 'initial');

  const handleStartTasks = useCallback(() => {
    startResetWorkForceType(billingCode);
    startSyncBillable(billingCode);
    startRateApi(billingCode);
    startSetBillingCodeAdditionalData(billingCode);
  }, [billingCode, startRateApi, startResetWorkForceType, startSyncBillable, startSetBillingCodeAdditionalData]);
  const { mapAll } = useFormMappings(costLineSubFormContext);
  const sync = useEffectEvent((formState: unknown) => {
    // Enforce a pseudo dependency on current form state since mapAll is a stable function.
    // This is usually provided as the form etag.
    if (!formState) {
      return;
    }

    // Disable auto-save outside of grid mode. Instead, we rely on manual patching functions.
    if (!gridMode) {
      return;
    }

    // TODO: Should be done as part of the patching functions.
    //  Prevent accidental changes when the component is disabled.
    if (disabled) {
      return;
    }

    onChange(mapAll('sync'));
  });

  const formEtag = useFormEtag(costLineSubFormContext);
  const formDirty = useFormIsDirty(costLineSubFormContext);
  useEffect(() => {
    if (!formDirty) {
      return;
    }

    if (!billingCode) {
      return;
    }

    if (!(resetWorkForceTypeDone && syncBillableDone && rateApiDone && setBillingCodeAdditionalDataDone)) {
      return;
    }

    sync(formEtag);
  }, [billingCode, formDirty, formEtag, rateApiDone, resetWorkForceTypeDone, sync, syncBillableDone, setBillingCodeAdditionalDataDone]);

  return billingCode?.id && (billingCodeIsDirty || workForceTypeIsDirty || craneIndexIsDirty) ? (
    <Suspense
      fallback={
        <CostLinesFields_ItemContent
          presentFn={presentFallbackFn}
          gridMode={gridMode}
          disabled={true}
          onStartTask={() => {}}
          saleStage={saleStage}
          {...patchableProps}
        />
      }>
      <CostLinesFields_ItemQuery
        billingCodeId={billingCode.id}
        presentFn={presentFn}
        gridMode={gridMode}
        disabled={disabled}
        required={required}
        onCompleteResetWorkForceType={handleCompleteResetWorkForceType}
        onCompleteSyncBillable={handleCompleteSyncBillable}
        onCompleteRateApi={handleCompleteRateApi}
        onCompleteSetBillingCodeAdditionalData={handleCompleteSetBillingCodeAdditionalData}
        onStartTasks={handleStartTasks}
        $key={$data}
        saleStage={saleStage}
        {...patchableProps}
      />
    </Suspense>
  ) : (
    <CostLinesFields_ItemContent
      presentFn={presentFn}
      gridMode={gridMode}
      disabled={disabled}
      saleStage={saleStage}
      onStartTask={() => {}}
      {...patchableProps}
    />
  );
}

function useResetWorkForceType(
  $key: CostLinesFields_useResetWorkForceTypeFragment$key,
  value: CostLine | null,
  disabled: boolean,
  onComplete: () => void,
) {
  const { billingCode, billingCodeIsDirty } = useFieldCostLineBillingCode(value?.billingCode, disabled);
  const { resetWorkForceType } = useFieldCostLineWorkForceType(value?.workForceType, value?.requireWorkForceType, disabled);

  const $data = useFragment(
    graphql`
      fragment CostLinesFields_useResetWorkForceTypeFragment on BillingCode {
        billingCodeCategory
      }
    `,
    $key,
  );

  const reset = useEffectEvent(() => {
    if (!billingCodeIsDirty) {
      onComplete();
      return;
    }

    resetWorkForceType(castBillingCodeCategory($data.billingCodeCategory));
    onComplete();
  });
  const skip = useEffectEvent(() => {
    onComplete();
  });
  useEffect(() => {
    if (billingCode) {
      reset();
    } else {
      skip();
    }
  }, [billingCode, reset, skip]);
}

function useSetBillingCodeAdditionalData(
  $key: CostLinesFields_useSetBillingCodeAdditionalDataFragment$key,
  value: CostLine | null,
  disabled: boolean,
  onComplete: () => void,
) {
  const { billingCode, billingCodeIsDirty } = useFieldCostLineBillingCode(value?.billingCode, disabled);
  const { setBillingCodeCategory } = useFieldCostLineBillingCodeCategory(value?.billingCodeCategory ?? null);
  const { setCostLineIsFractionAllowed } = useFieldCostLineFractionAllowed(value?.isFractionAllowed);
  const { setCostLineIsFixedQuantity } = useFieldCostLineIsFixedQuantity(value?.isFixedQuantity);
  const { setCostLineDefaultQuantity } = useFieldCostLineDefaultQuantity(value?.defaultQuantity);

  const $data = useFragment(
    graphql`
      fragment CostLinesFields_useSetBillingCodeAdditionalDataFragment on BillingCode {
        billingCodeCategory
        isFractionAllowed
        isFixedQuantity
        defaultQuantity
      }
    `,
    $key,
  );

  const setAdditionalData = useEffectEvent(() => {
    if (!billingCodeIsDirty) {
      onComplete();
      return;
    }
    setBillingCodeCategory(asBillingCodeCategory($data.billingCodeCategory));
    setCostLineIsFractionAllowed($data.isFractionAllowed);
    setCostLineIsFixedQuantity($data.isFixedQuantity);
    setCostLineDefaultQuantity($data.defaultQuantity ?? null);
    onComplete();
  });

  const skip = useEffectEvent(() => {
    onComplete();
  });

  useEffect(() => {
    if (billingCode) {
      setAdditionalData();
    } else {
      skip();
    }
  }, [billingCode, setAdditionalData, skip]);
}

function useSyncQuantityRateBillableWithBillingCode(value: CostLine | null, disabled: boolean, onComplete: () => void) {
  const { billingCode, billingCodeIsDirty } = useFieldCostLineBillingCode(value?.billingCode, disabled);
  const { costLineBillable, setCostLineBillable } = useFieldCostLineBillable(value?.billable, disabled);

  const setBillable = useEffectEvent(() => {
    if (!billingCodeIsDirty) {
      onComplete();
      return;
    }

    const newBillable = true; //< Not controlled by BillingCode
    if (costLineBillable !== newBillable) {
      setCostLineBillable(newBillable);
    }

    onComplete();
  });
  const skip = useEffectEvent(() => {
    onComplete();
  });

  useEffect(() => {
    if (billingCode) {
      setBillable();
    } else {
      skip();
    }
  }, [billingCode, setBillable, skip]);
}

function useQuantityRateFromSalesRate(
  value: CostLine | null,
  disabled: boolean,
  onComplete: () => void,
  $key: CostLinesFields_useQuantityRateFromSalesRateFragment$key | null | undefined,
  billingCode$key: CostLinesFields_useQuantityRateFromSalesRate_DefaultQuantityFragment$key | null | undefined,
) {
  const $data = useFragment(
    graphql`
      fragment CostLinesFields_useQuantityRateFromSalesRateFragment on SalesRatesResponse {
        results {
          craneIndex
          salesRates {
            canEditRate
            value {
              isFlexiblePrice
              isAnnualContract
              minimumQuantity
              price
              createdAt
            }
            error {
              code
              description
            }
          }
        }
      }
    `,
    $key,
  );

  const billingCode$data = useFragment(
    graphql`
      fragment CostLinesFields_useQuantityRateFromSalesRate_DefaultQuantityFragment on BillingCode {
        defaultQuantity
        isFixedQuantity
      }
    `,
    billingCode$key,
  );
  const { costLineQuantity, setCostLineQuantity } = useFieldCostLineQuantity(value?.quantity, disabled);
  const { costLineRate, setCostLineRate } = useFieldCostLineRate(value?.rate, disabled);
  const { costLineSalesRateResult, setCostLineSalesResultResult } = useFieldCostLineSalesRateResult(value?.salesRateResult);
  const defaultQuantity = useMemo(
    () => (billingCode$data?.isFixedQuantity ? 1 : (billingCode$data?.defaultQuantity ?? null)),
    [billingCode$data?.defaultQuantity, billingCode$data?.isFixedQuantity],
  );

  const setSalesRate = useEffectEvent((result: SalesRateResult) => {
    if (result.error) {
      setCostLineSalesResultResult(result);
      onComplete();
      return;
    }
    const newQuantity = !result.value || result.value.minimumQuantity <= 0 ? defaultQuantity : result.value.minimumQuantity;
    if (costLineQuantity !== newQuantity && costLineSalesRateResult?.value?.minimumQuantity !== costLineQuantity) {
      setCostLineQuantity(newQuantity);
    }

    const newRate = Price.fromApi(result.value?.price);
    if (costLineRate !== newRate) {
      setCostLineRate(newRate);
    }
    setCostLineSalesResultResult(result);

    onComplete();
  });
  const skipSalesRate = useEffectEvent(() => {
    onComplete();
  });
  const executeFallBackRate = useEffectEvent(() => {
    setCostLineSalesResultResult({
      etag: nanoid(),
      canEditRate: false,
      value: null,
      error: { code: '500', description: 'Server Error' },
    } satisfies SalesRateResult);
    setCostLineRate(null);
    setCostLineQuantity(defaultQuantity);
    onComplete();
  });
  useEffect(() => {
    if (!$data) {
      skipSalesRate();
      return;
    }
    if (!$data.results[0]?.salesRates[0]) {
      executeFallBackRate();
      return;
    }

    setSalesRate({
      etag: nanoid(),
      // results[0].salesRates[0] because this is for a single cost line of a single equipment so there will only be 1 result
      canEditRate: $data.results[0].salesRates[0].canEditRate,
      value: $data.results[0].salesRates[0].value ?? null,
      error: $data.results[0].salesRates[0].error ?? null,
    });
  }, [$data, executeFallBackRate, setSalesRate, skipSalesRate]);
}

function CostLinesFields_ItemQuery({
  billingCodeId,
  presentFn,
  gridMode,
  disabled,
  required,
  onCompleteResetWorkForceType,
  onCompleteSyncBillable,
  onCompleteRateApi,
  onCompleteSetBillingCodeAdditionalData,
  onStartTasks: handleStartTasks,
  $key,
  saleStage,
  ...patchableProps
}: {
  billingCodeId: DataID;
  $key: CostLinesFields_ItemQueryFragment$key | null | undefined;
  presentFn: CostLinesFields_ItemContent_PresentFn;
  gridMode: boolean;
  disabled: boolean;
  required: boolean;
  onCompleteResetWorkForceType: () => void;
  onCompleteSyncBillable: () => void;
  onCompleteRateApi: () => void;
  onCompleteSetBillingCodeAdditionalData: () => void;
  onStartTasks: () => void;
  saleStage: SaleStage;
} & (PatchableEditProps<CostLine> | PatchableNewProps<CostLine>)) {
  const { value } = isPatchableEditProps<CostLine>(patchableProps) ? patchableProps : { value: null };

  const $data = useFragment(
    graphql`
      fragment CostLinesFields_ItemQueryFragment on ISale {
        ...useMissingCostsDependenciesFragment
        projectBase {
          ...ProjectBaseFields_ArrivalDateFragment @arguments(isCopy: false)
          ...ProjectBaseFields_AssignedWorksiteFragment
        }
        clientBase {
          ...ClientBaseFields_AssignedClientFragment
        }
        project {
          ...SaleProjectFields_DispatchBranchFragment
          ...SaleProjectFields_NatureOfWorkFragment
        }
        equipmentBase {
          craneSelector {
            ...JobEquipment_useCraneSelectorFavoriteFragment
          }
        }
        costsBase {
          ...CostLinesFields_CostLineCollectionFragment
        }
      }
    `,
    $key,
  );
  const { arrivalDate } = useFieldArrivalDateValue($data?.projectBase);
  const { assignedClient } = useFieldAssignedClient($data?.clientBase);
  const { dispatchBranch } = useFieldDispatchBranch($data?.project, disabled);
  const { assignedWorksite } = useFieldAssignedWorksite($data?.projectBase);
  const { natureOfWork } = useFieldNatureOfWork($data?.project, disabled);
  const { favorite } = useCraneSelectorFavorite($data?.equipmentBase?.craneSelector, required);
  const missingDependencies = useMissingCostsDependencies($data);
  if (missingDependencies) {
    throw new Error('Missing dependencies to render CostLinesFields_ItemQuery');
  }

  const { billingCode } = useFieldCostLineBillingCode(value?.billingCode, disabled);
  const { workForceType } = useFieldCostLineWorkForceType(value?.workForceType, value?.requireWorkForceType, required);
  const { craneIndex } = useFieldCostLineCraneIndex(value?.craneIndex ?? 0, disabled);
  const rules$data = useLazyLoadQuery<CostLinesFields_Item_RulesQuery>(
    graphql`
      query CostLinesFields_Item_RulesQuery($billingCodeId: ID!) {
        node(id: $billingCodeId) @required(action: THROW) {
          ... on BillingCode {
            ...CostLinesFields_useResetWorkForceTypeFragment
            ...CostLinesFields_useSetBillingCodeAdditionalDataFragment
            ...CostLinesFields_useQuantityRateFromSalesRate_DefaultQuantityFragment
            billingCodeCategory
          }
        }
      }
    `,
    {
      billingCodeId: billingCodeId,
    },
    { fetchPolicy: 'network-only' }, //HACK: This is mandatory to prevent a weird interaction when having data coming from the cache we'll be fixed with the implementation of patchable2.0
  );

  const variables = useMemo<CostLinesFields_Item_SalesRateQuery$variables>(() => {
    const equipments: Array<AutomaticCostLineEquipmentInput | null> = [
      {
        boomConfigurationId: favorite?.boomConfiguration?.id,
        vehicleId: favorite?.vehicleId?.key ?? null,
      },
      ...(favorite?.additionalCranes.map((ac) =>
        ac.deletedAt
          ? null
          : {
              boomConfigurationId: ac?.boomConfiguration?.id,
              vehicleId: null,
            },
      ) ?? []),
    ];
    return {
      skip: workForceType?.code == null && rules$data.node.billingCodeCategory === 'labor',
      salesRateInput: {
        arrivalDate: arrivalDate!.toJSON() ?? _throw(new Error('Invalid arrivalDate')),
        clientId: assignedClient!.id,
        dispatchBranchId: dispatchBranch!.id,
        worksiteId: assignedWorksite?.id && assignedWorksite.id !== 'new' ? assignedWorksite.id : null,
        natureOfWorkCode: natureOfWork!.code,
        billingInfos: [
          {
            billingCodeId: billingCode?.id ?? _throw(new Error('Invalid billing code')),
            itemTypeCode: workForceType?.code ?? 0,
            craneIndex: craneIndex,
          },
        ],
        equipments: equipments,
      },
    };
  }, [
    arrivalDate,
    assignedClient,
    assignedWorksite?.id,
    billingCode?.id,
    craneIndex,
    dispatchBranch,
    favorite?.additionalCranes,
    favorite?.boomConfiguration,
    favorite?.vehicleId?.key,
    natureOfWork,
    rules$data.node.billingCodeCategory,
    workForceType?.code,
  ]);

  const salesRate$data = useLazyLoadQuery<CostLinesFields_Item_SalesRateQuery>(
    graphql`
      query CostLinesFields_Item_SalesRateQuery($salesRateInput: SalesRatesRequestInput!, $skip: Boolean!) {
        salesRates(input: $salesRateInput) @skip(if: $skip) {
          ...CostLinesFields_useQuantityRateFromSalesRateFragment
        }
      }
    `,
    variables,
    { fetchPolicy: 'network-only' }, //HACK: This is mandatory to prevent a weird interaction when having data coming from the cache we'll be fixed with the implementation of patchable2.0
  );

  useResetWorkForceType(rules$data.node, value, disabled, onCompleteResetWorkForceType);
  useSetBillingCodeAdditionalData(rules$data.node, value, disabled, onCompleteSetBillingCodeAdditionalData);
  useSyncQuantityRateBillableWithBillingCode(value, disabled, onCompleteSyncBillable);
  useQuantityRateFromSalesRate(value, disabled, onCompleteRateApi, salesRate$data.salesRates, rules$data.node);

  return (
    <CostLinesFields_ItemContent
      presentFn={presentFn}
      gridMode={gridMode}
      disabled={disabled}
      onStartTask={handleStartTasks}
      saleStage={saleStage}
      {...patchableProps}
    />
  );
}

type CostLinesFields_ItemContent_PresentFn = (
  id: string,
  render: {
    renderCostCraneIndex: (additionalCranes: AdditionalCrane[]) => ReactNode;
    renderCostBillingCode: (searchInput: SearchBillingCodeInput) => ReactNode;
    renderCostWorkForceType: () => ReactNode;
    renderCostQuantity: () => ReactNode;
    renderCostRate: () => ReactNode;
    renderCostBillable: () => ReactNode;
    renderDelete: () => ReactNode;
  },
) => ReactNode;
function CostLinesFields_ItemContent({
  presentFn,
  gridMode,
  disabled,
  onStartTask,
  saleStage,
  ...patchableProps
}: {
  onStartTask: () => void;
  presentFn: CostLinesFields_ItemContent_PresentFn;
  gridMode: boolean;
  disabled: boolean;
  saleStage: SaleStage;
} & (PatchableEditProps<CostLine> | PatchableNewProps<CostLine>)) {
  const { value, onDelete: handleDelete } = isPatchableEditProps<CostLine>(patchableProps)
    ? patchableProps
    : { value: null, onDelete: null };
  const { id } = patchableProps;
  const { costLineSalesRateResult, setCostLineSalesResultResult } = useFieldCostLineSalesRateResult(value?.salesRateResult);
  const { kind } = useFieldCostLineKind(value?.kind);
  const quoteCalculator = kind === 'quoteCalculator';
  const instantCalculator = kind === 'instantCalculator';
  const readOnly = kind === 'automatic' || quoteCalculator || instantCalculator;
  const canEditRate = instantCalculator || (costLineSalesRateResult?.canEditRate === true && !quoteCalculator) || saleStage === 'quote';
  const { costLineIsFractionAllowed } = useFieldCostLineFractionAllowed(value?.isFractionAllowed);
  const { billingCodeCategory } = useFieldCostLineBillingCodeCategory(value?.billingCodeCategory ?? null);
  const { renderCraneIndex } = useFieldCostLineCraneIndex(value?.craneIndex ?? 0, disabled || readOnly);
  const { renderBillingCode, billingCodeIsDirty } = useFieldCostLineBillingCode(value?.billingCode, disabled || readOnly);
  const { renderWorkForceType } = useFieldCostLineWorkForceType(value?.workForceType, value?.requireWorkForceType, disabled || readOnly);
  const { renderCostLineQuantity, costLineQuantity, setCostLineQuantity } = useFieldCostLineQuantity(
    value?.quantity,
    disabled || quoteCalculator,
  );
  const { renderCostLineRate, costLineRate, setCostLineRate } = useFieldCostLineRate(value?.rate, disabled || !canEditRate);
  const { renderCostLineBillable, costLineBillable, setCostLineBillable } = useFieldCostLineBillable(
    value?.billable,
    disabled || instantCalculator,
  );
  const { costLineIsFixedQuantity } = useFieldCostLineIsFixedQuantity(value?.isFixedQuantity);

  const costLineRateAdornment = useCallback(
    (rate: FieldCostLineRate) => {
      if (quoteCalculator) return null;

      return <IssueIndicatorSalesRate salesRateResult={costLineSalesRateResult} rate={rate} />;
    },
    [costLineSalesRateResult, quoteCalculator],
  );

  const reverseSync = useEffectEvent((val: CostLine) => {
    if (val.quantity !== costLineQuantity) {
      setCostLineQuantity(val.quantity);
    }
    if (val.rate !== costLineRate) {
      setCostLineRate(val.rate);
    }
    if (val.billable !== costLineBillable) {
      setCostLineBillable(val.billable);
    }
    if (val.salesRateResult?.etag !== costLineSalesRateResult?.etag) {
      setCostLineSalesResultResult(val.salesRateResult);
    }
  });

  useEffect(() => {
    if (!value) {
      return;
    }
    reverseSync(value);
  }, [reverseSync, value]);
  const startTasks = useEffectEvent(() => onStartTask());
  useEffect(() => {
    if (!billingCodeIsDirty) {
      return;
    }
    startTasks();
  }, [billingCodeIsDirty, onStartTask, startTasks]);

  return presentFn(id, {
    renderCostCraneIndex: (additionalCranes) => renderCraneIndex(additionalCranes, gridMode),
    renderCostBillingCode: (searchInput) => renderBillingCode(gridMode, true, searchInput),
    renderCostWorkForceType: () => renderWorkForceType(gridMode),
    renderCostQuantity: () =>
      renderCostLineQuantity(
        gridMode,
        <IssueIndicatorSalesQuantity
          quantity={costLineQuantity}
          salesRateResult={costLineSalesRateResult}
          isFixedQuantity={costLineIsFixedQuantity}
        />,
        costLineIsFractionAllowed,
        billingCodeCategory,
        costLineIsFixedQuantity,
      ),
    renderCostRate: () => renderCostLineRate(gridMode, costLineRateAdornment),
    renderCostBillable: () => renderCostLineBillable(gridMode),
    renderDelete: () =>
      handleDelete ? <ItemDeleteButton onClick={handleDelete} disabled={disabled} data-label-key='deletedCostLineButton' /> : null,
  });
}

function CostLinesFields_AddButton({
  append,
  onAdd,
  disabled,
}: {
  append: (value: CostLine) => void;
  onAdd: (() => void) | undefined;
  disabled: boolean;
}) {
  const { mapAll } = useFormMappings(costLineSubFormContext);
  const handleAdd = useCallback(() => {
    // id from mapping will be 'new'. We override it with a local id to track the item during edits.
    append({ ...mapAll('sync') });
    onAdd?.();
  }, [append, mapAll, onAdd]);

  // Intentionally looks like the SaveButton
  return (
    <Button
      onClick={handleAdd}
      disabled={disabled}
      variant='contained'
      size='toolbar'
      color='info'
      sx={(theme) => ({
        flexShrink: 0,
        '&.Mui-disabled': {
          backgroundColor: theme.palette.grey[300],
        },
      })}>
      <SaveIcon />
    </Button>
  );
}

function CostLinesFields_SaveButton({
  patch,
  onSave,
  disabled,
}: {
  patch: (value: CostLine) => void;
  onSave: (() => void) | undefined;
  disabled: boolean;
}) {
  const { mapAll } = useFormMappings(costLineSubFormContext);
  const handleSave = useCallback(() => {
    patch(mapAll('sync'));
    onSave?.();
  }, [mapAll, onSave, patch]);

  return (
    <Button
      onClick={handleSave}
      disabled={disabled}
      variant='contained'
      size='toolbar'
      color='info'
      sx={(theme) => ({
        flexShrink: 0,
        '&.Mui-disabled': {
          backgroundColor: theme.palette.grey[300],
        },
      })}>
      <SaveIcon />
    </Button>
  );
}

function CostLinesFields_DeleteByItemButton({
  remove,
  onDelete,
  disabled,
}: {
  remove: (value: CostLine) => void;
  onDelete: (() => void) | undefined;
  disabled: boolean;
}) {
  const { mapAll } = useFormMappings(costLineSubFormContext);
  const handleDelete = useCallback(() => {
    remove(mapAll('sync'));
    onDelete?.();
  }, [mapAll, onDelete, remove]);

  return (
    <Button
      onClick={handleDelete}
      disabled={disabled}
      variant='contained'
      size='toolbar'
      color='error'
      sx={(theme) => ({
        flexShrink: 0,
        mr: '-0.5rem',
        '&.Mui-disabled': {
          backgroundColor: theme.palette.grey[300],
        },
      })}>
      <DeleteIcon />
    </Button>
  );
}
