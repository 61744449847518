/**
 * @generated SignedSource<<10b64628237ec1144ef4a1a4eb657f43>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationRow_ModificationRequestedFragment$data = {
  readonly comment: string;
  readonly friendlyId: string;
  readonly isRead: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationRow_PermalinkFragment">;
  readonly " $fragmentType": "NotificationRow_ModificationRequestedFragment";
};
export type NotificationRow_ModificationRequestedFragment$key = {
  readonly " $data"?: NotificationRow_ModificationRequestedFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationRow_ModificationRequestedFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationRow_ModificationRequestedFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRead",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "friendlyId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comment",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotificationRow_PermalinkFragment"
    }
  ],
  "type": "ServiceCallModificationRequestedNotification",
  "abstractKey": null
};

(node as any).hash = "32970767b56215c000da26d49cada92a";

export default node;
