import { EmptyLayout, SidebarContentProps, SimpleLayout } from './layout/Layouts';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UnderConstructionImage } from './common/svg/UnderConstructionImage';
import { useCallback } from 'react';
import { NavigationMenu } from './layout/SidebarDrawer';
import { useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { MaintenanceQuery } from './__generated__/MaintenanceQuery.graphql';

export function MaintenanceFallback() {
  return (
    <EmptyLayout>
      <MaintenanceContent />
    </EmptyLayout>
  );
}

export function MaintenanceWithMenus() {
  const { t } = useTranslation('common');

  const $data = useLazyLoadQuery<MaintenanceQuery>(
    graphql`
      query MaintenanceQuery {
        ...SidebarDrawerFragment
        ...LayoutsSimpleLayoutFragment
      }
    `,
    {},
  );

  const sidebar = useCallback((props: SidebarContentProps) => <NavigationMenu {...props} $key={$data} />, [$data]);
  return (
    <SimpleLayout heading={t('maintenanceTitle')} sidebarProvider={sidebar} $key={$data}>
      <MaintenanceContent />
    </SimpleLayout>
  );
}

function MaintenanceContent() {
  const { t } = useTranslation('common');
  return (
    <Stack
      sx={{
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        height: '70vh',
        gap: '1rem',
        px: '1rem',
      }}>
      <UnderConstructionImage />
      <Box>
        <Typography sx={{ fontWeight: 500, fontSize: '1.5rem' }} component='h6'>
          {t('maintenanceTitle')}
        </Typography>
        <Typography>{t('maintenancePlaceholder')}</Typography>
      </Box>
    </Stack>
  );
}
