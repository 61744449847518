/**
 * @generated SignedSource<<b1d7c45471de60cd81c287eef53112ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PatchOperationAction = "delete" | "insert" | "skip" | "update" | "%future added value";
export type UpdateBillingCodeListInput = {
  billingCodes: ReadonlyArray<PatchOperationOfSaveBillingCodeInput | null | undefined>;
};
export type PatchOperationOfSaveBillingCodeInput = {
  action: PatchOperationAction;
  id?: string | null | undefined;
  value?: SaveBillingCodeInput | null | undefined;
};
export type SaveBillingCodeInput = {
  enMicroDescription?: string | null | undefined;
  frMicroDescription?: string | null | undefined;
};
export type BillingCodeFormMutation$variables = {
  data: UpdateBillingCodeListInput;
};
export type BillingCodeFormMutation$data = {
  readonly updateBillingCodes: {
    readonly billingCodes: ReadonlyArray<{
      readonly code: number;
      readonly description: Record<string, string>;
      readonly microDescription: Record<string, string> | null | undefined;
    }> | null | undefined;
    readonly errors: ReadonlyArray<{
      readonly __typename: string;
    }> | null | undefined;
  };
};
export type BillingCodeFormMutation = {
  response: BillingCodeFormMutation$data;
  variables: BillingCodeFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "data"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "updateBillingCodeList",
        "variableName": "data"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "microDescription",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BillingCodeFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateBillingCodesPayload",
        "kind": "LinkedField",
        "name": "updateBillingCodes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingCode",
            "kind": "LinkedField",
            "name": "billingCodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BillingCodeFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateBillingCodesPayload",
        "kind": "LinkedField",
        "name": "updateBillingCodes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingCode",
            "kind": "LinkedField",
            "name": "billingCodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "64b75c1e7b7a26efa2d993e00d700dd8",
    "id": null,
    "metadata": {},
    "name": "BillingCodeFormMutation",
    "operationKind": "mutation",
    "text": "mutation BillingCodeFormMutation(\n  $data: UpdateBillingCodeListInput!\n) {\n  updateBillingCodes(input: {updateBillingCodeList: $data}) {\n    billingCodes {\n      code\n      description\n      microDescription\n      id\n    }\n    errors {\n      __typename\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cddff7189eac003c51e50644b3da5326";

export default node;
