import { createRoutesFromElements, Navigate, NavigateProps, Route, useMatches } from 'react-router';
import { App } from './App';
import { DashboardPage } from './dashboard/components/DashboardPage';
import { OpportunitiesPage } from './opportunity/components/OpportunitiesPage';
import { RepresentativesPage } from './representative/components/RepresentativesPage';
import { NavigateExternal } from './common/components/NavigateExternal';
import { configurationRoutes } from './configuration/configurationRoutes';
import { workPlanningRoutes } from './workPlanning/workPlanningRoutes';
import { ClientsPage } from './client/ClientsPage';
import { WorksitesPage } from './worksite/WorksitesPage';
import { permalinkRoutes } from './permalink';
import { billingCodeRulesRoutes } from './billingCodeRules/billingCodeRulesRoutes';
import { quoteRoutes } from './quote/quoteRoutes';
import { serviceCallRoutes } from './serviceCall/serviceCallRoutes';
import { craneChartRoutes } from './craneCharts/craneChartRoutes';
import { maintenanceRoutes } from './maintenance/maintenanceRoutes';

export const routes = createRoutesFromElements(
  <Route path='/' element={<App />}>
    <Route index element={<Navigate to='service-calls' replace />} />
    <Route path='dashboard' element={<DashboardPage />} />
    <Route path='opportunities' element={<OpportunitiesPage />} />
    <Route path='clients' element={<ClientsPage />} />
    <Route path='worksites' element={<WorksitesPage />} />
    <Route path='representatives' element={<RepresentativesPage />} />
    {workPlanningRoutes}
    {quoteRoutes}
    {serviceCallRoutes}
    {configurationRoutes}
    {billingCodeRulesRoutes}
    {craneChartRoutes}
    {maintenanceRoutes}
    <Route
      path='api/*'
      element={
        <NavigateExternal
          transform={process.env.NODE_ENV === 'development' ? ({ pathname }) => `http://localhost:5000${pathname}` : undefined}
        />
      }
    />
    {permalinkRoutes}
    <Route path='*' element={<NavigateVerbose to='/service-calls' replace />} />
  </Route>,
);

function NavigateVerbose(props: NavigateProps) {
  const matches = useMatches();
  console.log('Navigating', 'from', matches, 'to', props);
  return <Navigate {...props} />;
}
