import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { useCallback, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { usePromiseMutation } from '../../common/hooks/usePromiseMutation';
import graphql from 'babel-plugin-relay/macro';
import { DataID } from 'react-relay';
import { useNavigate } from 'react-router';
import { Button } from '@mui/material';
import { ConfirmationDialog } from '../../common/dialogs/ConfirmationDialog';
import { _throw } from '../../common/utils/_throw';
import { useErrorBanner } from '../../common/components/ErrorBanner';
import { NAVIGATE_PREVENT_BLOCK_STATE } from '../../common/hooks/usePreventNavigationOnFormDirty';
import { BillingCodeRuleDeleteButtonMutation } from './__generated__/BillingCodeRuleDeleteButtonMutation.graphql';

export function BillingCodeRuleDeleteButton({ id }: { id: DataID | 'new' }) {
  const { t } = useAmbientTranslation();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { dismissError, reportUnexpectedError } = useErrorBanner();

  const [commit] = usePromiseMutation<BillingCodeRuleDeleteButtonMutation>(graphql`
    mutation BillingCodeRuleDeleteButtonMutation($input: DeleteBillingCodeRuleInput!) {
      deleteBillingCodeRule(input: $input) {
        deleteBillingCodeRuleResponse {
          success
        }
      }
    }
  `);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleCancel = useCallback(() => {
    setOpen(false);
  }, []);

  const handleConfirm = useCallback(async () => {
    dismissError(true);
    try {
      const { response } = await commit({
        variables: { input: { id: id === 'new' ? '' : id } },
      });

      if (response) {
        navigate('/billing-code-rules/cost-line-rules', { state: NAVIGATE_PREVENT_BLOCK_STATE });
      } else {
        _throw(new Error(t('error.errorDuringDeleteBillingCode')));
      }
    } catch {
      reportUnexpectedError(() => t('error.errorDuringDelete'));
    } finally {
      setOpen(false);
    }
  }, [commit, dismissError, id, navigate, reportUnexpectedError, t]);

  return (
    <>
      <Button variant='text' color='cancel' disabled={id === 'new'} onClick={handleOpen}>
        <DeleteIcon color='error' fontSize='small' sx={{ mr: '0.5rem' }} />
        {t('button.delete', { ns: 'common' })}
      </Button>
      <ConfirmationDialog
        title={t('dialog.deleteTitle')}
        message={t('dialog.deleteMessage')}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        confirmText={t('button.delete', { ns: 'common' })}
        isOpen={open}
      />
    </>
  );
}
