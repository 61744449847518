/**
 * @generated SignedSource<<b913fc9be1e097d8dd8aa84ac9232ffe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaleProjectFields_NatureOfWorkInput_SuggestionsFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useSuggestionsFragment">;
  readonly " $fragmentType": "SaleProjectFields_NatureOfWorkInput_SuggestionsFragment";
};
export type SaleProjectFields_NatureOfWorkInput_SuggestionsFragment$key = {
  readonly " $data"?: SaleProjectFields_NatureOfWorkInput_SuggestionsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_NatureOfWorkInput_SuggestionsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skipAccessories"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleProjectFields_NatureOfWorkInput_SuggestionsFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "skipAccessories",
          "variableName": "skipAccessories"
        }
      ],
      "kind": "FragmentSpread",
      "name": "useSuggestionsFragment"
    }
  ],
  "type": "ISale",
  "abstractKey": "__isISale"
};

(node as any).hash = "d9d65d6f3755d0c20059623b6bf52718";

export default node;
