/**
 * @generated SignedSource<<17b9423d1f413751e41913c9bcbd3d24>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as requirementLabelResolverType } from "../../__resolvers__/RequirementLabelResolver";
// Type assertion validating that `requirementLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(requirementLabelResolverType satisfies (
  rootKey: RequirementLabelResolver$key,
) => string | null | undefined);
export type AssignedWorksite_useUpdateWorksiteQueryFragment$data = {
  readonly contact: string | null | undefined;
  readonly location: {
    readonly address: string;
    readonly placeToken: {
      readonly id: string;
      readonly placeId: string;
    } | null | undefined;
  } | null | undefined;
  readonly name: string | null | undefined;
  readonly phoneNumber: string | null | undefined;
  readonly requirements: ReadonlyArray<{
    readonly id: string;
    readonly label: string | null | undefined;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"AssignedWorksite_Label_BaseFragment" | "AssignedWorksite_Overrides_BaseFragment" | "AssignedWorksite_Overrides_DenormalizableFragment">;
  readonly " $fragmentType": "AssignedWorksite_useUpdateWorksiteQueryFragment";
};
export type AssignedWorksite_useUpdateWorksiteQueryFragment$key = {
  readonly " $data"?: AssignedWorksite_useUpdateWorksiteQueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssignedWorksite_useUpdateWorksiteQueryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssignedWorksite_useUpdateWorksiteQueryFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssignedWorksite_Label_BaseFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssignedWorksite_Overrides_BaseFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssignedWorksite_Overrides_DenormalizableFragment"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Location",
      "kind": "LinkedField",
      "name": "location",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PlaceToken",
          "kind": "LinkedField",
          "name": "placeToken",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "placeId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Requirement",
      "kind": "LinkedField",
      "name": "requirements",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "fragment": {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RequirementLabelResolver"
          },
          "kind": "RelayResolver",
          "name": "label",
          "resolverModule": require('./../../__resolvers__/RequirementLabelResolver').label,
          "path": "requirements.label"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contact",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneNumber",
      "storageKey": null
    }
  ],
  "type": "Worksite",
  "abstractKey": null
};
})();

(node as any).hash = "36ad16adea67eb4b272ffd09a89a8b18";

export default node;
