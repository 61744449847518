/**
 * @generated SignedSource<<49ec6188ac11a3112e461f78c92af912>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CreditCategory = "A" | "AA" | "AAA" | "B" | "C" | "D" | "E" | "F" | "NONE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BillingCodeRuleFields_ClientsFragment$data = {
  readonly conditions: {
    readonly clientEntities: ReadonlyArray<{
      readonly category: CreditCategory;
      readonly deletedAt: string | null | undefined;
      readonly externalId: string;
      readonly id: string;
      readonly location: {
        readonly address: string;
      } | null | undefined;
      readonly name: string;
      readonly number: string;
      readonly phoneNumber: string | null | undefined;
      readonly representative: {
        readonly name: string;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"CreditAlertIndicatorFragment">;
    }>;
  };
  readonly " $fragmentType": "BillingCodeRuleFields_ClientsFragment";
};
export type BillingCodeRuleFields_ClientsFragment$key = {
  readonly " $data"?: BillingCodeRuleFields_ClientsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeRuleFields_ClientsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillingCodeRuleFields_ClientsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ConditionsInternal",
      "kind": "LinkedField",
      "name": "conditions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Client",
          "kind": "LinkedField",
          "name": "clientEntities",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Location",
              "kind": "LinkedField",
              "name": "location",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "address",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "category",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "number",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "phoneNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Representative",
              "kind": "LinkedField",
              "name": "representative",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CreditAlertIndicatorFragment"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "deletedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "externalId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BillingCodeRule",
  "abstractKey": null
};
})();

(node as any).hash = "7af2b8714d619bfc3059adf148d0082a";

export default node;
