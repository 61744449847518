export const billingCodeRule = {
  dialog: {
    deleteTitle: 'Delete Rule',
    deleteMessage: 'Do you want to delete this rule?',
    confirmation: 'Ok',
  },
  newTitle: 'New Rule',
  editTitle: 'Edit Rule',
  error: {
    errorDuringDeleteAccessory: 'Delete accessory rule mutation failed with a null response',
    errorDuringDeleteBillingCode: 'Delete billing code rule mutation failed with a null response',
    errorDuringSaveAccessory: 'Save mutation did not result in a valid accessory rule',
    errorDuringSaveBillingCode: 'Save mutation did not result in a valid billing code rule',
    errorDuringDelete: 'An error occurred while deleting the rule',
  },
  placeholder: {
    kindListEmpty: 'All kinds',
  },
  fields: {
    label: {
      accessories: 'Accessories',
      billed: 'Billed',
      billingCode: 'Name of billing code',
      billingCodes: 'Billing codes',
      billingCodesAutomatic: 'Automatic',
      billingCodesVisible: 'Visible',
      capacities: 'Capacities',
      clients: 'Clients',
      conditions: 'Conditions',
      description: 'Description',
      equipmentKinds: 'Equipment kinds',
      formKind: 'Form Kind',
      information: 'Information',
      kinds: 'Kinds',
      labor: 'Labor',
      natureOfWorkSubCategory: 'Nature of work subcategories',
      natureOfWorks: 'Natures of work',
      specialSituation: 'Particular situations',
    },
    specialProjects: 'Special projects',
  },
  section: {
    billingCodeRules: 'Cost Line Rules',
    accessoryRules: 'Accessory Rules',
  },
  title: 'Billing Code Rules',
};
