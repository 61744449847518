/**
 * @generated SignedSource<<7aad06ac22da51a109b696f9cd26982f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type clientAutocompleteListFragmentQuery$variables = {
  searchCount?: number | null | undefined;
  searchCursor?: string | null | undefined;
  searchTerm?: string | null | undefined;
};
export type clientAutocompleteListFragmentQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ClientAutocompleteListFragment">;
};
export type clientAutocompleteListFragmentQuery = {
  response: clientAutocompleteListFragmentQuery$data;
  variables: clientAutocompleteListFragmentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": 25,
    "kind": "LocalArgument",
    "name": "searchCount"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchCursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTerm"
  }
],
v1 = {
  "kind": "Variable",
  "name": "searchTerm",
  "variableName": "searchTerm"
},
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "searchCursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "searchCount"
  },
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "clientAutocompleteListFragmentQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "searchCount",
            "variableName": "searchCount"
          },
          {
            "kind": "Variable",
            "name": "searchCursor",
            "variableName": "searchCursor"
          },
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "ClientAutocompleteListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "clientAutocompleteListFragmentQuery",
    "selections": [
      {
        "alias": "searchResults",
        "args": (v2/*: any*/),
        "concreteType": "SearchClientsConnection",
        "kind": "LinkedField",
        "name": "searchClients",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SearchClientsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Client",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Location",
                    "kind": "LinkedField",
                    "name": "location",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "address",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "category",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "number",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phoneNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Representative",
                    "kind": "LinkedField",
                    "name": "representative",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "creditRisk",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "creditRiskDescription",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deletedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "externalId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "searchResults",
        "args": (v2/*: any*/),
        "filters": [
          "searchTerm"
        ],
        "handle": "connection",
        "key": "clientAutocompleteListFragmentQuery_searchResults",
        "kind": "LinkedHandle",
        "name": "searchClients"
      }
    ]
  },
  "params": {
    "cacheID": "e644415864444e63e32390792e66d301",
    "id": null,
    "metadata": {},
    "name": "clientAutocompleteListFragmentQuery",
    "operationKind": "query",
    "text": "query clientAutocompleteListFragmentQuery(\n  $searchCount: Int = 25\n  $searchCursor: String\n  $searchTerm: String\n) {\n  ...ClientAutocompleteListFragment_wtMSG\n}\n\nfragment ClientAutocompleteListFragment_wtMSG on Query {\n  searchResults: searchClients(searchTerm: $searchTerm, after: $searchCursor, first: $searchCount) {\n    edges {\n      node {\n        id\n        name\n        location {\n          address\n        }\n        category\n        number\n        phoneNumber\n        representative {\n          name\n          id\n        }\n        ...CreditAlertIndicatorFragment\n        deletedAt\n        externalId\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment CreditAlertIndicatorFragment on Client {\n  creditRisk\n  creditRiskDescription\n}\n"
  }
};
})();

(node as any).hash = "c13418a5cd40d05ee53eed5e0932bfb0";

export default node;
