/**
 * @generated SignedSource<<4c215b1487f309cb249bfe901518ee49>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationMenuUnreadFragmentQuery_unread$variables = {
  count?: number | null | undefined;
  cursor?: string | null | undefined;
};
export type NotificationMenuUnreadFragmentQuery_unread$data = {
  readonly " $fragmentSpreads": FragmentRefs<"NotificationMenuUnreadFragment">;
};
export type NotificationMenuUnreadFragmentQuery_unread = {
  response: NotificationMenuUnreadFragmentQuery_unread$data;
  variables: NotificationMenuUnreadFragmentQuery_unread$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": 100,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "isRead": {
        "eq": false
      }
    }
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isRead",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "friendlyId",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "permalink",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        }
      ],
      "type": "Notification",
      "abstractKey": "__isNotification"
    }
  ],
  "type": "PermalinkNotificationBase",
  "abstractKey": "__isPermalinkNotificationBase"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationMenuUnreadFragmentQuery_unread",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          }
        ],
        "kind": "FragmentSpread",
        "name": "NotificationMenuUnreadFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationMenuUnreadFragmentQuery_unread",
    "selections": [
      {
        "alias": "unreadNotifications",
        "args": (v1/*: any*/),
        "concreteType": "NotificationsConnection",
        "kind": "LinkedField",
        "name": "notifications",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NotificationsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isNotification"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "comment",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "type": "ServiceCallModificationRequestedNotification",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "clientName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DateTimeInTimeZone",
                        "kind": "LinkedField",
                        "name": "arrivalDate",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rawValue",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "equipmentKindAbbreviation",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "craneCapacity",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "type": "QuoteStatusChangeNotification",
                    "abstractKey": "__isQuoteStatusChangeNotification"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      },
      {
        "alias": "unreadNotifications",
        "args": (v1/*: any*/),
        "filters": [
          "where"
        ],
        "handle": "connection",
        "key": "NotificationMenuUnreadQuery_unreadNotifications",
        "kind": "LinkedHandle",
        "name": "notifications"
      }
    ]
  },
  "params": {
    "cacheID": "6f8978c58397f2d122b2e2e181e13c0c",
    "id": null,
    "metadata": {},
    "name": "NotificationMenuUnreadFragmentQuery_unread",
    "operationKind": "query",
    "text": "query NotificationMenuUnreadFragmentQuery_unread(\n  $count: Int = 100\n  $cursor: String\n) {\n  ...NotificationMenuUnreadFragment_1G22uz\n}\n\nfragment NotificationMenuUnreadFragment_1G22uz on Query {\n  unreadNotifications: notifications(where: {isRead: {eq: false}}, after: $cursor, first: $count) {\n    edges {\n      node {\n        __typename\n        ...NotificationRowFragment\n        id\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment NotificationRowFragment on Notification {\n  __isNotification: __typename\n  __typename\n  ...NotificationRow_ModificationRequestedFragment\n  ...NotificationRow_QuoteStatusNotificationFragment\n}\n\nfragment NotificationRow_BaseFragment on Notification {\n  __isNotification: __typename\n  id\n  isRead\n  createdAt\n}\n\nfragment NotificationRow_ModificationRequestedFragment on ServiceCallModificationRequestedNotification {\n  isRead\n  friendlyId\n  comment\n  ...NotificationRow_PermalinkFragment\n}\n\nfragment NotificationRow_PermalinkFragment on PermalinkNotificationBase {\n  __isPermalinkNotificationBase: __typename\n  permalink\n  isRead\n  ...NotificationRow_BaseFragment\n}\n\nfragment NotificationRow_QuoteStatusNotificationFragment on QuoteStatusChangeNotification {\n  __isQuoteStatusChangeNotification: __typename\n  isRead\n  friendlyId\n  clientName\n  arrivalDate {\n    rawValue\n  }\n  equipmentKindAbbreviation\n  craneCapacity\n  ...NotificationRow_PermalinkFragment\n}\n"
  }
};
})();

(node as any).hash = "29da69a2a70273925f5188a1ec0314f6";

export default node;
