export const maintenance = {
  dataSyncSection: {
    deleteSectionTitle: 'Suppression de données',
    deleting: {
      title: 'Confirmation',
      confirmationText: 'Voulez-vous supprimer toutes les données\u00a0?',
      confirmButton: 'Supprimer',
      button: 'Toutes les données',
      success: 'Données supprimées avec succès',
      fail: 'Une erreur est survenue, voir la console pour plus de détails',
    },
    seedSectionTitle: 'Injection de données',
    seeding: {
      button: 'Toutes les données',
      buttonGenerated: 'Données générées',
      buttonImported: 'Données importées',
      fail: 'Impossible d’injecter les données, vérifier la console pour une erreur',
      success: 'Les données ont été injectées avec succès',
      confirmationText: 'Voulez-vous injecter les données\u00a0?',
      confirmationButton: 'Injecter',
      title: 'Confirmation',
    },
    syncSectionTitle: 'Synchronization des données',
    synchronization: {
      title: 'Confirmation',
      confirmationText: 'Voulez-vous resynchroniser {{entity}}\u00a0?',
      confirmationButton: 'Synchronizer',
      success: 'Demande de synchronization réussie',
      fail: 'Demande de synchronization échoué pour {{entity}}',
    },
  },
  maintenance: 'Maintenance',
  section: {
    dataSync: 'Sync. des données',
  },
};
