/**
 * @generated SignedSource<<7f786fc3edc4667393a3239006ea4c20>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaleProjectFields_useFieldSchedulePeriodFragment$data = {
  readonly schedulePeriod: {
    readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_useFieldScheduleEndTimeFragment" | "SaleProjectFields_useFieldScheduleStartTimeFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_IsEstablishedScheduleFragment">;
  readonly " $fragmentType": "SaleProjectFields_useFieldSchedulePeriodFragment";
};
export type SaleProjectFields_useFieldSchedulePeriodFragment$key = {
  readonly " $data"?: SaleProjectFields_useFieldSchedulePeriodFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_useFieldSchedulePeriodFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleProjectFields_useFieldSchedulePeriodFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SaleProjectFields_IsEstablishedScheduleFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TimeRange",
      "kind": "LinkedField",
      "name": "schedulePeriod",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleProjectFields_useFieldScheduleStartTimeFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleProjectFields_useFieldScheduleEndTimeFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ISaleProject",
  "abstractKey": "__isISaleProject"
};

(node as any).hash = "94ebe2b37ca6e55c0f1f504b87993546";

export default node;
