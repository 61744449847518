/**
 * @generated SignedSource<<915a2ebf886befba955111a5f64a2133>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CostLinesFields_useQuantityRateFromSalesRateFragment$data = {
  readonly results: ReadonlyArray<{
    readonly craneIndex: number | null | undefined;
    readonly salesRates: ReadonlyArray<{
      readonly canEditRate: boolean;
      readonly error: {
        readonly code: string;
        readonly description: string;
      } | null | undefined;
      readonly value: {
        readonly createdAt: string;
        readonly isAnnualContract: boolean;
        readonly isFlexiblePrice: boolean;
        readonly minimumQuantity: number;
        readonly price: string;
      } | null | undefined;
    }>;
  }>;
  readonly " $fragmentType": "CostLinesFields_useQuantityRateFromSalesRateFragment";
};
export type CostLinesFields_useQuantityRateFromSalesRateFragment$key = {
  readonly " $data"?: CostLinesFields_useQuantityRateFromSalesRateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CostLinesFields_useQuantityRateFromSalesRateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CostLinesFields_useQuantityRateFromSalesRateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "KeyedSalesRatesResults",
      "kind": "LinkedField",
      "name": "results",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "craneIndex",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SalesRateEnvelope",
          "kind": "LinkedField",
          "name": "salesRates",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canEditRate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "SalesRate",
              "kind": "LinkedField",
              "name": "value",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isFlexiblePrice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isAnnualContract",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "minimumQuantity",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "price",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdAt",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "SalesRateError",
              "kind": "LinkedField",
              "name": "error",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "code",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SalesRatesResponse",
  "abstractKey": null
};

(node as any).hash = "1a6d22af688c8dad4dc11d1bfa274ace";

export default node;
