/**
 * @generated SignedSource<<8f981027ea7b32624573505f98958a9b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuestionsBaseFields_DistanceFragment$data = {
  readonly distance: string | null | undefined;
  readonly " $fragmentType": "QuestionsBaseFields_DistanceFragment";
};
export type QuestionsBaseFields_DistanceFragment$key = {
  readonly " $data"?: QuestionsBaseFields_DistanceFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuestionsBaseFields_DistanceFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuestionsBaseFields_DistanceFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "distance",
      "storageKey": null
    }
  ],
  "type": "SalesQuestionForExcavationProximityLessThan10Feet",
  "abstractKey": null
};

(node as any).hash = "e261e8c681473b822bc8fb5ba059f8d0";

export default node;
