/**
 * @generated SignedSource<<06e96803e1b0fec80b2be0c79b1ab6f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TabRateStrategiesQuery$variables = Record<PropertyKey, never>;
export type TabRateStrategiesQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"RateStrategyListFragment">;
};
export type TabRateStrategiesQuery = {
  response: TabRateStrategiesQuery$data;
  variables: TabRateStrategiesQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TabRateStrategiesQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "RateStrategyListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TabRateStrategiesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RateStrategyLookup",
        "kind": "LinkedField",
        "name": "rateStrategies",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "name": "label",
            "args": null,
            "fragment": {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                }
              ],
              "type": "RateStrategyLookup",
              "abstractKey": null
            },
            "kind": "RelayResolver",
            "storageKey": null,
            "isOutputType": true
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0e5cec68778584feb00539bcf09ceceb",
    "id": null,
    "metadata": {},
    "name": "TabRateStrategiesQuery",
    "operationKind": "query",
    "text": "query TabRateStrategiesQuery {\n  ...RateStrategyListFragment\n}\n\nfragment RateStrategyListFragment on Query {\n  rateStrategies {\n    id\n    ...RateStrategyLookupLabelResolver\n  }\n}\n\nfragment RateStrategyLookupLabelResolver on RateStrategyLookup {\n  description\n}\n"
  }
};

(node as any).hash = "d9d3716a0b209436f1812d349bcac633";

export default node;
