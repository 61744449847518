/**
 * @generated SignedSource<<e60b92549276a23f3a5a8a461ccbe794>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type RateStrategyCategory = "additionalCrane" | "boomCounterweightTransport" | "flatbedTransport" | "mainCrane" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RateStrategyFields_useFieldRateStrategyCategoriesFragment$data = {
  readonly categories: ReadonlyArray<RateStrategyCategory>;
  readonly " $fragmentType": "RateStrategyFields_useFieldRateStrategyCategoriesFragment";
};
export type RateStrategyFields_useFieldRateStrategyCategoriesFragment$key = {
  readonly " $data"?: RateStrategyFields_useFieldRateStrategyCategoriesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RateStrategyFields_useFieldRateStrategyCategoriesFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RateStrategyFields_useFieldRateStrategyCategoriesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "categories",
      "storageKey": null
    }
  ],
  "type": "RateStrategyLookup",
  "abstractKey": null
};

(node as any).hash = "bd8082769546cd5fe89cbac8cb16ce49";

export default node;
