/**
 * Returns a new collection based on source that does not contain the values of exclusions according to the given predicate.
 *
 * By default, the predicate is a reference equality comparison.
 * @param source      The collection to exclude
 * @param exclusions  The collection that lists duplicates
 * @param predicate   A function that determines if a pair of element from the two collections are the same.
 */
export function exclude<T>(
  source: readonly T[],
  exclusions: readonly T[],
  predicate: (source: T, excluded: T) => boolean = (s, e) => s === e,
): T[] {
  return source.filter((s) => !exclusions.some((e) => predicate(s, e)));
}

const pairwiseUndefined = Symbol('pairwiseUndefined');

/**
 * Groups an array of values into pairs.
 * @param source
 */
export function pairwise<V>(source: V[]): [V, V | undefined][] {
  let prev: V | symbol = pairwiseUndefined;
  return source.reduce(
    (acc, cur, ind) => {
      // First value of a pair
      if (prev === pairwiseUndefined) {
        // There's only one value left
        if (source.length === ind + 1) {
          return [...acc, [cur, undefined]];
        }
        prev = cur;
        return acc;
      }
      const pair: [V, V] = [prev as V, cur];
      prev = pairwiseUndefined;
      return [...acc, pair];
    },
    [] as [V, V | undefined][],
  );
}

/**
 * Compares two sequences and returns whether they contain the same entries in the same order.
 * @param seq1
 * @param seq2
 */
export function sequenceEqual(seq1: unknown[], seq2: unknown[]): boolean;
export function sequenceEqual(seq1: object, seq2: object): boolean;
export function sequenceEqual(seq1: Map<unknown, unknown>, seq2: Map<unknown, unknown>): boolean;
export function sequenceEqual(seq1: unknown[] | object | Map<unknown, unknown>, seq2: unknown[] | object | Map<unknown, unknown>): boolean {
  if (seq1 === seq2) {
    return true;
  }

  if (Array.isArray(seq1) && Array.isArray(seq2)) {
    if (seq1.length !== seq2.length) {
      return false;
    }

    for (let i = 0; i < seq1.length; i++) {
      if (seq1[i] !== seq2[i]) {
        return false;
      }
    }

    return true;
  }

  if (seq1 instanceof Map && seq2 instanceof Map) {
    if (seq1.size !== seq2.size) {
      return false;
    }

    for (const [key, value] of seq1) {
      if (value !== seq2.get(key)) {
        return false;
      }
    }

    return true;
  }

  const entries1 = Object.entries(seq1);
  const entries2 = Object.entries(seq2);

  if (entries1.length !== entries2.length) {
    return false;
  }

  for (let i = 0; i < entries1.length; i++) {
    // Object.entries() doesn't produce sparse arrays, so non-null assertions are safe since we just checked the lengths
    if (entries1[i]![0] !== entries2[i]![0] || entries1[i]![1] !== entries2[i]![1]) {
      return false;
    }
  }

  return true;
}

export const unrelated = Symbol('unrelated');

/**
 * Merge two sequences, pairing similarly indexed elements across sequences into a single tuple.
 *
 * When sequences have different lengths, the `unrelated` value will be used to fill in the gap.
 *
 * @example relate([1, 2, 3], ['a', 'b', 'c']) => [[1, 'a'], [2, 'b'], [3, 'c']]
 * @example relate([1], ['a', 'b', 'c']) => [[1, 'a'], [unrelated, 'b'], [unrelated, 'c']]
 * @example relate([null, undefined, false], ['a', 'b']) => [[null, 'a'], [undefined, 'b'], [false, unrelated]]
 *
 * @param seq1
 * @param seq2
 */
export function relate<V1, V2>(
  seq1: readonly V1[],
  seq2: readonly V2[],
): (readonly [V1, V2] | readonly [V1, typeof unrelated] | readonly [typeof unrelated, V2])[] {
  const length = Math.max(seq1.length, seq2.length);
  const results: ([V1, V2] | [V1, typeof unrelated] | [typeof unrelated, V2])[] = [];

  for (let i = 0; i < length; i++) {
    const relatable1 = i in seq1; // support sparse arrays
    const relatable2 = i in seq2; // support sparse arrays
    const v1 = seq1[i];
    const v2 = seq2[i];

    // The following lines do `vN as VN` and not `vN!` because the latter asserts that `vN` is non-nullable, which we
    // can't know for sure since `VN` is generic and might include `null` or `undefined`. The only thing we know for
    // sure when `relatableN` is true is that `vN` is of type `VN`, so `vN as VN` is the only right type assertion here.
    if (relatable1 && relatable2) {
      results.push([v1 as V1, v2 as V2]);
    } else if (relatable1 && !relatable2) {
      results.push([v1 as V1, unrelated]);
    } else if (!relatable1 && relatable2) {
      results.push([unrelated, v2 as V2]);
    }
  }

  return results;
}
