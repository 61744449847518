const placeholder = {
  noLifts: 'No lift',
  newLiftName: 'Lift {{number}}',
  selectConfiguration: 'Select a configuration',
};

const field = {
  name: 'Lift Name',
  securityFactorPercentage: 'Security Factor',
  building: {
    height: 'Height (A)',
    interiorRadius: 'Interior Radius (B)',
    exteriorRadius: 'Exterior Radius (C)',
    ceilingHeight: 'Ceiling Height (D)',
  },
  site: {
    width: 'Width (E)',
    length: 'Length (F)',
  },
  load: {
    weight: 'Weight (G)',
    height: 'Height (H)',
    width: 'Width (I)',
    length: 'Length',
  },
  accessories: {
    height: 'Height of Accessories (J)',
    weight: 'Weight of Accessories',
  },
  mode: {
    lifts: 'Crane Selector',
    manual: 'Manual Configuration',
  },
  equipment: {
    addConfiguration: 'Add Configuration',
    additionalCrane: 'Additional Crane',
    boom: 'Boom',
    boomConfiguration: 'Boom Configuration',
    capacity: 'Capacity',
    configuration: 'Configuration',
    configurationOption: 'Configuration Option',
    counterWeight: 'Counterweight',
    craneSelectorConfiguration: 'Crane Selector Configuration',
    jib: 'Jib',
    manualBoomConfiguration: 'Manual Boom Configuration',
    maxWeight: 'Load Weight',
    model: 'Model',
    noReplacement: 'No Replacement',
    noSubcontractor: 'No Subcontractor',
    offset: 'Offset',
    boomOffset: 'Boom Offset',
    jibOffset: 'Jib Offset',
    otherInformation: 'Other Information',
    radius: 'Radius',
    requiredEquipment: 'Required Equipment',
    type: 'Type',
  },
};

const lift = {
  deleteLift: 'Delete Lift',
  deleteLiftMessage: 'Do you want to delete this lift?',
};

const configurationList = {
  title: 'Configurations',
};

export const craneSelector = {
  addLift: 'Add Lift',
  building: 'Building',
  configurationList,
  craneSelectionMode: 'Crane Selection Mode',
  craneSelector: 'Crane Selector',
  drawing: 'Technical Drawing',
  editLift: 'Edit Lift',
  field,
  lift,
  lifts: 'Lifts',
  loadAndAccessories: 'Load & Accessories',
  manualConfiguration: 'Manual Configuration',
  models: 'Corresponding Models',
  placeholder,
  site: 'Site',
  parallel: 'Parallel',
  perpendicular: 'Perpendicular',
  viewLift: 'View Lift',
};
