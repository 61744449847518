import { LoaderFunction, Route } from 'react-router';
import { TranslationNamespaceContext } from '../common/hooks/useAmbientTranslation';
import { ServiceCallListPage } from './ServiceCallListPage';

export const serviceCallLoader = (async () => {
  return { shouldValidateReadyDate: true };
}) satisfies LoaderFunction;

export const serviceCallRoutes = (
  <>
    <Route path='service-calls/:id' id='service-call' lazy={() => import('./ServiceCallDetails.Page')} loader={serviceCallLoader}>
      <Route index lazy={() => import('./ServiceCallDetails.Mobile.Index')} />
      <Route path='client' lazy={() => import('../jobs/SaleDetails.Mobile.Client')} />
      <Route path='client/pick-client-f' lazy={() => import('../jobs/SaleDetails.Mobile.ClientPicker')} />
      <Route path='project' lazy={() => import('../jobs/SaleDetails.Mobile.Project')} />
      <Route path='project/pick-worksite-f' lazy={() => import('../jobs/SaleDetails.Mobile.WorksitePicker')} />
      <Route path='questions' lazy={() => import('../jobs/SaleDetails.Mobile.Questions')} />
      <Route path='equipment' lazy={() => import('../jobs/SaleDetails.Mobile.Equipment')} />
      <Route path='documents' lazy={() => import('../jobs/JobDetails.Mobile.JobDocumentsProgression')} />
      <Route path='equipment/lift-f/:liftId' lazy={() => import('../jobs/JobDetails.Mobile.Lift.Edit')} />
      <Route path='equipment/configurations' lazy={() => import('../jobs/JobDetails.Mobile.Equipment.Configuration')} />
      <Route path='equipment/configurations/:eTag' lazy={() => import('../jobs/JobDetails.Mobile.Equipment.Configuration.Item')} />
      <Route path='info' lazy={() => import('./ServiceCallDetails.Desktop.TabInfo')}>
        <Route path='pick-client' lazy={() => import('../jobs/SaleDetails.Desktop.ClientPicker')} />
        <Route path='pick-worksite' lazy={() => import('../jobs/SaleDetails.Desktop.WorksitePicker')} />
        <Route path='lift/:liftId' lazy={() => import('../jobs/JobDetails.Desktop.Lift.Edit')} />
      </Route>
      <Route path='costs' lazy={() => import('./ServiceCallDetails_Costs')} />
      <Route path='m/linked' lazy={() => import('./ServiceCallDetails.Mobile.Linked')} />
      <Route path='m/linked/pick-link-f' lazy={() => import('./ServiceCallDetails.Mobile.LinkPicker')} />
      <Route path='linked' lazy={() => import('./ServiceCallDetails.Desktop.TabLinked')}>
        <Route path='pick-link' lazy={() => import('./ServiceCallDetails.Desktop.LinkPicker')} />
      </Route>
      <Route path='costs/accessories/:accessoryLineId' lazy={() => import('../jobs/SaleDetails.Mobile.AccessoryLine.Edit')} />
      <Route path='costs/costLines/:costLineId' lazy={() => import('../jobs/SaleDetails.Mobile.CostLine.Edit')} />
      <Route path='addenda' lazy={() => import('./ServiceCallDetails_Addenda')} />
      <Route path='addenda/addenda/:addendumId' lazy={() => import('../jobs/SaleDetails.Mobile.Addendum.Edit')} />
      <Route path='addenda/attachment/:attachmentId' lazy={() => import('../jobs/JobDetails.Mobile.Attachment.Edit')} />
    </Route>
    <Route
      path='service-calls'
      element={
        <TranslationNamespaceContext.Provider value='serviceCall'>
          <ServiceCallListPage />
        </TranslationNamespaceContext.Provider>
      }
    />
  </>
);
