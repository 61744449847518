/**
 * @generated SignedSource<<d583c7a845f58a8f7cda08e60af1f2ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationRow_QuoteStatusNotificationFragment$data = {
  readonly arrivalDate: {
    readonly rawValue: string;
  };
  readonly clientName: string;
  readonly craneCapacity: number | null | undefined;
  readonly equipmentKindAbbreviation: string | null | undefined;
  readonly friendlyId: string;
  readonly isRead: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationRow_PermalinkFragment">;
  readonly " $fragmentType": "NotificationRow_QuoteStatusNotificationFragment";
};
export type NotificationRow_QuoteStatusNotificationFragment$key = {
  readonly " $data"?: NotificationRow_QuoteStatusNotificationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationRow_QuoteStatusNotificationFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationRow_QuoteStatusNotificationFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRead",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "friendlyId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DateTimeInTimeZone",
      "kind": "LinkedField",
      "name": "arrivalDate",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rawValue",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "equipmentKindAbbreviation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "craneCapacity",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotificationRow_PermalinkFragment"
    }
  ],
  "type": "QuoteStatusChangeNotification",
  "abstractKey": "__isQuoteStatusChangeNotification"
};

(node as any).hash = "5f5907cd864b564bfdcf103c8016d6a5";

export default node;
