/**
 * @generated SignedSource<<a78cb82214f642ee64b3ed3e96e05f62>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BillingCodeRuleListItemFragment$data = {
  readonly conditions: {
    readonly kinds: ReadonlyArray<string>;
  };
  readonly description: string;
  readonly id: string;
  readonly " $fragmentType": "BillingCodeRuleListItemFragment";
};
export type BillingCodeRuleListItemFragment$key = {
  readonly " $data"?: BillingCodeRuleListItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeRuleListItemFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillingCodeRuleListItemFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ConditionsInternal",
      "kind": "LinkedField",
      "name": "conditions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kinds",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BillingCodeRule",
  "abstractKey": null
};

(node as any).hash = "1b9c659fd80f4179c82778440f7e4d0a";

export default node;
