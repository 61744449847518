import { Box, Chip, ListItemIcon, ListItemText, Skeleton, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DataID, useFragment, useRefetchableFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { visuallyHidden } from '@mui/utils';
import { EllipsisedTypography } from '../common/components/EllipsisedTypography';
import {
  getNodeById,
  ResponsiveGrid,
  ResponsiveGridColumnDefinition,
  ResponsiveGridColumnOrderer,
  ResponsiveGridFilters,
  ResponsiveGridForwardProps,
  ResponsiveGridImperativeHandle,
  ResponsiveGridProps,
  useElementFactory,
  useSkeletonFactory,
} from '../common/components/ResponsiveGrid';
import { NullableCell } from '../common/components/NullableCell';
import { DateTime } from 'luxon';
import { dateFormat } from '../common/utils/dateTimeUtils';
import { useCallback, useMemo, useRef } from 'react';
import { QuoteListFragment$key } from './__generated__/QuoteListFragment.graphql';
import { castQuoteKind, quoteKinds } from '../__enums__/QuoteKind';
import { QuoteListFiltersFragment$key } from './__generated__/QuoteListFiltersFragment.graphql';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { DateRange } from '@mui/x-date-pickers-pro';
import { isQuoteStatus, quoteStatuses } from '../__enums__/QuoteStatus';
import { QuoteStatusChip } from './QuoteStatusChip';
import {
  ArrivalDateFilter,
  CraneCapacityFilter,
  DispatchBranchFilter,
  EquipmentKindFilter,
  JobKindFilter,
  JobStatusFilter,
  ProjectManagerFilter,
  RepresentativeFilter,
  TextSearchFilter,
} from '../jobs/JobFilters';
import { convertToTsQuery } from '../common/utils/stringUtils';
import { discriminate, isDefined } from '../common/utils/typeUtils';
import { AuthorizationWriteFragment$key } from '../auth/__generated__/AuthorizationWriteFragment.graphql';
import { RequireWrite } from '../auth/Authorization';
import { QuoteList_ActionsFragment$key } from './__generated__/QuoteList_ActionsFragment.graphql';
import { QuoteList_RowFragment$key } from './__generated__/QuoteList_RowFragment.graphql';
import { QuoteList_ItemFragment$key } from './__generated__/QuoteList_ItemFragment.graphql';
import { SaleList_CopyButton } from '../jobs/SaleList.CopyButton';
import { useHideAccessorySection } from '../jobs/accessoryLines/AccessoryLinesFields';
import { QuoteFilters, QuoteResponsiveGridFilters } from './QuoteFilters';
import { QuoteListFragmentQuery } from './__generated__/QuoteListFragmentQuery.graphql';

export interface QuoteListProps extends ResponsiveGridForwardProps {
  $key: QuoteListFragment$key;
  filters$key: QuoteListFiltersFragment$key;
  write$key: AuthorizationWriteFragment$key;
  filters: QuoteFilters;
  onFiltersChange: (filters: QuoteFilters) => void;
}

export function QuoteList({
  $key,
  filters$key,
  write$key,
  filters,
  onFiltersChange: handleFiltersChange,
  onItemClick,
  ...gridProps
}: QuoteListProps) {
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const gridRef = useRef<ResponsiveGridImperativeHandle | null>(null);

  const { t } = useAmbientTranslation();

  const [$data, refetch] = useRefetchableFragment<QuoteListFragmentQuery, QuoteListFragment$key>(
    graphql`
      fragment QuoteListFragment on Query
      @refetchable(queryName: "QuoteListFragmentQuery")
      @argumentDefinitions(
        searchTerm: { type: "String" }
        representativeIds: { type: "[ID!]" }
        after: { type: "String" }
        before: { type: "String" }
        first: { type: "Int" }
        last: { type: "Int" }
        where: { type: "QuoteJobRevisionFilterType" }
      ) {
        searchQuotes(
          searchTerm: $searchTerm
          representativeIds: $representativeIds
          after: $after
          before: $before
          first: $first
          last: $last
          where: $where
          order: [{ snapshot: { statusOrder: ASC } }, { snapshot: { projectBase: { arrivalDate: { date: ASC } } } }, { id: ASC }]
        ) @required(action: THROW) {
          ...ResponsiveGridFragment
          edges {
            node {
              id
              lifeCycleBranchId
              ...QuoteList_RowFragment
              ...QuoteList_ItemFragment
            }
          }
        }
      }
    `,
    $key,
  );

  const refetchFn = useCallback<ResponsiveGridProps['refetch']>(
    (vars, options) =>
      refetch(
        {
          ...vars,
          searchTerm: convertToTsQuery(filters.get('searchTerm'), '|'),
          representativeIds: filters.get('representatives').map(({ id }) => id),
          where: filters.toJobRevisionFilter(),
        },
        options,
      ),
    [refetch, filters],
  );

  const edges = $data.searchQuotes?.edges;

  const columns: ResponsiveGridColumnDefinition[] = [
    { id: 'friendlyId', label: t('list.column.friendlyId'), size: '7rem' },
    { id: 'client', label: t('list.column.client'), size: 'minmax(6rem, 2fr)' },
    { id: 'worksite', label: t('list.column.worksite'), size: 'minmax(6rem, 2fr)' },
    { id: 'kind', label: t('list.column.kind'), size: 'minmax(6rem, 1fr)' },
    { id: 'crane', label: t('list.column.crane'), size: 'auto', sx: { textAlign: 'center' } },
    { id: 'status', label: compact ? '' : t('list.column.status'), size: 'auto' },
    { id: 'date', label: t('list.column.date'), size: 'auto' },
    { id: 'actions', label: '', size: 'minmax(3rem, auto)' },
  ];

  const rowElementFactory = useElementFactory(edges, (node, orderByColumns) => (
    <QuoteList_Row $key={node} write$key={write$key} orderByColumns={orderByColumns} />
  ));
  const listElementFactory = useElementFactory(edges, (node) => <QuoteList_Item fragmentKey={node} />);

  const handleItemClick = useCallback(
    (id: DataID) => {
      return onItemClick?.(getNodeById(id, edges).lifeCycleBranchId);
    },
    [edges, onItemClick],
  );

  const rowSkeletonFactory = useSkeletonFactory(() => <RowSkeleton columns={columns} />);
  const listSkeletonFactory = useSkeletonFactory(() => <ListSkeleton />);

  return (
    $data.searchQuotes && (
      <>
        <QuoteFiltersComponent $key={filters$key} filters={filters} onFiltersChange={handleFiltersChange} />
        <ResponsiveGrid
          ref={gridRef}
          connectionFragmentKey={$data.searchQuotes}
          refetch={refetchFn}
          columnDefinitions={columns}
          rowElementFactory={rowElementFactory}
          listElementFactory={listElementFactory}
          rowSkeletonFactory={rowSkeletonFactory}
          listSkeletonFactory={listSkeletonFactory}
          listSx={{
            "&[data-mode='grid']": {
              // Adds a padding in cells to improve readability, specially when elipsing.
              'li.responsive-grid__header  > *': {
                px: '1rem',
              },
              'li:not(.responsive-grid__header)  > *': {
                px: '0.5rem',
              },
            },

            // Controls the gap between the content of list items and the status chip.
            "&[data-mode='list'] > li": {
              gap: '0.5rem',
            },
          }}
          onItemClick={handleItemClick}
          {...gridProps}
        />
      </>
    )
  );
}

function QuoteFiltersComponent({
  $key,
  filters,
  onFiltersChange: handleFiltersChange,
}: {
  $key: QuoteListFiltersFragment$key;
  filters: QuoteFilters;
  onFiltersChange: (filters: QuoteFilters) => void;
}) {
  const { t } = useAmbientTranslation();
  const theme = useTheme();
  const compact = useMediaQuery(theme.breakpoints.down('md'));

  const $data = useFragment(
    graphql`
      fragment QuoteListFiltersFragment on Query
      @argumentDefinitions(
        dispatchBranchIds: { type: "[ID!]!" }
        equipmentKindCodes: { type: "[Int!]!" }
        projectManagerIds: { type: "[ID!]!" }
        representativeIds: { type: "[ID!]!" }
      ) {
        dispatchBranches: nodes(ids: $dispatchBranchIds) {
          __typename
          ... on Branch {
            id
            label
            deletedAt
          }
        }
        equipmentKinds(codes: $equipmentKindCodes) {
          ... on EquipmentKindLookup {
            id
            code
            label
          }
        }
        projectManagers: nodes(ids: $projectManagerIds) {
          __typename
          ... on Representative {
            id
            label
            deletedAt
          }
        }
        representatives: nodes(ids: $representativeIds) {
          __typename
          ... on Representative {
            id
            label
            deletedAt
          }
        }
      }
    `,
    $key,
  );

  const responsiveGridFilters: QuoteResponsiveGridFilters = useMemo(() => {
    // Adding the extra data required by the responsive grid filter components to the filters should only be done once,
    // since this data is only fetched once during list page load. So once the filters have been modified by the user,
    // this extra data might not be up-to-date anymore, and overwriting the filters with it might corrupt the filters.
    // This is safe since the autocompletes which require this data also put it back in the filters on modification.
    if (filters instanceof QuoteResponsiveGridFilters) return filters;
    return filters.toResponsiveGridFilters({
      dispatchBranches: $data.dispatchBranches.filter(isDefined).filter(discriminate('__typename', 'Branch')),
      equipmentKinds: $data.equipmentKinds,
      projectManagers: $data.projectManagers.filter(isDefined).filter(discriminate('__typename', 'Representative')),
      representatives: $data.representatives.filter(isDefined).filter(discriminate('__typename', 'Representative')),
    });
  }, [filters, $data]);

  return (
    <ResponsiveGridFilters<QuoteResponsiveGridFilters>
      filters={responsiveGridFilters}
      emptyFilters={QuoteResponsiveGridFilters.EMPTY}
      onFiltersChange={handleFiltersChange}
      compact={compact}
      sx={{
        [theme.breakpoints.down('sm')]: { mx: '1rem' },
        [theme.breakpoints.up('md')]: { justifyContent: 'flex-start' },
      }}
      elements={(mode, state, setState) => [
        mode === 'inline' && (
          <TextSearchFilter
            key='fts'
            value={state.get('searchTerm')}
            placeHolder={t('search', { ns: 'serviceCall' })}
            onChange={(searchTerm) => setState((prev) => prev.with({ searchTerm }))}></TextSearchFilter>
        ),
        mode === 'dialog' && (
          <JobKindFilter
            key='kind'
            value={state.get('kinds')}
            label={t('list.column.kind')}
            options={quoteKinds}
            onChange={(kinds) => setState((prev) => prev.with({ kinds }))}></JobKindFilter>
        ),
        mode === 'dialog' && (
          <CraneCapacityFilter
            key='capacity'
            value={state.get('capacities')}
            onChange={(value) => setState((prev) => prev.with({ capacities: value.map(({ capacity }) => capacity) }))}
          />
        ),
        mode === 'dialog' && (
          <EquipmentKindFilter
            key='equipmentKind'
            value={state.get('equipmentKinds')}
            onChange={(equipmentKinds) => setState((prev) => prev.with({ equipmentKinds }))}
          />
        ),
        mode === 'dialog' && (
          <JobStatusFilter
            key='status'
            value={state.get('statuses')}
            options={quoteStatuses.filter((s) => s !== 'locked')}
            onChange={(statuses) => setState((prev) => prev.with({ statuses }))}
            label={t('list.column.status')}
            renderChip={(option, handleDelete) => <QuoteStatusChip key={option} statuses={[option]} onDelete={handleDelete} />}
          />
        ),
        mode === 'dialog' && (
          <DispatchBranchFilter
            key='dispatchBranch'
            value={state.get('dispatchBranches')}
            onChange={(dispatchBranches) => setState((prev) => prev.with({ dispatchBranches }))}
          />
        ),
        mode === 'dialog' && (
          <ProjectManagerFilter
            key='projectManager'
            value={state.get('projectManagers')}
            onChange={(projectManagers) => setState((prev) => prev.with({ projectManagers }))}
          />
        ),
        mode === 'dialog' && (
          <RepresentativeFilter
            key='representative'
            value={state.get('representatives')}
            onChange={(representatives) => setState((prev) => prev.with({ representatives }))}
          />
        ),
        mode === 'dialog' && (
          <ArrivalDateFilter
            key='arrivalDate'
            value={state.get('arrivalDate')}
            onChange={([start, end]: DateRange<DateTime>) => {
              setState((prev) => prev.with({ arrivalDate: start?.isValid && end?.isValid ? { start, end } : null }));
            }}
          />
        ),
      ]}
    />
  );
}

export function QuoteList_Row({
  $key,
  write$key,
  orderByColumns,
}: {
  $key: QuoteList_RowFragment$key;
  write$key: AuthorizationWriteFragment$key;
  orderByColumns: ResponsiveGridColumnOrderer;
}) {
  const { t } = useAmbientTranslation();
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const $data = useFragment(
    graphql`
      fragment QuoteList_RowFragment on QuoteJobRevision {
        friendlyId
        snapshot {
          kind
          statuses
          clientBase {
            assignedClient {
              name
              category
              number
              isDirectSales
            }
            assignedClientInfo {
              name
            }
          }
          equipmentBase {
            craneSelector {
              favoriteConfiguration {
                capacity {
                  capacity
                }
                equipmentKind {
                  abbreviation
                }
              }
            }
          }
          projectBase {
            assignedWorksiteInfo {
              name
            }
            arrivalDate {
              rawValue
            }
          }
        }
        ...QuoteList_ActionsFragment
      }
    `,
    $key,
  );
  const { snapshot } = $data;
  const clientName = snapshot?.clientBase.assignedClient?.isDirectSales
    ? snapshot.clientBase.assignedClientInfo.name
    : snapshot?.clientBase.assignedClient?.name;
  const clientNameInfo = useMemo(
    () => ({ number: snapshot?.clientBase.assignedClient?.number, name: clientName }),
    [clientName, snapshot?.clientBase.assignedClient?.number],
  );
  const favouriteConfiguration = snapshot?.equipmentBase.craneSelector.favoriteConfiguration;
  const statuses = snapshot?.statuses.every(isQuoteStatus) ? (snapshot?.statuses ?? []) : [];

  return (
    <>
      <h3 style={visuallyHidden}>
        <NullableCell value={$data.friendlyId?.toString()} />
        <br />
        <NullableCell value={clientName} />
        <br />
        <NullableCell value={snapshot?.projectBase.assignedWorksiteInfo?.name} />
      </h3>

      {orderByColumns([
        <Typography key='friendlyId' variant='body2'>
          <NullableCell value={$data.friendlyId?.toString()} />
        </Typography>,

        <Box key='client' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0.25rem' }}>
          <EllipsisedTypography variant='body2' component='span'>
            <NullableCell
              value={clientNameInfo}
              formatter={(v) => (
                <>
                  {v.number} —&nbsp;{v.name}
                </>
              )}
            />
          </EllipsisedTypography>
          {snapshot?.clientBase.assignedClient && snapshot?.clientBase.assignedClient?.category !== 'NONE' && (
            <Chip variant='outlined' label={snapshot?.clientBase.assignedClient?.category} size='small' />
          )}
        </Box>,

        <EllipsisedTypography key='worksite' variant='body2' component='span'>
          <NullableCell value={snapshot?.projectBase.assignedWorksiteInfo?.name} />
        </EllipsisedTypography>,

        <EllipsisedTypography key='kind' variant='body2' component='span' title={t(`kind.${snapshot?.kind}`)}>
          {t(`kindShort.${snapshot?.kind}`)}
        </EllipsisedTypography>,

        <Typography key='crane' variant='body2' textAlign='center'>
          <NullableCell
            value={
              favouriteConfiguration
                ? {
                    capacity: favouriteConfiguration.capacity?.capacity,
                    equipmentKind: favouriteConfiguration.equipmentKind?.abbreviation,
                  }
                : null
            }
            formatter={({ capacity, equipmentKind }) => (
              <>
                {capacity}
                {capacity && equipmentKind && <span>&ndash;</span>}
                {equipmentKind}
              </>
            )}
          />
        </Typography>,

        <Box key='status' display='flex' alignItems='center'>
          <QuoteStatusChip statuses={statuses} compact={compact} />
        </Box>,

        <Typography
          key='date'
          variant='body2'
          title={DateTime.fromISO(snapshot?.projectBase.arrivalDate?.rawValue ?? '')
            .setLocale(t('locale', { ns: 'common' }))
            .toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' })}>
          <NullableCell
            formatter={(v: string) =>
              DateTime.fromISO(v)
                .setLocale(t('locale', { ns: 'common' }))
                .toLocaleString({
                  month: 'short',
                  day: 'numeric',
                })
            }
            value={snapshot?.projectBase.arrivalDate?.rawValue}
          />
        </Typography>,

        <QuoteList_Actions key='actions' $key={$data} write$key={write$key} />,
      ])}
    </>
  );
}

function QuoteList_Actions({ $key, write$key }: { $key: QuoteList_ActionsFragment$key | null; write$key: AuthorizationWriteFragment$key }) {
  const $data = useFragment(
    graphql`
      fragment QuoteList_ActionsFragment on QuoteJobRevision {
        ...SaleList_CopyButtonFragment
        snapshot {
          kind
        }
      }
    `,
    $key,
  );

  const hideAccessories = useHideAccessorySection(castQuoteKind($data?.snapshot?.kind ?? ''));

  return (
    // need to fallback on an empty element to keep grid columns from displaying correctly
    <RequireWrite $key={write$key} fallback={<div />}>
      <SaleList_CopyButton $key={$data} hideAccessories={hideAccessories} />
    </RequireWrite>
  );
}

export function QuoteList_Item({ fragmentKey }: { fragmentKey: QuoteList_ItemFragment$key }) {
  const { t } = useAmbientTranslation();

  const quote = useFragment(
    graphql`
      fragment QuoteList_ItemFragment on QuoteJobRevision {
        friendlyId
        snapshot {
          statuses
          clientBase {
            assignedClient {
              name
              isDirectSales
            }
            assignedClientInfo {
              name
            }
          }
          equipmentBase {
            craneSelector {
              favoriteConfiguration {
                capacity {
                  capacity
                }
                equipmentKind {
                  abbreviation
                }
              }
            }
          }
          projectBase {
            assignedWorksiteInfo {
              name
            }
            arrivalDate {
              rawValue
            }
          }
        }
      }
    `,
    fragmentKey,
  );
  const { snapshot } = quote;
  const clientName = snapshot?.clientBase.assignedClient?.isDirectSales
    ? snapshot.clientBase.assignedClientInfo.name
    : snapshot?.clientBase.assignedClient?.name;
  const capacityInTons = snapshot?.equipmentBase.craneSelector.favoriteConfiguration?.capacity?.capacity ?? 0;
  const statuses = snapshot?.statuses.every(isQuoteStatus) ? (snapshot?.statuses ?? []) : [];

  return (
    <>
      <h3 style={visuallyHidden}>
        <NullableCell value={quote.friendlyId?.toString()} />
        <br />
        <NullableCell value={snapshot?.clientBase.assignedClient?.name} />
        <br />
        <NullableCell value={snapshot?.projectBase.assignedWorksiteInfo?.name} />
      </h3>

      <ListItemText disableTypography={true}>
        <Typography color='text.secondary' fontSize='0.75rem'>
          <NullableCell value={quote.friendlyId?.toString()} />
          <span> | </span>
          <NullableCell
            formatter={(v: string) => DateTime.fromISO(v).toFormat(dateFormat)}
            value={snapshot?.projectBase.arrivalDate?.rawValue}
          />
        </Typography>
        <EllipsisedTypography variant='subtitle2' component='p' color='text.primary'>
          <NullableCell value={clientName} />
        </EllipsisedTypography>
        <EllipsisedTypography variant='subtitle2' component='p' color='text.primary'>
          <NullableCell value={snapshot?.projectBase.assignedWorksiteInfo?.name} />
        </EllipsisedTypography>
        <Typography variant='body2' component='span' color='text.secondary'>
          {t('unit.full.ton', { ns: 'common', count: capacityInTons })}
          &nbsp;
          {snapshot?.equipmentBase.craneSelector.favoriteConfiguration?.equipmentKind?.abbreviation ?? ''}
        </Typography>
      </ListItemText>
      <ListItemIcon>
        <QuoteStatusChip statuses={statuses} />
      </ListItemIcon>
    </>
  );
}

function RowSkeleton({ columns }: { columns: ResponsiveGridColumnDefinition[] }) {
  return (
    <span style={{ gridColumn: `1 / span ${columns.length}` }}>
      <Skeleton variant='rounded' height='1.5rem' sx={{ my: '0.875rem' }} />
    </span>
  );
}

function ListSkeleton() {
  return (
    <>
      <ListItemText>
        <Skeleton variant='rounded' width='6rem' height='1rem' sx={{ mb: '0.25rem' }} />
        <Skeleton variant='rounded' width='14rem' height='1.25rem' sx={{ mb: '0.25rem' }} />
        <Skeleton variant='rounded' width='10rem' height='1.25rem' sx={{ mb: '0.25rem' }} />
        <Skeleton variant='rounded' width='6rem' height='1rem' />
      </ListItemText>
      <ListItemIcon>
        <Skeleton variant='rounded' width='4.5rem' height='1.5rem' sx={{ borderRadius: '1rem' }} />
      </ListItemIcon>
    </>
  );
}
