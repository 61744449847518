/**
 * @generated SignedSource<<21806e3a717afb4b7f0526de46ee4cf7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccessoryRuleFields_SaleStagesFragment$data = {
  readonly conditions: {
    readonly jobConcreteTypes: ReadonlyArray<string>;
  };
  readonly " $fragmentType": "AccessoryRuleFields_SaleStagesFragment";
};
export type AccessoryRuleFields_SaleStagesFragment$key = {
  readonly " $data"?: AccessoryRuleFields_SaleStagesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryRuleFields_SaleStagesFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccessoryRuleFields_SaleStagesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccessoryConditionsInternal",
      "kind": "LinkedField",
      "name": "conditions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jobConcreteTypes",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AccessoryRule",
  "abstractKey": null
};

(node as any).hash = "47ebc2ac5c2dfef19084c19868f882c8";

export default node;
