/**
 * @generated SignedSource<<3e86e91f114e84f556b2ab16e3c788ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectBaseFields_WorksiteOverrides_PhoneNumberFragment$data = {
  readonly phoneNumber: string | null | undefined;
  readonly " $fragmentType": "ProjectBaseFields_WorksiteOverrides_PhoneNumberFragment";
};
export type ProjectBaseFields_WorksiteOverrides_PhoneNumberFragment$key = {
  readonly " $data"?: ProjectBaseFields_WorksiteOverrides_PhoneNumberFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverrides_PhoneNumberFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectBaseFields_WorksiteOverrides_PhoneNumberFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneNumber",
      "storageKey": null
    }
  ],
  "type": "IOverridableWorksite",
  "abstractKey": "__isIOverridableWorksite"
};

(node as any).hash = "bd16c2b013cf71978e7f1527abb7aa41";

export default node;
