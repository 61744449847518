import {
  createFieldKey,
  useField,
  useFieldErrors,
  useFieldErrorsFirstMessage,
  useFieldIsDirty,
  useFieldMapper,
  useFormEtag,
  useFormIsDirty,
  useFormMappings,
} from '../../common/utils/forms';
import { DataID, useFragment, useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { ReactNode, Suspense, useCallback, useEffect, useMemo } from 'react';
import { Button } from '@mui/material';
import {
  arrPatchBy,
  flagRemoved,
  hasChanged,
  isPatchableEditProps,
  Patchable,
  PatchableEditProps,
  toPatchOperation,
  usePatchable,
} from '../../common/utils/patchable';
import { nanoid } from 'nanoid';
import { jobStageBaseFormContext } from '../JobStageBaseFields';
import { undefinedIfEmpty } from '../../common/utils/formUtils';
import { AccessoryLinesFields_AccessoryLineCollectionFragment$key } from './__generated__/AccessoryLinesFields_AccessoryLineCollectionFragment.graphql';
import DeleteIcon from '@mui/icons-material/Delete';
import { useFieldDispatchBranch, useFieldNatureOfWork } from '../fields/SaleProjectFields';
import { AccessoryLinesFields_Item_SaleProjectFragment$key } from './__generated__/AccessoryLinesFields_Item_SaleProjectFragment.graphql';
import {
  accessoryLineSubFormContext,
  FieldAccessoryBillable,
  FieldAccessoryBillingCode,
  FieldAccessoryGroup,
  FieldAccessoryLineDefaultQuantity,
  FieldAccessoryLineIsFixedQuantity,
  FieldAccessoryLineSalesRateResult,
  FieldAccessoryOutOfInventory,
  FieldAccessoryQuantity,
  FieldAccessoryRate,
  FieldAccessoryType,
  FieldRequireAccessory,
  useFieldAccessoryBillable,
  useFieldAccessoryBillingCode,
  useFieldAccessoryGroupOrOutOfInventory,
  useFieldAccessoryId,
  useFieldAccessoryLineDefaultQuantity,
  useFieldAccessoryLineIsFixedQuantity,
  useFieldAccessoryLineKind,
  useFieldAccessoryLineSalesRateResult,
  useFieldAccessoryQuantity,
  useFieldAccessoryRate,
  useFieldAccessoryType,
} from './AccessoryLineSubFormFields';
import SaveIcon from '@mui/icons-material/Save';
import { PatchableNewProps, useRenderItemById, useRenderNewItem, useRenderSubFormCollection } from '../../common/utils/patchableForm';
import { AccessoryLinesFields_ItemContent_SaleProjectFragment$key } from './__generated__/AccessoryLinesFields_ItemContent_SaleProjectFragment.graphql';
import { AccessoryLinesFields_ItemContent_AccessoryTypeBusinessRulesFragment$key } from './__generated__/AccessoryLinesFields_ItemContent_AccessoryTypeBusinessRulesFragment.graphql';
import {
  AccessoryLinesFields_Item_BusinessRulesQuery,
  AccessoryLinesFields_Item_BusinessRulesQuery$variables,
} from './__generated__/AccessoryLinesFields_Item_BusinessRulesQuery.graphql';
import { AccessoryLinesFields_Item_RulesFragment$key } from './__generated__/AccessoryLinesFields_Item_RulesFragment.graphql';
import { AccessoryLineSubFormFields_InputAccessoryType_SuggestionsFragment$key } from './__generated__/AccessoryLineSubFormFields_InputAccessoryType_SuggestionsFragment.graphql';
import { ServiceCallKind } from '../../__enums__/ServiceCallKind';
import { QuoteKind } from '../../__enums__/QuoteKind';
import { useEffectEvent } from '../../common/utils/effectUtils';
import { useTaskState } from '../../common/utils/useTaskState';
import { AccessoryLinesFields_useResetAccessoryAndOutOfInventoryFragment$key } from './__generated__/AccessoryLinesFields_useResetAccessoryAndOutOfInventoryFragment.graphql';
import { AccessoryLinesFields_SyncBillableWithAccessoryType$key } from './__generated__/AccessoryLinesFields_SyncBillableWithAccessoryType.graphql';
import { AccessoryLinesFields_useQuantityRateFromSalesRateFragment$key } from './__generated__/AccessoryLinesFields_useQuantityRateFromSalesRateFragment.graphql';
import { useFieldArrivalDateValue, useFieldAssignedWorksite } from '../fields/ProjectBaseFields';
import { useFieldAssignedClient } from '../fields/ClientBaseFields';
import { useCraneSelectorFavorite } from '../JobEquipment.CraneSelector.Favorite';
import { useMissingCostsDependencies } from '../useMissingCostsDependencies';
import { _throw } from '../../common/utils/_throw';
import { AccessoryLinesFields_ItemQueryFragment$key } from './__generated__/AccessoryLinesFields_ItemQueryFragment.graphql';
import { AccessoryLinesFields_ItemFragment$key } from './__generated__/AccessoryLinesFields_ItemFragment.graphql';
import { IssueIndicatorSalesQuantity, IssueIndicatorSalesRate } from '../salesRate/IssueIndicator';
import { SalesRateResult } from '../../common/types/externalResult';
import { AccessoryLinesFields_useSetBillingCodeAdditionalDataFragment$key } from './__generated__/AccessoryLinesFields_useSetBillingCodeAdditionalDataFragment.graphql';
import { AccessoryLinesFields_useQuantityRateFromSalesRate_BillingCodeFragment$key } from './__generated__/AccessoryLinesFields_useQuantityRateFromSalesRate_BillingCodeFragment.graphql';
import { AccessoryLineKind, castAccessoryLineKind } from '../../__enums__/AccessoryLineKind';
import { Price } from '../../common/utils/price';
import { ItemDeleteButton } from '../../common/components/ItemDeleteButton';
import { SaveAccessoryLineInput } from '../../quote/__generated__/QuoteSaveButtonMutation.graphql';
import { useSalesSearchParams } from '../useSalesSearchParams';
import { SaleStage } from '../saleStage';
import { AccessoryLinesFields_AccessoryLineItemsFragment$key } from './__generated__/AccessoryLinesFields_AccessoryLineItemsFragment.graphql';

export type AccessoryLine = Patchable<{
  id: DataID;
  kind: AccessoryLineKind;
  rules$key: AccessoryLinesFields_Item_RulesFragment$key | null | undefined;
  salesRateResult: FieldAccessoryLineSalesRateResult;
  isFixedQuantity: FieldAccessoryLineIsFixedQuantity;
  defaultQuantity: FieldAccessoryLineDefaultQuantity;

  // Editable fields
  accessoryType: FieldAccessoryType;
  requireAccessory: FieldRequireAccessory;
  accessoryGroup: FieldAccessoryGroup;
  accessoryOutOfInventory: FieldAccessoryOutOfInventory | null | undefined;
  billingCode: FieldAccessoryBillingCode;
  quantity: FieldAccessoryQuantity;
  rate: FieldAccessoryRate;
  billable: FieldAccessoryBillable;
}>;
function keySelector(v: AccessoryLine) {
  return v.id;
}

const fieldAccessoryLineCollectionKey = createFieldKey<AccessoryLine[]>();
export function useFieldAccessoryLinesCollection(
  $key: AccessoryLinesFields_AccessoryLineCollectionFragment$key | null | undefined,
  disabled: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment AccessoryLinesFields_AccessoryLineCollectionFragment on CostsInternalBase
      @argumentDefinitions(skipAccessories: { type: "Boolean!" }) {
        accessoryLines @skip(if: $skipAccessories) {
          ...AccessoryLinesFields_AccessoryLineItemsFragment
        }
      }
    `,
    $key,
  );

  const item$key: AccessoryLinesFields_AccessoryLineItemsFragment$key | undefined = $data?.accessoryLines;
  const items$data = useFragment(
    graphql`
      fragment AccessoryLinesFields_AccessoryLineItemsFragment on AccessoryLine @relay(plural: true) {
        id
        ...AccessoryLinesFields_Item_RulesFragment

        accessoryType @required(action: THROW) {
          id
          label
          code
        }
        requireAccessory
        accessoryGroup {
          id
          label
          branch {
            name
          }
        }
        accessoryOutOfInventory
        kind
        billingCode {
          id
          label
        }
        quantity
        rate
        billable
        isFixedQuantity
        defaultQuantity
        salesRateResult {
          canEditRate
          value {
            isAnnualContract
            isFlexiblePrice
            price
            minimumQuantity
            createdAt
          }
          error {
            code
            description
          }
        }
      }
    `,
    item$key,
  );

  // FIXME in GG-8492. Temporary fix for mapAll in SaveCopyButton which would not return any item since they were not dirty or new. Once we have created create endpoint in backend, remove this.
  const { isCopying, isCopyInNewProject } = useSalesSearchParams();

  //If you call a setAccessoryLines manually it will not trigger a call to the sales rate api. It only monitors changes in other field in the form OR explicit changes in a specific row.
  const [accessoryLines, setAccessoryLines] = useField(
    jobStageBaseFormContext,
    fieldAccessoryLineCollectionKey,
    () =>
      items$data?.map(({ salesRateResult, quantity, rate, billable, defaultQuantity, kind, ...rest }) => ({
        ...(isCopying ? { $new: true as const } : {}), // FIXME in GG-8492 remove this
        ...rest,
        kind: castAccessoryLineKind(kind),
        quantity: quantity ?? null,
        rate: isCopyInNewProject && salesRateResult?.value?.price ? Price.fromApi(salesRateResult.value.price) : Price.fromApi(rate),
        billable: billable ?? null,
        defaultQuantity: defaultQuantity ?? null,
        salesRateResult: salesRateResult
          ? {
              etag: nanoid(),
              canEditRate: salesRateResult.canEditRate,
              value: salesRateResult.value ?? null,
              error: salesRateResult.error ?? null,
            }
          : null,
        rules$key: { ...rest, salesRateResult },
      })) ?? [],
  );
  const accessoryLinesIsDirty = useFieldIsDirty(jobStageBaseFormContext, fieldAccessoryLineCollectionKey);

  const {
    append,
    prepend,
    remove: removeAccessoryLine,
    replace: replaceAccessoryLine,
    patch: patchAccessoryLine,
  } = usePatchable(setAccessoryLines, keySelector);
  const appendAccessoryLine = useCallback((value: AccessoryLine) => append({ ...value, id: nanoid() }), [append]);
  const prependAccessoryLine = useCallback((value: AccessoryLine) => prepend({ ...value, id: nanoid() }), [prepend]);
  const clearAutomaticAccessoryLines = useCallback(() => {
    setAccessoryLines(arrPatchBy(flagRemoved, (cl) => cl.kind === 'automatic'));
  }, [setAccessoryLines]);
  const accessoryLineById = useCallback((id: DataID) => accessoryLines.find((a) => a.id === id), [accessoryLines]);

  const useMapperAccessoryLines = useFieldMapper(jobStageBaseFormContext, fieldAccessoryLineCollectionKey);
  useMapperAccessoryLines(
    (rows) => ({
      costsBase: {
        accessoryLines: undefinedIfEmpty(
          rows
            .filter((v) => hasChanged(v))
            .map((v) =>
              toPatchOperation(
                v,
                keySelector,
                (val) =>
                  ({
                    // TODO: Most of these shouldn't be defined as optional in the API.
                    accessoryTypeCode: val.accessoryType?.code ?? null,
                    requireAccessory: val.requireAccessory,
                    accessoryGroupId: val.accessoryGroup?.id ?? null,
                    billingCodeId: val.billingCode?.id ?? null,
                    accessoryOutOfInventory: val.accessoryOutOfInventory ?? null,
                    billable: val.billable,
                    quantity: val.quantity,
                    rate: val.rate?.toJSON() ?? null,
                    isFixedQuantity: val.isFixedQuantity,
                    defaultQuantity: val.defaultQuantity,
                    salesRateResult: { value: val.salesRateResult?.value ?? null, error: val.salesRateResult?.error ?? null },
                    kind: val.kind,
                  }) satisfies SaveAccessoryLineInput,
              ),
            ),
        ),
      },
    }),
    [],
    'save',
  );

  const renderAccessoryLinesCollection = useRenderSubFormCollection(
    accessoryLines,
    setAccessoryLines,
    keySelector,
    accessoryLineSubFormContext,
  );

  const renderAccessoryLinesNewItem = useRenderNewItem(accessoryLines, appendAccessoryLine, accessoryLineSubFormContext);
  const renderAccessoryLinesItemById = useRenderItemById(accessoryLines, setAccessoryLines, keySelector, accessoryLineSubFormContext);

  const renderAddButton = useCallback(
    (handleAdd: () => void) => {
      return <AccessoryLinesFields_AddButton append={appendAccessoryLine} onAdd={handleAdd} disabled={disabled} />;
    },
    [appendAccessoryLine, disabled],
  );
  const renderSaveButton = useCallback(
    (handleSave: () => void) => {
      return <AccessoryLinesFields_SaveButton patch={patchAccessoryLine} onSave={handleSave} disabled={disabled} />;
    },
    [disabled, patchAccessoryLine],
  );
  const renderDeleteButton = useCallback(
    (handleDelete: () => void) => {
      return <AccessoryLinesFields_DeleteByItemButton remove={removeAccessoryLine} onDelete={handleDelete} disabled={disabled} />;
    },
    [disabled, removeAccessoryLine],
  );

  return {
    accessoryLines,
    accessoryLinesIsDirty,
    accessoryLineById,
    setAccessoryLines,
    clearAutomaticAccessoryLines,
    appendAccessoryLine,
    prependAccessoryLine,
    removeAccessoryLine,
    replaceAccessoryLine,
    patchAccessoryLine,
    renderAccessoryLinesCollection,
    renderAccessoryLinesNewItem,
    renderAccessoryLinesItemById,
    renderAddButton,
    renderSaveButton,
    renderDeleteButton,
  };
}

export function useHideAccessorySection(kind: QuoteKind | ServiceCallKind) {
  return [
    'laborRental',
    'laborRentalOnBare',
    'laborRentalOnOperated',
    'rollingEquipment',
    'liftingPlan',
    'liftingTest',
    'storage',
  ].includes(kind);
}

export function useFieldAccessoryLinesErrors() {
  const hasErrors = useFieldErrors(jobStageBaseFormContext, fieldAccessoryLineCollectionKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(jobStageBaseFormContext, fieldAccessoryLineCollectionKey);

  return { accessoryLineErrorMessage: message, accessoryLineErrorParams: errorParams, accessoryLineHasErrors: hasErrors };
}

export type AccessoryLineFields_Item_PresentFn = AccessoryLinesFields_ItemContent_PresentFn;
export function AccessoryLineFields_Item({
  project$key,
  $key,
  disabled,
  required,
  gridMode,
  presentFn,
  presentFallbackFn,
  saleStage,
  ...patchableProps
}: {
  project$key: AccessoryLinesFields_Item_SaleProjectFragment$key | null | undefined;
  $key: AccessoryLinesFields_ItemFragment$key | null | undefined;
  presentFn: AccessoryLineFields_Item_PresentFn;
  presentFallbackFn: AccessoryLineFields_Item_PresentFn;
  gridMode: boolean;
  disabled: boolean;
  required: boolean;
  saleStage: SaleStage;
} & (PatchableEditProps<AccessoryLine> | PatchableNewProps<AccessoryLine>)): ReactNode {
  const { value } = isPatchableEditProps<AccessoryLine>(patchableProps) ? patchableProps : { value: null };
  const { onChange } = patchableProps;

  useFieldAccessoryId(value?.id ?? 'new');

  const project$data = useFragment(
    graphql`
      fragment AccessoryLinesFields_Item_SaleProjectFragment on ISaleProject {
        ...AccessoryLinesFields_ItemContent_SaleProjectFragment
      }
    `,
    project$key,
  );

  const rules$data = useFragment(
    graphql`
      fragment AccessoryLinesFields_Item_RulesFragment on AccessoryLine {
        accessoryType {
          ...AccessoryLinesFields_ItemContent_AccessoryTypeBusinessRulesFragment
        }
      }
    `,
    value?.rules$key,
  );

  const item$data = useFragment(
    graphql`
      fragment AccessoryLinesFields_ItemFragment on ISale {
        ...AccessoryLinesFields_ItemQueryFragment
      }
    `,
    $key,
  );

  const { accessoryType, accessoryTypeIsDirty } = useFieldAccessoryType(value?.accessoryType, disabled);
  const { accessoryBillingCodeIsDirty } = useFieldAccessoryBillingCode(value?.billingCode, disabled);
  const {
    done: resetAccessoryDone,
    start: startResetAccessory,
    complete: handleCompleteResetAccessory,
  } = useTaskState(accessoryType ? 'completed' : 'initial');
  useEffect(() => {
    if (!accessoryTypeIsDirty) {
      return;
    }

    startResetAccessory(accessoryType);
  }, [accessoryTypeIsDirty, accessoryType, startResetAccessory]);
  const {
    done: syncBillableDone,
    start: startSyncBillable,
    complete: handleCompleteSyncBillable,
  } = useTaskState(accessoryType ? 'completed' : 'initial');
  useEffect(() => {
    if (!accessoryTypeIsDirty) {
      return;
    }

    startSyncBillable(accessoryType);
  }, [accessoryType, accessoryTypeIsDirty, startSyncBillable]);

  const { done: rateApiDone, start: startRateApi, complete: handleCompleteRateApi } = useTaskState(accessoryType ? 'completed' : 'initial');
  useEffect(() => {
    if (!accessoryTypeIsDirty) {
      return;
    }

    startRateApi(accessoryType);
  }, [accessoryType, accessoryTypeIsDirty, startRateApi]);
  const {
    done: setBillingCodeAdditionalDataDone,
    start: startSetBillingCodeAdditionalData,
    complete: handleCompleteSetBillingCodeAdditionalData,
  } = useTaskState(accessoryType ? 'completed' : 'initial');
  useEffect(() => {
    if (!accessoryTypeIsDirty) {
      return;
    }

    startSetBillingCodeAdditionalData(accessoryType);
  }, [accessoryType, accessoryTypeIsDirty, startSetBillingCodeAdditionalData]);

  const { mapAll } = useFormMappings(accessoryLineSubFormContext);
  const sync = useEffectEvent((formState: unknown) => {
    // Enforce a pseudo dependency on current form state since mapAll is a stable function.
    // This is usually provided as the form etag.
    if (!formState) {
      return;
    }

    // Disable auto-save outside of grid mode. Instead, we rely on manual patching functions.
    if (!gridMode) {
      return;
    }

    // TODO: Should be done as part of the patching functions.
    //  Prevent accidental changes when the component is disabled.
    if (disabled) {
      return;
    }

    onChange(mapAll('sync'));
  });

  const formEtag = useFormEtag(accessoryLineSubFormContext);
  const formDirty = useFormIsDirty(accessoryLineSubFormContext);
  useEffect(() => {
    if (!formDirty) {
      return;
    }

    if (!accessoryType) {
      return;
    }

    if (!(resetAccessoryDone && syncBillableDone && rateApiDone && setBillingCodeAdditionalDataDone)) {
      return;
    }

    sync(formEtag);
  }, [accessoryType, formDirty, formEtag, resetAccessoryDone, syncBillableDone, sync, rateApiDone, setBillingCodeAdditionalDataDone]);

  return accessoryType?.id && (accessoryTypeIsDirty || accessoryBillingCodeIsDirty) ? (
    <Suspense
      fallback={
        <AccessoryLinesFields_ItemContent
          project$key={project$data}
          rulesAccessoryType$key={rules$data?.accessoryType}
          presentFn={presentFallbackFn}
          gridMode={gridMode}
          disabled={true}
          saleStage={saleStage}
          {...patchableProps}
        />
      }>
      <AccessoryLinesFields_ItemQuery
        project$key={project$data}
        $key={item$data}
        accessoryTypeId={accessoryType.id}
        presentFn={presentFn}
        gridMode={gridMode}
        disabled={disabled}
        required={required}
        onCompleteResetAccessory={handleCompleteResetAccessory}
        onCompleteSyncBillable={handleCompleteSyncBillable}
        onCompleteRateApi={handleCompleteRateApi}
        onCompleteSetBillingCodeAdditionalData={handleCompleteSetBillingCodeAdditionalData}
        saleStage={saleStage}
        {...patchableProps}
      />
    </Suspense>
  ) : (
    <AccessoryLinesFields_ItemContent
      project$key={project$data}
      rulesAccessoryType$key={rules$data?.accessoryType}
      presentFn={presentFn}
      gridMode={gridMode}
      disabled={disabled}
      saleStage={saleStage}
      {...patchableProps}
    />
  );
}

function useSetBillingCodeAdditionalData(
  $key: AccessoryLinesFields_useSetBillingCodeAdditionalDataFragment$key | null | undefined,
  value: AccessoryLine | null,
  disabled: boolean,
  onComplete: () => void,
) {
  const { accessoryBillingCode, accessoryBillingCodeIsDirty } = useFieldAccessoryBillingCode(value?.billingCode, disabled);
  const { setAccessoryLineIsFixedQuantity } = useFieldAccessoryLineIsFixedQuantity(value?.isFixedQuantity);
  const { setAccessoryLineDefaultQuantity } = useFieldAccessoryLineDefaultQuantity(value?.defaultQuantity ?? null);

  const $data = useFragment(
    graphql`
      fragment AccessoryLinesFields_useSetBillingCodeAdditionalDataFragment on BillingCode {
        isFixedQuantity
        defaultQuantity
      }
    `,
    $key,
  );

  const setAdditionalData = useEffectEvent(() => {
    //the setTimeout is used to make the code in the useEffectEvent to be executed in the next render cycle to make sure it happens after the task get started
    setTimeout(() => {
      if (!accessoryBillingCodeIsDirty) {
        onComplete();
        return;
      }
      setAccessoryLineIsFixedQuantity($data?.isFixedQuantity ?? false);
      setAccessoryLineDefaultQuantity($data?.defaultQuantity ?? null);
      onComplete();
    });
  });

  const skip = useEffectEvent(() => {
    //the setTimeout is used to make the code in the useEffectEvent to be executed in the next render cycle to make sure it happens after the task get started
    setTimeout(() => {
      onComplete();
    });
  });

  useEffect(() => {
    if (accessoryBillingCode) {
      setAdditionalData();
    } else {
      skip();
    }
  }, [accessoryBillingCode, setAdditionalData, skip]);
}

function useResetAccessoryAndOutOfInventory(
  $key: AccessoryLinesFields_useResetAccessoryAndOutOfInventoryFragment$key,
  value: AccessoryLine | null,
  disabled: boolean,
  onComplete: () => void,
) {
  const $data = useFragment(
    graphql`
      fragment AccessoryLinesFields_useResetAccessoryAndOutOfInventoryFragment on AccessoryTypeLookup {
        isNumberMandatory
      }
    `,
    $key,
  );

  const { accessoryType, accessoryTypeIsDirty } = useFieldAccessoryType(value?.accessoryType, disabled);
  const { resetAccessoryAndOutOfInventory } = useFieldAccessoryGroupOrOutOfInventory(
    null,
    value?.requireAccessory,
    value?.accessoryGroup,
    value?.accessoryOutOfInventory,
    null,
    null,
    disabled,
  );

  const reset = useEffectEvent(() => {
    //the setTimeout is used to make the code in the useEffectEvent to be executed in the next render cycle to make sure it happens after the task get started
    setTimeout(() => {
      if (!accessoryTypeIsDirty) {
        onComplete();
        return;
      }

      resetAccessoryAndOutOfInventory($data.isNumberMandatory);
      onComplete();
    });
  });
  const skip = useEffectEvent(() => {
    //the setTimeout is used to make the code in the useEffectEvent to be executed in the next render cycle to make sure it happens after the task get started
    setTimeout(() => {
      onComplete();
    });
  });
  useEffect(() => {
    if (accessoryType) {
      reset();
    } else {
      skip();
    }
  }, [accessoryType, skip, reset]);
}

function useSyncBillableWithAccessoryType(
  $key: AccessoryLinesFields_SyncBillableWithAccessoryType$key,
  value: AccessoryLine | null,
  disabled: boolean,
  onComplete: () => void,
) {
  const $data = useFragment(
    graphql`
      fragment AccessoryLinesFields_SyncBillableWithAccessoryType on AccessoryTypeLookup {
        isBillable
      }
    `,
    $key,
  );

  const { accessoryType, accessoryTypeIsDirty } = useFieldAccessoryType(value?.accessoryType, disabled);
  const { accessoryBillable, setAccessoryBillable } = useFieldAccessoryBillable(value?.billable, disabled);

  const setBillable = useEffectEvent(() => {
    //the setTimeout is used to make the code in the useEffectEvent to be executed in the next render cycle to make sure it happens after the task get started
    setTimeout(() => {
      if (!accessoryTypeIsDirty) {
        onComplete();
        return;
      }

      const newBillable = $data.isBillable;
      if (accessoryBillable !== newBillable) {
        setAccessoryBillable(newBillable);
      }

      onComplete();
    });
  });
  const skip = useEffectEvent(() => {
    //the setTimeout is used to make the code in the useEffectEvent to be executed in the next render cycle to make sure it happens after the task get started
    setTimeout(() => {
      onComplete();
    });
  });
  useEffect(() => {
    if (accessoryType) {
      setBillable();
    } else {
      skip();
    }
  }, [accessoryType, setBillable, skip]);
}

function useQuantityRateFromSalesRate(
  value: AccessoryLine | null,
  disabled: boolean,
  onComplete: () => void,
  $key: AccessoryLinesFields_useQuantityRateFromSalesRateFragment$key | null | undefined,
  billingCode$key: AccessoryLinesFields_useQuantityRateFromSalesRate_BillingCodeFragment$key | null | undefined,
) {
  const $data = useFragment(
    graphql`
      fragment AccessoryLinesFields_useQuantityRateFromSalesRateFragment on SalesRatesResponse {
        results {
          craneIndex
          salesRates {
            canEditRate
            value {
              isFlexiblePrice
              isAnnualContract
              minimumQuantity
              price
              createdAt
            }
            error {
              code
              description
            }
          }
        }
      }
    `,
    $key,
  );

  const billingCode$data = useFragment(
    graphql`
      fragment AccessoryLinesFields_useQuantityRateFromSalesRate_BillingCodeFragment on BillingCode {
        isFixedQuantity
        defaultQuantity
      }
    `,
    billingCode$key,
  );

  const { accessoryQuantity, setAccessoryQuantity } = useFieldAccessoryQuantity(value?.quantity, disabled);
  const { accessoryRate, setAccessoryRate } = useFieldAccessoryRate(value?.rate, disabled);
  const { accessoryLineSalesRateResult, setAccessoryLineSalesResultResult } = useFieldAccessoryLineSalesRateResult(value?.salesRateResult);
  const defaultQuantity = useMemo(
    () => (billingCode$data?.isFixedQuantity ? 1 : (billingCode$data?.defaultQuantity ?? null)),
    [billingCode$data?.defaultQuantity, billingCode$data?.isFixedQuantity],
  );

  const setSalesRate = useEffectEvent((result: SalesRateResult) => {
    //the setTimeout is used to make the code in the useEffectEvent to be executed in the next render cycle to make sure it happens after the task get started
    setTimeout(() => {
      if (result.error) {
        setAccessoryLineSalesResultResult(result);
        onComplete();
        return;
      }

      const newQuantity = !result.value || result.value.minimumQuantity <= 0 ? defaultQuantity : result.value.minimumQuantity;
      if (accessoryQuantity !== newQuantity && accessoryLineSalesRateResult?.value?.minimumQuantity !== accessoryQuantity) {
        setAccessoryQuantity(newQuantity);
      }

      const newRate = Price.fromApi(result.value?.price);
      if (accessoryRate !== newRate) {
        setAccessoryRate(newRate);
      }
      setAccessoryLineSalesResultResult(result);

      onComplete();
    });
  });
  const skipSalesRate = useEffectEvent(() => {
    //the setTimeout is used to make the code in the useEffectEvent to be executed in the next render cycle to make sure it happens after the task get started
    setTimeout(() => {
      onComplete();
    });
  });
  const executeFallBackRate = useEffectEvent(() => {
    //the setTimeout is used to make the code in the useEffectEvent to be executed in the next render cycle to make sure it happens after the task get started
    setTimeout(() => {
      setAccessoryLineSalesResultResult({
        etag: nanoid(),
        canEditRate: false,
        value: null,
        error: { code: '500', description: 'Server Error' },
      } satisfies SalesRateResult);
      onComplete();
    });
  });
  useEffect(() => {
    if (!$data) {
      setTimeout(() => {
        skipSalesRate();
      });
      return;
    }
    if (!$data.results[0]?.salesRates[0]) {
      setTimeout(() => {
        executeFallBackRate();
      });
      return;
    }
    setTimeout(() => {
      const salesRate = $data.results[0]?.salesRates[0] ?? _throw("Couldn't find sales rate result at index 0");
      setSalesRate({
        etag: nanoid(),
        // results[0].salesRates[0] because this is for a single accessory line of a single equipment so there will only be 1 result
        canEditRate: salesRate.canEditRate,
        value: salesRate.value ?? null,
        error: salesRate.error ?? null,
      });
    });
  }, [$data, executeFallBackRate, setSalesRate, skipSalesRate]);
}

function AccessoryLinesFields_ItemQuery({
  project$key,
  $key,
  accessoryTypeId,
  presentFn,
  gridMode,
  disabled,
  required,
  onCompleteResetAccessory,
  onCompleteSyncBillable,
  onCompleteRateApi,
  onCompleteSetBillingCodeAdditionalData,
  saleStage,
  ...patchableProps
}: {
  project$key: AccessoryLinesFields_ItemContent_SaleProjectFragment$key | null | undefined;
  $key: AccessoryLinesFields_ItemQueryFragment$key | null | undefined;
  accessoryTypeId: DataID;
  presentFn: AccessoryLinesFields_ItemContent_PresentFn;
  gridMode: boolean;
  disabled: boolean;
  required: boolean;
  onCompleteResetAccessory: () => void;
  onCompleteSyncBillable: () => void;
  onCompleteRateApi: () => void;
  onCompleteSetBillingCodeAdditionalData: () => void;
  saleStage: SaleStage;
} & (PatchableEditProps<AccessoryLine> | PatchableNewProps<AccessoryLine>)) {
  const { value } = isPatchableEditProps<AccessoryLine>(patchableProps) ? patchableProps : { value: null };

  const $data = useFragment(
    graphql`
      fragment AccessoryLinesFields_ItemQueryFragment on ISale {
        ...useMissingCostsDependenciesFragment
        projectBase {
          ...ProjectBaseFields_ArrivalDateFragment @arguments(isCopy: false)
          ...ProjectBaseFields_AssignedWorksiteFragment
        }
        clientBase {
          ...ClientBaseFields_AssignedClientFragment
        }
        project {
          ...SaleProjectFields_DispatchBranchFragment
          ...SaleProjectFields_NatureOfWorkFragment
        }
        equipmentBase {
          craneSelector {
            ...JobEquipment_useCraneSelectorFavoriteFragment
          }
        }
        costsBase {
          ...AccessoryLinesFields_AccessoryLineCollectionFragment @arguments(skipAccessories: false)
        }
      }
    `,
    $key,
  );
  const { arrivalDate } = useFieldArrivalDateValue($data?.projectBase);
  const { assignedClient } = useFieldAssignedClient($data?.clientBase);
  const { dispatchBranch } = useFieldDispatchBranch($data?.project, disabled);
  const { assignedWorksite } = useFieldAssignedWorksite($data?.projectBase);
  const { natureOfWork } = useFieldNatureOfWork($data?.project, disabled);
  const { favorite } = useCraneSelectorFavorite($data?.equipmentBase?.craneSelector, required);
  const missingDependencies = useMissingCostsDependencies($data);
  if (missingDependencies) {
    throw new Error('Missing dependencies to render AccessoryLinesFields_ItemQuery');
  }

  const { accessoryType } = useFieldAccessoryType(value?.accessoryType, disabled);
  const { accessoryBillingCode } = useFieldAccessoryBillingCode(value?.billingCode, disabled);

  const variables = useMemo<AccessoryLinesFields_Item_BusinessRulesQuery$variables>(() => {
    const unspecifiedBillingCode = accessoryBillingCode?.id == null;
    return {
      accessoryTypeId: accessoryTypeId,
      unspecifiedBillingCode,
      billingCodeId: !unspecifiedBillingCode ? [accessoryBillingCode?.id ?? _throw(new Error('This id should be specified'))] : [],
      salesRateInput: {
        arrivalDate: arrivalDate!.toJSON() ?? _throw(new Error('Invalid arrivalDate')),
        clientId: assignedClient!.id,
        dispatchBranchId: dispatchBranch!.id,
        worksiteId: assignedWorksite?.id && assignedWorksite.id !== 'new' ? assignedWorksite.id : null,
        natureOfWorkCode: natureOfWork!.code,
        billingInfos: !unspecifiedBillingCode
          ? [
              {
                billingCodeId: accessoryBillingCode?.id,
                itemTypeCode: accessoryType?.code,
                craneIndex: 0, // accessory are always and only on the primary
              },
            ]
          : [],
        equipments: [
          {
            boomConfigurationId: favorite?.boomConfiguration?.id,
            vehicleId: favorite?.vehicleId?.key,
          },
        ],
      },
    };
  }, [
    accessoryBillingCode?.id,
    accessoryType?.code,
    accessoryTypeId,
    arrivalDate,
    assignedClient,
    assignedWorksite?.id,
    dispatchBranch,
    favorite?.boomConfiguration,
    favorite?.vehicleId?.key,
    natureOfWork,
  ]);

  const rules$data = useLazyLoadQuery<AccessoryLinesFields_Item_BusinessRulesQuery>(
    graphql`
      query AccessoryLinesFields_Item_BusinessRulesQuery(
        $accessoryTypeId: ID!
        $billingCodeId: [ID!]!
        $salesRateInput: SalesRatesRequestInput!
        $unspecifiedBillingCode: Boolean!
      ) {
        node(id: $accessoryTypeId) @required(action: THROW) {
          ...AccessoryLinesFields_ItemContent_AccessoryTypeBusinessRulesFragment
          ...AccessoryLinesFields_SyncBillableWithAccessoryType
          ...AccessoryLinesFields_useResetAccessoryAndOutOfInventoryFragment
        }
        #We query nodeS because node doesn't support a null ID
        billingCode: nodes(ids: $billingCodeId) @skip(if: $unspecifiedBillingCode) {
          ...AccessoryLinesFields_useSetBillingCodeAdditionalDataFragment
          ...AccessoryLinesFields_useQuantityRateFromSalesRate_BillingCodeFragment
        }
        salesRates(input: $salesRateInput) @skip(if: $unspecifiedBillingCode) {
          ...AccessoryLinesFields_useQuantityRateFromSalesRateFragment
        }
      }
    `,
    variables,
    { fetchPolicy: 'network-only' }, //HACK: This is mandatory to prevent a weird interaction when having data coming from the cache will be fixed with the implementation of patchable2.0
  );
  useResetAccessoryAndOutOfInventory(rules$data.node, value, disabled, onCompleteResetAccessory);
  useSyncBillableWithAccessoryType(rules$data.node, value, disabled, onCompleteSyncBillable);
  useQuantityRateFromSalesRate(value, disabled, onCompleteRateApi, rules$data.salesRates, rules$data.billingCode?.[0]);
  useSetBillingCodeAdditionalData(rules$data.billingCode?.[0], value, disabled, onCompleteSetBillingCodeAdditionalData);
  return (
    <AccessoryLinesFields_ItemContent
      project$key={project$key}
      rulesAccessoryType$key={rules$data?.node}
      presentFn={presentFn}
      gridMode={gridMode}
      disabled={disabled}
      saleStage={saleStage}
      {...patchableProps}
    />
  );
}

type AccessoryLinesFields_ItemContent_PresentFn = (
  id: string,
  render: {
    renderAccessoryType: (
      $key: AccessoryLineSubFormFields_InputAccessoryType_SuggestionsFragment$key | null | undefined,
      saleKind: ServiceCallKind,
    ) => ReactNode;
    renderAccessoryOrOutOfInventory: () => ReactNode;
    renderAccessoryBillingCode: () => ReactNode;
    renderAccessoryQuantity: () => ReactNode;
    renderAccessoryRate: () => ReactNode;
    renderAccessoryBillable: () => ReactNode;
    renderDelete: () => ReactNode;
  },
) => ReactNode;
function AccessoryLinesFields_ItemContent({
  project$key,
  rulesAccessoryType$key,
  presentFn,
  gridMode,
  disabled,
  saleStage,
  ...patchableProps
}: {
  project$key: AccessoryLinesFields_ItemContent_SaleProjectFragment$key | null | undefined;
  rulesAccessoryType$key: AccessoryLinesFields_ItemContent_AccessoryTypeBusinessRulesFragment$key | null | undefined;
  presentFn: AccessoryLinesFields_ItemContent_PresentFn;
  gridMode: boolean;
  disabled: boolean;
  saleStage: SaleStage;
} & (PatchableEditProps<AccessoryLine> | PatchableNewProps<AccessoryLine>)) {
  const { value, onDelete: handleDelete } = isPatchableEditProps<AccessoryLine>(patchableProps)
    ? patchableProps
    : { value: null, onDelete: null };
  const { id } = patchableProps;

  const project$data = useFragment(
    graphql`
      fragment AccessoryLinesFields_ItemContent_SaleProjectFragment on ISaleProject {
        ...SaleProjectFields_DispatchBranchFragment
      }
    `,
    project$key,
  );

  const rulesAccessoryType$data = useFragment(
    graphql`
      fragment AccessoryLinesFields_ItemContent_AccessoryTypeBusinessRulesFragment on AccessoryTypeLookup {
        ...AccessoryLineSubFormFields_AccessoryGroupOrOutOfInventoryRulesFragment
        ...AccessoryLinesFields_SyncBillableWithAccessoryType
        ...AccessoryLinesFields_useResetAccessoryAndOutOfInventoryFragment
      }
    `,
    rulesAccessoryType$key,
  );

  const { dispatchBranch } = useFieldDispatchBranch(project$data, disabled);

  const { kind } = useFieldAccessoryLineKind(value?.kind);
  const automatic = kind === 'automatic';
  const { accessoryType, renderAccessoryType } = useFieldAccessoryType(value?.accessoryType, disabled || automatic);
  const { renderAccessoryOrOutOfInventory } = useFieldAccessoryGroupOrOutOfInventory(
    rulesAccessoryType$data,
    value?.requireAccessory,
    value?.accessoryGroup,
    value?.accessoryOutOfInventory,
    accessoryType?.code ?? null,
    dispatchBranch?.id ?? null,
    disabled,
  );
  const { accessoryLineSalesRateResult, setAccessoryLineSalesResultResult } = useFieldAccessoryLineSalesRateResult(value?.salesRateResult);
  const canEditRate = accessoryLineSalesRateResult?.canEditRate === true || saleStage === 'quote';
  const { renderAccessoryBillingCode } = useFieldAccessoryBillingCode(value?.billingCode, disabled || automatic);
  const { renderAccessoryQuantity, accessoryQuantity, setAccessoryQuantity } = useFieldAccessoryQuantity(value?.quantity, disabled);
  const { renderAccessoryRate, accessoryRate, setAccessoryRate } = useFieldAccessoryRate(value?.rate, disabled || !canEditRate);
  const { renderAccessoryBillable, accessoryBillable, setAccessoryBillable } = useFieldAccessoryBillable(value?.billable, disabled);
  const { accessoryLineIsFixedQuantity } = useFieldAccessoryLineIsFixedQuantity(value?.isFixedQuantity);

  const reverseSync = useEffectEvent((val: AccessoryLine) => {
    if (val.quantity !== accessoryQuantity) {
      setAccessoryQuantity(val.quantity);
    }
    if (val.rate !== accessoryRate) {
      setAccessoryRate(val.rate);
    }
    if (val.billable !== accessoryBillable) {
      setAccessoryBillable(val.billable);
    }
    if (val.salesRateResult?.etag !== accessoryLineSalesRateResult?.etag) {
      setAccessoryLineSalesResultResult(val.salesRateResult);
    }
  });

  useEffect(() => {
    if (!value) {
      return;
    }
    reverseSync(value);
  }, [reverseSync, value]);

  return presentFn(id, {
    renderAccessoryType: ($key, saleKind) => renderAccessoryType($key, saleKind, gridMode),
    renderAccessoryOrOutOfInventory: () => renderAccessoryOrOutOfInventory(gridMode),
    renderAccessoryBillingCode: () => renderAccessoryBillingCode(gridMode),
    renderAccessoryQuantity: () =>
      renderAccessoryQuantity(
        gridMode,
        <IssueIndicatorSalesQuantity
          quantity={accessoryQuantity}
          salesRateResult={accessoryLineSalesRateResult}
          isFixedQuantity={accessoryLineIsFixedQuantity}
        />,
        accessoryLineIsFixedQuantity,
      ),
    renderAccessoryRate: () =>
      renderAccessoryRate(gridMode, (rate) => <IssueIndicatorSalesRate salesRateResult={accessoryLineSalesRateResult} rate={rate} />),
    renderAccessoryBillable: () => renderAccessoryBillable(gridMode),
    renderDelete: () => (handleDelete ? <ItemDeleteButton onClick={handleDelete} disabled={disabled} /> : null),
  });
}

function AccessoryLinesFields_AddButton({
  append,
  onAdd,
  disabled,
}: {
  append: (value: AccessoryLine) => void;
  onAdd: (() => void) | undefined;
  disabled: boolean;
}) {
  const { mapAll } = useFormMappings(accessoryLineSubFormContext);
  const handleAdd = useCallback(() => {
    // id from mapping will be 'new'. We override it with a local id to track the item during edits.
    append({ ...mapAll('sync') });
    onAdd?.();
  }, [append, mapAll, onAdd]);

  // Intentionally looks like the SaveButton
  return (
    <Button
      onClick={handleAdd}
      disabled={disabled}
      variant='contained'
      size='toolbar'
      color='info'
      sx={(theme) => ({
        flexShrink: 0,
        '&.Mui-disabled': {
          backgroundColor: theme.palette.grey[300],
        },
      })}>
      <SaveIcon />
    </Button>
  );
}

function AccessoryLinesFields_SaveButton({
  patch,
  onSave,
  disabled,
}: {
  patch: (value: AccessoryLine) => void;
  onSave: (() => void) | undefined;
  disabled: boolean;
}) {
  const { mapAll } = useFormMappings(accessoryLineSubFormContext);
  const handleSave = useCallback(() => {
    patch(mapAll('sync'));
    onSave?.();
  }, [mapAll, onSave, patch]);

  return (
    <Button
      onClick={handleSave}
      disabled={disabled}
      variant='contained'
      size='toolbar'
      color='info'
      sx={(theme) => ({
        flexShrink: 0,
        '&.Mui-disabled': {
          backgroundColor: theme.palette.grey[300],
        },
      })}>
      <SaveIcon />
    </Button>
  );
}

function AccessoryLinesFields_DeleteByItemButton({
  remove,
  onDelete,
  disabled,
}: {
  remove: (value: AccessoryLine) => void;
  onDelete: (() => void) | undefined;
  disabled: boolean;
}) {
  const { mapAll } = useFormMappings(accessoryLineSubFormContext);
  const handleDelete = useCallback(() => {
    remove(mapAll('sync'));
    onDelete?.();
  }, [mapAll, onDelete, remove]);

  return (
    <Button
      onClick={handleDelete}
      disabled={disabled}
      variant='contained'
      size='toolbar'
      color='error'
      sx={(theme) => ({
        flexShrink: 0,
        mr: '-0.5rem',
        '&.Mui-disabled': {
          backgroundColor: theme.palette.grey[300],
        },
      })}>
      <DeleteIcon />
    </Button>
  );
}
