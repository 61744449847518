/**
 * @generated SignedSource<<7a5ebd3568b849afa275071114e95c65>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SidebarDrawerFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AuthorizationAdminFragment" | "AuthorizationCraneChartMaintainerFragment">;
  readonly " $fragmentType": "SidebarDrawerFragment";
};
export type SidebarDrawerFragment$key = {
  readonly " $data"?: SidebarDrawerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SidebarDrawerFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SidebarDrawerFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AuthorizationAdminFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AuthorizationCraneChartMaintainerFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "60bfdc438ec0c0f37c957c7178fcdf48";

export default node;
