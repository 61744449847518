/**
 * @generated SignedSource<<6412cefaecf116858f3cc89e6ba1eb45>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssignedWorksite_useUpdateWorksiteFragment$data = {
  readonly assignedWorksite: {
    readonly requirements: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_RequirementsFragment">;
    }>;
  } | null | undefined;
  readonly assignedWorksiteInfo: {
    readonly requirements: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_RequirementsFragment">;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverrides_ContactFragment" | "ProjectBaseFields_WorksiteOverrides_LocationFragment" | "ProjectBaseFields_WorksiteOverrides_NameFragment" | "ProjectBaseFields_WorksiteOverrides_PhoneNumberFragment">;
  };
  readonly " $fragmentType": "AssignedWorksite_useUpdateWorksiteFragment";
};
export type AssignedWorksite_useUpdateWorksiteFragment$key = {
  readonly " $data"?: AssignedWorksite_useUpdateWorksiteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssignedWorksite_useUpdateWorksiteFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "Requirement",
  "kind": "LinkedField",
  "name": "requirements",
  "plural": true,
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectBaseFields_RequirementsFragment"
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssignedWorksite_useUpdateWorksiteFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Worksite",
      "kind": "LinkedField",
      "name": "assignedWorksite",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WorksiteInfoInternal",
      "kind": "LinkedField",
      "name": "assignedWorksiteInfo",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectBaseFields_WorksiteOverrides_NameFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectBaseFields_WorksiteOverrides_LocationFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectBaseFields_WorksiteOverrides_ContactFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectBaseFields_WorksiteOverrides_PhoneNumberFragment"
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "ProjectInternalBase",
  "abstractKey": null
};
})();

(node as any).hash = "ef9c102ea532407c0c897f01777e6e52";

export default node;
