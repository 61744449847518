/**
 * @generated SignedSource<<c62b10aeef5ff6625a6754a07a9dbdf0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccessoryLinesFields_SyncBillableWithAccessoryType$data = {
  readonly isBillable: boolean;
  readonly " $fragmentType": "AccessoryLinesFields_SyncBillableWithAccessoryType";
};
export type AccessoryLinesFields_SyncBillableWithAccessoryType$key = {
  readonly " $data"?: AccessoryLinesFields_SyncBillableWithAccessoryType$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryLinesFields_SyncBillableWithAccessoryType">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccessoryLinesFields_SyncBillableWithAccessoryType",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isBillable",
      "storageKey": null
    }
  ],
  "type": "AccessoryTypeLookup",
  "abstractKey": null
};

(node as any).hash = "e009a4959220f63da8b6d578f157f914";

export default node;
