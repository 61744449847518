/**
 * @generated SignedSource<<41162cb4bed92b346acbe71f330c0d3b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CostLinesFields_ItemQueryFragment$data = {
  readonly clientBase: {
    readonly " $fragmentSpreads": FragmentRefs<"ClientBaseFields_AssignedClientFragment">;
  };
  readonly costsBase: {
    readonly " $fragmentSpreads": FragmentRefs<"CostLinesFields_CostLineCollectionFragment">;
  };
  readonly equipmentBase: {
    readonly craneSelector: {
      readonly " $fragmentSpreads": FragmentRefs<"JobEquipment_useCraneSelectorFavoriteFragment">;
    };
  };
  readonly project: {
    readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_DispatchBranchFragment" | "SaleProjectFields_NatureOfWorkFragment">;
  };
  readonly projectBase: {
    readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_ArrivalDateFragment" | "ProjectBaseFields_AssignedWorksiteFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"useMissingCostsDependenciesFragment">;
  readonly " $fragmentType": "CostLinesFields_ItemQueryFragment";
};
export type CostLinesFields_ItemQueryFragment$key = {
  readonly " $data"?: CostLinesFields_ItemQueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CostLinesFields_ItemQueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CostLinesFields_ItemQueryFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMissingCostsDependenciesFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectInternalBase",
      "kind": "LinkedField",
      "name": "projectBase",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Literal",
              "name": "isCopy",
              "value": false
            }
          ],
          "kind": "FragmentSpread",
          "name": "ProjectBaseFields_ArrivalDateFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectBaseFields_AssignedWorksiteFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientInternalBase",
      "kind": "LinkedField",
      "name": "clientBase",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientBaseFields_AssignedClientFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleProjectFields_DispatchBranchFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleProjectFields_NatureOfWorkFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquipmentInternalBase",
      "kind": "LinkedField",
      "name": "equipmentBase",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CraneSelectorInternal",
          "kind": "LinkedField",
          "name": "craneSelector",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "JobEquipment_useCraneSelectorFavoriteFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CostsInternalBase",
      "kind": "LinkedField",
      "name": "costsBase",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CostLinesFields_CostLineCollectionFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ISale",
  "abstractKey": "__isISale"
};

(node as any).hash = "ac9358d7e6ddcb0254786eec0c3f008a";

export default node;
