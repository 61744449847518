/**
 * @generated SignedSource<<fd9d3b02a0226556ec04c39363f3ed9b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BlobStatus = "error" | "processing" | "received" | "success" | "uploading" | "%future added value";
export type StartCraneChartBlobUploadInput = {
  byteCount: any;
  name: string;
};
export type CraneChartImportPageStartBlobUploadMutation$variables = {
  inputs: ReadonlyArray<StartCraneChartBlobUploadInput>;
};
export type CraneChartImportPageStartBlobUploadMutation$data = {
  readonly startCraneChartBlobUpload: {
    readonly craneChartBlobSasUris: ReadonlyArray<{
      readonly craneChartBlobMetadata: {
        readonly byteCount: any;
        readonly id: string;
        readonly mimeType: string;
        readonly name: string;
        readonly status: BlobStatus;
        readonly storageContainer: string;
      };
      readonly sasUri: string;
    }> | null | undefined;
    readonly errors: ReadonlyArray<{
      readonly __typename: "SalesApiValidationError";
      readonly " $fragmentSpreads": FragmentRefs<"ErrorBannerValidationErrorFragment">;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    }> | null | undefined;
  };
};
export type CraneChartImportPageStartBlobUploadMutation = {
  response: CraneChartImportPageStartBlobUploadMutation$data;
  variables: CraneChartImportPageStartBlobUploadMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "inputs"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "inputs",
    "variableName": "inputs"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "CraneChartBlobSasUriPayload",
  "kind": "LinkedField",
  "name": "craneChartBlobSasUris",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CraneChartBlobMetadata",
      "kind": "LinkedField",
      "name": "craneChartBlobMetadata",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "byteCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mimeType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "storageContainer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sasUri",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CraneChartImportPageStartBlobUploadMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StartCraneChartBlobUploadPayload",
        "kind": "LinkedField",
        "name": "startCraneChartBlobUpload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ErrorBannerValidationErrorFragment"
                  }
                ],
                "type": "SalesApiValidationError",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CraneChartImportPageStartBlobUploadMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StartCraneChartBlobUploadPayload",
        "kind": "LinkedField",
        "name": "startCraneChartBlobUpload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SalesValidationError",
                    "kind": "LinkedField",
                    "name": "errors",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "propertyName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "code",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "index",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "comparisonValue",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "comparisonPropertyName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "actualValue",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "SalesApiValidationError",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "997edc853efb4b598698bf9ce19e18ad",
    "id": null,
    "metadata": {},
    "name": "CraneChartImportPageStartBlobUploadMutation",
    "operationKind": "mutation",
    "text": "mutation CraneChartImportPageStartBlobUploadMutation(\n  $inputs: [StartCraneChartBlobUploadInput!]!\n) {\n  startCraneChartBlobUpload(inputs: $inputs) {\n    craneChartBlobSasUris {\n      craneChartBlobMetadata {\n        id\n        name\n        byteCount\n        mimeType\n        storageContainer\n        status\n      }\n      sasUri\n    }\n    errors {\n      __typename\n      ... on SalesApiValidationError {\n        ...ErrorBannerValidationErrorFragment\n      }\n    }\n  }\n}\n\nfragment ErrorBannerValidationErrorFragment on SalesApiValidationError {\n  errors {\n    propertyName\n    code\n    index\n    comparisonValue\n    comparisonPropertyName\n    actualValue\n  }\n}\n"
  }
};
})();

(node as any).hash = "f0287177369d00cd90bc3f27cf484948";

export default node;
