import {
  Context,
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useSyncExternalStore,
} from 'react';

/**
 * A non-exported symbol used to store state factories semi-privately within {@link SharedStateKey} objects.
 * @internal
 * @see SharedStateKey
 * @see createSharedStateKey
 */
const STATE_FACTORY_PROP = Symbol('SharedStateKey factory');

/**
 * An opaque object identifying a piece of shared state, specifying its type and default value factory.
 * @typeParam T - The type of the state value
 * @see createSharedStateKey
 * @see useSharedState
 */
export interface SharedStateKey<T> {
  readonly [STATE_FACTORY_PROP]: () => T;
}

/**
 * Create a {@link SharedStateKey} to be used with {@link useSharedState}.
 * @typeParam T The type of the state value
 * @param factory - A factory function returning the default value for this shared state
 * @returns A new {@link SharedStateKey} to be used with {@link useSharedState}
 * @see useSharedState
 */
export function createSharedStateKey<T>(factory: () => T): SharedStateKey<T> {
  return Object.freeze({ [STATE_FACTORY_PROP]: factory });
}

/**
 * A shared state store allowing to get and set states by key, and to subscribe to changes.
 * @internal
 * @see useSharedStateStore
 * @see useSharedState
 */
interface SharedStateStore {
  /**
   * Register a store-wide change listener.
   * @param onStoreChange - will be called every time the store is changed
   * @returns a cleanup function which should be called to unregister the listener
   */
  readonly subscribe: (onStoreChange: () => void) => () => void;
  /**
   * Get the state value for the provided `key`.
   * @typeParam - T the type of state value for the provided `key`
   * @param key - a {@link SharedStateKey}
   * @returns the current value for the provided `key`
   */
  readonly get: <T>(key: SharedStateKey<T>) => T;
  /**
   * Set a new state value for the provided `key`.
   * @typeParam - T the type of state value for the provided `key`
   * @param key - a {@link SharedStateKey}
   * @param value - either a new state value, or an updater function which takes the current state value as argument and
   *   should return a new state value
   */
  readonly set: <T>(key: SharedStateKey<T>, value: SetStateAction<T>) => void;
}

/**
 * A custom hook which creates and returns a new {@link SharedStateStore}.
 * @internal
 */
function useSharedStateStore(): SharedStateStore {
  const statesRef = useRef<ReadonlyMap<SharedStateKey<unknown>, unknown>>(new Map());
  const listenersRef = useRef<ReadonlyArray<() => void>>([]);

  return useMemo(
    () =>
      Object.freeze<SharedStateStore>({
        subscribe: (onStoreChange) => {
          // Wrap the listener to make sure all registered listeners have different identities. This is necessary
          // because the returned unregister callback looks up listeners by identity, which would work incorrectly with
          // duplicate listeners.
          const listener = () => {
            onStoreChange();
          };
          listenersRef.current = [...listenersRef.current, listener];
          return () => {
            listenersRef.current = listenersRef.current.filter((l) => l !== listener);
          };
        },

        get: <T,>(key: SharedStateKey<T>): T => {
          if (!statesRef.current.has(key)) {
            statesRef.current = new Map(statesRef.current).set(key, key[STATE_FACTORY_PROP]());
          }
          return statesRef.current.get(key) as T;
        },

        set: (key, value) => {
          statesRef.current = new Map(statesRef.current).set(
            key,
            // Consider function values as updaters just like useState()
            typeof value === 'function'
              ? (value as (prevState: unknown) => unknown)(
                  statesRef.current.has(key) ? statesRef.current.get(key) : key[STATE_FACTORY_PROP](),
                )
              : value,
          );
          for (const listener of listenersRef.current) {
            listener();
          }
        },
      }),
    [],
  );
}

/**
 * A context object used to bind a reference to a {@link SharedStateStore} to a component tree.
 * @see createSharedStateContext
 * @see useSharedState
 */
export type SharedStateContext = Context<SharedStateStore>;

/**
 * Create a React context used to store a reference to a {@link SharedStateStore}.
 * @returns a new {@link SharedStateContext}
 * @see useSharedState
 */
export function createSharedStateContext(): SharedStateContext {
  return createContext<SharedStateStore>(null as never);
}

/**
 * A component used to provide a new {@link SharedStateStore} through a {@link SharedStateContext}.
 * @param context - a {@link SharedStateContext} object to which bind a new {@link SharedStateStore}
 * @param children - JSX children
 * @see createSharedStateContext
 * @see useSharedState
 */
export function SharedStateStoreProvider({ context, children }: PropsWithChildren<{ context: SharedStateContext }>) {
  const store = useSharedStateStore();
  return <context.Provider value={store}>{children}</context.Provider>;
}

/**
 * A hook used to retrieve a `[value, setValue]` pair for the provided {@link SharedStateKey} within the specified
 * {@link SharedStateContext}.
 *
 * @param context - the {@link SharedStateContext} to which the shared state should be bound
 * @param key - a {@link SharedStateKey} identifying a piece of shared state within the provided
 *   {@link SharedStateContext}
 * @returns a `[value, setValue]` tuple which can be used the same way as if it was produced by `useState()`, except the
 *   state is stored inside the designated {@link SharedStateContext} instead of inside the component itself, so it can
 *   be shared between components, and will survive the components being unmounted / remounted.
 */
export function useSharedState<T>(context: SharedStateContext, key: SharedStateKey<T>): [T, Dispatch<SetStateAction<T>>] {
  const store = useContext(context);
  if (!store) {
    throw new Error('useSharedState() can only be called from components with a <SharedStateStoreProvider> parent');
  }

  const value = useSyncExternalStore(store.subscribe, () => store.get(key));
  const setValue = useCallback(
    (v: SetStateAction<T>) => {
      store.set(key, v);
    },
    [key, store],
  );

  // Return a [value, setValue] array with the same semantics as useState()
  return [value, setValue];
}

// Example usage
//
// const stateContextA = createSharedStateContext();
// const stateContextB = createSharedStateContext();
//
// const counterSharedStateKey = createSharedStateKey<number>(() => 0);
//
// function CounterComponent({ stateContext, name }: { stateContext: SharedStateContext; name: string }) {
//   const [counter, setCounter] = useSharedState(stateContext, counterSharedStateKey);
//   return (
//     <>
//       <Typography>
//         Counter {name}: {counter}
//       </Typography>
//       <Button onClick={() => setCounter((current) => current - 1)}>Decrement &darr;</Button>
//       <Button onClick={() => setCounter((current) => current + 1)}>Increment &uarr;</Button>
//     </>
//   );
// }
//
// export function SharedStateTestPage() {
//   return (
//     <SharedStateStoreProvider context={stateContextA}>
//       <CounterComponent stateContext={stateContextA} name='A1' />
//       <SharedStateStoreProvider context={stateContextB}>
//         <CounterComponent stateContext={stateContextA} name='A1' />
//         <CounterComponent stateContext={stateContextB} name='B1' />
//         <SharedStateStoreProvider context={stateContextA}>
//           <CounterComponent stateContext={stateContextB} name='B1' />
//           <CounterComponent stateContext={stateContextA} name='A2' />
//         </SharedStateStoreProvider>
//       </SharedStateStoreProvider>
//     </SharedStateStoreProvider>
//   );
// }
