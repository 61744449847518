/**
 * @generated SignedSource<<1a86a58daeddaea97c4f63da67ecb76b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as requirementLabelResolverType } from "../../../__resolvers__/RequirementLabelResolver";
// Type assertion validating that `requirementLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(requirementLabelResolverType satisfies (
  rootKey: RequirementLabelResolver$key,
) => string | null | undefined);
export type ProjectBaseFields_RequirementsFragment$data = ReadonlyArray<{
  readonly id: string;
  readonly label: string | null | undefined;
  readonly " $fragmentType": "ProjectBaseFields_RequirementsFragment";
}>;
export type ProjectBaseFields_RequirementsFragment$key = ReadonlyArray<{
  readonly " $data"?: ProjectBaseFields_RequirementsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_RequirementsFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ProjectBaseFields_RequirementsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "fragment": {
        "args": null,
        "kind": "FragmentSpread",
        "name": "RequirementLabelResolver"
      },
      "kind": "RelayResolver",
      "name": "label",
      "resolverModule": require('./../../../__resolvers__/RequirementLabelResolver').label,
      "path": "label"
    }
  ],
  "type": "Requirement",
  "abstractKey": null
};

(node as any).hash = "55e9907b4d2baa6d7324fda035a1cb14";

export default node;
