/**
 * @generated SignedSource<<6a241b8eb76ddc12dd8e459b6de96b0e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssignedWorksiteFragment$data = {
  readonly assignedWorksite: {
    readonly " $fragmentSpreads": FragmentRefs<"AssignedWorksite_Label_BaseFragment" | "AssignedWorksite_Overrides_BaseFragment">;
  } | null | undefined;
  readonly assignedWorksiteInfo: {
    readonly " $fragmentSpreads": FragmentRefs<"AssignedWorksite_Overrides_DenormalizableFragment" | "AssignedWorksite_UseHasWorksiteInfoFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_AssignedWorksiteFragment">;
  readonly " $fragmentType": "AssignedWorksiteFragment";
};
export type AssignedWorksiteFragment$key = {
  readonly " $data"?: AssignedWorksiteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssignedWorksiteFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssignedWorksiteFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Worksite",
      "kind": "LinkedField",
      "name": "assignedWorksite",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AssignedWorksite_Label_BaseFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AssignedWorksite_Overrides_BaseFragment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectBaseFields_AssignedWorksiteFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WorksiteInfoInternal",
      "kind": "LinkedField",
      "name": "assignedWorksiteInfo",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AssignedWorksite_Overrides_DenormalizableFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AssignedWorksite_UseHasWorksiteInfoFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProjectInternalBase",
  "abstractKey": null
};

(node as any).hash = "cb39c6f705725505e3a52a0e788f6e48";

export default node;
