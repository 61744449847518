/**
 * @generated SignedSource<<726856a3589959b5273e167e0b1aa577>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type NatureOfWorkSubCategoryEnum = "accessory" | "airports" | "antennas" | "aqueductsSewer" | "blade" | "bridges" | "cages" | "chambers" | "dams" | "demolition" | "distribution" | "docks" | "excavation" | "gearboxes" | "generator" | "gondola" | "hand" | "handling" | "landscaping" | "lifting" | "lines" | "modules" | "newConstruction" | "nonOperated" | "powerPlants" | "pruning" | "pylons" | "refurbishment" | "retentionBasins" | "roadsRails" | "rotor" | "shelters" | "substations" | "towers" | "transformers" | "transport" | "ventilationPlumbingElectrical" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SaleProjectFields_NatureOfWorkSubCategoryFragment$data = {
  readonly natureOfWorkSubCategory: NatureOfWorkSubCategoryEnum | null | undefined;
  readonly " $fragmentType": "SaleProjectFields_NatureOfWorkSubCategoryFragment";
};
export type SaleProjectFields_NatureOfWorkSubCategoryFragment$key = {
  readonly " $data"?: SaleProjectFields_NatureOfWorkSubCategoryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_NatureOfWorkSubCategoryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleProjectFields_NatureOfWorkSubCategoryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "natureOfWorkSubCategory",
      "storageKey": null
    }
  ],
  "type": "ISaleProject",
  "abstractKey": "__isISaleProject"
};

(node as any).hash = "815f191f06e75512bec6d9f3639b9129";

export default node;
