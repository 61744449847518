/**
 * @generated SignedSource<<4808107fdc584eb5b4d5d011cc9e511f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaleProjectFields_UseDispatchBranchChangeFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_DispatchBranchFragment" | "SaleProjectFields_SalesBranchFragment">;
  readonly " $fragmentType": "SaleProjectFields_UseDispatchBranchChangeFragment";
};
export type SaleProjectFields_UseDispatchBranchChangeFragment$key = {
  readonly " $data"?: SaleProjectFields_UseDispatchBranchChangeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_UseDispatchBranchChangeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleProjectFields_UseDispatchBranchChangeFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SaleProjectFields_DispatchBranchFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SaleProjectFields_SalesBranchFragment"
    }
  ],
  "type": "ISaleProject",
  "abstractKey": "__isISaleProject"
};

(node as any).hash = "f97c52c961e503b3bf70a1f00ebb73bd";

export default node;
