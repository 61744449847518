import { SyntheticEvent, useCallback, useMemo } from 'react';
import { Typography } from '@mui/material';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { resolvedLanguage } from '../i18n';
import { useFormContext } from '../common/utils/formUtils';
import { ResponsiveGridColumnDefinition, ResponsiveGridColumnOrderer } from '../common/components/ResponsiveGrid';
import { FormGrid, useFormGridElementFactory } from '../common/components/FormGrid';
import { EllipsisedTypography } from '../common/components/EllipsisedTypography';

import { NatureOfWorkFormValues } from './tabEnumsSections/NatureOfWorkForm';

import { workScheduleEnums } from '../__enums__/WorkScheduleEnum';
import { natureOfWorkSubCategoryEnums } from '../__enums__/NatureOfWorkSubCategoryEnum';
import { SelectPicker } from '../common/components/SelectPicker';
import { _throw } from '../common/utils/_throw';

export function NatureOfWorkList() {
  const { t } = useTranslation('configuration');
  const { getValues } = useFormContext<NatureOfWorkFormValues>();

  const columns: ResponsiveGridColumnDefinition[] = [
    { id: 'code', label: t('enums.natureOfWork.code'), size: 'minmax(1rem, 1fr)', sx: { fontWeight: 'bold' } },
    {
      id: 'description',
      label: t('enums.natureOfWork.description'),
      size: 'minmax(6rem, 4fr)',
      sx: { fontWeight: 'bold' },
    },
    {
      id: 'workSchedules',
      label: t('enums.natureOfWork.workSchedules'),
      size: 'minmax(6rem, 4fr)',
      sx: { fontWeight: 'bold' },
    },
    {
      id: 'defaultWorkSchedule',
      label: t('enums.natureOfWork.defaultWorkSchedule'),
      size: 'minmax(6rem, 2fr)',
      sx: { fontWeight: 'bold' },
    },
    {
      id: 'natureOfWorkSubCategories',
      label: t('enums.natureOfWork.natureOfWorkSubCategories'),
      size: 'minmax(6rem, 4fr)',
      sx: { fontWeight: 'bold' },
    },
  ];

  const rowElementFactory = useFormGridElementFactory(getValues('natureOfWorks'), (node, orderByColumns, index) => (
    <NatureOfWorkRow index={index} orderByColumns={orderByColumns} />
  ));

  return (
    <FormGrid<NatureOfWorkFormValues['natureOfWorks'][number]>
      columnDefinitions={columns}
      data={getValues('natureOfWorks')}
      listElementFactory={rowElementFactory}
      rowElementFactory={rowElementFactory}
      listSx={{
        // determine row height from content
        '&': {
          gridAutoRows: 'max-content',
        },
        // make grid cells use all the available row height
        '& li > *': {
          height: '100%',
        },
        'li:not(.responsive-grid__header) > *': {
          px: '1rem',
        },
      }}
    />
  );
}

function NatureOfWorkRow({ index, orderByColumns }: { index: number; orderByColumns: ResponsiveGridColumnOrderer }) {
  const { t, i18n } = useTranslation(['configuration', 'common']);
  const { control, watch, getValues, setValue, trigger } = useFormContext<NatureOfWorkFormValues>();
  const selectedWorkSchedules = watch(`natureOfWorks.${index}.workSchedules`);
  const i18nLang = resolvedLanguage(i18n);

  const handleWorkSchedulesChange = useCallback(
    (field: ControllerRenderProps<NatureOfWorkFormValues, `natureOfWorks.${number}.workSchedules`>) => {
      return async (e: SyntheticEvent, workSchedules: readonly string[]) => {
        // always store work schedules in the same order to make rows easier to compare visually
        field.onChange(workSchedules.slice().sort(), e);

        const defaultWorkSchedule = getValues(`natureOfWorks.${index}.defaultWorkSchedule`);
        if (defaultWorkSchedule != null && !workSchedules.includes(defaultWorkSchedule)) {
          setValue(`natureOfWorks.${index}.defaultWorkSchedule`, null, { shouldValidate: true });
        }

        // TODO remove once this react-hook-form bug is fixed, validation for array field don't work properly without the trigger:
        // https://github.com/react-hook-form/react-hook-form/issues/10862
        await trigger();
      };
    },
    [getValues, index, setValue, trigger],
  );

  const handleSubCategoriesChange = useCallback(
    (field: ControllerRenderProps<NatureOfWorkFormValues, `natureOfWorks.${number}.natureOfWorkSubCategories`>) => {
      return async (e: SyntheticEvent, natureOfWorksSubCategories: readonly string[]) => {
        // always store work categories in the same order to make rows easier to compare visually
        field.onChange(natureOfWorksSubCategories.slice().sort(), e);

        // TODO remove once this react-hook-form bug is fixed, validation for array field don't work properly without the trigger:
        // https://github.com/react-hook-form/react-hook-form/issues/10862
        await trigger();
      };
    },
    [trigger],
  );

  const natureOfWorkSubCategoryOptions = useMemo(
    () =>
      natureOfWorkSubCategoryEnums
        .map((cat) => ({ key: cat, label: t(`natureOfWorkSubCategories.${cat}`, { ns: 'common' }) }))
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((cr) => cr.key),
    [t],
  );

  return (
    <>
      {orderByColumns([
        <EllipsisedTypography key='code' variant='body2' component='div'>
          {getValues(`natureOfWorks.${index}.code`)}
        </EllipsisedTypography>,
        <Typography key='description' variant='body2' component='div'>
          {getValues(`natureOfWorks.${index}.description.${i18nLang}`)}
        </Typography>,
        <Controller
          key='workSchedules'
          name={`natureOfWorks.${index}.workSchedules`}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <SelectPicker
              {...field}
              multiple
              onChange={handleWorkSchedulesChange(field)}
              options={workScheduleEnums}
              getOptionKey={(o) => o}
              getOptionLabel={(o) => t(`workSchedules.${o}`, { ns: 'common' })}
              slotProps={{ popper: { sx: { zIndex: 1000 } } }} // make sure Popper stays below AppBar
              textFieldProps={() => ({ className: 'borderless', error: fieldState.invalid })}
            />
          )}
        />,
        <Controller
          key='defaultWorkSchedule'
          name={`natureOfWorks.${index}.defaultWorkSchedule`}
          control={control}
          rules={{
            required: true,
            validate: (value, formValues) => {
              if (value == null) return false;
              const natureOfWork = formValues.natureOfWorks[index] ?? _throw(`Couldn't find nature of work at index ${index}`);
              return natureOfWork.workSchedules.includes(value);
            },
          }}
          render={({ field, fieldState }) => (
            <SelectPicker
              {...field}
              onChange={(e, v) => field.onChange(v, e)}
              options={selectedWorkSchedules}
              getOptionKey={(o) => o}
              getOptionLabel={(o) => t(`workSchedules.${o}`, { ns: 'common' })}
              slotProps={{ popper: { sx: { zIndex: 1000 } } }} // make sure Popper stays below AppBar
              textFieldProps={() => ({ className: 'borderless', error: fieldState.invalid })}
            />
          )}
        />,

        <Controller
          key='natureOfWorkSubCategories'
          name={`natureOfWorks.${index}.natureOfWorkSubCategories`}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <SelectPicker
              {...field}
              multiple
              onChange={handleSubCategoriesChange(field)}
              options={natureOfWorkSubCategoryOptions}
              getOptionKey={(o) => o}
              getOptionLabel={(o) => t(`natureOfWorkSubCategories.${o}`, { ns: 'common' })}
              slotProps={{ popper: { sx: { zIndex: 1000 } } }} // make sure Popper stays below AppBar
              textFieldProps={() => ({ className: 'borderless', error: fieldState.invalid })}
            />
          )}
        />,
      ])}
    </>
  );
}
