import { _throw } from '../common/utils/_throw';

export const saleStages = ['quote', 'serviceCall'] as const;
export type SaleStage = (typeof saleStages)[number];

export function isSaleStage(value: string): value is SaleStage {
  return saleStages.includes(value as SaleStage);
}

export const flagNameMap: Record<SaleStage, string> = {
  quote: 'app_navigation_quote',
  serviceCall: 'app_navigation_service_calls',
};

// todo remove this. we will move saleStage to jobContext
export function getFlagName() {
  const url = window.location.href;

  if (url.includes('/work-plannings')) {
    return 'app_navigation_work_plannings';
  }
  if (url.includes('/service-calls')) {
    return 'app_navigation_service_calls';
  }
  if (url.includes('/quotes')) {
    return 'app_navigation_quote';
  }
  _throw('Unknown sale stage');
}
