import { ConfigurationTabs } from './ConfigurationTabs';
import graphql from 'babel-plugin-relay/macro';
import { ListLayout, SidebarContentProps } from '../layout/Layouts';
import { namedPageTitle, usePageTitle } from '../common/hooks/usePageTitle';
import { useLazyLoadQuery } from 'react-relay';
import { Button, Skeleton } from '@mui/material';
import { Suspense, useCallback } from 'react';
import { useNavigate, useOutletContext } from 'react-router';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { RequireAdmin, UnauthorizedFallback } from '../auth/Authorization';
import { ConfigurationPageQuery$data } from './__generated__/ConfigurationPageQuery.graphql';
import { NavigationMenu } from '../layout/SidebarDrawer';
import { ErrorBanner } from '../common/components/ErrorBanner';
import { RateStrategyList } from './RateStrategies/RateStrategyList';
import { TabRateStrategiesQuery } from './__generated__/TabRateStrategiesQuery.graphql';

export function TabRateStrategies() {
  const { t } = useAmbientTranslation();
  usePageTitle(namedPageTitle('sidebar.configuration'));
  const navigate = useNavigate();
  const $data = useOutletContext<ConfigurationPageQuery$data>();

  const handleCreateClick = useCallback(() => navigate('/configuration/rate-strategies/new'), [navigate]);
  const sidebar = useCallback((props: SidebarContentProps) => <NavigationMenu {...props} $key={$data} />, [$data]);
  const flagName = 'app_navigation_configuration';

  return (
    <RequireAdmin
      $key={$data}
      fallback={
        <ListLayout heading={t('configuration')} flagName={flagName} sidebarProvider={sidebar} $key={$data}>
          <UnauthorizedFallback />
        </ListLayout>
      }>
      <ListLayout
        heading={t('configuration')}
        flagName={flagName}
        sidebarProvider={sidebar}
        $key={$data}
        actions={<Button onClick={handleCreateClick}>{t('button.create', { ns: 'common' })}</Button>}>
        <ErrorBanner />
        <ConfigurationTabs tab='rate-strategies'></ConfigurationTabs>

        <Suspense fallback={<ContentSkeleton />}>
          <RateStrategyListContent />
        </Suspense>
      </ListLayout>
    </RequireAdmin>
  );
}

function RateStrategyListContent() {
  const $data = useLazyLoadQuery<TabRateStrategiesQuery>(
    graphql`
      query TabRateStrategiesQuery {
        ...RateStrategyListFragment
      }
    `,
    {},
    { fetchPolicy: 'network-only' },
  );

  return <RateStrategyList $key={$data} />;
}

function ContentSkeleton() {
  return <Skeleton variant='rounded' height='30rem' />;
}
