/**
 * @generated SignedSource<<630683c1d202f146a59e7fe90d4a1b57>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkPlanningList_Actions_MeFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AuthorizationWriteFragment">;
  readonly " $fragmentType": "WorkPlanningList_Actions_MeFragment";
};
export type WorkPlanningList_Actions_MeFragment$key = {
  readonly " $data"?: WorkPlanningList_Actions_MeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkPlanningList_Actions_MeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkPlanningList_Actions_MeFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AuthorizationWriteFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "be96d7bac0b1d5cdac0df128c0124985";

export default node;
