import graphql from 'babel-plugin-relay/macro';
import { fetchQuery, useRelayEnvironment } from 'react-relay';

import { ElementType, forwardRef, ReactElement, Ref, RefAttributes, useCallback, useEffect, useRef } from 'react';
import { ChipTypeMap } from '@mui/material';
import { BoomConfigurationAutocompleteFragment$data } from './__generated__/BoomConfigurationAutocompleteFragment.graphql';
import { BoomConfigurationAutocompleteQuery } from './__generated__/BoomConfigurationAutocompleteQuery.graphql';
import {
  AutocompleteMetadata,
  ConnectionNode,
  ConnectionPaginatedAutocomplete,
  ForwardPaginatedAutocompleteProps,
  Queryable,
} from './PaginatedAutocomplete';

export interface BoomConfigurationAutocompleteProps {
  capacity: number;
  equipmentKindCode: number;
  configurationKindCode: number;
}

export type ForwardBoomConfigurationAutocompleteProps<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = ForwardPaginatedAutocompleteProps<
  ConnectionNode<BoomConfigurationAutocompleteFragment$data>,
  'fragment' | 'onQuery' | 'getOptionLabel' | 'getOptionKey',
  Multiple,
  DisableClearable,
  ChipComponent
>;

export const BoomConfigurationAutocomplete = forwardRef<
  HTMLInputElement,
  BoomConfigurationAutocompleteProps &
    ForwardPaginatedAutocompleteProps<
      ConnectionNode<BoomConfigurationAutocompleteFragment$data> & AutocompleteMetadata,
      'fragment' | 'onQuery' | 'getOptionLabel' | 'getOptionKey',
      boolean,
      boolean,
      ElementType
    >
>(function BoomConfigurationAutocomplete<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  {
    capacity,
    equipmentKindCode,
    configurationKindCode,
    ...paginatedAutocompleteProps
  }: BoomConfigurationAutocompleteProps &
    ForwardPaginatedAutocompleteProps<
      ConnectionNode<BoomConfigurationAutocompleteFragment$data> & AutocompleteMetadata,
      'fragment' | 'onQuery' | 'getOptionLabel' | 'getOptionKey',
      Multiple,
      DisableClearable,
      ChipComponent
    >,
  ref: Ref<HTMLInputElement>,
) {
  const env = useRelayEnvironment();
  const queryRef = useRef<Queryable>(null);

  useEffect(() => {
    queryRef.current?.reset();
  }, [capacity, configurationKindCode, equipmentKindCode]);

  const handleQuery = useCallback(
    (searchTerm: string | null) => {
      return fetchQuery<BoomConfigurationAutocompleteQuery>(
        env,
        graphql`
          query BoomConfigurationAutocompleteQuery(
            $searchTerm: String
            $capacity: Int!
            $equipmentKindCode: Int!
            $configurationKindCode: Int!
          ) {
            ...BoomConfigurationAutocompleteFragment
              @arguments(
                searchTerm: $searchTerm
                capacity: $capacity
                equipmentKindCode: $equipmentKindCode
                configurationKindCode: $configurationKindCode
              )
          }
        `,
        {
          searchTerm,
          capacity,
          equipmentKindCode,
          configurationKindCode,
        },
      );
    },
    [env, capacity, equipmentKindCode, configurationKindCode],
  );

  return (
    <ConnectionPaginatedAutocomplete<
      ConnectionNode<BoomConfigurationAutocompleteFragment$data> & AutocompleteMetadata,
      Multiple,
      DisableClearable,
      ChipComponent
    >
      ref={ref}
      queryRef={queryRef}
      fragment={graphql`
        fragment BoomConfigurationAutocompleteFragment on Query
        @refetchable(queryName: "BoomConfigurationAutocompleteFragmentQuery")
        @argumentDefinitions(
          searchTerm: { type: "String" }
          capacity: { type: "Int!" }
          equipmentKindCode: { type: "Int!" }
          configurationKindCode: { type: "Int!" }
          cursor: { type: "String" }
          count: { type: "Int", defaultValue: 25 }
        ) {
          searchResults: searchBoomConfigurations(
            searchTerm: $searchTerm
            capacity: $capacity
            equipmentKindCode: $equipmentKindCode
            configurationKindCode: $configurationKindCode
            after: $cursor
            first: $count
          ) @connection(key: "BoomConfigurationAutocompleteFragment_searchResults") {
            edges {
              node {
                id
                label
                preparationHours
              }
            }
            pageInfo {
              hasNextPage
            }
          }
        }
      `}
      onQuery={handleQuery}
      getOptionKey={(o) => o.id}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      {...paginatedAutocompleteProps}
    />
  );
}) as <
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  props: BoomConfigurationAutocompleteProps &
    ForwardBoomConfigurationAutocompleteProps<Multiple, DisableClearable, ChipComponent> &
    RefAttributes<HTMLInputElement>,
) => ReactElement;
