import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

export function ItemDeleteButton({
  onClick: handleClick,
  disabled,
  'data-label-key': dataLabelKey,
}: {
  onClick: () => void;
  disabled: boolean;
  'data-label-key'?: string;
}) {
  return (
    <IconButton
      className='delete-button'
      data-label-key={dataLabelKey}
      onClick={handleClick}
      disabled={disabled}
      sx={(theme) => ({
        mx: '0.2rem',
        '@media (pointer: fine)': {
          opacity: 0,
          transition: theme.transitions.create(['transform', 'opacity'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shortest,
          }),
        },
      })}>
      <DeleteIcon color={disabled ? 'disabled' : 'error'} />
    </IconButton>
  );
}
