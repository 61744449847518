/**
 * @generated SignedSource<<ec6891143715bfef2a61b444927b59bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaleProjectFields_useFieldScheduleEndTimeFragment$data = {
  readonly endTime: string | null | undefined;
  readonly " $fragmentType": "SaleProjectFields_useFieldScheduleEndTimeFragment";
};
export type SaleProjectFields_useFieldScheduleEndTimeFragment$key = {
  readonly " $data"?: SaleProjectFields_useFieldScheduleEndTimeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_useFieldScheduleEndTimeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleProjectFields_useFieldScheduleEndTimeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endTime",
      "storageKey": null
    }
  ],
  "type": "TimeRange",
  "abstractKey": null
};

(node as any).hash = "dcc51fd5450f5b48de24ade1fa0c55b7";

export default node;
