/**
 * @generated SignedSource<<9fed3617b6a729e637b328926829e3fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobEquipment_useSyncAdditionalCranesAutomaticFragment$data = {
  readonly automaticConfiguration: {
    readonly " $fragmentSpreads": FragmentRefs<"AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"SaleCraneSelectorFields_CraneConfigurationCollection_FavoriteFragment" | "SaleCraneSelectorFields_CraneSelectorModeFragment">;
  readonly " $fragmentType": "JobEquipment_useSyncAdditionalCranesAutomaticFragment";
};
export type JobEquipment_useSyncAdditionalCranesAutomaticFragment$key = {
  readonly " $data"?: JobEquipment_useSyncAdditionalCranesAutomaticFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobEquipment_useSyncAdditionalCranesAutomaticFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobEquipment_useSyncAdditionalCranesAutomaticFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SaleCraneSelectorFields_CraneConfigurationCollection_FavoriteFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SaleCraneSelectorFields_CraneSelectorModeFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AutomaticConfigurationInternal",
      "kind": "LinkedField",
      "name": "automaticConfiguration",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CraneSelectorInternal",
  "abstractKey": null
};

(node as any).hash = "2b7e302f98fc531a39d59fb608471f1d";

export default node;
