/**
 * @generated SignedSource<<e41aa74d6768fe654aabd94189921998>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CraneChartListDeactivateButtonFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "CraneChartListDeactivateButtonFragment";
};
export type CraneChartListDeactivateButtonFragment$key = {
  readonly " $data"?: CraneChartListDeactivateButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CraneChartListDeactivateButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CraneChartListDeactivateButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "CraneChartBlobMetadata",
  "abstractKey": null
};

(node as any).hash = "fe0a86ac621119c109616a044e89ff20";

export default node;
