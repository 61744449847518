/**
 * @generated SignedSource<<55e06659ce68cf265802e79f6314f440>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ServiceCallDelayButtonFragment$data = {
  readonly id: string;
  readonly snapshot: {
    readonly transferredAt: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ServiceCallDelayButtonFragment";
};
export type ServiceCallDelayButtonFragment$key = {
  readonly " $data"?: ServiceCallDelayButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ServiceCallDelayButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ServiceCallDelayButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ServiceCall",
      "kind": "LinkedField",
      "name": "snapshot",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "transferredAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ServiceCallJobRevision",
  "abstractKey": null
};

(node as any).hash = "0d2ed1202e305a626454bf4fe0f33dd1";

export default node;
