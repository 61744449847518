/**
 * @generated SignedSource<<c44d25cb4821f25a4d52dcf2d20d8e6e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectBaseFields_DurationHoursPerDayInput_SuggestionsFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useSuggestionsFragment">;
  readonly " $fragmentType": "ProjectBaseFields_DurationHoursPerDayInput_SuggestionsFragment";
};
export type ProjectBaseFields_DurationHoursPerDayInput_SuggestionsFragment$key = {
  readonly " $data"?: ProjectBaseFields_DurationHoursPerDayInput_SuggestionsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_DurationHoursPerDayInput_SuggestionsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skipAccessories"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectBaseFields_DurationHoursPerDayInput_SuggestionsFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "skipAccessories",
          "variableName": "skipAccessories"
        }
      ],
      "kind": "FragmentSpread",
      "name": "useSuggestionsFragment"
    }
  ],
  "type": "IJobStage",
  "abstractKey": "__isIJobStage"
};

(node as any).hash = "9d4b18a56a1b6360e576782c96c3645e";

export default node;
