import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { ReactNode } from 'react';
import { EmployeeNotFoundError } from './EmployeeNotFoundErrorBoundary';
import { useTranslation } from 'react-i18next';
import { AuthorizationWriteFragment$key } from './__generated__/AuthorizationWriteFragment.graphql';
import { AuthorizationReadFragment$key } from './__generated__/AuthorizationReadFragment.graphql';
import { AuthorizationAdminFragment$key } from './__generated__/AuthorizationAdminFragment.graphql';
import { Button, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { AuthorizationSupervisorFragment$key } from './__generated__/AuthorizationSupervisorFragment.graphql';
import { AuthorizationQuotesExpertFragment$key } from './__generated__/AuthorizationQuotesExpertFragment.graphql';
import { AuthorizationCraneChartMaintainerFragment$key } from './__generated__/AuthorizationCraneChartMaintainerFragment.graphql';

export function useUserHasReadAccess($key: AuthorizationReadFragment$key) {
  const $data = useFragment(
    graphql`
      fragment AuthorizationReadFragment on Query {
        me {
          accessLevels {
            hasRead
          }
        }
      }
    `,
    $key,
  );

  if (!$data.me) throw new EmployeeNotFoundError();

  return $data.me.accessLevels.hasRead;
}

export function RequireRead({
  $key,
  fallback,
  children,
}: {
  $key: AuthorizationReadFragment$key;
  fallback?: ReactNode;
  children: ReactNode;
}) {
  return <CanRead $key={$key} render={(hasRead: boolean) => (hasRead ? children : fallback)} />;
}

export function CanRead({ $key, render }: { $key: AuthorizationReadFragment$key; render: (hasRead: boolean) => ReactNode }) {
  const hasRead = useUserHasReadAccess($key);
  return render(hasRead);
}

export function useUserHasWriteAccess($key: AuthorizationWriteFragment$key) {
  const $data = useFragment(
    graphql`
      fragment AuthorizationWriteFragment on Query {
        me {
          accessLevels {
            hasWrite
          }
        }
      }
    `,
    $key,
  );

  if (!$data.me) throw new EmployeeNotFoundError();

  return $data.me.accessLevels.hasWrite;
}

export function RequireWrite({
  $key,
  fallback,
  children,
}: {
  $key: AuthorizationWriteFragment$key;
  fallback?: ReactNode;
  children: ReactNode;
}) {
  return <CanWrite $key={$key} render={(hasWrite: boolean) => (hasWrite ? children : fallback)} />;
}

export function CanWrite({ $key, render }: { $key: AuthorizationWriteFragment$key; render: (hasWrite: boolean) => ReactNode }) {
  const hasWrite = useUserHasWriteAccess($key);
  return render(hasWrite);
}

export function useUserHasQuotesExpertAccess($key: AuthorizationQuotesExpertFragment$key) {
  const $data = useFragment(
    graphql`
      fragment AuthorizationQuotesExpertFragment on Query {
        me {
          accessLevels {
            hasQuotesExpert
          }
        }
      }
    `,
    $key,
  );

  if (!$data.me) throw new EmployeeNotFoundError();

  return $data.me.accessLevels.hasQuotesExpert;
}

export function RequireQuotesExpert({
  $key,
  fallback,
  children,
}: {
  $key: AuthorizationQuotesExpertFragment$key;
  fallback?: ReactNode;
  children: ReactNode;
}) {
  return <CanQuotesExpert $key={$key} render={(hasQuotesExpert: boolean) => (hasQuotesExpert ? children : fallback)} />;
}

export function CanQuotesExpert({
  $key,
  render,
}: {
  $key: AuthorizationQuotesExpertFragment$key;
  render: (hasQuotesExpert: boolean) => ReactNode;
}) {
  const hasQuotesExpert = useUserHasQuotesExpertAccess($key);
  return render(hasQuotesExpert);
}

export function useUserHasSupervisorAccess($key: AuthorizationSupervisorFragment$key) {
  const $data = useFragment(
    graphql`
      fragment AuthorizationSupervisorFragment on Query {
        me {
          accessLevels {
            hasSupervisor
          }
        }
      }
    `,
    $key,
  );

  if (!$data.me) throw new EmployeeNotFoundError();

  return $data.me.accessLevels.hasSupervisor;
}

export function RequireSupervisor({
  $key,
  fallback,
  children,
}: {
  $key: AuthorizationSupervisorFragment$key;
  fallback?: ReactNode;
  children: ReactNode;
}) {
  return <CanSupervise $key={$key} render={(hasSupervisor: boolean) => (hasSupervisor ? children : fallback)} />;
}

export function CanSupervise({
  $key,
  render,
}: {
  $key: AuthorizationSupervisorFragment$key;
  render: (hasSupervisor: boolean) => ReactNode;
}) {
  const hasSupervisor = useUserHasSupervisorAccess($key);
  return render(hasSupervisor);
}

export function useUserHasAdminAccess($key: AuthorizationAdminFragment$key) {
  const $data = useFragment(
    graphql`
      fragment AuthorizationAdminFragment on Query {
        me {
          accessLevels {
            hasAdmin
          }
        }
      }
    `,
    $key,
  );

  if (!$data.me) throw new EmployeeNotFoundError();

  return $data.me.accessLevels.hasAdmin;
}

export function RequireAdmin({
  $key,
  fallback,
  children,
}: {
  $key: AuthorizationAdminFragment$key;
  fallback?: ReactNode;
  children: ReactNode;
}) {
  return <CanAdmin $key={$key} render={(hasAdmin: boolean) => (hasAdmin ? children : fallback)} />;
}

export function CanAdmin({ $key, render }: { $key: AuthorizationAdminFragment$key; render: (hasAdmin: boolean) => ReactNode }) {
  const hasAdmin = useUserHasAdminAccess($key);
  return render(hasAdmin);
}

export function useUserHasCraneChartMaintainerAccess($key: AuthorizationCraneChartMaintainerFragment$key) {
  const $data = useFragment(
    graphql`
      fragment AuthorizationCraneChartMaintainerFragment on Query {
        me {
          accessLevels {
            hasCraneChartMaintainer
          }
        }
      }
    `,
    $key,
  );

  if (!$data.me) throw new EmployeeNotFoundError();

  return $data.me.accessLevels.hasCraneChartMaintainer;
}

export function RequireCraneChartMaintainer({
  $key,
  fallback,
  children,
}: {
  $key: AuthorizationCraneChartMaintainerFragment$key;
  fallback?: ReactNode;
  children: ReactNode;
}) {
  return (
    <CanCraneChartMaintainer $key={$key} render={(hasCraneChartMaintainer: boolean) => (hasCraneChartMaintainer ? children : fallback)} />
  );
}

export function CanCraneChartMaintainer({
  $key,
  render,
}: {
  $key: AuthorizationCraneChartMaintainerFragment$key;
  render: (hasCraneChartMaintainer: boolean) => ReactNode;
}) {
  const hasCraneChartMaintainer = useUserHasCraneChartMaintainerAccess($key);
  return render(hasCraneChartMaintainer);
}

export function UnauthorizedFallback({ onButtonClick: handleButtonClick }: { onButtonClick?: () => void }) {
  const { t } = useTranslation('auth');

  return (
    <Stack
      sx={{
        px: '1rem',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        height: '70vh',
      }}
      spacing={1}>
      <Typography variant='h5'>{t('unauthorized')}</Typography>
      <Typography>{t('contactYourAdmin')}</Typography>
      {handleButtonClick && (
        <Button onClick={handleButtonClick} variant='contained'>
          {t('button.goBack', { ns: 'common' })}
        </Button>
      )}
    </Stack>
  );
}
