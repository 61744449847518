import { Navigate, Route } from 'react-router';
import { CraneChartImportPage } from './CraneChartImportPage';
import { EmptyLayout } from '../layout/Layouts';

export const craneChartRoutes = (
  <Route path='crane-charts' lazy={() => import('./CraneChartPage')}>
    <Route
      index
      element={
        <EmptyLayout>
          <Navigate to='import' replace />
        </EmptyLayout>
      }
    />
    <Route path='import' element={<CraneChartImportPage />} />
  </Route>
);
