import { createSearchParam, createSearchParamsClass, SearchParamsProps } from '../common/utils/searchParams';
import {
  ARRIVAL_DATE_FORMAT,
  baseJobFilterParams,
  JobFiltersSearchParams,
  representativesResponsiveGridSearchParam,
} from '../jobs/JobFilters';
import { isWorkPlanningStatus, WorkPlanningStatus } from '../__enums__/WorkPlanningStatus';
import { WorkPlanningJobRevisionFilterType } from './__generated__/WorkPlanningListFragmentQuery.graphql';

const workPlanningStatusesSearchParam = createSearchParam<readonly WorkPlanningStatus[]>(
  (searchParams) => searchParams.getAll(JobFiltersSearchParams.STATUS).filter(isWorkPlanningStatus),
  (statuses, searchParams) => {
    for (const status of statuses) searchParams.append(JobFiltersSearchParams.STATUS, status);
  },
);

const workPlanningFiltersParams = {
  ...baseJobFilterParams,
  statuses: workPlanningStatusesSearchParam,
};

export class WorkPlanningFilters extends createSearchParamsClass(workPlanningFiltersParams) {
  static readonly EMPTY = WorkPlanningFilters.fromSearchParams(new URLSearchParams());

  static fromSearchParams(searchParams: URLSearchParams): WorkPlanningFilters {
    return new WorkPlanningFilters(this.searchParamsToProps(searchParams));
  }

  toResponsiveGridFilters(props: WorkPlanningToResponsiveGridFiltersProps): WorkPlanningResponsiveGridFilters {
    return new WorkPlanningResponsiveGridFilters({ ...this.props, ...props });
  }

  toJobRevisionFilter(): WorkPlanningJobRevisionFilterType {
    const simplify = (op: 'and' | 'or', fs: WorkPlanningJobRevisionFilterType[]): WorkPlanningJobRevisionFilterType => {
      const activeFilters = fs.filter((f) => Object.keys(f).length); //ignore empty filters
      return activeFilters.length > 1 ? { [op]: activeFilters } : activeFilters[0] || {};
    };
    const and = (fs: WorkPlanningJobRevisionFilterType[]) => simplify('and', fs);
    const or = (fs: WorkPlanningJobRevisionFilterType[]) => simplify('or', fs);

    return and([
      this.props.arrivalDate
        ? {
            snapshot: {
              projectBase: {
                arrivalDate: {
                  date: {
                    gte: this.props.arrivalDate.start.toFormat(ARRIVAL_DATE_FORMAT),
                    lte: this.props.arrivalDate.end.toFormat(ARRIVAL_DATE_FORMAT),
                  },
                },
              },
            },
          }
        : {},
      or(this.props.statuses.map((s) => ({ snapshot: { [`${s}At`]: { neq: null } } }))),
    ]);
  }
}

const workPlanningResponsiveGridFiltersParams = {
  ...workPlanningFiltersParams,
  representatives: representativesResponsiveGridSearchParam,
};

export type WorkPlanningToResponsiveGridFiltersProps = Pick<
  SearchParamsProps<typeof workPlanningResponsiveGridFiltersParams>,
  'representatives'
>;

export class WorkPlanningResponsiveGridFilters
  extends createSearchParamsClass(workPlanningResponsiveGridFiltersParams)
  implements WorkPlanningFilters
{
  static readonly EMPTY = WorkPlanningFilters.EMPTY.toResponsiveGridFilters({ representatives: [] });

  toResponsiveGridFilters(props: WorkPlanningToResponsiveGridFiltersProps): WorkPlanningResponsiveGridFilters {
    return new WorkPlanningResponsiveGridFilters({ ...this.props, ...props });
  }

  toJobRevisionFilter(): WorkPlanningJobRevisionFilterType {
    return WorkPlanningFilters.prototype.toJobRevisionFilter.call(this);
  }
}
