import Button from '@mui/material/Button';
import { ArrowDropUp } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { PaperProps } from '@mui/material/Paper';
import { MenuListProps } from '@mui/material/MenuList';
import { ButtonProps, Menu, MenuProps } from '@mui/material';
import { ReactNode, useCallback, useRef, useState } from 'react';

export type MoreButtonProps = {
  items: (close: () => void) => ReactNode;
  anchorOrigin?: MenuProps['anchorOrigin'];
  transformOrigin?: MenuProps['transformOrigin'];
  componentProps?: {
    root?: Partial<Omit<ButtonProps, 'ref' | 'onClick'>>;
    menu?: Partial<Omit<MenuProps, 'anchorEl' | 'open' | 'onClose' | 'anchorOrigin' | 'transformOrigin'>>;
    paper?: Partial<PaperProps>;
    menuList?: Partial<MenuListProps>;
  };
};

export function MoreButton({
  items,
  anchorOrigin = { vertical: 'bottom', horizontal: 'right' },
  transformOrigin = { vertical: 'top', horizontal: 'right' },
  componentProps,
}: MoreButtonProps) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = useCallback(() => {
    setOpen((v) => !v);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Button {...componentProps?.root} ref={anchorRef} onClick={handleToggle} data-testid='moreButton'>
        {componentProps?.root?.children ?? (open ? <ArrowDropUp /> : <ArrowDropDownIcon />)}
      </Button>

      <Menu
        data-testid='moreButton.menu'
        {...componentProps?.menu}
        MenuListProps={{
          autoFocusItem: true,
          ...componentProps?.menuList,
          ...componentProps?.menu?.MenuListProps,
        }}
        slotProps={{
          paper: componentProps?.paper,
          ...componentProps?.menu?.slotProps,
        }}
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}>
        {items(handleClose)}
      </Menu>
    </>
  );
}
