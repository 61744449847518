/**
 * @generated SignedSource<<a77aaa4b2d005d3a493e895b94c03247>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationMenu_NotificationReadSubscription$variables = {
  deleteFrom: ReadonlyArray<string>;
};
export type NotificationMenu_NotificationReadSubscription$data = {
  readonly notificationRead: {
    readonly cursor: string;
    readonly node: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"NotificationRowFragment">;
    };
  };
};
export type NotificationMenu_NotificationReadSubscription = {
  response: NotificationMenu_NotificationReadSubscription$data;
  variables: NotificationMenu_NotificationReadSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deleteFrom"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isRead",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "friendlyId",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "permalink",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        }
      ],
      "type": "Notification",
      "abstractKey": "__isNotification"
    }
  ],
  "type": "PermalinkNotificationBase",
  "abstractKey": "__isPermalinkNotificationBase"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationMenu_NotificationReadSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NotificationEdge",
        "kind": "LinkedField",
        "name": "notificationRead",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "NotificationRowFragment"
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationMenu_NotificationReadSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NotificationEdge",
        "kind": "LinkedField",
        "name": "notificationRead",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteEdge",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "deleteFrom"
                  }
                ]
              },
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isNotification"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "comment",
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "type": "ServiceCallModificationRequestedNotification",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DateTimeInTimeZone",
                    "kind": "LinkedField",
                    "name": "arrivalDate",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "rawValue",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "equipmentKindAbbreviation",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "craneCapacity",
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "type": "QuoteStatusChangeNotification",
                "abstractKey": "__isQuoteStatusChangeNotification"
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "14ec3ebb40ad57ed1ced3811749ac119",
    "id": null,
    "metadata": {},
    "name": "NotificationMenu_NotificationReadSubscription",
    "operationKind": "subscription",
    "text": "subscription NotificationMenu_NotificationReadSubscription {\n  notificationRead {\n    node {\n      __typename\n      id\n      ...NotificationRowFragment\n    }\n    cursor\n  }\n}\n\nfragment NotificationRowFragment on Notification {\n  __isNotification: __typename\n  __typename\n  ...NotificationRow_ModificationRequestedFragment\n  ...NotificationRow_QuoteStatusNotificationFragment\n}\n\nfragment NotificationRow_BaseFragment on Notification {\n  __isNotification: __typename\n  id\n  isRead\n  createdAt\n}\n\nfragment NotificationRow_ModificationRequestedFragment on ServiceCallModificationRequestedNotification {\n  isRead\n  friendlyId\n  comment\n  ...NotificationRow_PermalinkFragment\n}\n\nfragment NotificationRow_PermalinkFragment on PermalinkNotificationBase {\n  __isPermalinkNotificationBase: __typename\n  permalink\n  isRead\n  ...NotificationRow_BaseFragment\n}\n\nfragment NotificationRow_QuoteStatusNotificationFragment on QuoteStatusChangeNotification {\n  __isQuoteStatusChangeNotification: __typename\n  isRead\n  friendlyId\n  clientName\n  arrivalDate {\n    rawValue\n  }\n  equipmentKindAbbreviation\n  craneCapacity\n  ...NotificationRow_PermalinkFragment\n}\n"
  }
};
})();

(node as any).hash = "c9da3485f6965d09b54e01f22cb2277e";

export default node;
