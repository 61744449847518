import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

export type PageTitleFactory<Params extends Record<string, string> = Record<string, never>> = (
  params: Params,
  t: TFunction<'layout'>,
) => string;

export type PageCommandFactory<Params extends Record<string, string> = Record<string, never>> = (params: Params) => JSX.Element;

export function defaultPageTitle<Params extends Record<string, string> = Record<string, never>>(_: Params, t: TFunction<'layout'>): string {
  return t('app.title');
}

export function namedPageTitle<Params extends Record<string, string> = Record<string, never>>(titleKey: string): PageTitleFactory<Params> {
  return function (params: Params, t: TFunction<'layout'>) {
    return `${defaultPageTitle(params, t)} - ${t(titleKey)}`;
  };
}

export function indexedPageTitle<Params extends { id: string | 'new'; number?: string }>(
  titleKey: string,
  friendlyId?: unknown,
): PageTitleFactory<Params> {
  return function (params: Params, t: TFunction<'layout'>) {
    return `${namedPageTitle<Params>(titleKey)(params, t)} - ${
      // TODO review this GG-2470
      friendlyId || params.number || (params.id === 'new' ? t('new') : params.id)
    }`;
  };
}

export function usePageTitle<Params extends Record<string, string> = Record<string, never>>(factory?: PageTitleFactory<Params>): void {
  const params = useParams() as Params;
  const { t } = useTranslation('layout');

  useEffect(() => {
    document.title = factory ? factory(params, t) : defaultPageTitle(params, t);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
}
