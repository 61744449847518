import { LoaderFunction, redirect, Route } from 'react-router';

const serviceCallPermalinkLoader: LoaderFunction = ({ params: { id } }) => redirect(`/service-calls/${id}`);
const quotePermalinkLoader: LoaderFunction = ({ params: { id } }) => redirect(`/quotes/${id}`);

export const permalinkRoutes = (
  <>
    <Route path='/p/sc/:id' element={null} loader={serviceCallPermalinkLoader} />
    <Route path='/p/q/:id' element={null} loader={quotePermalinkLoader} />
  </>
);
