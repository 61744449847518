/**
 * @generated SignedSource<<578e951a2ddeaf269db32ca4c61f492b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccessoryLinesFields_useSetBillingCodeAdditionalDataFragment$data = {
  readonly defaultQuantity: number | null | undefined;
  readonly isFixedQuantity: boolean;
  readonly " $fragmentType": "AccessoryLinesFields_useSetBillingCodeAdditionalDataFragment";
};
export type AccessoryLinesFields_useSetBillingCodeAdditionalDataFragment$key = {
  readonly " $data"?: AccessoryLinesFields_useSetBillingCodeAdditionalDataFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryLinesFields_useSetBillingCodeAdditionalDataFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccessoryLinesFields_useSetBillingCodeAdditionalDataFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFixedQuantity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultQuantity",
      "storageKey": null
    }
  ],
  "type": "BillingCode",
  "abstractKey": null
};

(node as any).hash = "0ddab783794d76f5f83aac38fa6475c0";

export default node;
