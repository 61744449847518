/**
 * @generated SignedSource<<248e6fc2e199f287415e4f32188c7dae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConfigurationKindLookupLabelResolver$data = {
  readonly description: Record<string, string>;
  readonly " $fragmentType": "ConfigurationKindLookupLabelResolver";
};
export type ConfigurationKindLookupLabelResolver$key = {
  readonly " $data"?: ConfigurationKindLookupLabelResolver$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConfigurationKindLookupLabelResolver">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConfigurationKindLookupLabelResolver",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "type": "ConfigurationKindLookup",
  "abstractKey": null
};

(node as any).hash = "cd181f1bffc13f40eb8e0364ef1ba942";

export default node;
