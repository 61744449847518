import { DataID } from 'react-relay';
import { isPromiseFulfilledResult, isPromiseRejectedResult } from './promiseUtils';

export interface FileImport {
  file: File;
  blobMetadataId: DataID;
  mimeType: string;
  sasUri: string;
}

/**
 * Uploads the given @see FileImport to their respective sasUri
 * @param uploads The @see FileImport to upload to their sasUri
 * @param onError The callback called if an error occurred
 * @param onSuccess The callback called with all succeeded @see FileImport
 */
export async function uploadFiles({
  uploads,
  onError,
  onSuccess,
}: {
  uploads: FileImport[];
  onError: (errors: UploadError[]) => void;
  onSuccess: (files: FileImport[]) => void;
}): Promise<void> {
  if (!uploads.length) return;

  return await Promise.allSettled(
    uploads.map(async (item): Promise<FileImport> => {
      try {
        const response = await fetch(item.sasUri, {
          method: 'put',
          headers: {
            'Content-Type': item.mimeType,
            'x-ms-date': new Date().toUTCString(),
            'x-ms-blob-type': 'BlockBlob',
          },
          body: item.file,
        });
        if (!response.ok) {
          throw new UploadError(await response.text(), item.blobMetadataId, response);
        }
      } catch (e: unknown) {
        if (e instanceof UploadError) throw e;
        if (e instanceof Error) throw new UploadError(e.message, item.blobMetadataId);
        else throw new UploadError('unknown', item.blobMetadataId);
      }
      return item;
    }),
  ).then((results) => {
    const fulfilled = results.filter(isPromiseFulfilledResult);
    const failed = results.filter(isPromiseRejectedResult);

    onSuccess(fulfilled.map((x) => x.value));
    onError(failed.map((x) => x.reason));
  });
}

export class UploadError extends Error {
  readonly erroneousFileId: DataID;
  constructor(message: string, fileImportId: DataID, response?: Response) {
    super(
      response ? response.statusText : message,
      response && {
        cause: {
          status: response.status,
          statusText: response.statusText,
          body: message,
        },
      },
    );

    this.erroneousFileId = fileImportId;
  }
}
