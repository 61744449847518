/**
 * @generated SignedSource<<d020442d5b9db577f0e9156fe0e5f15f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccessoryRuleDetailsPage_EffectsFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryRuleFields_AutomaticGridFragment">;
  readonly " $fragmentType": "AccessoryRuleDetailsPage_EffectsFragment";
};
export type AccessoryRuleDetailsPage_EffectsFragment$key = {
  readonly " $data"?: AccessoryRuleDetailsPage_EffectsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryRuleDetailsPage_EffectsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccessoryRuleDetailsPage_EffectsFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccessoryRuleFields_AutomaticGridFragment"
    }
  ],
  "type": "AccessoryRule",
  "abstractKey": null
};

(node as any).hash = "348a1dc6ab06e29f86f4ffd21e0de241";

export default node;
