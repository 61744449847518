/**
 * @generated SignedSource<<fceb4a8ca3bff8d89cfab173eea7b22f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccessoryRuleListItemFragment$data = {
  readonly conditions: {
    readonly kinds: ReadonlyArray<string>;
  };
  readonly description: string;
  readonly id: string;
  readonly " $fragmentType": "AccessoryRuleListItemFragment";
};
export type AccessoryRuleListItemFragment$key = {
  readonly " $data"?: AccessoryRuleListItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryRuleListItemFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccessoryRuleListItemFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccessoryConditionsInternal",
      "kind": "LinkedField",
      "name": "conditions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kinds",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AccessoryRule",
  "abstractKey": null
};

(node as any).hash = "b0920b0bb1c188c87ca29cbc693d7bce";

export default node;
