import { createSearchParam, createSearchParamsClass, SearchParamsProps } from '../common/utils/searchParams';
import {
  ARRIVAL_DATE_FORMAT,
  baseJobFilterParams,
  capacitiesSearchParam,
  dispatchBranchesResponsiveGridSearchParam,
  dispatchBranchesSearchParam,
  equipmentKindsResponsiveGridSearchParam,
  equipmentKindsSearchParam,
  JobFiltersSearchParams,
  projectManagersResponsiveGridSearchParam,
  projectManagersSearchParam,
  representativesResponsiveGridSearchParam,
} from '../jobs/JobFilters';
import { isServiceCallKind, ServiceCallKind } from '../__enums__/ServiceCallKind';
import { isServiceCallStatus, ServiceCallStatus } from '../__enums__/ServiceCallStatus';
import { ServiceCallJobRevisionFilterType } from './__generated__/ServiceCallListFragmentQuery.graphql';

const serviceCallKindsSearchParam = createSearchParam<readonly ServiceCallKind[]>(
  (searchParams) => searchParams.getAll(JobFiltersSearchParams.KIND).filter(isServiceCallKind),
  (kinds, searchParams) => {
    for (const kind of kinds) searchParams.append(JobFiltersSearchParams.KIND, kind);
  },
);

const serviceCallStatusesSearchParam = createSearchParam<readonly ServiceCallStatus[]>(
  (searchParams) => searchParams.getAll(JobFiltersSearchParams.STATUS).filter(isServiceCallStatus),
  (statuses, searchParams) => {
    for (const status of statuses) searchParams.append(JobFiltersSearchParams.STATUS, status);
  },
);

const serviceCallFiltersParams = {
  ...baseJobFilterParams,
  capacities: capacitiesSearchParam,
  dispatchBranches: dispatchBranchesSearchParam,
  equipmentKinds: equipmentKindsSearchParam,
  kinds: serviceCallKindsSearchParam,
  projectManagers: projectManagersSearchParam,
  statuses: serviceCallStatusesSearchParam,
};

export class ServiceCallFilters extends createSearchParamsClass(serviceCallFiltersParams) {
  static readonly EMPTY = ServiceCallFilters.fromSearchParams(new URLSearchParams());

  static fromSearchParams(searchParams: URLSearchParams): ServiceCallFilters {
    return new ServiceCallFilters(this.searchParamsToProps(searchParams));
  }

  toResponsiveGridFilters(props: ServiceCallToResponsiveGridFiltersProps): ServiceCallResponsiveGridFilters {
    return new ServiceCallResponsiveGridFilters({ ...this.props, ...props });
  }

  toJobRevisionFilter(): ServiceCallJobRevisionFilterType {
    const simplify = (op: 'and' | 'or', fs: ServiceCallJobRevisionFilterType[]): ServiceCallJobRevisionFilterType => {
      const activeFilters = fs.filter((f) => Object.keys(f).length); // ignore empty filters
      return activeFilters.length > 1 ? { [op]: activeFilters } : activeFilters[0] || {};
    };
    const and = (fs: ServiceCallJobRevisionFilterType[]) => simplify('and', fs);
    const or = (fs: ServiceCallJobRevisionFilterType[]) => simplify('or', fs);

    return and([
      this.props.capacities.length
        ? { snapshot: { equipmentBase: { craneSelector: { favoriteConfiguration: { capacity: { in: this.props.capacities } } } } } }
        : {},
      this.props.arrivalDate
        ? {
            snapshot: {
              projectBase: {
                arrivalDate: {
                  date: {
                    gte: this.props.arrivalDate.start.toFormat(ARRIVAL_DATE_FORMAT),
                    lte: this.props.arrivalDate.end.toFormat(ARRIVAL_DATE_FORMAT),
                  },
                },
              },
            },
          }
        : {},
      this.props.dispatchBranches.length
        ? { snapshot: { project: { dispatchBranchId: { in: this.props.dispatchBranches.map(({ id }) => id) } } } }
        : {},
      this.props.equipmentKinds.length
        ? {
            snapshot: {
              equipmentBase: {
                craneSelector: {
                  favoriteConfiguration: { equipmentKindCode: { in: this.props.equipmentKinds.map(({ code }) => code) } },
                },
              },
            },
          }
        : {},
      this.props.kinds.length ? { snapshot: { kind: { in: this.props.kinds } } } : {},
      this.props.projectManagers.length
        ? { snapshot: { client: { projectManagerId: { in: this.props.projectManagers.map(({ id }) => id) } } } }
        : {},
      or(this.props.statuses.map((s) => ({ snapshot: { [`${s}At`]: { neq: null } } }))),
    ]);
  }
}

const serviceCallResponsiveGridFiltersParams = {
  ...serviceCallFiltersParams,
  dispatchBranches: dispatchBranchesResponsiveGridSearchParam,
  equipmentKinds: equipmentKindsResponsiveGridSearchParam,
  projectManagers: projectManagersResponsiveGridSearchParam,
  representatives: representativesResponsiveGridSearchParam,
};

export type ServiceCallToResponsiveGridFiltersProps = Pick<
  SearchParamsProps<typeof serviceCallResponsiveGridFiltersParams>,
  'dispatchBranches' | 'equipmentKinds' | 'projectManagers' | 'representatives'
>;

export class ServiceCallResponsiveGridFilters
  extends createSearchParamsClass(serviceCallResponsiveGridFiltersParams)
  implements ServiceCallFilters
{
  static readonly EMPTY = ServiceCallFilters.EMPTY.toResponsiveGridFilters({
    dispatchBranches: [],
    equipmentKinds: [],
    projectManagers: [],
    representatives: [],
  });

  toResponsiveGridFilters(props: ServiceCallToResponsiveGridFiltersProps): ServiceCallResponsiveGridFilters {
    return new ServiceCallResponsiveGridFilters({ ...this.props, ...props });
  }

  toJobRevisionFilter(): ServiceCallJobRevisionFilterType {
    return ServiceCallFilters.prototype.toJobRevisionFilter.call(this);
  }
}
