/**
 * @generated SignedSource<<2d160d7cbba189e96413669a48ea7c35>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaleProjectFields_useIncludeWeekendsAndHolidays_SaleFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_IncludeWeekendsAndHolidaysFragment">;
  readonly " $fragmentType": "SaleProjectFields_useIncludeWeekendsAndHolidays_SaleFragment";
};
export type SaleProjectFields_useIncludeWeekendsAndHolidays_SaleFragment$key = {
  readonly " $data"?: SaleProjectFields_useIncludeWeekendsAndHolidays_SaleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_useIncludeWeekendsAndHolidays_SaleFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleProjectFields_useIncludeWeekendsAndHolidays_SaleFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SaleProjectFields_IncludeWeekendsAndHolidaysFragment"
    }
  ],
  "type": "ISaleProject",
  "abstractKey": "__isISaleProject"
};

(node as any).hash = "c6bedee43dfa0482cb217b4edb7788c4";

export default node;
