/**
 * @generated SignedSource<<d178c316774248ae2915917e73b41721>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LayoutsDetailsLayoutFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LayoutsDetailsLayoutToolbarFragment">;
  readonly " $fragmentType": "LayoutsDetailsLayoutFragment";
};
export type LayoutsDetailsLayoutFragment$key = {
  readonly " $data"?: LayoutsDetailsLayoutFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"LayoutsDetailsLayoutFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LayoutsDetailsLayoutFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LayoutsDetailsLayoutToolbarFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "b13abedb5d225916bcb138e940ff9870";

export default node;
