/**
 * @generated SignedSource<<301ab188c9e676889cc90d6c2869152d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkPlanningList_ActionsFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"WorkPlanningList_CopyButtonFragment">;
  readonly " $fragmentType": "WorkPlanningList_ActionsFragment";
};
export type WorkPlanningList_ActionsFragment$key = {
  readonly " $data"?: WorkPlanningList_ActionsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkPlanningList_ActionsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkPlanningList_ActionsFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorkPlanningList_CopyButtonFragment"
    }
  ],
  "type": "WorkPlanningJobRevision",
  "abstractKey": null
};

(node as any).hash = "26d4979b326d364e8f65d115f08303c6";

export default node;
