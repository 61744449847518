/**
 * @generated SignedSource<<b39725e6a9bc013592b829a7174ed21f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectBaseFields_DurationLengthInDaysFragment$data = {
  readonly duration: {
    readonly lengthInDays: number | null | undefined;
  };
  readonly " $fragmentType": "ProjectBaseFields_DurationLengthInDaysFragment";
};
export type ProjectBaseFields_DurationLengthInDaysFragment$key = {
  readonly " $data"?: ProjectBaseFields_DurationLengthInDaysFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_DurationLengthInDaysFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectBaseFields_DurationLengthInDaysFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkSchedule",
      "kind": "LinkedField",
      "name": "duration",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lengthInDays",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProjectInternalBase",
  "abstractKey": null
};

(node as any).hash = "9746843b849aabbc150ecc1d7f7605b3";

export default node;
