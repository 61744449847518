import { useRef } from 'react';

/**
 * A hook that returns a constant value.
 *
 * The value or factory provided during the first render will define the value that this hook will return until the
 * component is destroyed.
 *
 * @param value The value that the hook should return until the component is destroyed, or a factory function that will
 *   be called to get the value that the hook should return until the component is destroyed
 * @returns The value that was provided to the hook during the first render, or the value that the factory provided to
 *   the hook during the first render returned
 */
export function useConstantValue<T>(value: T extends (...args: never[]) => unknown ? () => T : T | (() => T)): T {
  const valueRef = useRef<T>(typeof value === 'function' ? value() : value);
  return valueRef.current;
}
