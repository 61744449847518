/**
 * @generated
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck


export const blobStatuses = ["error", "processing", "received", "success", "uploading"] as const;
export type BlobStatus = typeof blobStatuses[number];

export function isBlobStatus(value: string): value is BlobStatus {
  return blobStatuses.includes(value)
}

export function asBlobStatus(value: string): BlobStatus | null {
  return isBlobStatus(value) ? value : null;
}

export function castBlobStatus(value: string): BlobStatus {
  if(!isBlobStatus(value)) {
    throw new Error(`Invalid Enum value for type "BlobStatus": "${value}"`);
  }
  
  return value;
}
