/**
 * @generated SignedSource<<abef7799d7d0b0ef4190ba38865f21bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssignedWorksite_Label_BaseFragment$data = {
  readonly code: string | null | undefined;
  readonly deletedAt: string | null | undefined;
  readonly " $fragmentType": "AssignedWorksite_Label_BaseFragment";
};
export type AssignedWorksite_Label_BaseFragment$key = {
  readonly " $data"?: AssignedWorksite_Label_BaseFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssignedWorksite_Label_BaseFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssignedWorksite_Label_BaseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deletedAt",
      "storageKey": null
    }
  ],
  "type": "Worksite",
  "abstractKey": null
};

(node as any).hash = "e8e1308ff318d95676f48e55d93aeb1f";

export default node;
