/**
 * @generated SignedSource<<1f22abec0cab107d0857ee6f07629a94>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LayoutsSearchLayoutFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LayoutsDetailsLayoutToolbarFragment">;
  readonly " $fragmentType": "LayoutsSearchLayoutFragment";
};
export type LayoutsSearchLayoutFragment$key = {
  readonly " $data"?: LayoutsSearchLayoutFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"LayoutsSearchLayoutFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LayoutsSearchLayoutFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LayoutsDetailsLayoutToolbarFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "57392cf92cfe5f4e41077a1875efdf28";

export default node;
