/**
 * @generated SignedSource<<22292996fd97cbfc5fa06089cf131618>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as vehicleIdLabelResolverType } from "../../../__resolvers__/VehicleIdLabelResolver";
// Type assertion validating that `vehicleIdLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(vehicleIdLabelResolverType satisfies (
  rootKey: VehicleIdLabelResolver$key,
) => string | null | undefined);
export type CraneVehicleIdAutocompleteFragment$data = {
  readonly searchResults: {
    readonly items: ReadonlyArray<{
      readonly result: {
        readonly key: string;
        readonly label: string | null | undefined;
      };
    }> | null | undefined;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
    };
  } | null | undefined;
  readonly " $fragmentType": "CraneVehicleIdAutocompleteFragment";
};
export type CraneVehicleIdAutocompleteFragment$key = {
  readonly " $data"?: CraneVehicleIdAutocompleteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CraneVehicleIdAutocompleteFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "capacity"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "configurationKindCode"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "equipmentKindCode"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchTerm"
    },
    {
      "defaultValue": 0,
      "kind": "LocalArgument",
      "name": "skip"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./craneVehicleIdAutocomplete2FragmentQuery.graphql')
    }
  },
  "name": "CraneVehicleIdAutocompleteFragment",
  "selections": [
    {
      "alias": "searchResults",
      "args": [
        {
          "kind": "Variable",
          "name": "capacity",
          "variableName": "capacity"
        },
        {
          "kind": "Variable",
          "name": "configurationKindCode",
          "variableName": "configurationKindCode"
        },
        {
          "kind": "Variable",
          "name": "equipmentKindCode",
          "variableName": "equipmentKindCode"
        },
        {
          "kind": "Variable",
          "name": "searchTerm",
          "variableName": "searchTerm"
        },
        {
          "kind": "Variable",
          "name": "skip",
          "variableName": "skip"
        },
        {
          "kind": "Literal",
          "name": "take",
          "value": 25
        }
      ],
      "concreteType": "SearchCraneVehicleIdsCollectionSegment",
      "kind": "LinkedField",
      "name": "searchCraneVehicleIds",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FullTextSearchResultOfVehicleId",
          "kind": "LinkedField",
          "name": "items",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "VehicleId",
              "kind": "LinkedField",
              "name": "result",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "key",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "fragment": {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "VehicleIdLabelResolver"
                  },
                  "kind": "RelayResolver",
                  "name": "label",
                  "resolverModule": require('./../../../__resolvers__/VehicleIdLabelResolver').label,
                  "path": "searchResults.items.result.label"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CollectionSegmentInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "e22afc4c923531e40073033eea96dae1";

export default node;
