/**
 * @generated SignedSource<<4729fc58b2304154858a65cfcc57bcf9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as natureOfWorkLookupLabelResolverType } from "../../../__resolvers__/NatureOfWorkLabelResolver";
// Type assertion validating that `natureOfWorkLookupLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(natureOfWorkLookupLabelResolverType satisfies (
  rootKey: NatureOfWorkLabelResolver$key,
) => Record<string, string> | null | undefined);
export type SaleProjectFields_NatureOfWorkFragment$data = {
  readonly natureOfWork: {
    readonly code: number;
    readonly id: string;
    readonly label: Record<string, string> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "SaleProjectFields_NatureOfWorkFragment";
};
export type SaleProjectFields_NatureOfWorkFragment$key = {
  readonly " $data"?: SaleProjectFields_NatureOfWorkFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_NatureOfWorkFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleProjectFields_NatureOfWorkFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "NatureOfWorkLookup",
      "kind": "LinkedField",
      "name": "natureOfWork",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "fragment": {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NatureOfWorkLabelResolver"
          },
          "kind": "RelayResolver",
          "name": "label",
          "resolverModule": require('./../../../__resolvers__/NatureOfWorkLabelResolver').label,
          "path": "natureOfWork.label"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ISaleProject",
  "abstractKey": "__isISaleProject"
};

(node as any).hash = "2e67e17303ffc72ec64fb4671c0c3eb5";

export default node;
