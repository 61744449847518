/**
 * @generated SignedSource<<dea334e954477882abd8f28171963aa5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaleProjectFields_useFieldScheduleStartTimeFragment$data = {
  readonly startTime: string | null | undefined;
  readonly " $fragmentType": "SaleProjectFields_useFieldScheduleStartTimeFragment";
};
export type SaleProjectFields_useFieldScheduleStartTimeFragment$key = {
  readonly " $data"?: SaleProjectFields_useFieldScheduleStartTimeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_useFieldScheduleStartTimeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleProjectFields_useFieldScheduleStartTimeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startTime",
      "storageKey": null
    }
  ],
  "type": "TimeRange",
  "abstractKey": null
};

(node as any).hash = "cbfe9a777be616a240008ad2136bcc76";

export default node;
