/**
 * @generated SignedSource<<6dee6dbdd5010d8eff6c2b67d7ed8069>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientBaseFields_ClientOverrides_PhoneNumberFragment$data = {
  readonly phoneNumber: string | null | undefined;
  readonly " $fragmentType": "ClientBaseFields_ClientOverrides_PhoneNumberFragment";
};
export type ClientBaseFields_ClientOverrides_PhoneNumberFragment$key = {
  readonly " $data"?: ClientBaseFields_ClientOverrides_PhoneNumberFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientBaseFields_ClientOverrides_PhoneNumberFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientBaseFields_ClientOverrides_PhoneNumberFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneNumber",
      "storageKey": null
    }
  ],
  "type": "IOverridableClient",
  "abstractKey": "__isIOverridableClient"
};

(node as any).hash = "f00c7e37b06e3250f58ae080826353fb";

export default node;
