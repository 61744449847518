/**
 * @generated SignedSource<<2b928606c4dc89cf28145cc5ed8b1f4b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BlobStatus = "error" | "processing" | "received" | "success" | "uploading" | "%future added value";
export type CompleteCraneChartBlobUploadInput = {
  id: string;
};
export type CraneChartImportPageCompleteBlobUploadMutation$variables = {
  inputs: ReadonlyArray<CompleteCraneChartBlobUploadInput>;
};
export type CraneChartImportPageCompleteBlobUploadMutation$data = {
  readonly completeCraneChartBlobUpload: {
    readonly craneChartBlobMetadatas: ReadonlyArray<{
      readonly byteCount: any;
      readonly id: string;
      readonly mimeType: string;
      readonly name: string;
      readonly status: BlobStatus;
      readonly storageContainer: string;
    }> | null | undefined;
    readonly errors: ReadonlyArray<{
      readonly __typename: string;
    }> | null | undefined;
  };
};
export type CraneChartImportPageCompleteBlobUploadMutation = {
  response: CraneChartImportPageCompleteBlobUploadMutation$data;
  variables: CraneChartImportPageCompleteBlobUploadMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "inputs"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "inputs",
        "variableName": "inputs"
      }
    ],
    "concreteType": "CompleteCraneChartBlobUploadPayload",
    "kind": "LinkedField",
    "name": "completeCraneChartBlobUpload",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CraneChartBlobMetadata",
        "kind": "LinkedField",
        "name": "craneChartBlobMetadatas",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "byteCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mimeType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "storageContainer",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CraneChartImportPageCompleteBlobUploadMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CraneChartImportPageCompleteBlobUploadMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1cb324189556433560b3adce7f81eff1",
    "id": null,
    "metadata": {},
    "name": "CraneChartImportPageCompleteBlobUploadMutation",
    "operationKind": "mutation",
    "text": "mutation CraneChartImportPageCompleteBlobUploadMutation(\n  $inputs: [CompleteCraneChartBlobUploadInput!]!\n) {\n  completeCraneChartBlobUpload(inputs: $inputs) {\n    craneChartBlobMetadatas {\n      id\n      name\n      byteCount\n      mimeType\n      storageContainer\n      status\n    }\n    errors {\n      __typename\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "03b442ffc6e804edadb1dbb0d17dd2e4";

export default node;
