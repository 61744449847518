import { SelectChangeEvent } from '@mui/material';
import Qty from 'js-quantities';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UnitInput } from './UnitInput';
import { clamp, isDecimalNumber } from '../utils/numberUtils';
import { useEffectEvent } from '../utils/effectUtils';
import { defaultLogger, Logger } from '../utils/logging';
import { _throw } from '../utils/_throw';

const logger = new Logger(defaultLogger, 'AngleInput', () => new Date().toISOString());

interface Props {
  value: string | null;
  enabledUnits: readonly AngleUnit[];
  label: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  min?: Record<AngleUnit, number> | number;
  max?: Record<AngleUnit, number> | number;
  step?: number;
  onChange: (val: string | null) => void;
  'data-label-key'?: string;
}

export const angleUnits = ['deg'] as const;
export type AngleUnit = (typeof angleUnits)[number];

function isAngleUnit(val: unknown): val is AngleUnit {
  return angleUnits.includes(val as AngleUnit);
}

const effectiveMin = (min: Props['min'], unit: AngleUnit) => (typeof min === 'number' ? min : (min?.[unit] ?? 0));
const effectiveMax = (max: Props['max'], unit: AngleUnit) => (typeof max === 'number' ? max : (max?.[unit] ?? Infinity));

const normalizeScalar = (scalar: number | null | undefined, unit: AngleUnit, min: Props['min'], max: Props['max']) =>
  scalar == null || Number.isNaN(scalar) ? undefined : clamp(scalar, effectiveMin(min, unit), effectiveMax(max, unit));

const serializeValue = (scalar: number | undefined, unit: AngleUnit) => (scalar == null ? null : `${scalar} ${unit}`);

export function AngleInput({
  value,
  enabledUnits,
  label,
  min,
  max,
  step,
  required,
  disabled,
  onChange: handleChange,
  error,
  'data-label-key': dataLabelKey,
}: Props) {
  const defaultUnit = enabledUnits[0] ?? _throw('Empty enabledUnits. At least one unit must be enabled.');

  const [scalar, setScalar] = useState<number | undefined>(undefined);
  const [unit, setUnit] = useState<AngleUnit>(defaultUnit);
  const { t } = useTranslation('common');

  const setState = useCallback((sclr: typeof scalar, u: typeof unit) => {
    setScalar(sclr);
    setUnit(u);
  }, []);

  const syncState = useEffectEvent(() => {
    if (!value) {
      // Since serializeValue()'s return value is empty whenever the scalar is empty, when we get an empty value,
      // default to the current unit to avoid resetting the unit needlessly every time the scalar is cleared, unless it
      // isn't included in the enabled units anymore, in which case default to the first enabled unit.
      return setState(undefined, enabledUnits.includes(unit) ? unit : defaultUnit);
    }

    const quantity = Qty.parse(value);
    if (!quantity) {
      logger.error("Couldn't parse value", value);
      return setState(undefined, defaultUnit);
    }

    const qtyUnit = quantity.units();
    if (!isAngleUnit(qtyUnit)) {
      logger.error('Detected unit is not a valid Angle unit', qtyUnit);
      return setState(undefined, defaultUnit);
    }

    if (!enabledUnits.includes(qtyUnit)) {
      logger.error('Detected unit is not enabled', qtyUnit, enabledUnits);
      return setState(undefined, defaultUnit);
    }

    return setState(quantity.scalar, qtyUnit);
  });

  useEffect(syncState, [syncState, value, enabledUnits]);

  const handleUnitChange = useCallback(
    (event: SelectChangeEvent) => {
      const newUnit = isAngleUnit(event.target.value) && enabledUnits.includes(event.target.value) ? event.target.value : defaultUnit;
      const newScalar = normalizeScalar(scalar, newUnit, min, max);
      setState(newScalar, newUnit);
      handleChange(serializeValue(newScalar, newUnit));
    },
    [defaultUnit, enabledUnits, handleChange, max, min, scalar, setState],
  );

  const handleScalarChange = useCallback(
    (newValue: number | null, _: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (newValue != null && !Number.isNaN(newValue)) {
        setScalar(newValue);
        handleChange(serializeValue(newValue, unit));
      } else {
        setScalar(undefined);
        handleChange(serializeValue(undefined, unit));
      }
    },
    [handleChange, unit],
  );

  const handleBlur = useCallback(() => {
    if (scalar != null) {
      const { intValue } = isDecimalNumber(scalar);
      const newScalar = normalizeScalar(intValue, unit, min, max);
      setScalar(newScalar);
      handleChange(serializeValue(newScalar, unit));
    }
  }, [handleChange, max, min, scalar, unit]);

  return (
    <UnitInput<AngleUnit>
      supportedUnits={enabledUnits}
      onScalarChange={handleScalarChange}
      onUnitChange={handleUnitChange}
      fieldLabel={label}
      scalar={scalar}
      unit={unit}
      onBlur={handleBlur}
      required={required}
      disabled={disabled}
      error={error}
      renderMenuValue={(v) => t(`unit.angle.short.${v}`)}
      renderAdornmentValue={(v) => t(`unit.angle.short.${v}`)}
      min={effectiveMin(min, unit)}
      max={effectiveMax(max, unit)}
      step={step}
      data-label-key={dataLabelKey}
    />
  );
}
