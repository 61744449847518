/**
 * @generated SignedSource<<aa438e3e470741f823240730942c13da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccessoryRuleDetailsPage_InformationFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryRuleFields_DescriptionFragment">;
  readonly " $fragmentType": "AccessoryRuleDetailsPage_InformationFragment";
};
export type AccessoryRuleDetailsPage_InformationFragment$key = {
  readonly " $data"?: AccessoryRuleDetailsPage_InformationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryRuleDetailsPage_InformationFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccessoryRuleDetailsPage_InformationFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccessoryRuleFields_DescriptionFragment"
    }
  ],
  "type": "AccessoryRule",
  "abstractKey": null
};

(node as any).hash = "a440e55eb25e3af91e0f42862479b656";

export default node;
