export const maintenance = {
  dataSyncSection: {
    deleteSectionTitle: 'Delete Data',
    deleting: {
      title: 'Confirmation',
      confirmationText: 'Do you want to delete all the data',
      confirmButton: 'Delete',
      button: 'Delete all data',
      success: 'Data deleted successfully',
      fail: 'Unable to delete data check console for error',
    },
    seedSectionTitle: 'Seed Data',
    seeding: {
      button: 'Seed all data',
      buttonGenerated: 'Seed generated data',
      buttonImported: 'Seed imported data',
      fail: 'Unable to seed data check console for error',
      success: 'Data seeded successfully',
      confirmationText: 'Do you want to seed all the data?',
      confirmationButton: 'Seed',
      title: 'Confirmation',
    },
    syncSectionTitle: 'Sync Data',
    synchronization: {
      title: 'Confirmation',
      confirmationText: 'Do you want to resync {{entity}}',
      confirmationButton: 'Sync',
      success: 'Data sync request sent successfully',
      fail: 'Unable to send the sync request for {{entity}}',
    },
  },
  maintenance: 'Maintenance',
  section: {
    dataSync: 'Data Sync.',
  },
};
