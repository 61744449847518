/**
 * @generated SignedSource<<d7afb7d7eb0a58eb075fc93313c0b754>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useInstantCalculatorFragment$data = {
  readonly costsBase: {
    readonly " $fragmentSpreads": FragmentRefs<"CostLinesFields_CostLineCollectionFragment">;
  };
  readonly equipment: {
    readonly " $fragmentSpreads": FragmentRefs<"SaleEquipmentFields_BranchToWorksiteDistanceFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"useInstantCalculator_useSalesRateInputFragment">;
  readonly " $fragmentType": "useInstantCalculatorFragment";
};
export type useInstantCalculatorFragment$key = {
  readonly " $data"?: useInstantCalculatorFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useInstantCalculatorFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useInstantCalculatorFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useInstantCalculator_useSalesRateInputFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CostsInternalBase",
      "kind": "LinkedField",
      "name": "costsBase",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CostLinesFields_CostLineCollectionFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "equipment",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleEquipmentFields_BranchToWorksiteDistanceFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ISale",
  "abstractKey": "__isISale"
};

(node as any).hash = "15570ef7daf6db32f03b5bc6bc4f78a5";

export default node;
