/**
 * @generated SignedSource<<ac444d6ec7d872b669f9b1ec209b7c87>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobEquipment_useCraneSelectorAutomaticFavorite_BoomConfigurationFragment$data = {
  readonly boomConfigurationId: string;
  readonly " $fragmentType": "JobEquipment_useCraneSelectorAutomaticFavorite_BoomConfigurationFragment";
};
export type JobEquipment_useCraneSelectorAutomaticFavorite_BoomConfigurationFragment$key = {
  readonly " $data"?: JobEquipment_useCraneSelectorAutomaticFavorite_BoomConfigurationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobEquipment_useCraneSelectorAutomaticFavorite_BoomConfigurationFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobEquipment_useCraneSelectorAutomaticFavorite_BoomConfigurationFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "boomConfigurationId",
      "storageKey": null
    }
  ],
  "type": "AutomaticConfigurationInfo",
  "abstractKey": null
};

(node as any).hash = "baed4d0b83d0e7df6503645cefc957d0";

export default node;
