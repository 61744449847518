/**
 * @generated SignedSource<<6daa00cccb70f342acebab7d4c0d4ca3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddendumTemplateFields_TemplateFrFragment$data = {
  readonly template: Record<string, string>;
  readonly " $fragmentType": "AddendumTemplateFields_TemplateFrFragment";
};
export type AddendumTemplateFields_TemplateFrFragment$key = {
  readonly " $data"?: AddendumTemplateFields_TemplateFrFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddendumTemplateFields_TemplateFrFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddendumTemplateFields_TemplateFrFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "template",
      "storageKey": null
    }
  ],
  "type": "AddendumTemplate",
  "abstractKey": null
};

(node as any).hash = "a244641ed9cd801feb9668f2214d10ba";

export default node;
