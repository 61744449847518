/**
 * @generated SignedSource<<4051f9f82833f6f055f0f2005053f749>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TabAccessoryRulesQuery$variables = Record<PropertyKey, never>;
export type TabAccessoryRulesQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryRuleListFragment">;
};
export type TabAccessoryRulesQuery = {
  response: TabAccessoryRulesQuery$data;
  variables: TabAccessoryRulesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TabAccessoryRulesQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AccessoryRuleListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TabAccessoryRulesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccessoryRulesConnection",
        "kind": "LinkedField",
        "name": "accessoryRules",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccessoryRulesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AccessoryRule",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccessoryConditionsInternal",
                    "kind": "LinkedField",
                    "name": "conditions",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "kinds",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPreviousPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Connection",
            "abstractKey": "__isConnection"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c48698016d9f94889f49bcf188b06700",
    "id": null,
    "metadata": {},
    "name": "TabAccessoryRulesQuery",
    "operationKind": "query",
    "text": "query TabAccessoryRulesQuery {\n  ...AccessoryRuleListFragment\n}\n\nfragment AccessoryRuleListFragment on Query {\n  accessoryRules {\n    ...ResponsiveGridFragment\n    edges {\n      node {\n        id\n        ...AccessoryRuleListItemFragment\n      }\n    }\n  }\n}\n\nfragment AccessoryRuleListItemFragment on AccessoryRule {\n  description\n  id\n  conditions {\n    kinds\n  }\n}\n\nfragment ResponsiveGridFragment on Connection {\n  __isConnection: __typename\n  totalCount\n  pageInfo {\n    startCursor\n    endCursor\n    hasPreviousPage\n    hasNextPage\n  }\n  edges {\n    __typename\n    node {\n      __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "894e8e218fca657bdf89a1ad3b4d60a6";

export default node;
