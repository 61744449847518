/**
 * @generated SignedSource<<649f1eb4edc01b1c8b916fd6d7850dd2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as requirementLabelResolverType } from "../../../__resolvers__/RequirementLabelResolver";
// Type assertion validating that `requirementLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(requirementLabelResolverType satisfies (
  rootKey: RequirementLabelResolver$key,
) => string | null | undefined);
export type WorksiteRequirementAutocompleteListFragment$data = {
  readonly searchResults: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly label: string | null | undefined;
      };
    }> | null | undefined;
  } | null | undefined;
  readonly suggestions?: ReadonlyArray<{
    readonly score: number;
    readonly value: {
      readonly id: string;
      readonly label: string | null | undefined;
    };
  }>;
  readonly " $fragmentType": "WorksiteRequirementAutocompleteListFragment";
};
export type WorksiteRequirementAutocompleteListFragment$key = {
  readonly " $data"?: WorksiteRequirementAutocompleteListFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorksiteRequirementAutocompleteListFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "searchResults"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "RequirementLabelResolver"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 25,
      "kind": "LocalArgument",
      "name": "searchCount"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchCursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchTerm"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skipSuggestion"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "suggestionCount"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "suggestionPrompt"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "suggestionScoreThreshold"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "searchCount",
        "cursor": "searchCursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "searchCount",
          "cursor": "searchCursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./worksiteRequirementAutocomplete2ListFragmentQuery.graphql')
    }
  },
  "name": "WorksiteRequirementAutocompleteListFragment",
  "selections": [
    {
      "alias": "searchResults",
      "args": [
        {
          "kind": "Variable",
          "name": "searchTerm",
          "variableName": "searchTerm"
        }
      ],
      "concreteType": "SearchRequirementsConnection",
      "kind": "LinkedField",
      "name": "__worksiteRequirementAutocomplete2ListFragment_searchResults_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchRequirementsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Requirement",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "fragment": (v2/*: any*/),
                  "kind": "RelayResolver",
                  "name": "label",
                  "resolverModule": require('./../../../__resolvers__/RequirementLabelResolver').label,
                  "path": "searchResults.edges.node.label"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "condition": "skipSuggestion",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": "suggestions",
          "args": [
            {
              "kind": "Variable",
              "name": "count",
              "variableName": "suggestionCount"
            },
            {
              "kind": "Variable",
              "name": "prompt",
              "variableName": "suggestionPrompt"
            },
            {
              "kind": "Variable",
              "name": "scoreThreshold",
              "variableName": "suggestionScoreThreshold"
            }
          ],
          "concreteType": "SuggestionOfRequirement",
          "kind": "LinkedField",
          "name": "suggestedWorksiteRequirements",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "score",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Requirement",
              "kind": "LinkedField",
              "name": "value",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "fragment": (v2/*: any*/),
                  "kind": "RelayResolver",
                  "name": "label",
                  "resolverModule": require('./../../../__resolvers__/RequirementLabelResolver').label,
                  "path": "suggestions.value.label"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "09a1b7c1d52d0bcbd6c8e94ead2dbf5c";

export default node;
