import { Value, ValueArray, ValueObject } from 'value-object-cache';

/** Defines how a search parameter of type `T` can be parsed from, and serialized to, URL search params. */
export type SearchParam<T> = {
  /** Defines how to parse the parameter from URL search params. */
  readonly fromSearchParams: (searchParams: URLSearchParams) => T;
  /** Defines how to serialize the parameter to URL search params. */
  readonly writeToSearchParams: (value: T, searchParams: URLSearchParams) => void;
  /** Defines how to convert the parameter to a {@link Value} for value comparison. */
  readonly toValue: (value: T) => Value;
};

/** Define a search parameter of type `T`. */
export function createSearchParam<T extends Value>(
  fromSearchParams: (searchParams: URLSearchParams) => T,
  writeToSearchParams: (value: T, searchParams: URLSearchParams) => void,
  toValue?: (value: T) => Value, // optional when T extends Value
): SearchParam<T>;
export function createSearchParam<T>(
  fromSearchParams: (searchParams: URLSearchParams) => T,
  writeToSearchParams: (value: T, searchParams: URLSearchParams) => void,
  toValue: (value: T) => Value, // required otherwise
): SearchParam<T>;
export function createSearchParam<T>(
  fromSearchParams: (searchParams: URLSearchParams) => T,
  writeToSearchParams: (value: T, searchParams: URLSearchParams) => void,
  toValue: (value: T) => Value = (value: T) => value as Value,
): SearchParam<T> {
  return { fromSearchParams, writeToSearchParams, toValue };
}

/** Given a `SearchParam<T>`, infers the type of `T`. */
export type SearchParamType<T> = T extends SearchParam<infer U> ? U : never;

/** Given a record of search params, produce a type that maps each key to the type wrapped in its search param. */
export type SearchParamsProps<T extends object> = {
  readonly [K in keyof T]: SearchParamType<T[K & string]>;
};

export interface ISearchParams<Params extends object = object> {
  get<K extends keyof Params>(key: K): SearchParamType<Params[K]>;
  with(props: Partial<SearchParamsProps<Params>>): this;
  toSearchParams(): URLSearchParams;
}

/**
 * Dynamically create a class to manipulate the search params defined by the provided `Params` object.
 *
 * This class is a {@link ValueObject}, which means that if two instances of this class, `a` and `b`, contain the same
 * values, then they also have the same identity (i.e. `a === b`). This allows to compare the value of two instances
 * with a simple `===` check. This approach works particularly well with react hooks such as {@link useCallback},
 * {@link useMemo}, {@link useEffect}, etc. which only support comparison by identity (i.e. they don't allow the
 * developer to specify a custom equality check function).
 */
export const createSearchParamsClass = <Params extends object>(params: Params) =>
  class SearchParams extends ValueObject<SearchParamsProps<Params>> implements ISearchParams<Params> {
    /** Parse a {@link URLSearchParams} object into a properties object according to the specified params. */
    protected static searchParamsToProps(searchParams: URLSearchParams): SearchParamsProps<Params> {
      const props: Record<string, unknown> = {};
      this.forEachParam((key, param) => {
        props[key] = param.fromSearchParams(searchParams);
      });
      return props as SearchParamsProps<Params>;
    }

    /** Convert a {@link SearchParamsProps} properties object into an array of {@link Value}s according to the specified
     * params. */
    protected static toValues(props: SearchParamsProps<Params>): ValueArray {
      const values: Value[] = [];
      this.forEachParam((key, param) => {
        values.push(param.toValue((props as Record<string, unknown>)[key]));
      });
      return values;
    }

    /** Iterate over the configured params object in a deterministic way (required for stable value
     * comparison) and call the provided callback for each {@link SearchParam} entry. */
    private static forEachParam(callback: (key: string, param: SearchParam<unknown>) => void) {
      const sortedParams = Object.entries(params).sort(([keyA], [keyB]) => keyA.localeCompare(keyB));
      for (const [key, param] of sortedParams) {
        callback(key, param);
      }
    }

    constructor(props: SearchParamsProps<Params>) {
      super(props, SearchParams.toValues(props));
    }

    get<K extends keyof Params>(key: K): SearchParamType<Params[K]> {
      return this.props[key];
    }

    /** Create a new instance of the class containing the same properties as the current object, merged with the
     * properties provided as argument. */
    with(props: Partial<SearchParamsProps<Params>>): this {
      return new (this.constructor as new (p: SearchParamsProps<Params>) => this)({ ...this.props, ...props });
    }

    /** Convert the current parameters into a {@link URLSearchParams} object. */
    toSearchParams(): URLSearchParams {
      const searchParams = new URLSearchParams();
      SearchParams.forEachParam((key, param) => {
        param.writeToSearchParams((this.props as Record<string, unknown>)[key], searchParams);
      });
      return searchParams;
    }
  };
