/**
 * @generated SignedSource<<63eaa286255b18563d015aed7d768825>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AddendumTemplateDeleteButtonMutation$variables = {
  id: string;
};
export type AddendumTemplateDeleteButtonMutation$data = {
  readonly deleteAddendumTemplate: {
    readonly addendumTemplate: {
      readonly id: string;
    } | null | undefined;
    readonly errors: ReadonlyArray<{
      readonly __typename: string;
    }> | null | undefined;
  };
};
export type AddendumTemplateDeleteButtonMutation = {
  response: AddendumTemplateDeleteButtonMutation$data;
  variables: AddendumTemplateDeleteButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "DeleteAddendumTemplatePayload",
    "kind": "LinkedField",
    "name": "deleteAddendumTemplate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AddendumTemplate",
        "kind": "LinkedField",
        "name": "addendumTemplate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddendumTemplateDeleteButtonMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddendumTemplateDeleteButtonMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bfaaafb834339664c298e40cea910d7c",
    "id": null,
    "metadata": {},
    "name": "AddendumTemplateDeleteButtonMutation",
    "operationKind": "mutation",
    "text": "mutation AddendumTemplateDeleteButtonMutation(\n  $id: ID!\n) {\n  deleteAddendumTemplate(input: {id: $id}) {\n    addendumTemplate {\n      id\n    }\n    errors {\n      __typename\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1c7cd4fb084dac7d99cf7b64325f3292";

export default node;
