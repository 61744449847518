export const craneCharts = {
  craneCharts: 'Chartes de grues',
  dialog: {
    title: 'Désactiver la charte',
    body: 'Toutes les données de cette charte de grue seront effacées',
    confirmButton: 'Désactiver',
  },
  error: {
    unexpectedError: 'Une erreur inattendue est survenue. Veuillez réessayer.',
    duringCraneChartDeactivation: 'Une erreur est survenue lors de la désactivation de la charte de grue',
    errorDuringUpload: 'Une erreur est survenue lors du transfert des fichiers',
  },
  importErrorMessage: 'Une erreur est survenue lors du transfert des fichiers.',
  importSuccessMessage_one: '{{count}} fichier transféré et traité.',
  importSuccessMessage_other: '{{count}} fichiers transférés et traités.',
  list: {
    column: {
      name: 'Nom',
      make: 'Marque',
      model: 'Modèle',
      equipmentKind: 'Genre',
      capacity: 'Capacité',
      updatedAt: 'Mis à jour le',
      isActive: 'Actif',
      status: 'Statut',
    },
  },
};
