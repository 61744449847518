/**
 * @generated SignedSource<<32f9760ebbcce4cb458a672cfc48219c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DeleteAccessoryRuleInput = {
  id: string;
};
export type AccessoryRuleDeleteButtonMutation$variables = {
  input: DeleteAccessoryRuleInput;
};
export type AccessoryRuleDeleteButtonMutation$data = {
  readonly deleteAccessoryRule: {
    readonly deleteAccessoryRuleResponse: {
      readonly success: boolean;
    } | null | undefined;
  };
};
export type AccessoryRuleDeleteButtonMutation = {
  response: AccessoryRuleDeleteButtonMutation$data;
  variables: AccessoryRuleDeleteButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteAccessoryRulePayload",
    "kind": "LinkedField",
    "name": "deleteAccessoryRule",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DeleteAccessoryRuleResponse",
        "kind": "LinkedField",
        "name": "deleteAccessoryRuleResponse",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "success",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccessoryRuleDeleteButtonMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccessoryRuleDeleteButtonMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1df2f842bd2cab5c86b2d53de3865441",
    "id": null,
    "metadata": {},
    "name": "AccessoryRuleDeleteButtonMutation",
    "operationKind": "mutation",
    "text": "mutation AccessoryRuleDeleteButtonMutation(\n  $input: DeleteAccessoryRuleInput!\n) {\n  deleteAccessoryRule(input: $input) {\n    deleteAccessoryRuleResponse {\n      success\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "82e6a4b6ce1185c1f37564878fb75620";

export default node;
