/**
 * @generated SignedSource<<549de45178364303a7412d97124c83dd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccessoryRuleFields_DescriptionFragment$data = {
  readonly description: string;
  readonly " $fragmentType": "AccessoryRuleFields_DescriptionFragment";
};
export type AccessoryRuleFields_DescriptionFragment$key = {
  readonly " $data"?: AccessoryRuleFields_DescriptionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryRuleFields_DescriptionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccessoryRuleFields_DescriptionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "type": "AccessoryRule",
  "abstractKey": null
};

(node as any).hash = "5f1746101a6836851d794c090378f06d";

export default node;
