/**
 * @generated SignedSource<<5468b291a487305f99c8fdef6e62954d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as branchLabelResolverType } from "../../../__resolvers__/BranchLabelResolver";
// Type assertion validating that `branchLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(branchLabelResolverType satisfies (
  rootKey: BranchLabelResolver$key,
) => Record<string, string> | null | undefined);
export type SaleProjectFields_SalesBranchFragment$data = {
  readonly salesBranch: {
    readonly deletedAt: string | null | undefined;
    readonly id: string;
    readonly label: Record<string, string> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "SaleProjectFields_SalesBranchFragment";
};
export type SaleProjectFields_SalesBranchFragment$key = {
  readonly " $data"?: SaleProjectFields_SalesBranchFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_SalesBranchFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleProjectFields_SalesBranchFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Branch",
      "kind": "LinkedField",
      "name": "salesBranch",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "fragment": {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BranchLabelResolver"
          },
          "kind": "RelayResolver",
          "name": "label",
          "resolverModule": require('./../../../__resolvers__/BranchLabelResolver').label,
          "path": "salesBranch.label"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deletedAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ISaleProject",
  "abstractKey": "__isISaleProject"
};

(node as any).hash = "2e8bc457f54133bebb9df61888d5e36d";

export default node;
