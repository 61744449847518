import { useParams, useSearchParams } from 'react-router';
import { DataID } from 'react-relay';
import { _throw } from '../common/utils/_throw';
import { useMemo } from 'react';

interface UseSalesSearchParamsReturn {
  branchId: DataID;
  isNew: boolean;
  isCopying: boolean;
  isLinking: boolean;
  revisionId: DataID;
  searchParamsKind: string | null;
  isCopyInNewProject: boolean;
  isCopyInSameProject: boolean;
}

export function useSalesSearchParams(): UseSalesSearchParamsReturn {
  const pathParams = useParams<{ id: DataID }>();
  const [searchParams] = useSearchParams();

  return useMemo(() => {
    const branchId = pathParams.id ?? 'new';
    const isNew = branchId === 'new';
    const isCopying = branchId === 'copy';
    const isLinking = branchId === 'link';
    const revisionId = isCopying ? (searchParams.get('revisionId') ?? _throw('RevisionId is required for copy')) : '';
    const searchParamsKind = searchParams.get('kind');
    const isCopyInNewProject = isCopying && searchParams.has('strategy') && searchParams.get('strategy') === 'unlinked';
    const isCopyInSameProject = isCopying && searchParams.has('strategy') && searchParams.get('strategy') === 'linked';

    return { branchId, isNew, isCopying, revisionId, searchParamsKind, isLinking, isCopyInNewProject, isCopyInSameProject };
  }, [pathParams.id, searchParams]);
}
