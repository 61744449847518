import { RateStrategyCategory } from '../../__enums__/RateStrategyCategory';

export const configuration = {
  addenda: {
    delete: 'Delete Addendum',
    deleteMessage: 'Do you want to delete this addendum?',
    edit: 'Edit Addendum',
    field: {
      templateEn: 'Description EN',
      templateFr: 'Description FR',
      kinds: 'Kinds',
      natureOfWorks: 'Natures of work',
      natureOfWorkSubCategory: 'Nature of work subcategories',
      specialSituation: 'Particular situations',
      specialProjects: 'Special projects',
      kind: 'Type',
      title: 'Title',
      titleEn: 'Title EN',
      titleFr: 'Title FR',
    },
    information: 'Information',
    conditions: 'Conditions',
    new: 'New Addendum',
  },
  competitors: {
    title: 'Competitors',
    name: 'Competitor Name',
    placeholder: 'Enter a competitor name',
  },
  configuration: 'Configuration',
  enums: {
    billingCode: {
      code: 'Code',
      description: 'Description',
      labelEn: 'English Label',
      labelFr: 'French Label',
      title: 'Billing Codes (Accessories)',
    },
    natureOfWork: {
      code: 'Code',
      defaultWorkSchedule: 'Default work schedule',
      description: 'Description',
      title: 'Natures of Work',
      workSchedules: 'Work schedules',
      natureOfWorkSubCategories: 'Categories',
    },
  },
  error: {
    errorDuringDelete: 'An error occurred while deleting the element',
    errorDuringSaveCompetitor: 'An error occurred while updating the list of competitors',
    errorDuringSaveBillingCodes: 'An error occurred while updating the billing codes',
    errorDuringSaveNatureOfWorks: 'An error occurred while updating the nature of works',
  },
  rateStrategies: {
    labelEn: 'English Label',
    labelFr: 'French Label',
    edit: 'Edit Rate Strategy',
    new: 'New Rate Strategy',
    label: 'Label',
    categories: 'Categories',
    delete: 'Delete Rate Strategy',
    deleteMessage: 'Do you want to delete this rate strategy?',
    transportCategoryKinds: 'Transport Categories',
    rateStrategyCategory: {
      mainCrane: 'Main Crane',
      additionalCrane: 'Additional Crane',
      boomCounterweightTransport: 'Boom and Counterweight Transport',
      flatbedTransport: 'Flatbed Transport',
    } satisfies Record<RateStrategyCategory, string>,
  },
  section: {
    addenda: 'Addenda',
    competitors: 'Competitors',
    enums: 'Enums.',
    rateStrategies: 'Rate Strategies',
  },
};
