/**
 * @generated SignedSource<<825e286b60bef5f9db21027566204252>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaleCraneSelectorFields_Manual_RadiusFragment$data = {
  readonly radius: string | null | undefined;
  readonly " $fragmentType": "SaleCraneSelectorFields_Manual_RadiusFragment";
};
export type SaleCraneSelectorFields_Manual_RadiusFragment$key = {
  readonly " $data"?: SaleCraneSelectorFields_Manual_RadiusFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleCraneSelectorFields_Manual_RadiusFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleCraneSelectorFields_Manual_RadiusFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "radius",
      "storageKey": null
    }
  ],
  "type": "ManualConfigurationInfo",
  "abstractKey": null
};

(node as any).hash = "b019b14d5a92375a68451250b2bae0d7";

export default node;
