/**
 * @generated SignedSource<<3d4d92e8fcad0b99ae7c21cee0446c51>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CraneCapacityLabelResolver$data = {
  readonly capacity: number;
  readonly " $fragmentType": "CraneCapacityLabelResolver";
};
export type CraneCapacityLabelResolver$key = {
  readonly " $data"?: CraneCapacityLabelResolver$data;
  readonly " $fragmentSpreads": FragmentRefs<"CraneCapacityLabelResolver">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CraneCapacityLabelResolver",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capacity",
      "storageKey": null
    }
  ],
  "type": "CraneCapacity",
  "abstractKey": null
};

(node as any).hash = "92b79f261d45388b790f151be25fd64c";

export default node;
