/**
 * @generated SignedSource<<3c270880ff350537c4e42092fca0d9a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CalculatorSalesRatesInput = {
  arrivalDate: string;
  clientId: string;
  dispatchBranchId: string;
  equipments: ReadonlyArray<CalculatorSalesRatesEquipmentInput | null | undefined>;
  natureOfWorkCode: number;
  worksiteId?: string | null | undefined;
};
export type CalculatorSalesRatesEquipmentInput = {
  boomConfigurationId?: string | null | undefined;
  vehicleId?: string | null | undefined;
};
export type useInstantCalculatorQuery$variables = {
  branchToWorksiteDistance: string;
  salesRatesInput: CalculatorSalesRatesInput;
};
export type useInstantCalculatorQuery$data = {
  readonly createInstantCalculatorCostLines: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"useInstantCalculator_useApplyInstantCalculatorFragment">;
};
export type useInstantCalculatorQuery = {
  response: useInstantCalculatorQuery$data;
  variables: useInstantCalculatorQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "branchToWorksiteDistance"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "salesRatesInput"
},
v2 = [
  {
    "kind": "Variable",
    "name": "branchToWorksiteDistance",
    "variableName": "branchToWorksiteDistance"
  },
  {
    "kind": "Variable",
    "name": "salesRatesInput",
    "variableName": "salesRatesInput"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = [
  (v4/*: any*/),
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useInstantCalculatorQuery",
    "selections": [
      {
        "args": (v2/*: any*/),
        "kind": "FragmentSpread",
        "name": "useInstantCalculator_useApplyInstantCalculatorFragment"
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CostLine",
        "kind": "LinkedField",
        "name": "createInstantCalculatorCostLines",
        "plural": true,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useInstantCalculatorQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CostLine",
        "kind": "LinkedField",
        "name": "createInstantCalculatorCostLines",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "kind",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "craneIndex",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingCode",
            "kind": "LinkedField",
            "name": "billingCode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "subCode",
                "storageKey": null
              },
              {
                "name": "label",
                "args": null,
                "fragment": {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "microDescription",
                      "storageKey": null
                    },
                    (v5/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "units",
                      "storageKey": null
                    }
                  ],
                  "type": "BillingCode",
                  "abstractKey": null
                },
                "kind": "RelayResolver",
                "storageKey": null,
                "isOutputType": true
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BillingSection",
                "kind": "LinkedField",
                "name": "billingSection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shortDescription",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "billingCodeCategory",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isFractionAllowed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isFixedQuantity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "defaultQuantity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "requireWorkForceType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SalesRateResult",
            "kind": "LinkedField",
            "name": "salesRateResult",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canEditRate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SalesRate",
                "kind": "LinkedField",
                "name": "value",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isAnnualContract",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isFlexiblePrice",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "minimumQuantity",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "price",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SalesRateError",
                "kind": "LinkedField",
                "name": "error",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkForceTypeLookup",
            "kind": "LinkedField",
            "name": "workForceType",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "name": "label",
                "args": null,
                "fragment": {
                  "kind": "InlineFragment",
                  "selections": (v6/*: any*/),
                  "type": "WorkForceTypeLookup",
                  "abstractKey": null
                },
                "kind": "RelayResolver",
                "storageKey": null,
                "isOutputType": true
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "quantity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "billable",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "af4191296871c0b0a2854b9be77fb735",
    "id": null,
    "metadata": {},
    "name": "useInstantCalculatorQuery",
    "operationKind": "query",
    "text": "query useInstantCalculatorQuery(\n  $salesRatesInput: CalculatorSalesRatesInput!\n  $branchToWorksiteDistance: Length!\n) {\n  ...useInstantCalculator_useApplyInstantCalculatorFragment_ojbVo\n  createInstantCalculatorCostLines(salesRatesInput: $salesRatesInput, branchToWorksiteDistance: $branchToWorksiteDistance) {\n    id\n  }\n}\n\nfragment BillingCodeLabelResolver on BillingCode {\n  microDescription\n  description\n  units\n}\n\nfragment WorkForceTypeLabelResolver on WorkForceTypeLookup {\n  code\n  description\n}\n\nfragment useInstantCalculator_useApplyInstantCalculatorFragment_ojbVo on Query {\n  createInstantCalculatorCostLines(salesRatesInput: $salesRatesInput, branchToWorksiteDistance: $branchToWorksiteDistance) {\n    id\n    kind\n    craneIndex\n    billingCode {\n      id\n      code\n      subCode\n      ...BillingCodeLabelResolver\n      billingSection {\n        shortDescription\n        id\n      }\n    }\n    billingCodeCategory\n    isFractionAllowed\n    isFixedQuantity\n    defaultQuantity\n    requireWorkForceType\n    salesRateResult {\n      canEditRate\n      value {\n        isAnnualContract\n        isFlexiblePrice\n        minimumQuantity\n        price\n        createdAt\n      }\n      error {\n        code\n        description\n      }\n    }\n    workForceType {\n      id\n      ...WorkForceTypeLabelResolver\n      code\n    }\n    quantity\n    rate\n    billable\n  }\n}\n"
  }
};
})();

(node as any).hash = "4ad7e022c7f0ec9d69a4c0b14ad11fa3";

export default node;
