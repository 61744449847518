/**
 * @generated SignedSource<<6a843d9f1815b7cbd7d54e4a4dc4423c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TabEnumsQuery$variables = Record<PropertyKey, never>;
export type TabEnumsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeFormFragment" | "NatureOfWorkFormFragment">;
};
export type TabEnumsQuery = {
  response: TabEnumsQuery$data;
  variables: TabEnumsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TabEnumsQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "BillingCodeFormFragment"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "NatureOfWorkFormFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TabEnumsQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "where",
            "value": {
              "billingCodeCategory": {
                "eq": "accessories"
              }
            }
          }
        ],
        "concreteType": "BillingCode",
        "kind": "LinkedField",
        "name": "billingCodes",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "microDescription",
            "storageKey": null
          }
        ],
        "storageKey": "billingCodes(where:{\"billingCodeCategory\":{\"eq\":\"accessories\"}})"
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "order",
            "value": {
              "code": "ASC"
            }
          }
        ],
        "concreteType": "NatureOfWorkLookup",
        "kind": "LinkedField",
        "name": "natureOfWorks",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "defaultWorkSchedule",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "workSchedules",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "natureOfWorkSubCategories",
            "storageKey": null
          }
        ],
        "storageKey": "natureOfWorks(order:{\"code\":\"ASC\"})"
      }
    ]
  },
  "params": {
    "cacheID": "e8b3241036b16e8c5bf54dc68a2e4cf4",
    "id": null,
    "metadata": {},
    "name": "TabEnumsQuery",
    "operationKind": "query",
    "text": "query TabEnumsQuery {\n  ...BillingCodeFormFragment\n  ...NatureOfWorkFormFragment\n}\n\nfragment BillingCodeFormFragment on Query {\n  billingCodes(where: {billingCodeCategory: {eq: accessories}}) {\n    id\n    code\n    description\n    microDescription\n  }\n}\n\nfragment NatureOfWorkFormFragment on Query {\n  natureOfWorks(order: {code: ASC}) {\n    id\n    code\n    description\n    defaultWorkSchedule\n    workSchedules\n    natureOfWorkSubCategories\n  }\n}\n"
  }
};
})();

(node as any).hash = "692547ccbbbf3e12887b8bb7fc80ad8c";

export default node;
