/**
 * @generated SignedSource<<89ef848f958b46f81eae6639ecd5bad4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AccessoryLineKind = "automatic" | "manual" | "%future added value";
export type AddendumKind = "archived" | "clientOrWorksite" | "craneOperator" | "dispatching" | "internalNote" | "invoicing" | "quote" | "%future added value";
export type AttachmentKind = "assemblySequence" | "bargeStabilityStudy" | "bargeStowageInspection" | "certifications" | "charters" | "competenceCards" | "engineeringCertificate" | "feasibilityStudy" | "groundPressureCalculations" | "insuranceDeclaration" | "liftingPlanOneCrane" | "liftingPlanReview" | "liftingPlanTwoCranes" | "listOfTransports" | "location" | "others" | "parkingPermit" | "photos" | "projectDocuments" | "roadSpecifications" | "signage" | "technicalDrawings" | "transportPermit" | "travelSpecifications" | "workMethodEventSealedByEngineer" | "%future added value";
export type BillingCodeCategory = "accessories" | "boomAndCounterWeightTransport" | "equipment" | "escort" | "fixedFees" | "flatbedTransport" | "labor" | "movingFees" | "otherFees" | "permit" | "rateKind" | "%future added value";
export type ClientLanguage = "en" | "fr" | "%future added value";
export type CostLineKind = "automatic" | "instantCalculator" | "manual" | "quoteCalculator" | "%future added value";
export type CraneSelectorMode = "lifts" | "manual" | "%future added value";
export type NatureOfWorkSubCategoryEnum = "accessory" | "airports" | "antennas" | "aqueductsSewer" | "blade" | "bridges" | "cages" | "chambers" | "dams" | "demolition" | "distribution" | "docks" | "excavation" | "gearboxes" | "generator" | "gondola" | "hand" | "handling" | "landscaping" | "lifting" | "lines" | "modules" | "newConstruction" | "nonOperated" | "powerPlants" | "pruning" | "pylons" | "refurbishment" | "retentionBasins" | "roadsRails" | "rotor" | "shelters" | "substations" | "towers" | "transformers" | "transport" | "ventilationPlumbingElectrical" | "%future added value";
export type Orientation = "parallel" | "perpendicular" | "%future added value";
export type PatchOperationAction = "delete" | "insert" | "skip" | "update" | "%future added value";
export type PowerlineProximity = "from126KVTo250KV" | "from251KVTo550KV" | "lessThan126KV" | "moreThan550KV" | "%future added value";
export type SalesQuestionKind = "accidentScene" | "bargeWork" | "clamShellBucketOrGrapple" | "conventionalCrawlerCranesWithTravel" | "conventionalCrawlerCranesWithoutTravel" | "craneOrObstacleProximity" | "events" | "excavationProximityLessThan10Feet" | "excavationProximityMoreThan10Feet" | "hazardousTerrain" | "jettyBridgeViaductDamSlabOrParkingDeck" | "luffingJibOrExtensionInstallation" | "movingCraneOrEquipmentWithPulling" | "movingCraneOrEquipmentWithoutPulling" | "peopleLifting" | "powerlineProximity" | "tandemLiftingClientCrane" | "tandemLiftingGuayCrane" | "unloadingOnQuay" | "valueOf1MillionOrMore" | "videoOrPhotoShooting" | "windTurbines" | "%future added value";
export type ServiceCallKind = "accessoriesRental" | "bare" | "bareWithOperators" | "laborRental" | "laborRentalOnBare" | "laborRentalOnOperated" | "liftingPlan" | "liftingTest" | "operatedHourly" | "operatedMonthly" | "rollingEquipment" | "storage" | "%future added value";
export type WorkScheduleEnum = "fiveDaysEightHours" | "fiveDaysNineHours" | "fiveDaysTenHours" | "fourDaysTenHours" | "fourDaysTenHoursOneDayFiveHours" | "%future added value";
export type SaveServiceCallInput = {
  addenda?: ReadonlyArray<PatchOperationOfSaveAddendumInput | null | undefined> | null | undefined;
  attachments?: ReadonlyArray<PatchOperationOfSaveAttachmentInput | null | undefined> | null | undefined;
  client?: SaveServiceCallClientInternalInput | null | undefined;
  clientBase?: SaveClientInternalBaseInput | null | undefined;
  costsBase?: SaveCostsInternalBaseInput | null | undefined;
  equipmentBase?: SaveEquipmentInternalBaseInput | null | undefined;
  equipmentSale?: SaveSaleEquipmentInternalInput | null | undefined;
  kind?: ServiceCallKind | null | undefined;
  project?: SaveServiceCallProjectInternalInput | null | undefined;
  projectBase?: SaveProjectInternalBaseInput | null | undefined;
  questionsBase?: SaveQuestionsInternalBaseInput | null | undefined;
};
export type PatchOperationOfSaveAddendumInput = {
  action: PatchOperationAction;
  id?: string | null | undefined;
  value?: SaveAddendumInput | null | undefined;
};
export type SaveAddendumInput = {
  automatic: boolean;
  description: string;
  kind: AddendumKind;
  order: number;
  title: string;
};
export type PatchOperationOfSaveAttachmentInput = {
  action: PatchOperationAction;
  id?: string | null | undefined;
  value?: SaveAttachmentInput | null | undefined;
};
export type SaveAttachmentInput = {
  blobId?: string | null | undefined;
  kind?: AttachmentKind | null | undefined;
  name?: string | null | undefined;
};
export type SaveServiceCallClientInternalInput = {
  inTheCareOf?: string | null | undefined;
  orderNumber?: string | null | undefined;
  projectManagerId?: string | null | undefined;
};
export type SaveClientInternalBaseInput = {
  assignedClientId?: string | null | undefined;
  assignedClientInfo?: SaveClientInfoInput | null | undefined;
};
export type SaveClientInfoInput = {
  contact?: string | null | undefined;
  isDirectSales?: boolean | null | undefined;
  language?: ClientLanguage | null | undefined;
  location?: SaveLocationInput | null | undefined;
  name?: string | null | undefined;
  phoneNumber?: string | null | undefined;
  requirements?: RequirementsInput | null | undefined;
};
export type SaveLocationInput = {
  address: string;
  placeId?: string | null | undefined;
};
export type RequirementsInput = {
  ids: ReadonlyArray<string>;
};
export type SaveCostsInternalBaseInput = {
  accessoryLines?: ReadonlyArray<PatchOperationOfSaveAccessoryLineInput | null | undefined> | null | undefined;
  costLines?: ReadonlyArray<PatchOperationOfSaveCostLineInput | null | undefined> | null | undefined;
};
export type PatchOperationOfSaveAccessoryLineInput = {
  action: PatchOperationAction;
  id?: string | null | undefined;
  value?: SaveAccessoryLineInput | null | undefined;
};
export type SaveAccessoryLineInput = {
  accessoryGroupId?: string | null | undefined;
  accessoryOutOfInventory?: string | null | undefined;
  accessoryTypeCode?: number | null | undefined;
  billable?: boolean | null | undefined;
  billingCodeId?: string | null | undefined;
  defaultQuantity?: number | null | undefined;
  isFixedQuantity?: boolean | null | undefined;
  kind?: AccessoryLineKind | null | undefined;
  quantity?: number | null | undefined;
  rate?: string | null | undefined;
  requireAccessory?: boolean | null | undefined;
  salesRateResult?: ExternalResultOfSalesRateAndSalesRateErrorInput | null | undefined;
};
export type ExternalResultOfSalesRateAndSalesRateErrorInput = {
  error?: SalesRateErrorInput | null | undefined;
  value?: SalesRateInput | null | undefined;
};
export type SalesRateErrorInput = {
  code: string;
  description: string;
};
export type SalesRateInput = {
  createdAt: string;
  isAnnualContract: boolean;
  isFlexiblePrice: boolean;
  minimumQuantity: number;
  price: string;
};
export type PatchOperationOfSaveCostLineInput = {
  action: PatchOperationAction;
  id?: string | null | undefined;
  value?: SaveCostLineInput | null | undefined;
};
export type SaveCostLineInput = {
  billable?: boolean | null | undefined;
  billingCodeCategory?: BillingCodeCategory | null | undefined;
  billingCodeId?: string | null | undefined;
  craneIndex?: number | null | undefined;
  defaultQuantity?: number | null | undefined;
  isFixedQuantity?: boolean | null | undefined;
  isFractionAllowed?: boolean | null | undefined;
  kind?: CostLineKind | null | undefined;
  quantity?: number | null | undefined;
  rate?: string | null | undefined;
  requireWorkForceType?: boolean | null | undefined;
  salesRateResult?: ExternalResultOfSalesRateAndSalesRateErrorInput | null | undefined;
  workForceTypeCode?: number | null | undefined;
};
export type SaveEquipmentInternalBaseInput = {
  craneSelector?: SaveCraneSelectorInternalInput | null | undefined;
};
export type SaveCraneSelectorInternalInput = {
  automaticConfiguration?: SaveAutomaticConfigurationInput | null | undefined;
  manualConfiguration?: SaveManualConfigurationInput | null | undefined;
  mode?: CraneSelectorMode | null | undefined;
};
export type SaveAutomaticConfigurationInput = {
  additionalConfigurations?: ReadonlyArray<PatchOperationOfSaveAdditionalConfigurationInfoInput | null | undefined> | null | undefined;
  configurationETags?: ReadonlyArray<string> | null | undefined;
  favoriteETag?: string | null | undefined;
  lifts?: ReadonlyArray<PatchOperationOfSaveLiftInput | null | undefined> | null | undefined;
  snapshotId?: string | null | undefined;
};
export type PatchOperationOfSaveAdditionalConfigurationInfoInput = {
  action: PatchOperationAction;
  id?: string | null | undefined;
  value?: SaveAdditionalConfigurationInfoInput | null | undefined;
};
export type SaveAdditionalConfigurationInfoInput = {
  boomConfigurationId?: string | null | undefined;
  capacity?: number | null | undefined;
  configurationKindCode?: number | null | undefined;
  deletedAt?: string | null | undefined;
  equipmentKindCode?: number | null | undefined;
};
export type PatchOperationOfSaveLiftInput = {
  action: PatchOperationAction;
  id?: string | null | undefined;
  value?: SaveLiftInput | null | undefined;
};
export type SaveLiftInput = {
  accessories?: AccessoriesInternalInput | null | undefined;
  building?: BuildingInternalInput | null | undefined;
  isActive?: boolean | null | undefined;
  load?: LoadInternalInput | null | undefined;
  name?: string | null | undefined;
  securityFactorPercentage?: number | null | undefined;
  site?: SiteInternalInput | null | undefined;
};
export type AccessoriesInternalInput = {
  height?: string | null | undefined;
  weight?: string | null | undefined;
};
export type BuildingInternalInput = {
  ceilingHeight?: string | null | undefined;
  exteriorRadius?: string | null | undefined;
  height?: string | null | undefined;
  interiorRadius?: string | null | undefined;
};
export type LoadInternalInput = {
  height?: string | null | undefined;
  length?: string | null | undefined;
  weight?: string | null | undefined;
  width?: string | null | undefined;
};
export type SiteInternalInput = {
  length?: string | null | undefined;
  orientation?: Orientation | null | undefined;
  width?: string | null | undefined;
};
export type SaveManualConfigurationInput = {
  additionalConfigurations?: ReadonlyArray<PatchOperationOfSaveAdditionalConfigurationInfoInput | null | undefined> | null | undefined;
  configurationInfo?: SaveManualConfigurationInfoInput | null | undefined;
};
export type SaveManualConfigurationInfoInput = {
  boomConfigurationId?: string | null | undefined;
  boomLength?: string | null | undefined;
  capacity?: number | null | undefined;
  configurationKindCode?: number | null | undefined;
  counterweight?: string | null | undefined;
  equipmentKindCode?: number | null | undefined;
  jibLength?: string | null | undefined;
  maxWeight?: string | null | undefined;
  offsetAngle?: string | null | undefined;
  radius?: string | null | undefined;
  vehicleIds?: ReadonlyArray<string> | null | undefined;
};
export type SaveSaleEquipmentInternalInput = {
  branchToWorksiteDistance?: string | null | undefined;
  preventReplacement?: boolean | null | undefined;
  preventSubcontracting?: boolean | null | undefined;
};
export type SaveServiceCallProjectInternalInput = {
  departureDate?: string | null | undefined;
  dispatchBranchId?: string | null | undefined;
  includeWeekendsAndHolidays?: boolean | null | undefined;
  isEstablishedSchedule?: boolean | null | undefined;
  isSecondServing?: boolean | null | undefined;
  natureOfWorkCode?: number | null | undefined;
  natureOfWorkSubCategory?: NatureOfWorkSubCategoryEnum | null | undefined;
  readyDate?: string | null | undefined;
  salesBranchId?: string | null | undefined;
  schedulePeriod?: SaveTimeRangeInput | null | undefined;
  workDescription?: string | null | undefined;
  workSchedule?: WorkScheduleEnum | null | undefined;
};
export type SaveTimeRangeInput = {
  endTime?: string | null | undefined;
  startTime?: string | null | undefined;
};
export type SaveProjectInternalBaseInput = {
  arrivalDate?: string | null | undefined;
  assignedWorksiteId?: string | null | undefined;
  assignedWorksiteInfo?: SaveWorksiteInfoInput | null | undefined;
  duration?: SaveWorkScheduleInput | null | undefined;
};
export type SaveWorksiteInfoInput = {
  contact?: string | null | undefined;
  location?: SaveLocationInput | null | undefined;
  name?: string | null | undefined;
  phoneNumber?: string | null | undefined;
  requirements?: RequirementsInput | null | undefined;
};
export type SaveWorkScheduleInput = {
  hoursPerDay?: number | null | undefined;
  lengthInDays?: number | null | undefined;
};
export type SaveQuestionsInternalBaseInput = {
  hasWorksiteBeenVisited?: boolean | null | undefined;
  saveExcavationProximityLessThan10Feet?: SaveSalesQuestionForExcavationProximityLessThan10FeetInput | null | undefined;
  saveProximityToPowerline?: SaveSalesQuestionForProximityToPowerlineInput | null | undefined;
  saveSalesQuestions?: ReadonlyArray<SaveSalesQuestionInput> | null | undefined;
};
export type SaveSalesQuestionForExcavationProximityLessThan10FeetInput = {
  angle?: string | null | undefined;
  distance?: string | null | undefined;
  isBerlinWallOrSupportedExcavation?: boolean | null | undefined;
  isTilledSoil?: boolean | null | undefined;
};
export type SaveSalesQuestionForProximityToPowerlineInput = {
  proximity?: PowerlineProximity | null | undefined;
};
export type SaveSalesQuestionInput = {
  kind: SalesQuestionKind;
};
export type ServiceCallListDelayButtonMutation$variables = {
  revisionId: string;
  snapshot: SaveServiceCallInput;
};
export type ServiceCallListDelayButtonMutation$data = {
  readonly delayServiceCall: {
    readonly lifeCycleBranch: {
      readonly id: string;
    } | null | undefined;
  };
};
export type ServiceCallListDelayButtonMutation = {
  response: ServiceCallListDelayButtonMutation$data;
  variables: ServiceCallListDelayButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "revisionId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "snapshot"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "revisionId",
            "variableName": "revisionId"
          },
          {
            "kind": "Variable",
            "name": "snapshot",
            "variableName": "snapshot"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "DelayServiceCallPayload",
    "kind": "LinkedField",
    "name": "delayServiceCall",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LifeCycleBranch",
        "kind": "LinkedField",
        "name": "lifeCycleBranch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ServiceCallListDelayButtonMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ServiceCallListDelayButtonMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8e98d71ef487ca979947a856ceb2d13f",
    "id": null,
    "metadata": {},
    "name": "ServiceCallListDelayButtonMutation",
    "operationKind": "mutation",
    "text": "mutation ServiceCallListDelayButtonMutation(\n  $revisionId: ID!\n  $snapshot: SaveServiceCallInput!\n) {\n  delayServiceCall(input: {revisionId: $revisionId, snapshot: $snapshot}) {\n    lifeCycleBranch {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "712894783557606054ac4a96d8245dcd";

export default node;
