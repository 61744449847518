/**
 * @generated SignedSource<<74f02fefd3c371d8c4a7473cf630820b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectBaseFields_WorksiteOverrides_NameFragment$data = {
  readonly name: string | null | undefined;
  readonly " $fragmentType": "ProjectBaseFields_WorksiteOverrides_NameFragment";
};
export type ProjectBaseFields_WorksiteOverrides_NameFragment$key = {
  readonly " $data"?: ProjectBaseFields_WorksiteOverrides_NameFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverrides_NameFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectBaseFields_WorksiteOverrides_NameFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "IOverridableWorksite",
  "abstractKey": "__isIOverridableWorksite"
};

(node as any).hash = "ca0cba8f4316fdd4e062b0b620087ade";

export default node;
