/**
 * @generated SignedSource<<b49ef15ee5fde3efbf5ea17d5bc9f2af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkPlanningList_Row_MeFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"WorkPlanningList_Actions_MeFragment">;
  readonly " $fragmentType": "WorkPlanningList_Row_MeFragment";
};
export type WorkPlanningList_Row_MeFragment$key = {
  readonly " $data"?: WorkPlanningList_Row_MeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkPlanningList_Row_MeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkPlanningList_Row_MeFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorkPlanningList_Actions_MeFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "867ab3516df790ae8c229dd3dc18ca10";

export default node;
