/**
 * @generated SignedSource<<bc5862554961666b5b70ed6a5a5b63f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type NatureOfWorkSubCategoryEnum = "accessory" | "airports" | "antennas" | "aqueductsSewer" | "blade" | "bridges" | "cages" | "chambers" | "dams" | "demolition" | "distribution" | "docks" | "excavation" | "gearboxes" | "generator" | "gondola" | "hand" | "handling" | "landscaping" | "lifting" | "lines" | "modules" | "newConstruction" | "nonOperated" | "powerPlants" | "pruning" | "pylons" | "refurbishment" | "retentionBasins" | "roadsRails" | "rotor" | "shelters" | "substations" | "towers" | "transformers" | "transport" | "ventilationPlumbingElectrical" | "%future added value";
export type ServiceCallKind = "accessoriesRental" | "bare" | "bareWithOperators" | "laborRental" | "laborRentalOnBare" | "laborRentalOnOperated" | "liftingPlan" | "liftingTest" | "operatedHourly" | "operatedMonthly" | "rollingEquipment" | "storage" | "%future added value";
export type SuggestionPromptInput = {
  accessoryTypes: ReadonlyArray<string>;
  capacity?: number | null | undefined;
  clientId?: string | null | undefined;
  clientRequirements: ReadonlyArray<string>;
  dispatchBranchId?: string | null | undefined;
  equipmentKindCode?: string | null | undefined;
  hoursPerDay?: number | null | undefined;
  inTheCareOf?: string | null | undefined;
  isSecondServing?: boolean | null | undefined;
  lengthInDays?: number | null | undefined;
  natureOfWorkCode?: string | null | undefined;
  natureOfWorkSubCategory?: NatureOfWorkSubCategoryEnum | null | undefined;
  representativeId?: string | null | undefined;
  salesBranchId?: string | null | undefined;
  serviceCallKind?: ServiceCallKind | null | undefined;
  vehicleIds: ReadonlyArray<string>;
  workDescription?: string | null | undefined;
  worksiteId?: string | null | undefined;
  worksiteRequirements: ReadonlyArray<string>;
};
export type SuggestionsFakeQuery$variables = {
  count: number;
  prompt: SuggestionPromptInput;
  scoreThreshold: number;
};
export type SuggestionsFakeQuery$data = {
  readonly suggestedClients: ReadonlyArray<{
    readonly value: {
      readonly id: string;
    };
  }>;
};
export type SuggestionsFakeQuery = {
  response: SuggestionsFakeQuery$data;
  variables: SuggestionsFakeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "prompt"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "scoreThreshold"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "count",
        "variableName": "count"
      },
      {
        "kind": "Variable",
        "name": "prompt",
        "variableName": "prompt"
      },
      {
        "kind": "Variable",
        "name": "scoreThreshold",
        "variableName": "scoreThreshold"
      }
    ],
    "concreteType": "SuggestionOfClient",
    "kind": "LinkedField",
    "name": "suggestedClients",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Client",
        "kind": "LinkedField",
        "name": "value",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SuggestionsFakeQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SuggestionsFakeQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b2ee5248feaf9d5eb85ec77f91be74a8",
    "id": null,
    "metadata": {},
    "name": "SuggestionsFakeQuery",
    "operationKind": "query",
    "text": "query SuggestionsFakeQuery(\n  $count: Int!\n  $prompt: SuggestionPromptInput!\n  $scoreThreshold: Float!\n) {\n  suggestedClients(count: $count, prompt: $prompt, scoreThreshold: $scoreThreshold) {\n    value {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e6b3dc59a5bc739ee6353a9a1cb6602c";

export default node;
