/**
 * @generated SignedSource<<c5561d97575b9a796937251ea689818e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as accessoryTypeLookupLabelResolverType } from "../../../__resolvers__/AccessoryTypeLabelResolver";
// Type assertion validating that `accessoryTypeLookupLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(accessoryTypeLookupLabelResolverType satisfies (
  rootKey: AccessoryTypeLabelResolver$key,
) => Record<string, string> | null | undefined);
import { label as billingCodeLabelResolverType } from "../../../__resolvers__/BillingCodeLabelResolver";
// Type assertion validating that `billingCodeLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(billingCodeLabelResolverType satisfies (
  rootKey: BillingCodeLabelResolver$key,
) => Record<string, string> | null | undefined);
export type AccessoryRuleFields_BillingCodeCollectionFragment$data = {
  readonly effects: {
    readonly accessories: {
      readonly automatic: ReadonlyArray<{
        readonly accessoryType: {
          readonly code: number;
          readonly id: string;
          readonly label: Record<string, string> | null | undefined;
        };
        readonly billingCode: {
          readonly code: number;
          readonly id: string;
          readonly label: Record<string, string> | null | undefined;
        };
      }>;
    };
  };
  readonly " $fragmentType": "AccessoryRuleFields_BillingCodeCollectionFragment";
};
export type AccessoryRuleFields_BillingCodeCollectionFragment$key = {
  readonly " $data"?: AccessoryRuleFields_BillingCodeCollectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryRuleFields_BillingCodeCollectionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccessoryRuleFields_BillingCodeCollectionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccessoryEffectsInternal",
      "kind": "LinkedField",
      "name": "effects",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AccessoryEffects",
          "kind": "LinkedField",
          "name": "accessories",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AutomaticAccessoryEffectResponse",
              "kind": "LinkedField",
              "name": "automatic",
              "plural": true,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccessoryTypeLookup",
                    "kind": "LinkedField",
                    "name": "accessoryType",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "fragment": {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "AccessoryTypeLabelResolver"
                        },
                        "kind": "RelayResolver",
                        "name": "label",
                        "resolverModule": require('./../../../__resolvers__/AccessoryTypeLabelResolver').label,
                        "path": "effects.accessories.automatic.accessoryType.label"
                      }
                    ],
                    "storageKey": null
                  },
                  "action": "THROW"
                },
                {
                  "kind": "RequiredField",
                  "field": {
                    "alias": null,
                    "args": null,
                    "concreteType": "BillingCode",
                    "kind": "LinkedField",
                    "name": "billingCode",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "fragment": {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "BillingCodeLabelResolver"
                        },
                        "kind": "RelayResolver",
                        "name": "label",
                        "resolverModule": require('./../../../__resolvers__/BillingCodeLabelResolver').label,
                        "path": "effects.accessories.automatic.billingCode.label"
                      }
                    ],
                    "storageKey": null
                  },
                  "action": "THROW"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AccessoryRule",
  "abstractKey": null
};
})();

(node as any).hash = "b4525b22bb476fed4ae1baae80d1ac36";

export default node;
