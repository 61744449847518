/**
 * @generated SignedSource<<c1a45cbcc096c0de3c427c8dc9f67bc3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectBaseFields_WorksiteLocationInput_ClientFragment$data = {
  readonly assignedClient: {
    readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_CopyClientAddressButtonFragment">;
  } | null | undefined;
  readonly assignedClientInfo: {
    readonly " $fragmentSpreads": FragmentRefs<"ClientBaseFields_ClientOverrides_IsDirectSalesFragment" | "ProjectBaseFields_CopyClientAddressButtonFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ClientBaseFields_AssignedClientFragment">;
  readonly " $fragmentType": "ProjectBaseFields_WorksiteLocationInput_ClientFragment";
};
export type ProjectBaseFields_WorksiteLocationInput_ClientFragment$key = {
  readonly " $data"?: ProjectBaseFields_WorksiteLocationInput_ClientFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteLocationInput_ClientFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "ProjectBaseFields_CopyClientAddressButtonFragment"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectBaseFields_WorksiteLocationInput_ClientFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClientBaseFields_AssignedClientFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientInfoInternal",
      "kind": "LinkedField",
      "name": "assignedClientInfo",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientBaseFields_ClientOverrides_IsDirectSalesFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Client",
      "kind": "LinkedField",
      "name": "assignedClient",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "ClientInternalBase",
  "abstractKey": null
};
})();

(node as any).hash = "920c71164996de581471792640d45c29";

export default node;
