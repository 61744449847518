import { Tab, Tabs } from '@mui/material';
import { SyntheticEvent } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

interface Props {
  tab: 'addenda' | 'competitors' | 'enums' | 'rate-strategies';
}

export function ConfigurationTabs({ tab }: Props) {
  const { t } = useTranslation('configuration');
  const navigate = useNavigate();

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    navigate(`../${newValue}`);
  };

  return (
    <Tabs
      variant='scrollable'
      value={tab}
      onChange={handleChange}
      textColor='secondary'
      indicatorColor='secondary'
      scrollButtons='auto'
      sx={(theme) => ({ backgroundColor: theme.palette.background.paper, mb: '1rem' })}>
      <Tab value='addenda' label={t('section.addenda')} />
      <Tab value='competitors' label={t('section.competitors')} />
      <Tab value='enums' label={t('section.enums')} />
      <Tab value='rate-strategies' label={t('section.rateStrategies')} />
    </Tabs>
  );
}
