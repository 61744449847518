/**
 * @generated SignedSource<<f3b597bf6dd8f82284c8a36a0ec134fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as placesAutocompleteResultLabelResolverType } from "../../../__resolvers__/PlacesAutocompleteResultLabelResolver";
// Type assertion validating that `placesAutocompleteResultLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(placesAutocompleteResultLabelResolverType satisfies (
  rootKey: PlacesAutocompleteResultLabelResolver$key,
) => string | null | undefined);
export type AddressInputSearchQuery$variables = {
  searchTerm: string;
  sessionToken: string;
};
export type AddressInputSearchQuery$data = {
  readonly placesAutocomplete: ReadonlyArray<{
    readonly description: string;
    readonly label: string | null | undefined;
    readonly placeId: string;
  }>;
};
export type AddressInputSearchQuery = {
  response: AddressInputSearchQuery$data;
  variables: AddressInputSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTerm"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sessionToken"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "searchTerm",
    "variableName": "searchTerm"
  },
  {
    "kind": "Variable",
    "name": "sessionToken",
    "variableName": "sessionToken"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "placeId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddressInputSearchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PlacesAutocompleteResult",
        "kind": "LinkedField",
        "name": "placesAutocomplete",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "fragment": {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PlacesAutocompleteResultLabelResolver"
            },
            "kind": "RelayResolver",
            "name": "label",
            "resolverModule": require('./../../../__resolvers__/PlacesAutocompleteResultLabelResolver').label,
            "path": "placesAutocomplete.label"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddressInputSearchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PlacesAutocompleteResult",
        "kind": "LinkedField",
        "name": "placesAutocomplete",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "name": "label",
            "args": null,
            "fragment": {
              "kind": "InlineFragment",
              "selections": [
                (v2/*: any*/)
              ],
              "type": "PlacesAutocompleteResult",
              "abstractKey": null
            },
            "kind": "RelayResolver",
            "storageKey": null,
            "isOutputType": true
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0d713d5e5d41f750e785a3b33c721b44",
    "id": null,
    "metadata": {},
    "name": "AddressInputSearchQuery",
    "operationKind": "query",
    "text": "query AddressInputSearchQuery(\n  $searchTerm: String!\n  $sessionToken: String!\n) {\n  placesAutocomplete(searchTerm: $searchTerm, sessionToken: $sessionToken) {\n    description\n    ...PlacesAutocompleteResultLabelResolver\n    placeId\n  }\n}\n\nfragment PlacesAutocompleteResultLabelResolver on PlacesAutocompleteResult {\n  description\n}\n"
  }
};
})();

(node as any).hash = "5423c468ba8b95c05b9d7f25957f6036";

export default node;
