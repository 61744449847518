/**
 * @generated SignedSource<<8e832023de9c3a767ddd20a110948a41>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as craneCapacityLabelResolverType } from "../../../__resolvers__/CraneCapacityLabelResolver";
// Type assertion validating that `craneCapacityLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(craneCapacityLabelResolverType satisfies (
  rootKey: CraneCapacityLabelResolver$key,
) => string | null | undefined);
export type AccessoryRuleFields_CapacitiesFragment$data = {
  readonly conditions: {
    readonly capacities: ReadonlyArray<{
      readonly capacity: number;
      readonly label: string | null | undefined;
    }>;
  };
  readonly " $fragmentType": "AccessoryRuleFields_CapacitiesFragment";
};
export type AccessoryRuleFields_CapacitiesFragment$key = {
  readonly " $data"?: AccessoryRuleFields_CapacitiesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryRuleFields_CapacitiesFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccessoryRuleFields_CapacitiesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccessoryConditionsInternal",
      "kind": "LinkedField",
      "name": "conditions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CraneCapacity",
          "kind": "LinkedField",
          "name": "capacities",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "capacity",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "fragment": {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CraneCapacityLabelResolver"
              },
              "kind": "RelayResolver",
              "name": "label",
              "resolverModule": require('./../../../__resolvers__/CraneCapacityLabelResolver').label,
              "path": "conditions.capacities.label"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AccessoryRule",
  "abstractKey": null
};

(node as any).hash = "d18733be0c179f9e4e779eef6364af72";

export default node;
