/**
 * @generated SignedSource<<dd301e20fd40517c8ecc0c6c9d4e8486>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssignedWorksite_Overrides_ClientFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverrides_ClientLocationFragment">;
  readonly " $fragmentType": "AssignedWorksite_Overrides_ClientFragment";
};
export type AssignedWorksite_Overrides_ClientFragment$key = {
  readonly " $data"?: AssignedWorksite_Overrides_ClientFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssignedWorksite_Overrides_ClientFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssignedWorksite_Overrides_ClientFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectBaseFields_WorksiteOverrides_ClientLocationFragment"
    }
  ],
  "type": "ClientInternalBase",
  "abstractKey": null
};

(node as any).hash = "983e6225e6dcfadbdbfb48db63124e71";

export default node;
