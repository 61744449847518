import { ForwardedRef, forwardRef, MouseEvent, useCallback, useImperativeHandle, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useTranslation } from 'react-i18next';
import { useFragment, useRefetchableFragment } from 'react-relay';
import { DateTime } from 'luxon';

import { EllipsisedTypography } from '../common/components/EllipsisedTypography';
import {
  ResponsiveGrid,
  ResponsiveGridColumnDefinition,
  ResponsiveGridColumnOrderer,
  ResponsiveGridForwardProps,
  useElementFactory,
} from '../common/components/ResponsiveGrid';
import { NullableCell } from '../common/components/NullableCell';
import { dateTimeFormat } from '../common/utils/dateTimeUtils';
import DeleteIcon from '@mui/icons-material/Delete';
import graphql from 'babel-plugin-relay/macro';
import { castBlobStatus } from '../__enums__/BlobStatus';
import { EventBoundary } from '../common/components/EventBoundary';
import { ConfirmationDialog } from '../common/dialogs/ConfirmationDialog';
import { usePromiseMutation } from '../common/hooks/usePromiseMutation';
import { FileUploadStatusChip } from '../common/components/FileUploadStatusChip';
import { useErrorBanner } from '../common/components/ErrorBanner';
import { CraneChartListFragment$key } from './__generated__/CraneChartListFragment.graphql';
import { CraneChartListItemFragment$key } from './__generated__/CraneChartListItemFragment.graphql';
import { CraneChartListActionsFragment$key } from './__generated__/CraneChartListActionsFragment.graphql';
import { CraneChartListDeactivateButtonFragment$key } from './__generated__/CraneChartListDeactivateButtonFragment.graphql';
import { CraneChartListDeactivateButtonMutation } from './__generated__/CraneChartListDeactivateButtonMutation.graphql';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { CraneChartListFragmentQuery } from './__generated__/CraneChartListFragmentQuery.graphql';

export interface CraneChartListHandle {
  refresh: () => void;
}

export const CraneChartList = forwardRef(function CraneChartList(
  { $key, ...gridProps }: { $key: CraneChartListFragment$key } & ResponsiveGridForwardProps,
  ref: ForwardedRef<CraneChartListHandle>,
) {
  const { t } = useAmbientTranslation();

  const [$data, refetch] = useRefetchableFragment<CraneChartListFragmentQuery, CraneChartListFragment$key>(
    graphql`
      fragment CraneChartListFragment on Query
      @refetchable(queryName: "CraneChartListFragmentQuery")
      @argumentDefinitions(after: { type: "String" }, before: { type: "String" }, first: { type: "Int" }, last: { type: "Int" }) {
        craneChartBlobMetadatas(after: $after, before: $before, first: $first, last: $last, order: [{ createdAt: DESC }]) {
          ...ResponsiveGridFragment
          edges {
            node {
              id
              ...CraneChartListItemFragment
            }
          }
        }
      }
    `,
    $key,
  );

  useImperativeHandle(ref, () => ({
    refresh: () => refetch({}, { fetchPolicy: 'network-only' }),
  }));

  const columns: ResponsiveGridColumnDefinition[] = [
    { id: 'name', label: t('list.column.name'), size: 'auto' },
    { id: 'make', label: t('list.column.make'), size: 'auto' },
    { id: 'model', label: t('list.column.model'), size: 'auto' },
    {
      id: 'equipmentKind',
      label: t('list.column.equipmentKind'),
      size: 'minmax(5rem, auto)',
      sx: { textAlign: 'center' },
    },
    { id: 'capacity', label: t('list.column.capacity'), size: '12ch', sx: { textAlign: 'right' } },
    { id: 'updatedAt', label: t('list.column.updatedAt'), size: '14ch' },
    { id: 'isActive', label: t('list.column.isActive'), size: '8ch' },
    { id: 'status', label: t('list.column.status'), size: 'auto' },
    { id: 'actions', label: '', size: '4rem' },
  ];

  const rowElementFactory = useElementFactory($data.craneChartBlobMetadatas?.edges, (node, orderByColumns) => (
    <CraneChartListRow fragmentKey={node} orderByColumns={orderByColumns} />
  ));
  const listElementFactory = useElementFactory($data.craneChartBlobMetadatas?.edges, () => <div></div>);

  return (
    $data.craneChartBlobMetadatas && (
      <ResponsiveGrid
        connectionFragmentKey={$data.craneChartBlobMetadatas}
        refetch={refetch}
        columnDefinitions={columns}
        rowElementFactory={rowElementFactory}
        listElementFactory={listElementFactory}
        listSx={{
          overflowX: 'auto',
          overflowY: 'hidden',
          'li.responsive-grid__header  > *': {
            px: '1rem',
          },
          'li:not(.responsive-grid__header)  > *': {
            px: '0.5rem',
          },
        }}
        {...gridProps}
      />
    )
  );
});

export function CraneChartListRow({
  fragmentKey,
  orderByColumns,
}: {
  fragmentKey: CraneChartListItemFragment$key;
  orderByColumns: ResponsiveGridColumnOrderer;
}) {
  const { t } = useAmbientTranslation();
  const { t: tCommon } = useTranslation('common');
  const $data = useFragment(
    graphql`
      fragment CraneChartListItemFragment on CraneChartBlobMetadata {
        ...CraneChartListActionsFragment
        id
        name
        byteCount
        status
        updatedAt
        craneChartId
        craneChart {
          id
        }
        make
        model
        equipmentKind {
          abbreviation
        }
        capacity
      }
    `,
    fragmentKey,
  );

  return (
    <>
      <h3 style={visuallyHidden}>{$data.name}</h3>

      {orderByColumns([
        <Box key='name' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0.25rem' }}>
          <EllipsisedTypography variant='body2' component='span'>
            {$data.name}
          </EllipsisedTypography>
        </Box>,
        <Box key='make' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0.25rem' }}>
          <EllipsisedTypography variant='body2' component='span'>
            <NullableCell value={$data.make} />
          </EllipsisedTypography>
        </Box>,
        <Box key='model' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0.25rem' }}>
          <EllipsisedTypography variant='body2' component='span'>
            <NullableCell value={$data.model} />
          </EllipsisedTypography>
        </Box>,
        <Typography key='equipmentKind' variant='body2' textAlign='center'>
          <NullableCell value={$data.equipmentKind?.abbreviation} />
        </Typography>,
        <Typography key='capacity' variant='body2' textAlign='right'>
          <NullableCell formatter={(v: number) => `${t('unit.full.ton', { ns: 'common', count: v })}`} value={$data.capacity} />
        </Typography>,
        <Typography key='updatedAt' variant='body2'>
          <NullableCell formatter={(v: string) => DateTime.fromISO(v).toFormat(dateTimeFormat)} value={$data.updatedAt} />
        </Typography>,
        <Typography key='isActive' variant='body2' component='span'>
          {$data.craneChart?.id ? tCommon('yesNo.yes') : tCommon('yesNo.no')}
        </Typography>,
        <Typography key='status' variant='body2' component='span'>
          <FileUploadStatusChip status={castBlobStatus($data.status)} />
        </Typography>,

        <CraneChartListActions fragmentKey={$data} key='actions' />,
      ])}
    </>
  );
}

function CraneChartListActions({ fragmentKey }: { fragmentKey: CraneChartListActionsFragment$key | null }) {
  const blob = useFragment(
    graphql`
      fragment CraneChartListActionsFragment on CraneChartBlobMetadata {
        ...CraneChartListDeactivateButtonFragment
        craneChart {
          id
        }
      }
    `,
    fragmentKey,
  );

  return <CraneChartListDeactivateButton fragmentKey={blob} disabled={!blob?.craneChart?.id} />;
}

function CraneChartListDeactivateButton({
  fragmentKey,
  disabled,
}: {
  fragmentKey: CraneChartListDeactivateButtonFragment$key | null | undefined;
  disabled: boolean;
}) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { reportUnexpectedError, reportHandledError } = useErrorBanner();
  const { t } = useAmbientTranslation();
  const blob = useFragment(
    graphql`
      fragment CraneChartListDeactivateButtonFragment on CraneChartBlobMetadata {
        id
      }
    `,
    fragmentKey,
  );
  const blobId = blob?.id;
  const [commit, isLoading] = usePromiseMutation<CraneChartListDeactivateButtonMutation>(graphql`
    mutation CraneChartListDeactivateButtonMutation($id: ID!) {
      deactivateCraneChartBlob(input: { blobId: $id }) {
        craneChartBlobMetadata {
          ...CraneChartListItemFragment
        }
        errors {
          __typename
        }
      }
    }
  `);

  const handleConfirm = useCallback(async () => {
    if (!blobId) return;

    try {
      const { response } = await commit({ variables: { id: blobId } });

      if (response?.deactivateCraneChartBlob.errors?.length) {
        reportHandledError(response.deactivateCraneChartBlob.errors, () => t('error.duringCraneChartDeactivation'));
        return;
      }
    } catch {
      reportUnexpectedError(() => t('error.duringCraneChartDeactivation'));
    } finally {
      setIsDialogOpen(false);
    }
  }, [blobId, commit, reportHandledError, reportUnexpectedError, t]);

  const handleOpen = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsDialogOpen(true);
  }, []);

  const handleCancel = useCallback(() => setIsDialogOpen(false), []);

  return (
    <EventBoundary>
      <IconButton sx={{ opacity: 'var(--hover-highlight)' }} onClick={handleOpen} disabled={disabled}>
        <DeleteIcon />
      </IconButton>
      <ConfirmationDialog
        title={t('dialog.title')}
        message={t('dialog.body')}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        isOpen={isDialogOpen}
        isLoading={isLoading}
        confirmationButtonIcon={<DeleteIcon />}
        confirmText={t('dialog.confirmButton')}
      />
    </EventBoundary>
  );
}
