import { Navigate, Route } from 'react-router';
import { EmptyLayout } from '../layout/Layouts';
import { TabDataSync } from './TabDataSync';
import { config } from '../config';

const deleteEnabled = config.CAN_DELETE_DATA;
const seedEnabled = config.CAN_RESEED_DATA;
const syncEnabled = config.CAN_RESYNC_DATA;

export const maintenanceRoutes = (
  <Route path='maintenance' lazy={() => import('./MaintenancePage')}>
    <Route
      index
      element={
        <EmptyLayout>
          <Navigate to='data-sync' replace />
        </EmptyLayout>
      }
    />
    <Route path='data-sync' element={<TabDataSync deleteEnabled={deleteEnabled} seedEnabled={seedEnabled} syncEnabled={syncEnabled} />} />
  </Route>
);
