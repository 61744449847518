/**
 * @generated SignedSource<<c5f112431a372b1d6800fdfb2badab47>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CraneVehicleIdAutocompleteQuery$variables = {
  capacity?: number | null | undefined;
  configurationKindCode?: number | null | undefined;
  equipmentKindCode?: number | null | undefined;
  searchTerm?: string | null | undefined;
};
export type CraneVehicleIdAutocompleteQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CraneVehicleIdAutocompleteFragment">;
};
export type CraneVehicleIdAutocompleteQuery = {
  response: CraneVehicleIdAutocompleteQuery$data;
  variables: CraneVehicleIdAutocompleteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "capacity"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "configurationKindCode"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "equipmentKindCode"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchTerm"
},
v4 = {
  "kind": "Variable",
  "name": "capacity",
  "variableName": "capacity"
},
v5 = {
  "kind": "Variable",
  "name": "configurationKindCode",
  "variableName": "configurationKindCode"
},
v6 = {
  "kind": "Variable",
  "name": "equipmentKindCode",
  "variableName": "equipmentKindCode"
},
v7 = {
  "kind": "Variable",
  "name": "searchTerm",
  "variableName": "searchTerm"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CraneVehicleIdAutocompleteQuery",
    "selections": [
      {
        "args": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "CraneVehicleIdAutocompleteFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CraneVehicleIdAutocompleteQuery",
    "selections": [
      {
        "alias": "searchResults",
        "args": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "Literal",
            "name": "skip",
            "value": 0
          },
          {
            "kind": "Literal",
            "name": "take",
            "value": 25
          }
        ],
        "concreteType": "SearchCraneVehicleIdsCollectionSegment",
        "kind": "LinkedField",
        "name": "searchCraneVehicleIds",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FullTextSearchResultOfVehicleId",
            "kind": "LinkedField",
            "name": "items",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "VehicleId",
                "kind": "LinkedField",
                "name": "result",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "key",
                    "storageKey": null
                  },
                  {
                    "name": "label",
                    "args": null,
                    "fragment": {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "displayName",
                          "storageKey": null
                        }
                      ],
                      "type": "VehicleId",
                      "abstractKey": null
                    },
                    "kind": "RelayResolver",
                    "storageKey": null,
                    "isOutputType": true
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionSegmentInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "85bc2b6a0cdea8368e51b31867de2648",
    "id": null,
    "metadata": {},
    "name": "CraneVehicleIdAutocompleteQuery",
    "operationKind": "query",
    "text": "query CraneVehicleIdAutocompleteQuery(\n  $searchTerm: String\n  $capacity: Int\n  $equipmentKindCode: Int\n  $configurationKindCode: Int\n) {\n  ...CraneVehicleIdAutocompleteFragment_3nkxUZ\n}\n\nfragment CraneVehicleIdAutocompleteFragment_3nkxUZ on Query {\n  searchResults: searchCraneVehicleIds(searchTerm: $searchTerm, capacity: $capacity, equipmentKindCode: $equipmentKindCode, configurationKindCode: $configurationKindCode, skip: 0, take: 25) {\n    items {\n      result {\n        key\n        ...VehicleIdLabelResolver\n      }\n    }\n    pageInfo {\n      hasNextPage\n    }\n  }\n}\n\nfragment VehicleIdLabelResolver on VehicleId {\n  displayName\n}\n"
  }
};
})();

(node as any).hash = "aed8889c6681da74bdac1e0d8d118c6e";

export default node;
