/**
 * @generated SignedSource<<3a25dc71930cfea6dc0d660257e066be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type NatureOfWorkSubCategoryEnum = "accessory" | "airports" | "antennas" | "aqueductsSewer" | "blade" | "bridges" | "cages" | "chambers" | "dams" | "demolition" | "distribution" | "docks" | "excavation" | "gearboxes" | "generator" | "gondola" | "hand" | "handling" | "landscaping" | "lifting" | "lines" | "modules" | "newConstruction" | "nonOperated" | "powerPlants" | "pruning" | "pylons" | "refurbishment" | "retentionBasins" | "roadsRails" | "rotor" | "shelters" | "substations" | "towers" | "transformers" | "transport" | "ventilationPlumbingElectrical" | "%future added value";
export type ServiceCallKind = "accessoriesRental" | "bare" | "bareWithOperators" | "laborRental" | "laborRentalOnBare" | "laborRentalOnOperated" | "liftingPlan" | "liftingTest" | "operatedHourly" | "operatedMonthly" | "rollingEquipment" | "storage" | "%future added value";
export type SuggestionPromptInput = {
  accessoryTypes: ReadonlyArray<string>;
  capacity?: number | null | undefined;
  clientId?: string | null | undefined;
  clientRequirements: ReadonlyArray<string>;
  dispatchBranchId?: string | null | undefined;
  equipmentKindCode?: string | null | undefined;
  hoursPerDay?: number | null | undefined;
  inTheCareOf?: string | null | undefined;
  isSecondServing?: boolean | null | undefined;
  lengthInDays?: number | null | undefined;
  natureOfWorkCode?: string | null | undefined;
  natureOfWorkSubCategory?: NatureOfWorkSubCategoryEnum | null | undefined;
  representativeId?: string | null | undefined;
  salesBranchId?: string | null | undefined;
  serviceCallKind?: ServiceCallKind | null | undefined;
  vehicleIds: ReadonlyArray<string>;
  workDescription?: string | null | undefined;
  worksiteId?: string | null | undefined;
  worksiteRequirements: ReadonlyArray<string>;
};
export type ProjectBaseFields_DurationHoursPerDay_SuggestionsQuery$variables = {
  scoreThreshold: number;
  suggestionCount: number;
  suggestionPrompt: SuggestionPromptInput;
};
export type ProjectBaseFields_DurationHoursPerDay_SuggestionsQuery$data = {
  readonly suggestedHoursPerDay: ReadonlyArray<{
    readonly score: number;
    readonly value: number;
  }>;
};
export type ProjectBaseFields_DurationHoursPerDay_SuggestionsQuery = {
  response: ProjectBaseFields_DurationHoursPerDay_SuggestionsQuery$data;
  variables: ProjectBaseFields_DurationHoursPerDay_SuggestionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scoreThreshold"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "suggestionCount"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "suggestionPrompt"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "count",
        "variableName": "suggestionCount"
      },
      {
        "kind": "Variable",
        "name": "prompt",
        "variableName": "suggestionPrompt"
      },
      {
        "kind": "Variable",
        "name": "scoreThreshold",
        "variableName": "scoreThreshold"
      }
    ],
    "concreteType": "SuggestionOfDecimal",
    "kind": "LinkedField",
    "name": "suggestedHoursPerDay",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "score",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectBaseFields_DurationHoursPerDay_SuggestionsQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProjectBaseFields_DurationHoursPerDay_SuggestionsQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "436e2792c10b140804a2e825225e53d3",
    "id": null,
    "metadata": {},
    "name": "ProjectBaseFields_DurationHoursPerDay_SuggestionsQuery",
    "operationKind": "query",
    "text": "query ProjectBaseFields_DurationHoursPerDay_SuggestionsQuery(\n  $scoreThreshold: Float!\n  $suggestionPrompt: SuggestionPromptInput!\n  $suggestionCount: Int!\n) {\n  suggestedHoursPerDay(scoreThreshold: $scoreThreshold, prompt: $suggestionPrompt, count: $suggestionCount) {\n    value\n    score\n  }\n}\n"
  }
};
})();

(node as any).hash = "67345ca51fbaba1b845f91129be0358d";

export default node;
