/**
 * @generated SignedSource<<f501b05ccc958d956a167313cea1af6c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssignedWorksite_Overrides_DenormalizableFragment$data = {
  readonly requirements: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_RequirementsFragment">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_WorksiteOverrides_ContactFragment" | "ProjectBaseFields_WorksiteOverrides_LocationFragment" | "ProjectBaseFields_WorksiteOverrides_NameFragment" | "ProjectBaseFields_WorksiteOverrides_PhoneNumberFragment">;
  readonly " $fragmentType": "AssignedWorksite_Overrides_DenormalizableFragment";
};
export type AssignedWorksite_Overrides_DenormalizableFragment$key = {
  readonly " $data"?: AssignedWorksite_Overrides_DenormalizableFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssignedWorksite_Overrides_DenormalizableFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssignedWorksite_Overrides_DenormalizableFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectBaseFields_WorksiteOverrides_NameFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectBaseFields_WorksiteOverrides_LocationFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Requirement",
      "kind": "LinkedField",
      "name": "requirements",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectBaseFields_RequirementsFragment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectBaseFields_WorksiteOverrides_ContactFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectBaseFields_WorksiteOverrides_PhoneNumberFragment"
    }
  ],
  "type": "IOverridableWorksite",
  "abstractKey": "__isIOverridableWorksite"
};

(node as any).hash = "f3b042eef258bc32bc2ffa17d5c91a7a";

export default node;
