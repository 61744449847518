/**
 * @generated SignedSource<<9667c9a2a72a69b4db97c0187338df91>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccessoryLineSubFormFields_AccessoryGroupOrOutOfInventoryRulesFragment$data = {
  readonly isNumberMandatory: boolean;
  readonly " $fragmentType": "AccessoryLineSubFormFields_AccessoryGroupOrOutOfInventoryRulesFragment";
};
export type AccessoryLineSubFormFields_AccessoryGroupOrOutOfInventoryRulesFragment$key = {
  readonly " $data"?: AccessoryLineSubFormFields_AccessoryGroupOrOutOfInventoryRulesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryLineSubFormFields_AccessoryGroupOrOutOfInventoryRulesFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccessoryLineSubFormFields_AccessoryGroupOrOutOfInventoryRulesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isNumberMandatory",
      "storageKey": null
    }
  ],
  "type": "AccessoryTypeLookup",
  "abstractKey": null
};

(node as any).hash = "9c9fb78f6867389244725c35f8fef8da";

export default node;
