/**
 * @generated SignedSource<<222bbf53f6d78f6754dcfcbe5486914b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccessoryRuleFields_BaseQuestions$data = {
  readonly conditions: {
    readonly baseQuestions: ReadonlyArray<string>;
  };
  readonly " $fragmentType": "AccessoryRuleFields_BaseQuestions";
};
export type AccessoryRuleFields_BaseQuestions$key = {
  readonly " $data"?: AccessoryRuleFields_BaseQuestions$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryRuleFields_BaseQuestions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccessoryRuleFields_BaseQuestions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccessoryConditionsInternal",
      "kind": "LinkedField",
      "name": "conditions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "baseQuestions",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AccessoryRule",
  "abstractKey": null
};

(node as any).hash = "4d16f9f8fb072cf03779ac4dcafda2b1";

export default node;
