/**
 * @generated SignedSource<<752369bc13b901f0ad936807fcebddb8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ServiceCallListDelayButtonFragment$data = {
  readonly id: string;
  readonly snapshot: {
    readonly transferredAt: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ServiceCallListDelayButtonFragment";
};
export type ServiceCallListDelayButtonFragment$key = {
  readonly " $data"?: ServiceCallListDelayButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ServiceCallListDelayButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ServiceCallListDelayButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ServiceCall",
      "kind": "LinkedField",
      "name": "snapshot",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "transferredAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ServiceCallJobRevision",
  "abstractKey": null
};

(node as any).hash = "30239b2991e61af220651a29668d5230";

export default node;
