/**
 * @generated SignedSource<<dcbfed8d89ede41e39266dee3c32d4cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TabCompetitorsQuery$variables = Record<PropertyKey, never>;
export type TabCompetitorsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CompetitorsListFragment">;
};
export type TabCompetitorsQuery = {
  response: TabCompetitorsQuery$data;
  variables: TabCompetitorsQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TabCompetitorsQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "CompetitorsListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TabCompetitorsQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "order",
            "value": {
              "name": "ASC"
            }
          }
        ],
        "concreteType": "Competitor",
        "kind": "LinkedField",
        "name": "competitors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": "competitors(order:{\"name\":\"ASC\"})"
      }
    ]
  },
  "params": {
    "cacheID": "345fb44ba26f12e77c30206b4d452771",
    "id": null,
    "metadata": {},
    "name": "TabCompetitorsQuery",
    "operationKind": "query",
    "text": "query TabCompetitorsQuery {\n  ...CompetitorsListFragment\n}\n\nfragment CompetitorsListFragment on Query {\n  competitors(order: {name: ASC}) {\n    id\n    name\n  }\n}\n"
  }
};

(node as any).hash = "f3815afb1a2c690fc44eae6b1096c7b9";

export default node;
