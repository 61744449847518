/**
 * @generated SignedSource<<2262330607a77451d0453076bf455870>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccessoryLinesFields_ItemContent_SaleProjectFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_DispatchBranchFragment">;
  readonly " $fragmentType": "AccessoryLinesFields_ItemContent_SaleProjectFragment";
};
export type AccessoryLinesFields_ItemContent_SaleProjectFragment$key = {
  readonly " $data"?: AccessoryLinesFields_ItemContent_SaleProjectFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryLinesFields_ItemContent_SaleProjectFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccessoryLinesFields_ItemContent_SaleProjectFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SaleProjectFields_DispatchBranchFragment"
    }
  ],
  "type": "ISaleProject",
  "abstractKey": "__isISaleProject"
};

(node as any).hash = "6c88825825bf81cc768139a71a81c019";

export default node;
