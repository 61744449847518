import graphql from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay';
import { IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { MouseEvent, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router';
import { EventBoundary } from '../common/components/EventBoundary';
import { NAVIGATE_PREVENT_BLOCK_STATE } from '../common/hooks/usePreventNavigationOnFormDirty';
import { WorkPlanningCopyDialog, WorkPlanningCopyDialogRef } from './WorkPlanningCopyDialog';
import { WorkPlanningList_CopyButtonFragment$key } from './__generated__/WorkPlanningList_CopyButtonFragment.graphql';

export function WorkPlanningList_CopyButton({
  $key,
  disabled,
}: {
  $key: WorkPlanningList_CopyButtonFragment$key | null | undefined;
  disabled?: boolean;
}) {
  const copyRef = useRef<WorkPlanningCopyDialogRef>(null);
  const navigate = useNavigate();

  const $data = useFragment(
    graphql`
      fragment WorkPlanningList_CopyButtonFragment on WorkPlanningJobRevision {
        id
      }
    `,
    $key,
  );

  const handleConfirm = useCallback(
    async (search: string) => {
      if (!$data?.id) return;
      navigate(`./copy?revisionId=${$data.id}&` + search, { state: NAVIGATE_PREVENT_BLOCK_STATE });
      copyRef.current?.close();
    },
    [$data?.id, navigate],
  );

  const handleOpen = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      copyRef.current?.open(handleConfirm);
    },
    [handleConfirm],
  );

  return (
    <EventBoundary sx={{ cursor: 'default' }}>
      <IconButton sx={{ opacity: 'var(--hover-highlight)' }} onClick={handleOpen} disabled={disabled}>
        <ContentCopyIcon />
      </IconButton>

      <WorkPlanningCopyDialog ref={copyRef} />
    </EventBoundary>
  );
}
