/**
 * @generated SignedSource<<de373b907c20dfaa96a7ceefcd8a96b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaleProjectFields_IncludeWeekendsAndHolidaysFragment$data = {
  readonly includeWeekendsAndHolidays: boolean;
  readonly " $fragmentType": "SaleProjectFields_IncludeWeekendsAndHolidaysFragment";
};
export type SaleProjectFields_IncludeWeekendsAndHolidaysFragment$key = {
  readonly " $data"?: SaleProjectFields_IncludeWeekendsAndHolidaysFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_IncludeWeekendsAndHolidaysFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleProjectFields_IncludeWeekendsAndHolidaysFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "includeWeekendsAndHolidays",
      "storageKey": null
    }
  ],
  "type": "ISaleProject",
  "abstractKey": "__isISaleProject"
};

(node as any).hash = "91cfc3a13b873f49fc0bab2cb74caccd";

export default node;
