/**
 * @generated SignedSource<<2a8e05b88128e8f28596ad539452145c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkPlanningListPageContentDefaultFiltersFragment$data = {
  readonly representativeId: string | null | undefined;
  readonly roles: ReadonlyArray<string>;
  readonly " $fragmentSpreads": FragmentRefs<"JobFilters_useJobFiltersFragment">;
  readonly " $fragmentType": "WorkPlanningListPageContentDefaultFiltersFragment";
};
export type WorkPlanningListPageContentDefaultFiltersFragment$key = {
  readonly " $data"?: WorkPlanningListPageContentDefaultFiltersFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkPlanningListPageContentDefaultFiltersFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkPlanningListPageContentDefaultFiltersFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "representativeId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roles",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "JobFilters_useJobFiltersFragment"
    }
  ],
  "type": "Employee",
  "abstractKey": null
};

(node as any).hash = "e57ccdc396d942ae5d51a0042e34c806";

export default node;
