/**
 * @generated SignedSource<<b0b00d4b5fb4d7b734344836d483d249>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CostLinesFields_CostLineCollectionFragment$data = {
  readonly costLines: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"CostLinesFields_CostLineItemsFragment">;
  }>;
  readonly " $fragmentType": "CostLinesFields_CostLineCollectionFragment";
};
export type CostLinesFields_CostLineCollectionFragment$key = {
  readonly " $data"?: CostLinesFields_CostLineCollectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CostLinesFields_CostLineCollectionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CostLinesFields_CostLineCollectionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CostLine",
      "kind": "LinkedField",
      "name": "costLines",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CostLinesFields_CostLineItemsFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CostsInternalBase",
  "abstractKey": null
};

(node as any).hash = "0d47db0d636e60ac808adf61f64a616a";

export default node;
