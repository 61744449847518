/**
 * @generated
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck


export const addendumKinds = ["archived", "clientOrWorksite", "craneOperator", "dispatching", "internalNote", "invoicing", "quote"] as const;
export type AddendumKind = typeof addendumKinds[number];

export function isAddendumKind(value: string): value is AddendumKind {
  return addendumKinds.includes(value)
}

export function asAddendumKind(value: string): AddendumKind | null {
  return isAddendumKind(value) ? value : null;
}

export function castAddendumKind(value: string): AddendumKind {
  if(!isAddendumKind(value)) {
    throw new Error(`Invalid Enum value for type "AddendumKind": "${value}"`);
  }
  
  return value;
}
