/**
 * @generated SignedSource<<98a057f064e2cac69ddc7250e4f65d72>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LayoutsListLayoutFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LayoutsListLayoutToolbarFragment">;
  readonly " $fragmentType": "LayoutsListLayoutFragment";
};
export type LayoutsListLayoutFragment$key = {
  readonly " $data"?: LayoutsListLayoutFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"LayoutsListLayoutFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LayoutsListLayoutFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LayoutsListLayoutToolbarFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "6252970a8eda1fb2f94f1ba65f243222";

export default node;
