/**
 * @generated SignedSource<<bf6cb048a834e77ad3b8031371a67253>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as branchLabelResolverType } from "../../../__resolvers__/BranchLabelResolver";
// Type assertion validating that `branchLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(branchLabelResolverType satisfies (
  rootKey: BranchLabelResolver$key,
) => Record<string, string> | null | undefined);
export type DispatchBranchAutocompleteListFragment$data = {
  readonly searchResults: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly deletedAt: string | null | undefined;
        readonly id: string;
        readonly label: Record<string, string> | null | undefined;
      };
    }> | null | undefined;
  } | null | undefined;
  readonly suggestions?: ReadonlyArray<{
    readonly score: number;
    readonly value: {
      readonly deletedAt: string | null | undefined;
      readonly id: string;
      readonly label: Record<string, string> | null | undefined;
    };
  }>;
  readonly " $fragmentType": "DispatchBranchAutocompleteListFragment";
};
export type DispatchBranchAutocompleteListFragment$key = {
  readonly " $data"?: DispatchBranchAutocompleteListFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DispatchBranchAutocompleteListFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "searchResults"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "BranchLabelResolver"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isDispatchBranch"
    },
    {
      "defaultValue": 25,
      "kind": "LocalArgument",
      "name": "searchCount"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchCursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchTerm"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skipSuggestion"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "suggestionCount"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "suggestionPrompt"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "suggestionScoreThreshold"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "searchCount",
        "cursor": "searchCursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "searchCount",
          "cursor": "searchCursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./dispatchBranchAutocomplete2ListFragmentQuery.graphql')
    }
  },
  "name": "DispatchBranchAutocompleteListFragment",
  "selections": [
    {
      "alias": "searchResults",
      "args": [
        {
          "kind": "Variable",
          "name": "isDispatchBranch",
          "variableName": "isDispatchBranch"
        },
        {
          "kind": "Variable",
          "name": "searchTerm",
          "variableName": "searchTerm"
        }
      ],
      "concreteType": "SearchBranchesConnection",
      "kind": "LinkedField",
      "name": "__dispatchBranchAutocomplete2ListFragment_searchResults_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchBranchesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Branch",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "fragment": (v2/*: any*/),
                  "kind": "RelayResolver",
                  "name": "label",
                  "resolverModule": require('./../../../__resolvers__/BranchLabelResolver').label,
                  "path": "searchResults.edges.node.label"
                },
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "condition": "skipSuggestion",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": "suggestions",
          "args": [
            {
              "kind": "Variable",
              "name": "count",
              "variableName": "suggestionCount"
            },
            {
              "kind": "Variable",
              "name": "prompt",
              "variableName": "suggestionPrompt"
            },
            {
              "kind": "Variable",
              "name": "scoreThreshold",
              "variableName": "suggestionScoreThreshold"
            }
          ],
          "concreteType": "SuggestionOfBranch",
          "kind": "LinkedField",
          "name": "suggestedDispatchBranches",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "score",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Branch",
              "kind": "LinkedField",
              "name": "value",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "fragment": (v2/*: any*/),
                  "kind": "RelayResolver",
                  "name": "label",
                  "resolverModule": require('./../../../__resolvers__/BranchLabelResolver').label,
                  "path": "suggestions.value.label"
                },
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "882c89f4c25dff3f065ca2be825d292a";

export default node;
