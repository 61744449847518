/**
 * @generated SignedSource<<8e56f3b891bab593b12cb6f5a886e58d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobEquipment_useCraneSelectorManualFavoriteFragment$data = {
  readonly userInput: {
    readonly boomConfiguration: {
      readonly preparationHours: number;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"JobEquipment_useResetBoomConfigurationFragment" | "SaleCraneSelectorFields_CraneCapacityFragment" | "SaleCraneSelectorFields_CraneConfigurationKindFragment" | "SaleCraneSelectorFields_EquipmentKindFragment" | "SaleCraneSelectorFields_Manual_BoomConfigurationFragment" | "SaleCraneSelectorFields_Manual_BoomLengthFragment" | "SaleCraneSelectorFields_Manual_CounterweightFragment" | "SaleCraneSelectorFields_Manual_JibLengthFragment" | "SaleCraneSelectorFields_Manual_MaxWeightFragment" | "SaleCraneSelectorFields_Manual_OffsetAngleFragment" | "SaleCraneSelectorFields_Manual_RadiusFragment" | "SaleCraneSelectorFields_VehicleIdFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"AdditionalCranesFields_AdditionalCranesManualCollectionFragment">;
  readonly " $fragmentType": "JobEquipment_useCraneSelectorManualFavoriteFragment";
};
export type JobEquipment_useCraneSelectorManualFavoriteFragment$key = {
  readonly " $data"?: JobEquipment_useCraneSelectorManualFavoriteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobEquipment_useCraneSelectorManualFavoriteFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobEquipment_useCraneSelectorManualFavoriteFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdditionalCranesFields_AdditionalCranesManualCollectionFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ManualConfigurationInfo",
      "kind": "LinkedField",
      "name": "userInput",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BoomConfigurationSnapshot",
          "kind": "LinkedField",
          "name": "boomConfiguration",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "preparationHours",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleCraneSelectorFields_CraneCapacityFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleCraneSelectorFields_EquipmentKindFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleCraneSelectorFields_CraneConfigurationKindFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleCraneSelectorFields_Manual_BoomConfigurationFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleCraneSelectorFields_VehicleIdFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleCraneSelectorFields_Manual_BoomLengthFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleCraneSelectorFields_Manual_JibLengthFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleCraneSelectorFields_Manual_CounterweightFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleCraneSelectorFields_Manual_OffsetAngleFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleCraneSelectorFields_Manual_RadiusFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleCraneSelectorFields_Manual_MaxWeightFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "JobEquipment_useResetBoomConfigurationFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ManualConfigurationInternal",
  "abstractKey": null
};

(node as any).hash = "1a458161a9f39e31875924ced109cc2a";

export default node;
