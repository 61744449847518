import { Suspense, useCallback } from 'react';
import { DetailsLayout, EmptyLayout, SidebarContentProps } from '../../layout/Layouts';
import { DataID, useFragment, useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Navigate, useParams } from 'react-router';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { NavigationMenu } from '../../layout/SidebarDrawer';
import { FormProvider } from '../../common/utils/forms';
import { FormSectionContent, FormSectionHeader, FormSectionHeader_Title } from '../../layout/FormLayout';
import { AccessoryRuleSaveButton } from './AccessoryRuleSaveButton';
import { indexedPageTitle, usePageTitle } from '../../common/hooks/usePageTitle';
import { RequireAdmin } from '../../auth/Authorization';
import { ErrorBanner, ErrorStateProvider } from '../../common/components/ErrorBanner';
import { AccessoryRuleDeleteButton } from './AccessoryRuleDeleteButton';
import { usePreventNavigationOnFormDirty } from '../../common/hooks/usePreventNavigationOnFormDirty';
import { DetailsPageErrorBoundary } from '../../layout/DetailsPageErrorBoundary';
import { DetailsPageRootErrorBoundary } from '../../layout/DetailsPageRootErrorBoundary';
import { ElementNotFoundError } from '../../common/exceptions/ElementNotFoundError';
import { ElementNotFoundErrorBoundary } from '../../layout/ElementNotFoundErrorBoundary';
import {
  AccessoryBillingCodesAutomaticGrid,
  accessoryRuleDetailsFormContext,
  useFieldBaseQuestions,
  useFieldCapacities,
  useFieldClients,
  useFieldDescription,
  useFieldEquipmentKinds,
  useFieldNatureOfWorks,
  useFieldNatureOfWorkSubCategories,
  useFieldSaleKinds,
  useFieldSaleStages,
} from './AccessoryRuleFields';
import { AccessoryRuleDetailsPageRootQuery } from './__generated__/AccessoryRuleDetailsPageRootQuery.graphql';
import { AccessoryRuleDetailsPage_FormFragment$key } from './__generated__/AccessoryRuleDetailsPage_FormFragment.graphql';
import { AccessoryRuleDetailsPage_ConditionsFragment$key } from './__generated__/AccessoryRuleDetailsPage_ConditionsFragment.graphql';
import { EmployeeNotFoundErrorBoundary } from '../../auth/EmployeeNotFoundErrorBoundary';
import { AccessoryRuleDetailsPage_EffectsFragment$key } from './__generated__/AccessoryRuleDetailsPage_EffectsFragment.graphql';
import { AccessoryRuleDetailsPage_InformationFragment$key } from './__generated__/AccessoryRuleDetailsPage_InformationFragment.graphql';

const flagName = 'app_navigation_configuration';

export function AccessoryRuleDetailsPage() {
  const { t } = useAmbientTranslation();
  const params = useParams<{ id: DataID }>();
  const id = params.id ?? 'new';
  const heading = id === 'new' ? t('newTitle') : t('editTitle', { id });

  return (
    <DetailsPageErrorBoundary heading={heading} flagName={flagName}>
      <Suspense fallback={<EmptyLayout />}>
        <ErrorStateProvider>
          <EmployeeNotFoundErrorBoundary>
            <ElementNotFoundErrorBoundary sidebar$key={null} detailsLayout$key={null} heading={heading} flagName={flagName}>
              <AccessoryRuleDetailsPageRoot />
            </ElementNotFoundErrorBoundary>
          </EmployeeNotFoundErrorBoundary>
        </ErrorStateProvider>
      </Suspense>
    </DetailsPageErrorBoundary>
  );
}

function AccessoryRuleDetailsPageRoot() {
  const { t } = useAmbientTranslation();
  usePageTitle(indexedPageTitle('sidebar.billingCodeRules'));
  const params = useParams<{ id: DataID }>();
  const id = params.id ?? 'new';

  const $data = useLazyLoadQuery<AccessoryRuleDetailsPageRootQuery>(
    graphql`
      query AccessoryRuleDetailsPageRootQuery($id: ID!, $isNew: Boolean!) {
        ...AuthorizationAdminFragment
        ...SidebarDrawerFragment
        ...LayoutsDetailsLayoutFragment
        ...DetailsPageRootErrorBoundaryFragment
        accessoryRule(id: $id) @skip(if: $isNew) {
          ...AccessoryRuleDetailsPage_FormFragment
          eTag
        }
      }
    `,
    { id, isNew: id === 'new' },
    { fetchPolicy: 'store-and-network' },
  );

  if (id !== 'new' && $data.accessoryRule == null) {
    throw new ElementNotFoundError('AccessoryRule', id, '/billing-code-rules/accessory-rules');
  }

  const sidebar = useCallback((props: SidebarContentProps) => <NavigationMenu {...props} $key={$data} />, [$data]);
  const heading = id === 'new' ? t('newTitle') : t('editTitle', { id });

  return (
    <DetailsPageRootErrorBoundary heading={heading} flagName={flagName} $key={$data}>
      <RequireAdmin $key={$data} fallback={<Navigate to='..' replace />}>
        <FormProvider key={$data.accessoryRule?.eTag ?? id} context={accessoryRuleDetailsFormContext}>
          <DetailsLayout
            heading={heading}
            flagName={flagName}
            sidebarProvider={sidebar}
            $key={$data}
            actions={
              <>
                <AccessoryRuleDeleteButton id={id} />
                <AccessoryRuleSaveButton id={id} />
              </>
            }>
            <ErrorBanner />
            <AccessoryRuleDetailsPage_Form $key={$data.accessoryRule} />
          </DetailsLayout>
        </FormProvider>
      </RequireAdmin>
    </DetailsPageRootErrorBoundary>
  );
}

function AccessoryRuleDetailsPage_Form({ $key }: { $key: AccessoryRuleDetailsPage_FormFragment$key | null | undefined }) {
  const { t } = useAmbientTranslation();
  usePreventNavigationOnFormDirty([accessoryRuleDetailsFormContext]);

  const $data = useFragment(
    graphql`
      fragment AccessoryRuleDetailsPage_FormFragment on AccessoryRule {
        ...AccessoryRuleDetailsPage_InformationFragment
        ...AccessoryRuleDetailsPage_ConditionsFragment
        ...AccessoryRuleDetailsPage_EffectsFragment
      }
    `,
    $key,
  );

  return (
    <>
      <FormSectionHeader label={<FormSectionHeader_Title label={t('fields.label.information')} />} />
      <FormSectionContent>
        <AccessoryRule_Information $key={$data} />
      </FormSectionContent>
      <FormSectionHeader label={<FormSectionHeader_Title label={t('fields.label.conditions')} />} />
      <FormSectionContent>
        <AccessoryRule_Conditions $key={$data} />
      </FormSectionContent>
      <FormSectionHeader label={<FormSectionHeader_Title label={t('fields.label.accessories')} />} />
      <FormSectionContent>
        <AccessoryRule_Effects $key={$data} />
      </FormSectionContent>
    </>
  );
}

function AccessoryRule_Information({ $key }: { $key: AccessoryRuleDetailsPage_InformationFragment$key | null | undefined }) {
  const $data = useFragment(
    graphql`
      fragment AccessoryRuleDetailsPage_InformationFragment on AccessoryRule {
        ...AccessoryRuleFields_DescriptionFragment
      }
    `,
    $key,
  );

  const { renderDescription } = useFieldDescription($data);
  return renderDescription();
}

function AccessoryRule_Conditions({ $key }: { $key: AccessoryRuleDetailsPage_ConditionsFragment$key | null | undefined }) {
  const $data = useFragment(
    graphql`
      fragment AccessoryRuleDetailsPage_ConditionsFragment on AccessoryRule {
        ...AccessoryRuleFields_SaleStagesFragment
        ...AccessoryRuleFields_SaleKindsFragment
        ...AccessoryRuleFields_CapacitiesFragment
        ...AccessoryRuleFields_EquipmentKindsFragment
        ...AccessoryRuleFields_ClientsFragment
        ...AccessoryRuleFields_NatureOfWorkFragment
        ...AccessoryRuleFields_NatureOfWorkSubCategoriesFragment
        ...AccessoryRuleFields_BaseQuestions
      }
    `,
    $key,
  );

  const { renderSaleStages } = useFieldSaleStages($data);
  const { renderSaleKinds } = useFieldSaleKinds($data);
  const { renderCapacities } = useFieldCapacities($data);
  const { renderEquipmentKinds } = useFieldEquipmentKinds($data);
  const { renderClient } = useFieldClients($data);
  const { renderNatureOfWorks } = useFieldNatureOfWorks($data);
  const { renderNatureOfWorkSubCategories } = useFieldNatureOfWorkSubCategories($data);
  const { renderBaseQuestions } = useFieldBaseQuestions($data);

  return (
    <>
      {renderSaleStages()}
      {renderSaleKinds()}
      {renderCapacities()}
      {renderEquipmentKinds()}
      {renderClient()}
      {renderNatureOfWorks()}
      {renderNatureOfWorkSubCategories()}
      {renderBaseQuestions()}
    </>
  );
}

function AccessoryRule_Effects({ $key }: { $key: AccessoryRuleDetailsPage_EffectsFragment$key | null | undefined }) {
  const $data = useFragment(
    graphql`
      fragment AccessoryRuleDetailsPage_EffectsFragment on AccessoryRule {
        ...AccessoryRuleFields_AutomaticGridFragment
      }
    `,
    $key,
  );

  return <AccessoryBillingCodesAutomaticGrid $key={$data} />;
}
