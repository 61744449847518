/**
 * @generated SignedSource<<de0caf5ed8f4cb8ea93cacac8553296b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccessoryLinesFields_ItemFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryLinesFields_ItemQueryFragment">;
  readonly " $fragmentType": "AccessoryLinesFields_ItemFragment";
};
export type AccessoryLinesFields_ItemFragment$key = {
  readonly " $data"?: AccessoryLinesFields_ItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryLinesFields_ItemFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccessoryLinesFields_ItemFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccessoryLinesFields_ItemQueryFragment"
    }
  ],
  "type": "ISale",
  "abstractKey": "__isISale"
};

(node as any).hash = "28500da675b890c1588d48cdb3539bb2";

export default node;
