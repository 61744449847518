/**
 * @generated SignedSource<<8dc2ba07c0d44d822387d2d41bdb6d11>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as equipmentKindLookupLabelResolverType } from "../../../__resolvers__/EquipmentKindLookupLabelResolver";
// Type assertion validating that `equipmentKindLookupLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(equipmentKindLookupLabelResolverType satisfies (
  rootKey: EquipmentKindLookupLabelResolver$key,
) => Record<string, string> | null | undefined);
export type AccessoryRuleFields_EquipmentKindsFragment$data = {
  readonly conditions: {
    readonly equipmentKindLookups: ReadonlyArray<{
      readonly code: number;
      readonly id: string;
      readonly label: Record<string, string> | null | undefined;
    }>;
  };
  readonly " $fragmentType": "AccessoryRuleFields_EquipmentKindsFragment";
};
export type AccessoryRuleFields_EquipmentKindsFragment$key = {
  readonly " $data"?: AccessoryRuleFields_EquipmentKindsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryRuleFields_EquipmentKindsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccessoryRuleFields_EquipmentKindsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccessoryConditionsInternal",
      "kind": "LinkedField",
      "name": "conditions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EquipmentKindLookup",
          "kind": "LinkedField",
          "name": "equipmentKindLookups",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "code",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "fragment": {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EquipmentKindLookupLabelResolver"
              },
              "kind": "RelayResolver",
              "name": "label",
              "resolverModule": require('./../../../__resolvers__/EquipmentKindLookupLabelResolver').label,
              "path": "conditions.equipmentKindLookups.label"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AccessoryRule",
  "abstractKey": null
};

(node as any).hash = "4d8db1648aa9a83432ea1199675ccc03";

export default node;
