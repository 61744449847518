/**
 * @generated SignedSource<<1df9bb1af6388cc4a4cc0a21a26ccbe7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccessoryRuleDetailsPage_FormFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryRuleDetailsPage_ConditionsFragment" | "AccessoryRuleDetailsPage_EffectsFragment" | "AccessoryRuleDetailsPage_InformationFragment">;
  readonly " $fragmentType": "AccessoryRuleDetailsPage_FormFragment";
};
export type AccessoryRuleDetailsPage_FormFragment$key = {
  readonly " $data"?: AccessoryRuleDetailsPage_FormFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryRuleDetailsPage_FormFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccessoryRuleDetailsPage_FormFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccessoryRuleDetailsPage_InformationFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccessoryRuleDetailsPage_ConditionsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccessoryRuleDetailsPage_EffectsFragment"
    }
  ],
  "type": "AccessoryRule",
  "abstractKey": null
};

(node as any).hash = "d83c8013d124f9e582a68d2b6f2a6e10";

export default node;
