import { Navigate, Route } from 'react-router';
import { TabAddendumTemplates } from './TabAddendumTemplates';
import { TabCompetitors } from './TabCompetitors';
import { TabEnums } from './TabEnums';
import { AddendumTemplateDetailsPage } from './AddendumTemplate/AddendumTemplateDetailsPage';
import { EmptyLayout } from '../layout/Layouts';
import { TabRateStrategies } from './TabRateStrategies';
import { RateStrategyDetails_Page } from './RateStrategies/RateStrategyDetails_Page';

export const configurationRoutes = (
  <Route path='configuration' lazy={() => import('./ConfigurationPage')}>
    <Route
      index
      element={
        <EmptyLayout>
          <Navigate to='addenda' replace />
        </EmptyLayout>
      }
    />
    <Route path='addenda' element={<TabAddendumTemplates />} />
    <Route path='addenda/:id' element={<AddendumTemplateDetailsPage />} />
    <Route path='competitors' element={<TabCompetitors />} />
    <Route path='enums' element={<TabEnums />} />
    <Route path='rate-strategies' element={<TabRateStrategies />} />
    <Route path='rate-strategies/:id' element={<RateStrategyDetails_Page />} />
  </Route>
);
