import { Box, InputAdornment, SelectChangeEvent, Typography } from '@mui/material';
import Qty from 'js-quantities';
import { ForwardedRef, forwardRef, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UnitInput } from './UnitInput';
import { isDecimalNumber } from '../utils/numberUtils';
import { NumberInput } from './NumberInput';
import { useEffectEvent } from '../utils/effectUtils';
import { defaultLogger, Logger } from '../utils/logging';
import { _throw } from '../utils/_throw';

const logger = new Logger(defaultLogger, 'LengthInput', () => new Date().toISOString());

interface Props {
  value: string | null;
  enabledUnits: readonly LengthUnit[];
  label?: string;
  onChange: (val: string | null) => void;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  className?: string;
  'data-label-key'?: string;
}

export const lengthUnits = ['ft', 'in', 'ftin', 'mm', 'cm', 'm', 'km'] as const;
export type LengthUnit = (typeof lengthUnits)[number];

function isLengthUnit(val: unknown): val is LengthUnit {
  return lengthUnits.includes(val as LengthUnit);
}

const serializeValue = (scalar: number | undefined, footInchScalar: number | undefined, unit: LengthUnit) => {
  if (scalar == null && footInchScalar == null) {
    return null;
  }
  return unit === 'ftin' ? `${footInchScalar || 0} ft ${scalar || 0} in` : `${scalar} ${unit}`;
};

export const parseFtIn = (value: string): { feet: number; inches: number } | null => {
  const [feetStr, ft, inchesStr, in_] = value.trim().split(' ');
  if (feetStr && ft === 'ft' && inchesStr && in_ === 'in') {
    const feet = parseFloat(feetStr);
    const inches = parseFloat(inchesStr);
    if (!Number.isNaN(feet) && !Number.isNaN(inches)) {
      return { feet, inches };
    }
  }
  return null;
};

export const LengthInput = forwardRef(function LengthInput(
  { value, enabledUnits, label, required, disabled, onChange: handleChange, error, className, 'data-label-key': dataLabelKey }: Props,
  ref: ForwardedRef<HTMLTextAreaElement | HTMLInputElement>,
) {
  const defaultUnit = enabledUnits[0] ?? _throw('Empty enabledUnits. At least one unit must be enabled.');

  const [scalar, setScalar] = useState<number | undefined>(undefined);
  const [footInchScalar, setFootInchScalar] = useState<number | undefined>(undefined);
  const [unit, setUnit] = useState<LengthUnit>(defaultUnit);
  const { t } = useTranslation('common');

  const setState = useCallback((ftinSclr: typeof footInchScalar, sclr: typeof scalar, u: typeof unit) => {
    setFootInchScalar(ftinSclr);
    setScalar(sclr);
    setUnit(u);
  }, []);

  const syncState = useEffectEvent(() => {
    if (!value) {
      // Since serializeValue()'s return value is empty whenever the scalar is empty, when we get an empty value,
      // default to the current unit to avoid resetting the unit needlessly every time the scalar is cleared, unless it
      // isn't included in the enabled units anymore, in which case default to the first enabled unit.
      return setState(undefined, undefined, enabledUnits.includes(unit) ? unit : defaultUnit);
    }

    const ftIn = parseFtIn(value);
    if (ftIn) {
      return setState(ftIn.feet, ftIn.inches, 'ftin');
    }

    const quantity = Qty.parse(value);
    if (!quantity) {
      logger.error("Couldn't parse value", value);
      return setState(undefined, undefined, defaultUnit);
    }

    const qtyUnit = quantity.units();
    if (!isLengthUnit(qtyUnit)) {
      logger.error('Detected unit is not a valid Length unit', qtyUnit);
      return setState(undefined, undefined, defaultUnit);
    }

    if (!enabledUnits.includes(qtyUnit)) {
      logger.error('Detected unit is not enabled', qtyUnit, enabledUnits);
      return setState(undefined, undefined, defaultUnit);
    }

    return setState(undefined, quantity.scalar, qtyUnit);
  });

  useEffect(syncState, [syncState, value, enabledUnits]);

  const handleUnitChange = useCallback(
    (event: SelectChangeEvent) => {
      const newUnit = isLengthUnit(event.target.value) ? event.target.value : defaultUnit;
      setUnit(newUnit);
      handleChange(serializeValue(scalar, footInchScalar, newUnit));
    },
    [defaultUnit, footInchScalar, handleChange, scalar],
  );

  const handleFootInchScalarChange = useCallback(
    (newValue: number | null, _: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (newValue != null && !Number.isNaN(newValue)) {
        setFootInchScalar(newValue);
        handleChange(serializeValue(scalar, newValue, unit));
      } else {
        setFootInchScalar(undefined);
        handleChange(serializeValue(scalar, undefined, unit));
      }
    },
    [handleChange, scalar, unit],
  );

  const handleScalarChange = useCallback(
    (newValue: number | null, _: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (newValue != null && !Number.isNaN(newValue)) {
        setScalar(newValue);
        handleChange(serializeValue(newValue, footInchScalar, unit));
      } else {
        setScalar(undefined);
        handleChange(serializeValue(undefined, footInchScalar, unit));
      }
    },
    [footInchScalar, handleChange, unit],
  );

  const handleBlur = useCallback(() => {
    if (scalar != null) {
      const { intValue } = isDecimalNumber(scalar);
      setScalar(intValue);
      handleChange(serializeValue(intValue, footInchScalar, unit));

      if (scalar < 0) {
        setScalar(0);
        handleChange(serializeValue(0, footInchScalar, unit));
      }
    }
  }, [footInchScalar, handleChange, scalar, unit]);

  const handleFootBlur = useCallback(() => {
    if (footInchScalar != null) {
      const { intValue } = isDecimalNumber(footInchScalar);
      setFootInchScalar(intValue);
      handleChange(serializeValue(scalar, intValue, unit));

      if (footInchScalar < 0) {
        setFootInchScalar(0);
        handleChange(serializeValue(scalar, 0, unit));
      }
    }
  }, [footInchScalar, handleChange, scalar, unit]);

  return (
    <Box display='flex' gap={1} data-label-key={dataLabelKey}>
      {unit === 'ftin' && (
        <DualUnitAdditionalField
          required={required}
          fieldLabel={label}
          error={error}
          onBlur={handleFootBlur}
          footInchScalar={footInchScalar}
          onChange={handleFootInchScalarChange}
          disabled={disabled}
        />
      )}
      <UnitInput<LengthUnit>
        ref={ref}
        supportedUnits={enabledUnits}
        onScalarChange={handleScalarChange}
        onUnitChange={handleUnitChange}
        fieldLabel={unit !== 'ftin' ? label : ''}
        onBlur={handleBlur}
        scalar={scalar}
        error={error}
        disabled={disabled}
        required={required}
        unit={unit}
        renderMenuValue={(v: LengthUnit) => t('unit.length.short.' + v)}
        renderAdornmentValue={(v: LengthUnit) => (v === 'ftin' ? t('unit.length.short.in') : t('unit.length.short.' + v))}
        className={className}
      />
    </Box>
  );
});

interface DualUnitProps {
  fieldLabel?: string;
  footInchScalar: number | undefined;
  onBlur: (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onChange: (value: number | null, event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  className?: string;
}

export const DualUnitAdditionalField = forwardRef(function DualUnitAdditionalField(
  { fieldLabel, footInchScalar, onChange: handleChange, required, onBlur: handleBlur, disabled, error, className }: DualUnitProps,
  ref: ForwardedRef<HTMLTextAreaElement | HTMLInputElement>,
) {
  const { t } = useTranslation('common');

  return (
    <NumberInput
      ref={ref}
      data-testid='unitTextBox'
      label={fieldLabel}
      value={footInchScalar ?? null}
      onBlur={handleBlur}
      onChange={handleChange}
      disabled={disabled}
      error={error}
      required={required}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <Typography>{t('unit.length.short.ft')}</Typography>
          </InputAdornment>
        ),
      }}
      className={className}
    />
  );
});
