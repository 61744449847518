/**
 * @generated SignedSource<<a1e887a330372cd6639c047af441e15d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CostLinesFields_ItemFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CostLinesFields_ItemQueryFragment">;
  readonly " $fragmentType": "CostLinesFields_ItemFragment";
};
export type CostLinesFields_ItemFragment$key = {
  readonly " $data"?: CostLinesFields_ItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CostLinesFields_ItemFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CostLinesFields_ItemFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CostLinesFields_ItemQueryFragment"
    }
  ],
  "type": "ISale",
  "abstractKey": "__isISale"
};

(node as any).hash = "f250fb4a576757fe7e4b23533773ece5";

export default node;
