import { createFieldKey, createFormContext, SetValueFn, useField, useFieldMapper } from '../common/utils/forms';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { ChangeEvent, useCallback } from 'react';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { TextField, TextFieldProps } from '@mui/material';
import { WorkPlanningFields_NotesFragment$key } from './__generated__/WorkPlanningFields_NotesFragment.graphql';
import { nullIfEmpty } from '../common/utils/formUtils';

export interface WorkPlanningFieldsMappings {}

export const workPlanningFormContext = createFormContext<WorkPlanningFieldsMappings>();

const fieldNotesKey = createFieldKey<string>();

export function useFieldNotes($key: WorkPlanningFields_NotesFragment$key | null | undefined, disabled: boolean) {
  const $data = useFragment(
    graphql`
      fragment WorkPlanningFields_NotesFragment on WorkPlanningProjectInternal {
        notes
      }
    `,
    $key,
  );

  const [notes, setNotes] = useField(workPlanningFormContext, fieldNotesKey, $data?.notes ?? '');

  const useMapper = useFieldMapper(workPlanningFormContext, fieldNotesKey);
  useMapper((v) => ({ project: { notes: nullIfEmpty(v) } }), [], 'save');

  const renderNotes = useCallback(() => {
    return <NotesInput value={notes} setValue={setNotes} disabled={disabled} />;
  }, [disabled, setNotes, notes]);

  return { notes, setNotes, renderNotes };
}

function NotesInput({ value, setValue, disabled }: { value: string; setValue: SetValueFn<string>; disabled: boolean }) {
  const { t } = useAmbientTranslation();

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(e.target.value);
    },
    [setValue],
  );

  // HACK: Workaround a crash in ResizeObserver in MUI code: https://github.com/mui/base-ui/issues/167
  const workaroundTextFieldResizeObserverCrash: TextFieldProps = {
    // Need to use a fix row count to prevent height recalculation due to content changes.
    rows: 5,
    // Need to force visibility of vertical scrollbar to prevent height recalculation when it becomes visible otherwise.
    sx: { '& textarea': { overflowY: 'scroll !important' } },
  };

  return (
    <TextField
      multiline
      {...workaroundTextFieldResizeObserverCrash}
      label={t('field.project.date.notes')}
      data-label-key='field.project.date.notes'
      value={value}
      onChange={handleChange}
      disabled={disabled}
    />
  );
}
