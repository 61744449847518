/**
 * @generated SignedSource<<f770c2c9e4ce467a16efa70ee3b6119d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SalesRatesRequestInput = {
  arrivalDate: string;
  billingInfos: ReadonlyArray<SalesRatesBillingInfoRequestInput>;
  clientId: string;
  dispatchBranchId: string;
  equipments: ReadonlyArray<SalesRatesEquipmentRequestInput | null | undefined>;
  natureOfWorkCode: number;
  worksiteId?: string | null | undefined;
};
export type SalesRatesBillingInfoRequestInput = {
  billingCodeId?: string | null | undefined;
  craneIndex?: number | null | undefined;
  itemTypeCode?: number | null | undefined;
};
export type SalesRatesEquipmentRequestInput = {
  boomConfigurationId?: string | null | undefined;
  vehicleId?: string | null | undefined;
};
export type CostLinesFields_Item_SalesRateQuery$variables = {
  salesRateInput: SalesRatesRequestInput;
  skip: boolean;
};
export type CostLinesFields_Item_SalesRateQuery$data = {
  readonly salesRates?: {
    readonly " $fragmentSpreads": FragmentRefs<"CostLinesFields_useQuantityRateFromSalesRateFragment">;
  };
};
export type CostLinesFields_Item_SalesRateQuery = {
  response: CostLinesFields_Item_SalesRateQuery$data;
  variables: CostLinesFields_Item_SalesRateQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "salesRateInput"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "salesRateInput"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CostLinesFields_Item_SalesRateQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "SalesRatesResponse",
            "kind": "LinkedField",
            "name": "salesRates",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CostLinesFields_useQuantityRateFromSalesRateFragment"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CostLinesFields_Item_SalesRateQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "SalesRatesResponse",
            "kind": "LinkedField",
            "name": "salesRates",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "KeyedSalesRatesResults",
                "kind": "LinkedField",
                "name": "results",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "craneIndex",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SalesRateEnvelope",
                    "kind": "LinkedField",
                    "name": "salesRates",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "canEditRate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SalesRate",
                        "kind": "LinkedField",
                        "name": "value",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isFlexiblePrice",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isAnnualContract",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "minimumQuantity",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "price",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SalesRateError",
                        "kind": "LinkedField",
                        "name": "error",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "code",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "b7e0ec60417b9b8a00b5a90d09545170",
    "id": null,
    "metadata": {},
    "name": "CostLinesFields_Item_SalesRateQuery",
    "operationKind": "query",
    "text": "query CostLinesFields_Item_SalesRateQuery(\n  $salesRateInput: SalesRatesRequestInput!\n  $skip: Boolean!\n) {\n  salesRates(input: $salesRateInput) @skip(if: $skip) {\n    ...CostLinesFields_useQuantityRateFromSalesRateFragment\n  }\n}\n\nfragment CostLinesFields_useQuantityRateFromSalesRateFragment on SalesRatesResponse {\n  results {\n    craneIndex\n    salesRates {\n      canEditRate\n      value {\n        isFlexiblePrice\n        isAnnualContract\n        minimumQuantity\n        price\n        createdAt\n      }\n      error {\n        code\n        description\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "82d663619be8085268fb050c542e8285";

export default node;
