/**
 * @generated SignedSource<<4a22e8c2ca3434f0e9325229f9c34d13>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccessoryRuleFields_NatureOfWorkSubCategoriesFragment$data = {
  readonly conditions: {
    readonly natureOfWorkSubCategories: ReadonlyArray<string>;
  };
  readonly " $fragmentType": "AccessoryRuleFields_NatureOfWorkSubCategoriesFragment";
};
export type AccessoryRuleFields_NatureOfWorkSubCategoriesFragment$key = {
  readonly " $data"?: AccessoryRuleFields_NatureOfWorkSubCategoriesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryRuleFields_NatureOfWorkSubCategoriesFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccessoryRuleFields_NatureOfWorkSubCategoriesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccessoryConditionsInternal",
      "kind": "LinkedField",
      "name": "conditions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "natureOfWorkSubCategories",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AccessoryRule",
  "abstractKey": null
};

(node as any).hash = "35eef8bea96220d77c7238aae7b36ea9";

export default node;
