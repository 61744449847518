/**
 * @generated SignedSource<<5a2465b03ff142d88b4473f062a37619>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TabAccessoryRulesFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AuthorizationAdminFragment" | "LayoutsListLayoutFragment" | "SidebarDrawerFragment">;
  readonly " $fragmentType": "TabAccessoryRulesFragment";
};
export type TabAccessoryRulesFragment$key = {
  readonly " $data"?: TabAccessoryRulesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TabAccessoryRulesFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TabAccessoryRulesFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AuthorizationAdminFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SidebarDrawerFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LayoutsListLayoutFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "b26fa654e07efd94372fbbe79544b4cd";

export default node;
