import { Suspense, useCallback } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Skeleton, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { useLazyLoadQuery } from 'react-relay';
import { ConfigurationTabs } from './ConfigurationTabs';
import { ListLayout, SidebarContentProps } from '../layout/Layouts';
import { BillingCodeForm } from './tabEnumsSections/BillingCodeForm';
import { NatureOfWorkForm } from './tabEnumsSections/NatureOfWorkForm';
import graphql from 'babel-plugin-relay/macro';
import { TabEnumsQuery } from './__generated__/TabEnumsQuery.graphql';
import { RequireAdmin, UnauthorizedFallback } from '../auth/Authorization';
import { useOutletContext } from 'react-router';
import { ConfigurationPageQuery$data } from './__generated__/ConfigurationPageQuery.graphql';
import { NavigationMenu } from '../layout/SidebarDrawer';
import { ErrorBanner } from '../common/components/ErrorBanner';

export function TabEnums() {
  const { t } = useTranslation('configuration');
  const $data = useOutletContext<ConfigurationPageQuery$data>();
  const sidebar = useCallback((props: SidebarContentProps) => <NavigationMenu {...props} $key={$data} />, [$data]);
  const flagName = 'app_navigation_configuration';

  return (
    <RequireAdmin
      $key={$data}
      fallback={
        <ListLayout heading={t('configuration')} flagName={flagName} sidebarProvider={sidebar} $key={$data}>
          <UnauthorizedFallback />
        </ListLayout>
      }>
      <ListLayout heading={t('configuration')} flagName={flagName} sidebarProvider={sidebar} $key={$data}>
        <ErrorBanner />
        <ConfigurationTabs tab='enums'></ConfigurationTabs>

        <Suspense fallback={<SectionsSkeleton />}>
          <Sections />
        </Suspense>
      </ListLayout>
    </RequireAdmin>
  );
}

function Sections() {
  const { t } = useTranslation('configuration');

  const query = useLazyLoadQuery<TabEnumsQuery>(
    graphql`
      query TabEnumsQuery {
        ...BillingCodeFormFragment
        ...NatureOfWorkFormFragment
      }
    `,
    {},
    { fetchPolicy: 'store-and-network' },
  );

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{t('enums.billingCode.title')}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <BillingCodeForm fragmentKey={query} />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{t('enums.natureOfWork.title')}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <NatureOfWorkForm fragmentKey={query} />
        </AccordionDetails>
      </Accordion>
    </>
  );
}

function SectionsSkeleton() {
  return (
    <>
      <Skeleton variant='rectangular' width='100%' height='25rem' sx={{ my: '2rem' }} />
      <Skeleton variant='rectangular' width='100%' height='30rem' />
    </>
  );
}
